import React, { useEffect, useState, memo } from "react";
import { useHistory } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { DateFormatter } from "../../../../app/modules/Dashboard/helper/DateFormatter";
import {
  getreward,
  getColor,
} from "../../../../app/modules/Dashboard/helper/helper";
import { getCampaignType, productCategoryName } from "../../../../app/modules/CampaignV2/helperLabel";
import { getCurrencySymbol } from "../../../../app/modules/CampaignDetails/helper/helper";

export const nFormatter = (num) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 100000) {
    return (num / 100000).toFixed(1).replace(/\.0$/, "") + "L";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "k";
  }
  //   if (num <= 1000) {
  //     return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  //  }
  return num;
};

const SideBarCampaigns = () => {
  const [campaigns, setCampaigns] = useState([]);
  const history = useHistory();

  const { entities } = useSelector(
    (state) => ({
      entities: state.campaign.entities,
    }),
    shallowEqual
  );

  useEffect(() => {
    entities?.length > 0 && setCampaigns(entities?.slice(0, 3));
  }, [entities]);

  return (
    <div className="card-header border-0 p-0">
      <h3 className="card-title font-weight-SemiBold mb-4">Campaigns</h3>
      <div className="card-body p-0 row">
        {campaigns &&
          campaigns.map((item, index) => (
            <div className="col-md-12" key={index}>
              <div
                className="d_card campaign_card w-100 d-flex align-content-between flex-wrap cursor-pointer" onClick={() =>
                  history.push(`/campaigns/list/${item?.campaignId}/view`)
                }
              >
                <div class="w-100 thumb_img min-h-175px" style={{
                  backgroundImage: item?.productImageUrl
                    ? "url(" +
                      `${process.env.REACT_APP_AWS_S3_BASE_URL +
                        item?.productImageUrl}` +
                      ")"
                    : "url(/media/dummyImages/no_preview_v2.png)",
                }}>
                </div>
                <div className="col-12 px-4 d-flex justify-content-end campaign_cat">
                  <span
                    className="label label-outline-success label-pill label-inline label_dark"
                    style={{ color: getColor(item.productCategory) }}
                  >
                    {(item.campaignCategory)}
                  </span>
                </div>
                <div className="col-12 px-4">
                <p className="w-100 mb-1 text-dark text-truncate font-weight-SemiBold"  title={item?.campaignName}>{item?.campaignName}</p>

                  {/* <p
                    className="w-100 mb-1 text-dark text-truncate font-weight-SemiBold"
                    title={getCampaignType(item.campaignName)}
                  >
                    {getCampaignType(item.campaignName)}
                  </p> */}
                  <div className="d-flex d-flex align-items-center">
                    <div className="flex-grow-1">
                      <div className="d-flex align-items-center mb-0">
                        <div className="symbol symbol-30 symbol-circle symbol-light-success mr-2">
                          <span
                            className="symbol-label"
                            style={{
                              backgroundImage: item?.brandDetail?.profilePic
                                ? "url(" +
                                  `${process.env.REACT_APP_AWS_S3_BASE_URL +
                                    process.env
                                      .REACT_APP_S3_USER_PROFILE_FOLDER +
                                    "/" +
                                    item?.brandDetail?.profilePic}` +
                                  ")"
                                : "url(/media/dummyImages/no_preview.png)",
                            }}
                          >
                            {/* <img src={process.env.REACT_APP_AWS_S3_BASE_URL +
                                                        process.env.REACT_APP_S3_USER_PROFILE_FOLDER +
                                                        "/" +
                                                        item?.brandDetail?.profilePic} className="h-75 align-self-end rounded-circle" alt="" /> */}
                          </span>
                        </div>
                        <div className="d-flex flex-column flex-grow-1 font-weight-bold">
                          <a
                            href="#"
                            className="text-dark mb-0 font-size-sm text-truncate"
                          >
                            {item.brandDetail?.companyName}
                          </a>
                          <span className="text-dark font-size-xs">
                            <img
                              class="mr-1 h-10px mt-n1"
                              src="/media/svg/icons/custom/Icon-calendar.svg"
                            />{" "}
                            <span class="opacity-50">
                              {DateFormatter(item.campaignClosureTime)}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex-shrink-0">
                      {item.rewardModel === "PRODUCT" && (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="group-edit-tooltip">
                              {item.rewardProductDescription}
                            </Tooltip>
                          }
                        >
                          <span className="svg-icon svg-icon-md svg-icon-secondary">
                            {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} /> */}
                            <a
                              href="#"
                              class="btn bg-custom-primary btn-pill btn-sm"
                            >
                              Gift
                            </a>
                          </span>
                        </OverlayTrigger>
                      )}

                      {item.rewardModel === "OFFER" && (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="group-edit-tooltip">
                              {item.rewardProductDescription}
                            </Tooltip>
                          }
                        >
                          <span className="svg-icon svg-icon-md svg-icon-secondary">
                            {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} /> */}
                            <a
                              href="#"
                              class="btn bg-custom-primary btn-pill btn-sm"
                            >
                              Offer
                            </a>
                          </span>
                        </OverlayTrigger>
                      )}

                      {/* <span className="number text-theme font-weight-Bold font-size-h3">
                        {item.rewardModel === "CASH" && (
                          <img
                            class="mt-n1"
                            src="/media/custom/Icon_rupee_sign.svg"
                          />
                        )}{" "}
                        {item.rewardModel === "CASH" && getreward(item.reward)}
                      </span> */}
                      {item?.rewardModel === "CASH" && (
                        <span className="number text-theme font-weight-Bold font-size-h3">
                          {getCurrencySymbol(item?.productCurrency) +
                            nFormatter(item?.productValue)}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        <div className="col-md-12">
          <div
            className="d_card d_last w-100 d-flex align-content-between flex-wrap"
            onClick={() => {
              history.push("/campaigns/list");
            }}
          >
            <button className="btn btn-light btn-sm ">
              <span class="more_text text-hover-primary">View more</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(SideBarCampaigns);
