import axios from "axios";
// eslint-disable-next-line no-unused-vars
const baseURL = process.env.REACT_APP_API_URL,
  // apiVersion = "v1",
  inboxFetchApiPath = "v1/chat/chatManagement/listInbox",
  messagesFetchApiPath = "v1/chat/chatManagement/listMessages",
  sendMessageApiPath = "v1/chat/chatManagement/sendChat",
  inboxFetchApiAction = "chatManagementListInbox",
  getCampaignDetails = "v1/creator/campaigns/details",
  // applicationDetailsFetchApiPath =
  //   "/creator/campaign/applications/getapplicationDetails",
  messagesFetchApiAction = "chatManagementListMessages",
  getUserDataForChat = "v1/chat/chatManagement/getUserDataForChat",
  sendMessageApiAction = "chat.chatManagement.sendChat",
  trackingPriority = 0;

export const fetchChatInbox = (
  inboxUserId,
  page = 1,
  pageSize = 10,
  loggedInUserId
) => {
  return axios.post(inboxFetchApiPath, {
    trackingPriority,
    frontendAction: inboxFetchApiAction,
    loggedInUserId,
    inboxUserId,
    page,
    pageSize,
  });
};

export const fetchChatMessages = (
  fromUserId,
  toUserId,
  page = 1,
  pageSize = 10,
  campaignId
) => {
  return axios.post(messagesFetchApiPath, {
    trackingPriority,
    frontendAction: messagesFetchApiAction,
    fromUserId,
    loggedInUserId: fromUserId,
    toUserId,
    page,
    pageSize,
    campaignId,
    sort: "-createdAt",
  });
};

export const sendNewChat = (queryParams) => {
  return axios.post(sendMessageApiPath, {
    loggedInUserId: queryParams.loggedInUserId,
    trackingPriority,
    frontendAction: sendMessageApiAction,
    messageFromUser: queryParams.fromUserId,
    messageToUser: queryParams.toUserId,
    messageValue: queryParams.messageContent,
    campaignId: queryParams.campaignId,
    messageType: queryParams.messageType,
    messageInfo: "",
    roomName: "",
  });
};

export function fetchCampaignDetails(userId, campaignId) {
  return axios.post(getCampaignDetails, {
    loggedInUserId: userId,
    campaignId: campaignId,
    frontendAction: "creator.campaigns.details",
    trackingPriority: 0,
    fields: ["campaignName"],
  });
}

export function getUserData(params) {
  return axios.post(getUserDataForChat, {
    ...params,
    frontendAction: "chat.chatManagement.getUserDataForChat",
    trackingPriority: 0,
    fields: ["profilePic", "firstName", "lastName","companyName"],
  });
}

// export function getApplicationDetail(userId){
//   const applicationId=localStorage.getItem("applicationId")
//   return axios.post(baseURL + apiVersion +applicationDetailsFetchApiPath, {
//     loggedInUserId:userId,
//     trackingPriority: 0,
//   frontendAction: "creator.campaign.applications.getapplicationDetails",
//   applicationId:applicationId

//   });
// }
