import axios from "axios";

export const ADD_TO_PORTFOLIO =
"v1/creator/portfolio/addToPortfolio";
export const DELETE_PORTFOLIO =
"v1/creator/portfolio/deletePortfolioContents";
export const LIST_PORTFOLIO="v1/creator/portfolio/listPortfolioContents"

// export function generateMediaUploadUrl(params) {
//     return axios.post(GENERATE_MEDIA_FILE_URL, {
//       ...params,
//       frontendAction:
//         "creator.campaign.application.contents.generateMediaUploadUrl",
//       trackingPriority: 0,
//     });
//   }

  export function addToPortfolio(params) {
    return axios.post(ADD_TO_PORTFOLIO, {
      ...params,
      frontendAction:
        "creator.portfolio.addToPortfolio",
      trackingPriority: 0,
    });
  }

  export function getPortfolio(params){
    return axios.post(LIST_PORTFOLIO, {
      ...params,
      frontendAction: "creator.portfolio.listPortfolioContents",
      trackingPriority:0,
      sort:"-updatedAt"
    });
  }

  export function deleteToPortfolio(params) {
    return axios.post(DELETE_PORTFOLIO, {
      ...params,
      frontendAction:
        "creator.portfolio.deletePortfolioContents",
      trackingPriority: 0,
    });
  }
