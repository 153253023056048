import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  listNotifications,
  updateUnreadNotificationCount,
} from "../../extras/dropdowns/notifications/_redux/NotificationActions";
import * as actions from "../../../../../app/modules/_redux/commonActions";
import { setRightSideBar } from "../../../../../app/modules/_redux/commonActions";

function HeaderUserDetails({ setComponent }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    profileToEdit,
    viewSideBar,
    loggedInUserId,
    unreadNotificationCounts,
    isRightSidebar,
  } = useSelector((state) => ({
    profileToEdit: state.common.profileToEdit,
    viewSideBar: state.common.isVisibleSideBar,
    loggedInUserId: state.auth.user.userId,
    unreadNotificationCounts: state.notificationModule.unreadNotificationCount,
    isRightSidebar: state.common.isRightSidebar,
  }));

  const hideSideBar = () => {
    dispatch(actions.viewSideBar(false));
  };

  const listNotificationData = () => {
    dispatch(
      listNotifications({
        page: 1,
        pageSize: 50,
        loggedInUserId,
        appInfo: "CREATOR",
      })
    ).then(() => dispatch(updateUnreadNotificationCount(0)));
  };

  return (
    <div>
      <div class={` ${!isRightSidebar ? "sidebar-header flex-column-auto pt-0 pb-0 px-5 pl-lg-6 pr-6 mr-6 d-md-flex" : "sidebar-header flex-column-auto pt-5 pb-0 px-5 px-lg-10 d-md-flex"}`}
      >
      <span
        class={` ${!isRightSidebar ? "collapse_card slide_card active  d-none" : "collapse_card d-none"}`}  onClick={() => dispatch(setRightSideBar(!isRightSidebar))}>
        <img src="/media/svg/icons/custom/arrow_right.svg" />
      </span>
        <div class="d-flex w-100">
          <div class="quick-search quick-search-inline flex-grow-1">
            <div class="d-flex align-items-center mb-0">
              <div class="symbol symbol-50 flex-shrink-0 mr-2 cursor-pointer">
                <img
                  class="bg-white-o-80 symbol-label"
                  onClick={() => {
                    history.push("/user-profile/personal-information");
                    viewSideBar && hideSideBar();
                  }}
                  src={
                    profileToEdit?.profilePic
                          ? `${process.env.REACT_APP_AWS_S3_BASE_URL +
                              process.env.REACT_APP_S3_USER_PROFILE_FOLDER +
                              "/" +
                              profileToEdit?.profilePic}`
                          : "/media/dummyImages/no_preview.png"
                  }
                  style={{
                    objectFit: "cover",
                    border: "1px solid #dbdbdb63"
                  }}
                  alt="photo"
                />
              </div>
              <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                <a
                  onClick={() => {
                    history.push("/user-profile/personal-information");
                    viewSideBar && hideSideBar();
                  }}
                  class="text-dark text-hover-primary mb-0 font-size-md font-weight-bolder cursor-pointer"
                >
                  {profileToEdit?.companyName}
                </a>
                <span class="text-dark-50 font-size-xs">15 Apr 2022</span>
              </div>
            </div>
          </div>
          <div class="dropdown dropdown-inline">
            <a
              href="#"
              class="btn btn-icon btn-light-primary  ml-3 h-40px w-40px flex-shrink-0"
              onClick={() => {
                setComponent("notification");
                listNotificationData();
                dispatch(actions.setRightSideBar(true));
                // viewSideBar && hideSideBar();
              }}
            >
              <span class="svg-icon svg-icon-lg">
                <img
                  class="h-20px"
                  src="/media/svg/icons/custom/notifications-outline.svg"
                />
              </span>
              {unreadNotificationCounts && unreadNotificationCounts !== 0 ? (
                <>
                  <span className="pulse-ring"></span>
                  <span className="position-absolute badge badge-circle badge-sm badge-primary badge_user">
                    {unreadNotificationCounts && unreadNotificationCounts}
                  </span>
                </>
              ) : null}
            </a>
          </div>
          <div class="dropdown dropdown-inline">
            <a
              href="#"
              class="btn btn-icon btn-light-primary  ml-3 h-40px w-40px flex-shrink-0"
              onClick={() => {
                setComponent("messageInbox");
                dispatch(actions.setRightSideBar(true));
              }}
            >
              <span class="svg-icon svg-icon-lg">
                <img
                  class="h-20px"
                  src="/media/svg/icons/custom/message-square.svg"
                />
              </span>
            </a>
          </div>
          <div class="dropdown dropdown-inline view_mob">
            <a
              href="#"
              class="btn btn-icon btn-light  ml-3 h-40px w-40px flex-shrink-0"
              onClick={() => {
                hideSideBar();
              }}
            >
              <span class="svg-icon svg-icon-lg">
                <img
                  class="h-20px"
                  src="/media/svg/icons/custom/Icon_close.svg"
                />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderUserDetails;
