import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { customersSlice } from "../app/modules/ECommerce/_redux/customers/customersSlice";
import { productsSlice } from "../app/modules/ECommerce/_redux/products/productsSlice";
import { remarksSlice } from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import { specificationsSlice } from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";
import { ordersSlice } from "../app/modules/Orders/_redux/order-info/ordersSlice";
import { headerSlice } from "../_metronic/layout/components/header/_redux/HeaderSlice";
import { snackbarSlice } from "./slices/snackbar/snackbarSlice";
import { userAccessSlice } from "../app/modules/UserAccess/_redux/userAccessSlice";
import { campaignSlice } from "../app/modules/campaign/_redux/campaignSlice";
import { applicationSlice } from "../app/modules/campaignApplications/_redux/applicationSlice";
import { rewardSlice } from "../app/modules/reward/_redux/rewardSlice";
import { commonSlice } from "../app/modules/_redux/commonSlice";
import { ChatModuleSlice } from "../app/modules/ChatModule/_redux/ChatModuleSlice";
import { gallerySlice } from "../app/modules/gallery/_redux/gallerySlice";
import { postAnalyticsSlice } from "../app/modules/Post-Analytics/_redux/postAnalyticsSlice";
import {paymentHistorySlice} from "../app/modules/PaymentHistory/_redux/PaymentHistorySlice"
import { notificationSlice } from "../_metronic/layout/components/extras/dropdowns/notifications/_redux/NotificationSlice";
import {portfolioSlice} from "../app/modules/Portfolio/_redux/portfolioSlice"
import { creatorInviteeSlice } from "../app/modules/UserProfile/creator-send-invitee-dialog/_redux/creatorInviteSlice";
import { bankSlice } from "../app/modules/UserProfile/bankAccount/_redux/bankSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  orders: ordersSlice.reducer,
  header: headerSlice.reducer,
  products: productsSlice.reducer,
  userAccess: userAccessSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  snackbar: snackbarSlice.reducer,
  campaign: campaignSlice.reducer,
  application: applicationSlice.reducer,
  reward: rewardSlice.reducer,
  common: commonSlice.reducer,
  chatModule: ChatModuleSlice.reducer,
  gallery: gallerySlice.reducer,
  postAnalytics: postAnalyticsSlice.reducer,
  paymentHistory:paymentHistorySlice.reducer,
  notificationModule:notificationSlice.reducer,
  portfolio:portfolioSlice.reducer,
  creatorInvitation: creatorInviteeSlice.reducer,
  bank: bankSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
