// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";
import { parseISO } from "date-fns";
import { format } from "date-fns";

export function DateFormatter(cellContent) {
  let formattedDate = "";
  if (cellContent) {
    let date = parseISO(cellContent);
    formattedDate = format(date, "do MMM yyyy").toString();
  }
  return <span>{formattedDate.includes("th")&& formattedDate.replace("th",'') }
  {formattedDate.includes("st")&& formattedDate.replace("st",'') }
  {formattedDate.includes("nd")&& formattedDate.replace("nd",'') }
  {formattedDate.includes("rd")&& formattedDate.replace("rd",'') }
  </span>;
}
