import {createSlice} from "@reduxjs/toolkit";

const initialBranchState = {
  entities: undefined,
};

export const headerSlice = createSlice({
  name: "header",
  initialState: initialBranchState,
  reducers: {
  
   // findbranch
    branchFetched: (state, action) => {
      const data  = action.payload;
      state.entities = data;
    },
   
   
   
  }
});
