import * as requestFromServer from "./campaignCrud";
import { campaignSlice, callTypes } from "./campaignSlice";
import { Toaster } from "../../../components/Toaster";
import Swal from "sweetalert2";

const { actions } = campaignSlice;
// const rewadrModel= (data) => {
//   switch (data) {
//       case "CASH":
//       return "INR";
//       case "USD":
//       return "$";
//     default:
//       return data;
//   }
// };
export const listCampaigns = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getCampaigns(queryParams)
    .then((response) => {
      const { total: totalCount, rows, totalPages } = response.data.data;
      let campaigns = [];
      rows.forEach((item) => {
        const { productValue, productCurrency, ...rest } = item;
        campaigns.push({
          ...rest,
          productValue,
          productCurrency,
          reward: productValue + " " + productCurrency,
        });
      });
      // campaigns = campaigns.filter(campaigns => campaigns.creatorAppliedCount === 0);
      dispatch(actions.campaignsFetched({ totalCount, campaigns, totalPages }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find campaigns";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const listSpecificCampaigns = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .getSpecificCampaigns(queryParams)
    .then((response) => {
      const { total: totalCount, rows } = response.data.data;
      let campaigns = [];
      rows.forEach((item) => {
        const { productValue, productCurrency, ...rest } = item;
        campaigns.push({
          ...rest,
          reward: productValue + " " + productCurrency,
        });
      });
      dispatch(actions.campaignsFetched({ totalCount, campaigns }));
      // dispatch(requestFromServer.addQrCodeCount({
      //   loggedInUserId:queryParams.loggedInUserId,
      //   brandId:queryParams.query.brandId,
      // }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find campaigns";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const searchCampaigns = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .searchCampaigns(queryParams)
    .then((response) => {
      const { total: totalCount, rows } = response.data.data;
      let campaigns = [];
      rows.forEach((item) => {
        const { productValue, productCurrency, ...rest } = item;
        campaigns.push({
          productValue,
          productCurrency,
          ...rest,
          reward: productValue + " " + productCurrency,
        });
      });
      campaigns = campaigns.filter(
        (campaigns) => campaigns.creatorAppliedCount === 0
      );
      dispatch(actions.campaignsFetched({ totalCount, campaigns }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find campaigns";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const searchBrands = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.search }));
  return requestFromServer
    .searchBrands(queryParams)
    .then((response) => {
      dispatch(actions.brandsFetched({ brands: response.data.data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find brands";
      dispatch(actions.catchError({ error, callType: callTypes.search }));
    });
};

export const searchBrandsToEmpty = (queryParams) => (dispatch) => {
  dispatch(actions.brandsFetched({ brands: [] }));
};

export const getCampaignsByBrandId = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getCampaignsByBrandId(queryParams)
    .then((response) => {
      const { total: totalCount, rows } = response.data.data;
      let campaigns = [];
      rows.forEach((item) => {
        const { productValue, productCurrency, ...rest } = item;
        campaigns.push({
          productValue,
          productCurrency,
          ...rest,
          reward: productValue + " " + productCurrency,
        });
      });
      // campaigns = campaigns.filter(
      //   (campaigns) => campaigns.creatorAppliedCount === 0
      // );
      dispatch(actions.campaignsFetched({ totalCount, campaigns }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find campaigns";
      dispatch(actions.catchError({ error, callType: callTypes.search }));
    });
};

export const getCampaignDetails = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getCampaignDetails(queryParams)
    .then((response) => {
      const campaignDetails = response.data.data;
      dispatch(actions.campaignDetailsFetched({ campaignDetails }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find campaigns";
      dispatch(actions.campaignDetailsFetched({ campaignDetails: {} }));
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getPublicCampaignDetails = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getPublicCampaignDetails(queryParams)
    .then((response) => {
      const campaignDetails = response.data.data;
      dispatch(actions.campaignDetailsFetched({ campaignDetails }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find campaigns";
      dispatch(actions.campaignDetailsFetched({ campaignDetails: {} }));
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getApplicationDetails = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getApplicationDetails(queryParams)
    .then((response) => {
      const { rows: applicationDetails } = response.data.data;
      dispatch(actions.applicationDetailsFetched({ applicationDetails }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find campaigns";
      dispatch(actions.applicationDetailsFetched({ campaignDetails: {} }));
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const listCampaignApplications = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .listCampaignApplications(queryParams)
    .then((response) => {
      const { total: totalCount, rows: applications } = response.data.data;
      dispatch(actions.applicationsFetched({ totalCount, applications }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find campaign applications";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const applyForCampaign = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .applyForCampaign(queryParams)
    .then((response) => {
      Toaster({
        message:
          response.data.data.campaignType === "RELATION_POST"
            ? "Successfully applied for the campaign, now post the content on your social media profiles as per the brand requirements and then upload the post URL here"
            : "Successfully applied for the campaign, we will update you when brand approves your application request ",
        variant: "dark",
      });
      dispatch(actions.callSuccess({ callType: callTypes.action }));
      return response.data;
    })
    .catch((error) => {
      error.clientMessage = "Can't find campaigns";
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response.data.message,
      });
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const rejectCampaign = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .rejectCampaign(queryParams)
    .then((response) => {
      Toaster({ message: response?.data?.message, variant: "dark" });
      dispatch(actions.callSuccess({ callType: callTypes.action }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find campaigns";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const setWaitForAdminApproval = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .setWaitForAdminApproval(queryParams)
    .then((response) => {
      // Toaster({ message: response?.data?.message, variant: "success" });
      dispatch(actions.callSuccess({ callType: callTypes.action }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find campaigns";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getApplicationContent = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getApplicationContent(queryParams)
    .then((response) => {
      let { total: contentCount, rows: content } = response.data.data;
      dispatch(actions.applicationContentFetched({ content, contentCount }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find Application Contents";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const addQrCodeCount = (queryParams) => (dispatch) => {
  dispatch(
    requestFromServer.addQrCodeCount(queryParams).then((response) => {})
  ).catch((error) => {
    console.log("error while adding qr code count-", error);
  });
};

export const clearAllCampaignDetails = () => (dispatch) => {
  dispatch(actions.clearDetailsOfCampaign());
};
