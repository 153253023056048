import React, { memo } from "react";
import { Modal } from "react-bootstrap";

const CommonModal = ({
  modalTitle,
  modalBody,
  show,
  close,
  htmlRenderRequired,
  modalScroll,
  modalSize='xl'
}) => {
  const handleClose = () => close();
  return (
    <Modal show={show} onHide={handleClose} size={modalSize} scrollable={true}>
      <Modal.Header closeButton>
        <Modal.Title><b>{modalTitle}</b></Modal.Title>
      </Modal.Header>
      {htmlRenderRequired ? (
        <Modal.Body
          className="modal-body pt-0 mx-auto mt-3 sun-editor-editable sun-editor-view card_form"
          dangerouslySetInnerHTML={{
            __html: modalBody?.replace(
              // eslint-disable-next-line no-control-regex
              new RegExp("\n", "g"),
              "<br/>"
            ),
          }}
        ></Modal.Body>
      ) : (
        <Modal.Body className="modal-body pt-0 mx-auto text-center mt-3">
          {modalBody}
        </Modal.Body>
      )}
      <Modal.Footer>
        <button class="btn btn-primary" onClick={handleClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};


export default memo(CommonModal);
