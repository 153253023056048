/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from "react";
import { getConvertedApplicationStatus } from "../../helper/helper";

const ApplicationDetails = ({ stepper, applicationDetails }) => {
  return (
    <div
      class={`wizard-body py-8 px-8 py-lg-20 px-lg-10 ${
        stepper === "applicationDetails" ? "" : "d-none"
      }`}
    >
      <div className="row">
        <div className="w-100">
          <div
            className={`card  ${
              applicationDetails?.applicationStatus === "APPROVED_BY_BRAND"
                ? "bg-light-success"
                : "bg-light-danger"
            } rounded p-5 mb-5`}
          >
            <div class="flex-grow-1">
              <div class="d-flex align-items-center mb-0">
                <div class="symbol symbol-30 symbol-danger mr-2">
                  <a
                    href="#"
                    class={`btn  ${
                      applicationDetails?.applicationStatus ===
                      "APPROVED_BY_BRAND"
                        ? "bg-success"
                        : "bg-danger"
                    } mr-2 h-40px w-40px p-0 pt-3`}
                  >
                    <img
                      class="mr-0 h-20px"
                      src="/media/svg/icons/custom/Icon_edit.svg"
                      alt="edit-icon"
                    />
                  </a>
                </div>
                <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                  <a
                    href="#"
                    class={`${
                      applicationDetails?.applicationStatus ===
                      "APPROVED_BY_BRAND"
                        ? "text-success"
                        : "text-danger"
                    } mb-0 font-weight-bolder  font-size-lg text-truncate`}
                  >
                    {getConvertedApplicationStatus(
                      applicationDetails?.applicationStatus
                    )}
                  </a>
                  <span class="text-dark font-size-sm">Application Status</span>
                </div>
              </div>
              {/* {applicationDetails?.rejectedReason && (
                <p className="quotes mb-0 font-size-sm mt-5 font-weight-bold">
                  {applicationDetails?.rejectedReason}
                </p>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ApplicationDetails);
