import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  error: null,
  totalCount: 0,
  totalPages: 1,
  entities: null,
  currentId: null,
  fetchedRating: null,

  campaignDetails: {},
  applicationDetails: [],

  contentFirstStage: {},

  applicationContents: null,
  contentCount: 0,
  productPhotographyElements: [],
  lifestyleProductInActionElements: [],
  portraitStylePhotographyElements: [],
  actionProductInUseElements: [],
  unboxingExplanationVideoElements: [],
  productReviewTestimonialVideoElements: [],
  graphicDesignElements: [],
  promotionalVideoElements: [],
  contentHistory: [],
  galleryContents: [],

  mediaURL: "",
  contentMediaUrl: "",
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const applicationSlice = createSlice({
  name: "application",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
        state.currentId = action.payload.currentId;
      }
    },
    callSuccess: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    ratingFetched: (state, action) => {
      state.fetchedRating = action.payload;
      state.actionsLoading = false;
      state.error = null;
    },

    contentFirstStageRecieved: (state, action) => {
      state.contentFirstStage = action.payload;
      state.actionsLoading = false;
      state.error = null;
    },

    applicationsFetched: (state, action) => {
      let { applications, totalCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = applications;
      state.totalCount = totalCount;
    },

    contentsFetched: (state, action) => {
      let { contents, totalCount, contentHistory } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.contentHistory = contentHistory;
      state.applicationContents = contents;
      state.contentCount = totalCount;
    },

    galleryFetched: (state, action) => {
      let { contents, totalCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.galleryContents = contents;
      state.contentCount = totalCount;
    },

    campaignDetailsFetched: (state, action) => {
      let { campaignDetails } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.campaignDetails = campaignDetails;
    },

    updateProductPhotography: (state, action) => {
      let { productPhotographyElements } = action.payload;
      state.listLoading = true;
      state.error = null;
      state.productPhotographyElements = [
        ...state.productPhotographyElements,
        productPhotographyElements,
      ];
    },

    updateLifestyleProductInAction: (state, action) => {
      let { lifestyleProductInActionElements } = action.payload;
      state.listLoading = true;
      state.error = null;
      state.lifestyleProductInActionElements = [
        ...state.lifestyleProductInActionElements,
        lifestyleProductInActionElements,
      ];
    },
    updatePortraitStylePhotography: (state, action) => {
      let { portraitStylePhotographyElements } = action.payload;
      state.listLoading = true;
      state.error = null;
      state.portraitStylePhotographyElements = [
        ...state.portraitStylePhotographyElements,
        portraitStylePhotographyElements,
      ];
    },
    updateActionProductInUse: (state, action) => {
      let { actionProductInUseElements } = action.payload;
      state.listLoading = true;
      state.error = null;
      state.actionProductInUseElements = [
        ...state.actionProductInUseElements,
        actionProductInUseElements,
      ];
    },

    updateUnboxingExplanationVideo: (state, action) => {
      let { unboxingExplanationVideoElements } = action.payload;
      state.listLoading = true;
      state.error = null;
      state.unboxingExplanationVideoElements = [
        ...state.unboxingExplanationVideoElements,
        unboxingExplanationVideoElements,
      ];
    },

    updateProductReviewTestimonialVideo: (state, action) => {
      let { productReviewTestimonialVideoElements } = action.payload;
      state.listLoading = true;
      state.error = null;
      state.productReviewTestimonialVideoElements = [
        ...state.productReviewTestimonialVideoElements,
        productReviewTestimonialVideoElements,
      ];
    },

    updateGraphicDesign: (state, action) => {
      let { graphicDesignElements } = action.payload;
      state.listLoading = true;
      state.error = null;
      state.graphicDesignElements = [
        ...state.graphicDesignElements,
        graphicDesignElements,
      ];
    },

    updatePromotionalVideo: (state, action) => {
      let { promotionalVideoElements } = action.payload;
      state.listLoading = true;
      state.error = null;
      state.promotionalVideoElements = [
        ...state.promotionalVideoElements,
        promotionalVideoElements,
      ];
    },

    removeProductPhotography: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.productPhotographyElements = state.productPhotographyElements.filter(
        (productPhotography) =>
          productPhotography.applicationContentId !== action.payload
      );
    },

    removeLifestyleProductInAction: (state, action) => {
      // state.listLoading = true;
      state.error = null;
      state.lifestyleProductInActionElements = state.lifestyleProductInActionElements.filter(
        (lifestyleProductInAction) =>
          lifestyleProductInAction.applicationContentId !== action.payload
      );
    },

    removePortraitStylePhotography: (state, action) => {
      // state.listLoading = true;
      state.error = null;
      state.portraitStylePhotographyElements = state.portraitStylePhotographyElements.filter(
        (portraitStylePhotography) =>
          portraitStylePhotography.applicationContentId !== action.payload
      );
    },

    removeActionProductInUse: (state, action) => {
      // state.listLoading = true;
      state.error = null;
      state.actionProductInUseElements = state.actionProductInUseElements.filter(
        (actionProductInUse) =>
          actionProductInUse.applicationContentId !== action.payload
      );
    },

    removeUnboxingExplanationVideo: (state, action) => {
      // state.listLoading = true;
      state.error = null;
      state.unboxingExplanationVideoElements = state.unboxingExplanationVideoElements.filter(
        (unboxingExplanationVideo) =>
          unboxingExplanationVideo.applicationContentId !== action.payload
      );
    },

    removeProductReviewTestimonialVideo: (state, action) => {
      // state.listLoading = true;
      state.error = null;
      state.productReviewTestimonialVideoElements = state.productReviewTestimonialVideoElements.filter(
        (productReviewTestimonial) =>
          productReviewTestimonial.applicationContentId !== action.payload
      );
    },

    removeGraphicDesign: (state, action) => {
      // state.listLoading = true;
      state.error = null;
      state.graphicDesignElements = state.graphicDesignElements.filter(
        (graphicDesign) => graphicDesign.applicationContentId !== action.payload
      );
    },

    removePromotionalVideo: (state, action) => {
      // state.listLoading = true;
      state.error = null;
      state.promotionalVideoElements = state.promotionalVideoElements.filter(
        (promotionalVideo) =>
          promotionalVideo.applicationContentId !== action.payload
      );
    },

    clearProductPhotography: (state) => {
      // state.listLoading = true;
      state.error = null;
      state.productPhotographyElements = [];
    },

    clearLifestyleProductInAction: (state) => {
      // state.listLoading = true;
      state.error = null;
      state.lifestyleProductInActionElements = [];
    },

    clearPortraitStylePhotography: (state) => {
      // state.listLoading = true;
      state.error = null;
      state.portraitStylePhotographyElements = [];
    },

    clearActionProductInUse: (state) => {
      // state.listLoading = true;
      state.error = null;
      state.actionProductInUseElements = [];
    },

    clearUnboxingExplanationVideo: (state) => {
      // state.listLoading = true;
      state.error = null;
      state.unboxingExplanationVideoElements = [];
    },

    clearProductReviewTestimonialVideo: (state) => {
      // state.listLoading = true;
      state.error = null;
      state.productReviewTestimonialVideoElements = [];
    },

    clearGraphicDesign: (state) => {
      // state.listLoading = true;
      state.error = null;
      state.graphicDesignElements = [];
    },

    clearPromotionalVideo: (state) => {
      // state.listLoading = true;
      state.error = null;
      state.promotionalVideoElements = [];
    },

    TrackingDetailsFetched: (state, action) => {
      let { trackingDetails } = action.payload;
      state.trackingDetails = trackingDetails;
    },

    downloadMediaUrlFetched: (state, action) => {
      let { url, contentMediaUrl } = action.payload;
      state.mediaURL = url;
      state.contentMediaUrl = contentMediaUrl;
      state.listLoading = false;
      state.error = null;
    },

    clearDetailsOfCampaign: (state, action) => {
      state.applicationContents = [];
      state.applicationDetails = [];
      state.galleryContents = [];
      state.campaignDetails = {};
      state.entities = null;
      state.totalCount = 0;
    },
  },
});
