import * as requestFromServer from "./commonCrud";
import { commonSlice, callTypes } from "./commonSlice";
import { Toaster } from "../../components/Toaster";

const { actions } = commonSlice;

export const getSignedUrl = (signedUrlDataForCreation) => (dispatch) => {
  let { file, ...rest } = signedUrlDataForCreation;

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getSignedUrl({ ...rest })
    .then((response) => {
      console.log("res>>>>signed>>>>1st", response);
      return requestFromServer
        .signedUpload(response?.data?.data.signed_request, file)
        .then((res) => {
          dispatch(actions.signedUrlFetched(res.statusText));
          dispatch(actions.callSuccess({ callType: callTypes.action }));
          return response;
        })
        .catch((err) => {
          dispatch(actions.catchError({ callType: callTypes.action }));
        });
    })
    .catch((error) => {
      dispatch(actions.catchError({ callType: callTypes.action }));
      return error;
    });
};

export const uploadAllFileToS3 = (signedUrlDataForCreation) => (dispatch) => {
  let { file, ...rest } = signedUrlDataForCreation;

  // dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getSignedUrl({ ...rest })
    .then((response) => {
      console.log("res>>>>signed>>>>1st", response);
      return requestFromServer
        .signedUpload(response?.data?.data.signed_request, file)
        .then((res) => {
          // dispatch(actions.signedUrlFetched(res.statusText));
          // dispatch(actions.callSuccess({ callType: callTypes.action }));
          return response;
        })
        .catch((err) => {
          return err;
          // dispatch(actions.catchError({ callType: callTypes.action }));
        });
    })
    .catch((error) => {
      dispatch(actions.catchError({ callType: callTypes.action }));
      return error;
    });
};

export const clearSignedUrlFetched = () => (dispatch) => {
  dispatch(actions.signedUrlFetched(""));
};

export const getCreatorProfile = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getCreatorProfile(queryParams)
    .then((response) => {
      let profileToEdit = response.data.data;
      let { areasOfIneterst } = profileToEdit;
      let data = areasOfIneterst
        ? areasOfIneterst.map((item, index) => {
            return { value: index + 1, label: item };
          })
        : "";
      profileToEdit.areasOfIneterst = data;
      let firstName = response.data.data.firstName
        ? response.data.data.firstName
        : "";
      let lastName = response.data.data.lastName
        ? response.data.data.lastName
        : "";
      profileToEdit.fullName = `${firstName} ${lastName}`;
      dispatch(actions.creatorProfileFetched(profileToEdit));
    })
    .catch((error) => {
      error.clientMessage = "Can't fetch profile";
      Toaster({ message: error?.response?.data?.message, variant: "dark" });
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const updateCreatorProfile = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCreatorProfile(queryParams)
    .then((response) => {
      Toaster({ message: "Profile updated successfully", variant: "dark" });
      dispatch(actions.callSuccess({ callType: callTypes.action }));
      return queryParams?.profilePic;
    })
    .catch((error) => {
      error.clientMessage = "Can't update profile";
      Toaster({ message: error?.response?.data?.message, variant: "dark" });
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const changePassword = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .changePassword(queryParams)
    .then((response) => {
      Toaster({ message: response?.data?.message, variant: "dark" });
      dispatch(actions.callSuccess({ callType: callTypes.action }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update profile";
      Toaster({ message: error?.response?.data?.message, variant: "dark" });
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getProfilePicture = (queryParams) => (dispatch) => {
  const profileToEdit = { profilePic: queryParams };
  dispatch(actions.creatorProfileFetched(profileToEdit));
};

export const createSocket = (queryParams) => (dispatch) => {
  dispatch(actions.createSocket(queryParams));
};

export const startGetTokenDataLoader = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.web }));
};

export const endGetTokenDataLoader = (queryParams) => (dispatch) => {
  dispatch(actions.callSuccess({ callType: callTypes.web }));
};

export const getCreatorRating = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getCreatorRating(queryParams)
    .then((response) => {
      dispatch(actions.ratingFetched(response.data.data[0]));
    })
    .catch((error) => {
      error.clientMessage = "Can't fetch rating";
      dispatch(actions.ratingFetched(0));
      // Toaster({ message: error?.response?.data?.message, variant: "error" });
      // dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const updateNumber = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateNumber(queryParams)
    .then((response) => {
      // Toaster({
      //   message: "Mobile number updated successfully",
      //   variant: "dark",
      // });
      dispatch(actions.callSuccess({ callType: callTypes.action }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update profile";
      Toaster({
        message: "Mobile number already exist",
        variant: "dark",
      });
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateEmail = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateEmail(queryParams)
    .then((response) => {
      Toaster({
        message: "Verification link send successfully",
        variant: "dark",
      });
      dispatch(actions.callSuccess({ callType: callTypes.action }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update profile";
      Toaster({
        message: error?.response?.data?.data?.message,
        variant: "dark",
      });
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const verifyEmail = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .verifyBrandEmail(queryParams)
    .then((response) => {
      Toaster({ message: "email updated successfully", variant: "dark" });
      dispatch(actions.callSuccess({ callType: callTypes.action }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update profile";
      console.log("error", error?.response?.data);
      Toaster({ message: error?.response?.data?.message, variant: "dark" });
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const verifyNumber = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .verifyCreatorNumber(queryParams)
    .then((response) => {
      Toaster({
        message: "mobile number updated successfully",
        variant: "dark",
      });
      dispatch(actions.callSuccess({ callType: callTypes.action }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update profile";
      Toaster({ message: error?.response?.data?.message, variant: "dark" });
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const viewSideBar = (queryParams) => (dispatch) => {
  dispatch(actions.isVisibleSideBar(queryParams));
};

export const viewModalInfo = (queryParams) => (dispatch) => {
  dispatch(actions.isVisibleModalInfo(queryParams));
};

export const setRightSideBar = (queryParams) => (dispatch) => {
  dispatch(actions.setRightSidebar(queryParams));
};

