import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import CampaignDetailView from "../CampaignDetails/CampaignDetailView";
import CampaignCardV2 from "./CampaignCardV2";

export default function CampaignCardPageV2() {
  return (
        // <Suspense fallback={<LayoutSplashScreen />}>
          <Switch>
          <Route path="/campaigns/list" component={CampaignCardV2} exact={true} />
          <Route path="/campaigns/list/:campaignId/view" component={CampaignDetailView} exact={true} />
          {/* <Route path="/portfolio/upload" component={UploadPortfolio} /> */}
          {/* <Route path="/portfolio/videoUpload" component={UploadVideoPortfolio} /> */}
          {/* <Route path="/messagings" component={ChatInbox} /> */}
        </Switch>
          
        // </Suspense>
  )
}
