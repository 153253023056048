import React, { memo, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import useFileDownloader from "../../campaignApplications/pages/application-content-downloader/useFileDownloader";
import VideoSnapshot from "video-snapshot";

const ChatFile = ({ file, type }) => {
  const [fileType, setFiletype] = useState();
  const [downloadFile, downloaderComponentUI] = useFileDownloader();
  const download = (file) => downloadFile(file);
  const [showModal, setShowModal] = useState(false);
  const [preview, setPreview] = useState("");
  const handleClose = () => {
    setShowModal(false);
  };

  const getPreview = async () => {
    switch (type) {
      case "M_IMAGE":
        setPreview(`url('${process.env.REACT_APP_AWS_S3_BASE_URL + file}')`);
        break;
      case "M_VIDEO":
        await fetch(`${process.env.REACT_APP_AWS_S3_BASE_URL + file}`)
          .then((response) => response.blob())
          .then(async (blob) => {
            const snap = new VideoSnapshot(blob);
            const img = await snap.takeSnapshot(3);
            setPreview(`url('${img}')`);
          });
        break;
      case "M_FILE":
        setPreview("url(/media/dummyImages/PDF_no_prview.png)");
        break;
      default:
        setPreview("url(/media/dummyImages/no_preview_v2.png)");
    }
  };

  useEffect(() => {
    getPreview();
  }, [file]);
  return (
    <>
      <div
        class="d_card d_gallery_view d-flex align-content-between flex-wrap cursor-pointer"
        style={{
          width: 250,
          height: 150,
          backgroundSize: "cover",
          backgroundImage: preview,
        }}
        onClick={() => {
          if (type === "M_FILE") {
            window.open(process.env.REACT_APP_AWS_S3_BASE_URL + file);
          } else {
            setShowModal(true);
          }
          setFiletype(file);
        }}
      >
        {type === "M_VIDEO" && (
          <div class="w-100 h-100 d-flex justify-content-center align-items-center">
            <img
              style={{ width: "25%", resize: "contain" }}
              src="/media/svg/icons/Media/DVD.svg"
            />
          </div>
        )}
        <div class="d-flex justify-content-end mr-2">
          {downloaderComponentUI}
        </div>
      </div>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Body>
          <div className=" d-flex justify-content-center">
            <div
              class="p-1 rounded-circle d-flex justify-content-center align-items-center"
              onClick={() => {
                setShowModal(false);
              }}
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                width: 25,
                height: 25,
                backgroundColor: "#c0c0c0",
              }}
            >
              <img
                alt="icon-view"
                style={{ width: 8, height: 8, resizeMode: "contain" }}
                src={toAbsoluteUrl("/media/svg/icons/custom/Icon_close.svg")}
              />
            </div>
            <div>
              {fileType?.endsWith(".png") ||
              fileType?.endsWith(".jpg") ||
              fileType?.endsWith(".svg") ||
              fileType?.endsWith(".jpeg") ||
              fileType?.endsWith(".jfif") ? (
                <img
                  alt="content-file"
                  id="Media"
                  className="w-100 h-auto"
                  src={process.env.REACT_APP_AWS_S3_BASE_URL + file}
                />
              ) : (
                <video
                  className="iframe-resize w-100 h-auto"
                  title="Media"
                  id="Media"
                  // width="450"
                  // height="350"
                  src={process.env.REACT_APP_AWS_S3_BASE_URL + file}
                  frameBorder={0}
                  allowFullScreen
                  controls
                  controlsList="nodownload"
                />
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            class="p-3 d-flex flex-row align-items-center bg-primary"
            onClick={() => {
              download({
                file: process.env.REACT_APP_AWS_S3_BASE_URL + file,
                filename: file + "_" + Math.floor(Math.random() * 1000000 + 1),
              });
            }}
            style={{ borderRadius: 5 }}
          >
            <img
              alt="icon-view"
              style={{
                filter: "invert(100%)",
              }}
              src={toAbsoluteUrl("/media/svg/icons/custom/Icon_download.svg")}
            />
            <div class="text-white ml-4">Download</div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default memo(ChatFile);
