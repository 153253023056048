import * as requestFromServer from "./portfolioCrud";
import { portfolioSlice ,callTypes} from "./portfolioSlice";
import { Toaster } from "../../../components/Toaster";

const { actions } = portfolioSlice;

export const addToPortfolio = (queryParams) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer.addToPortfolio(queryParams)
      .then((response) => {
        dispatch(actions.callSuccess({ callType: callTypes.action }));
      })
      .catch((error) => {
        error.clientMessage = "Can't fetch profile";
        Toaster({ message: error?.response?.data?.message, variant: "dark" });
        dispatch(actions.catchError({ error }));
      }); 
  };

  export const getPortfolio = (queryParams) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
      .getPortfolio(queryParams)
      .then((response) => {
        // console.log("response.data.data",response.data.data)
        const portfolioDetails = response.data.data;
        dispatch(actions.PortfolioDetailsFetched({ portfolioDetails }));
        // console.log("response",response)
      })
      .catch((error) => {
        error.clientMessage = "Can't find ";
        // Toaster({ message: error?.response?.data?.message, variant: "error" });
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
  };

  export const deletPortfolio = (queryParams) => (dispatch) => {
    console.log("delete",queryParams)
    // dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer.deleteToPortfolio(queryParams)
      .then((response) => {
        console.log(response)
        dispatch(actions.callSuccess({ callType: callTypes.action }));
      })
      .catch((error) => {
        error.clientMessage = "Can't fetch profile";
        Toaster({ message: error?.response?.data?.message, variant: "dark" });
        dispatch(actions.catchError({ error }));
      }); 
  };