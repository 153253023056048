/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, memo } from "react";
import moment from "moment";
import {
  campaignType,
  getCurrencySymbol,
  getReferenceMediaCount,
  checkMediaContentRequest,
} from "../helper/helper";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CommonModal from "./CommonModal";
import { nFormatter } from "../../../../_metronic/layout/components/sidebar/SideBarCampaigns";

const LeftCampaignBriefInfo = ({ campaign }) => {
  const [mediaCount, setMediaCount] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    campaign && setMediaCount(getReferenceMediaCount(campaign));
  }, [campaign]);

  return (
    <div
      className="flex-row-auto offcanvas-mobile w-350px w-xl-275px gutter-b d-flex flex-column"
      id="kt_chat_aside"
    >
      <div
        className="d_card w-100 d-flex align-content-between flex-wrap mb-0 rounded-0 rounded-top min-h-250px no_hover"
        style={{
          backgroundImage: campaign?.productImageUrl
            ? "url(" +
              `${process.env.REACT_APP_AWS_S3_BASE_URL +
                campaign?.productImageUrl}` +
              ")"
            : "url(/media/dummyImages/no_preview.png)",
        }}
      >
        <div className="col-12 d-flex justify-content-end">
          {/* <span className="label label-outline-light label-pill label-inline font-size-lg label_dark text-light"> */}
          {/* <i className="text-light icon-sm flaticon-eye mr-1"></i>325 */}
          {/* </span> */}
        </div>
        <div className="col-12">
          <div className="d-flex d-flex align-items-center">
            <div className="flex-grow-1 w-100">
              <div className="d-flex align-items-center mb-0 w-100">
                <div className="d-flex flex-column flex-grow-1 font-weight-bold w-100">
                  <p
                    className="w-100 mb-0 text-light h6 font-weight-Bold "
                    title="campaign Name"
                  >
                    {campaign?.campaignName}
                  </p>
                  <p
                    className="w-100 mb-0 text-muted font-size-sm text-truncate font-weight-Medium"
                    title="campaign Name"
                  >
                    {campaignType(campaign?.campaignType)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 px-5 pt-0 pb-5 bg-dark rounded-bottom flex-grow-1">
        <div className="w-100 ">
          <p className="text-muted font-weight-Medium font-size-sm mb-2">
            Campaign Rewards
          </p>

          {campaign?.rewardModel === "CASH" && (
            <div
              className="btn bg-special-primary w-100 btn-pill btn-wide text-primary font-size-lg font-weight-bolder"
              style={{ cursor: "default" }}
            >
              <img
                className="mr-2"
                src="/media/svg/icons/custom/Icon_award.svg"
              />

              {getCurrencySymbol(campaign?.productCurrency) +
                nFormatter(campaign?.productValue)}
            </div>
          )}

          {campaign?.rewardModel === "PRODUCT" && (
            <OverlayTrigger
              overlay={
                <Tooltip id="group-edit-tooltip">
                  {campaign?.rewardProductDescription ||
                    "No Description Provided"}
                </Tooltip>
              }
            >
              <div className="btn bg-special-primary w-100 btn-pill btn-wide text-primary font-size-lg font-weight-bolder">
                <img
                  className="mr-2"
                  src="/media/svg/icons/custom/Icon_award.svg"
                />
                Gift
              </div>
            </OverlayTrigger>
          )}

          {campaign?.rewardModel === "OFFER" && (
            <OverlayTrigger
              overlay={
                <Tooltip id="group-edit-tooltip">
                  {campaign?.rewardProductDescription ||
                    "No Description Provided"}
                </Tooltip>
              }
            >
              <div className="btn bg-special-primary w-100 btn-pill btn-wide text-primary font-size-lg font-weight-bolder">
                <img
                  className="mr-2"
                  src="/media/svg/icons/custom/Icon_award.svg"
                />
                Offer
              </div>
            </OverlayTrigger>
          )}
        </div>
        <div className="w-100 py-5">
          {mediaCount?.imageCount ? (
            <span className="font-size-lg font-weight-bold text-light pr-3">
              <img
                className="mr-2"
                src="/media/svg/icons/custom/Icon_photo.svg"
              />
              {mediaCount?.imageCount} Image{mediaCount?.imageCount > 1?"s":""}
            </span>
          ) : null}
          {mediaCount?.videoCount ? (
            <span className="font-size-lg font-weight-bold text-light">
              <img
                className="mr-2"
                src="/media/svg/icons/custom/Icon_video.svg"
              />
              {mediaCount?.videoCount} Video{mediaCount?.videoCount > 1?"s":""}
            </span>
          ) : null}
          {mediaCount?.documentCount ? (
            <span className="font-size-lg font-weight-bold text-light">
              <img
                className="mr-2"
                src="/media/svg/icons/custom/Icon_photo.svg"
              />
              {mediaCount?.documentCount} Document{mediaCount?.documentCount > 1?"s":""}
            </span>
          ) : null}
          {mediaCount?.audioCount ? (
            <span className="font-size-lg font-weight-bold text-light">
              <img
                className="mr-2"
                src="/media/svg/icons/custom/Icon_photo.svg"
              />
              {mediaCount?.audioCount} Audio file{mediaCount?.audioCount > 1?"s":""}
            </span>
          ) : null}
          {mediaCount?.otherCount ? (
            <span className="font-size-lg font-weight-bold text-light">
              <img
                className="mr-2"
                src="/media/svg/icons/custom/Icon_photo.svg"
              />
              {mediaCount?.otherCount} Other file{mediaCount?.otherCount > 1?"s":""}
            </span>
          ) : null}
        </div>
        <div className="w-100 py-2">
          <div className="flex-grow-1">
            <div className="d-flex align-items-center mb-0">
              <div className="symbol symbol-30 symbol-light-success mr-2">
                <a
                  class="btn border-0 bg-special-primary mr-2 h-40px w-40px p-0 "
                  style={{
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    cursor: "default",
                    backgroundImage: campaign?.brandDetail?.profilePic
                      ? `url(${process.env.REACT_APP_AWS_S3_BASE_URL +
                          process.env.REACT_APP_S3_USER_PROFILE_FOLDER +
                          "/" +
                          campaign?.brandDetail?.profilePic})`
                      : "url(/media/dummyImages/no_preview.png)",
                  }}
                >
                  {/* <img
                    className="w-100 align-self-end"
                    style={{
                      backgroundImage: campaign?.brandDetail?.profilePic
                        ? `url(${process.env.REACT_APP_AWS_S3_BASE_URL +
                            process.env.REACT_APP_S3_USER_PROFILE_FOLDER +
                            "/" +
                            campaign?.brandDetail?.profilePic})`
                        : "url(/media/users/300_20.jpg)",
                    }}
                    // src="/media/svg/icons/custom/Icon-box.svg"
                  /> */}
                </a>
              </div>
              <div className="d-flex flex-column flex-grow-1 font-weight-bold ml-1">
                <div className="text-light text-hover-primary mb-0 font-weight-bold  font-size-sm text-truncate">
                  {campaign?.brandDetail?.companyName}
                </div>
                <span className="text-dark-50 font-size-xs">Brand Name</span>
              </div>
            </div>
          </div>
        </div>

        <div className="w-100 py-2">
          <div className="flex-grow-1">
            <div className="d-flex align-items-center mb-0">
              <div
                className="symbol symbol-30 symbol-light-success mr-3 overflow-hidden mr-2"
                style={{ cursor: "default" }}
              >
                <div
                  class="btn border-0 bg-special-primary mr-2 h-40px w-40px p-0 d-flex justify-content-center align-items-center"
                  style={{ cursor: "default" }}
                >
                  <img
                    src="/media/svg/icons/custom/Icon-box.svg"
                    className="mr-0 h-20px"
                    alt=""
                  />
                </div>
              </div>

              <div className="d-flex flex-column flex-grow-1 font-weight-bold">
                <div
                  className="text-light text-hover-primary mb-0 font-weight-bold font-size-sm text-truncate"
                >
                  {campaignType(campaign?.campaignType)}
                </div>
                <span className="text-dark-50 font-size-xs">Campaign Type</span>
              </div>
            </div>
          </div>
        </div>

        <div className="w-100 py-2">
          <div className="flex-grow-1">
            <div className="d-flex align-items-center mb-0">
              <div className="symbol symbol-30 symbol-light-success mr-2">
                <div
                  class="btn border-0 bg-special-primary mr-2 h-40px w-40px p-0 d-flex justify-content-center align-items-center "
                  style={{ cursor: "default" }}
                >
                  <img
                    className="mr-0 h-20px"
                    src="/media/svg/icons/custom/Icon_calendar.svg"
                  />
                </div>
              </div>
              <div className="d-flex flex-column flex-grow-1 font-weight-bold ml-1">
                <div
                  className="text-light text-hover-primary mb-0 font-size-sm font-weight-bold  text-truncate"
                >
                  {moment(new Date(campaign?.campaignClosureTime)).format(
                    "MMMM DD, YYYY"
                  )}
                </div>
                <span className="text-dark-50 font-size-xs">Last Date</span>
              </div>
            </div>
          </div>
        </div>
        <div className="w-100 py-2">
          <div className="flex-grow-1">
            <div className="flex-grow-1">
              
              { checkMediaContentRequest(mediaCount)
                  ? 
                  <div className="d-flex align-items-center mb-0">
                    <div className="symbol symbol-30 symbol-light-success mr-2">
                      <div
                        class="btn border-0 bg-special-warning mr-2 h-40px w-40px p-0 d-flex justify-content-center align-items-center "
                        style={{ cursor: "default" }}
                      >
                        <img
                          className="mr-0 h-20px"
                          src="/media/svg/icons/custom/revisions.svg"
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-column flex-grow-1 font-weight-bold ml-1">
                      <div className="text-light text-hover-primary mb-0 font-size-sm font-weight-bold  text-truncate">
                        {campaign?.revisionCount >0 ? campaign?.revisionCount:3}
                      </div>
                      <span className="text-dark-50 font-size-xs">
                        No of Revisions
                      </span>
                    </div>
                  </div>
                  :null
                }
            
            </div>
          </div>
        </div>

        <div className="w-100 py-2">
          <p class="text-light font-size-sm mb-0 mt-2">
            {campaign?.aboutCampaign?.length > 100
              ? campaign?.aboutCampaign?.substring(0, 100) + "..."
              : campaign?.aboutCampaign}
            {campaign?.aboutCampaign?.length > 100 && (
              <a
                className="pl-1 cursor-pointer"
                onClick={() => setShowModal(true)}
              >
                more
              </a>
            )}
          </p>
        </div>
        <CommonModal
          show={showModal}
          close={() => setShowModal(false)}
          modalTitle={"About Campaign"}
          modalBody={campaign?.aboutCampaign}
        />
      </div>
    </div>
  );
};

export default memo(LeftCampaignBriefInfo);
