import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import axios from 'axios'

const getShuffledArray = (totalQuestions) => {
  var arrayCounter = [];
  for (var i = 1; i <= totalQuestions; i++) {
    arrayCounter.push(i);
  }
  let shuffledCounter = arrayCounter
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)
  return shuffledCounter;
}

export function GuidelineQuizComponent({
  resetTimerPrimaryCounterFlag,
  setTimerResetPrimaryCounterFlag,
  expiredTimerPrimaryCounterFlag,
  setTimerExpiredPrimaryCounterFlag,
  resetTimerSecondaryCounterFlag,
  setTimerResetSecondaryCounterFlag,
  expiredTimerSecondaryCounterFlag,
  setTimerExpiredSecondaryCounterFlag,
  startFlag,
  setStartFlag,
  tickerActivate,
  setTickerActivate,
  campaignAppliedFlag,
  campaignFinish,
  setCampaignFinish,
  quizMasterData,
  quizUserState,
}) {
  const dispatch = useDispatch();

  var ans = document.querySelectorAll(".answer");
  var resetScore = document.querySelector("#resetscore");
  var skipQues = document.querySelector("#skip");
  var waiting = document.querySelector("#waiting");
  var loadAnim = document.querySelector("#waitanim");

  const [isLoaded, setIsLoaded] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  const [totalQuestions, setTotalQuestion] = useState(quizMasterData.max_number_question);
  const [data, setData] = useState({});

  const [quizCompleted, setQuizCompleted] = useState(campaignAppliedFlag); //this help

  const [correctAnswer, setCorrectAnswer] = useState(0);

  const [userStateData, setUserStateData] = useState(quizUserState);

  const [currentScore, setCurrentScore] = useState(quizUserState ? quizUserState.score : 0);

  const [skippedQuestion, setSkippedQuestion] = useState(quizUserState ? quizUserState.number_skipped_questions : 0);

  const [updateState, setUpdateState] = useState(false);

  const [activeQuestion, setActiveQuestion] = useState(quizUserState ? quizUserState.active_question_counter : 1);

  const user = useSelector((state) => state.auth.user, shallowEqual);

  const questionCounterArray = userStateData ? userStateData.question_order : getShuffledArray(totalQuestions);

  const campaign = useSelector((state) => state.campaign ? state.campaign.campaignDetails : null, shallowEqual);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (updateState) {
      setUpdateState(false);
      if (activeQuestion <= totalQuestions) {
        updateUserData(false);
        loading(true);
        fetchReq();
      } else {
        updateUserData(true);
        quizCompleteFunction();
      }
    }

  }, [updateState]);


  useEffect(() => {
    if (isLoaded) {
      setIsPageLoaded(true);
      ans = document.querySelectorAll(".answer");
      resetScore = document.querySelector("#resetscore");
      skipQues = document.querySelector("#skip");
      waiting = document.querySelector("#waiting");
      loadAnim = document.querySelector("#waitanim");
      var status = userStateData ? userStateData.status : "";
      if (activeQuestion < totalQuestions && status != "completed") {
        setStartFlag(true);
        setCurrentScore(currentScore);
        setUpdateState(true);
      } else {
        setQuizCompleted(true);
        setTickerActivate(false);
        setCampaignFinish(true)
      }
    }
  }, [isLoaded]);

  useEffect(() => {
    if (expiredTimerSecondaryCounterFlag) {
      skipQuestion()
      setTimerExpiredSecondaryCounterFlag(false);
    }
  }, [expiredTimerSecondaryCounterFlag]);

  useEffect(() => {
    if (expiredTimerPrimaryCounterFlag) {
      quizCompleteFunction();
      setTimerExpiredPrimaryCounterFlag(false);
    }
  }, [expiredTimerPrimaryCounterFlag]);

  const navigatetoHome = () => {
    //localStorage.setItem("reloadUrl", window.location.pathname);
    window.location.replace("/");
  };



  const { userId } = useSelector(
    (state) => ({
      userId: state.auth ? state.auth.user ? state.auth.user.userId : null : null,
    }),
    shallowEqual
  );

  const instance = axios.create({
    baseURL: 'https://iaaquiz-1cdf.restdb.io/rest/',
    headers: {
      'content-type': 'application/json',
      'x-apikey': '63c626c6969f06502871af7b'
    },
  });

  const fetchReq = () => {
    loading(true);
    //console.log(questionCounterArray,activeQuestion,questionCounterArray[activeQuestion])
    instance.get('thox-quiz-question?q={"question_counter":' + questionCounterArray[activeQuestion - 1] + '}&max=1')
      .then(updateQues)
  }



  const updateUserData = (finishFlag) => {
    loading(true);
    var localUserState = null;
    if (userStateData == null) {
      localUserState = {
        "quiz_master_id": quizMasterData._id,
        "status": "started",
        "user_id": user.userId,
        "campaign_id": (campaign ? campaign.campaignId : null),
        "user_email": user.loginEmail,
        "user_name": ((user.firstName + (user.lastName ? " " + user.lastName : ""))).trim(),
        "start_time": new Date(),
        "score": currentScore,
        "total_question": quizMasterData.max_number_question,
        "active_question_counter": activeQuestion,
        "question_order": questionCounterArray
      }
    } else {
      localUserState = userStateData;
      userStateData.status = (finishFlag ? "completed" : "in-progress")
      userStateData.end_time = new Date()
      userStateData.score = currentScore;
      userStateData.active_question_counter = activeQuestion
      userStateData.number_skipped_questions = skippedQuestion;
    }
    if (campaign != null) {
      instance.post('/thox-quiz-user-data', localUserState)
        .then(updateUserDataCompletion);
    }
  }

  const updateUserDataCompletion = (response) => {
    setUserStateData(response.data);
    //loading(true);
  }

  const updateQues = (response) => {
    setData(response.data[0]);
    var activeData = response.data[0];
    if (activeData != null) {
      document.querySelector("#question").innerHTML = activeData.question;
      var options = activeData.incorrect_answers;
      options.splice(randIndex(), 0, activeData.correct_answer);
      document.querySelector("#optn1").innerHTML = options[0];
      document.querySelector("#optn2").innerHTML = options[1];
      document.querySelector("#optn3").innerHTML = options[2];
      document.querySelector("#optn4").innerHTML = options[3];
      setTimerResetSecondaryCounterFlag(true);
      setStartFlag(true);
    }
    loading(false);
  }

  const skipQuestion = () => {
    var localSkippedQuestion = skippedQuestion + 1;
    setSkippedQuestion(localSkippedQuestion);
    setActiveQuestion(activeQuestion + 1);
    setUpdateState(true);
  }

  const quizCompleteFunction = () => {
    //updateUserData(true);
    setUpdateState(true);
    loading(false);
    setQuizCompleted(true);
    setTickerActivate(false);
    setCampaignFinish(true)
  }

  const randIndex = () => {
    return Math.floor(Math.random() * 4);
  }

  const checkAnswer = (event) => {
    setStartFlag(false);
    var ele = event.currentTarget;
    var score = currentScore;
    loading(true);
    if (ele.innerHTML === data.correct_answer) {
      ele.classList.add("correct");
      score = currentScore + 1;
    } else {
      ele.classList.add("incorrect");
      //score = currentScore - 1;
    }
    setCurrentScore(score);
    setActiveQuestion(activeQuestion + 1);
    setUpdateState(true);
    if (activeQuestion < totalQuestions) {
      for (var j = 0; j < ans.length; j++) {
        ans[j].classList.remove("incorrect")
        ans[j].classList.remove("correct")
      }
      //fetchReq();
    }
  }

  const loading = (fire) => {
    if (waiting != null) {
      if (fire) {
        waiting.classList.add("loading");
        loadAnim.classList.add("animation");
      } else {
        waiting.classList.remove("loading");
        loadAnim.classList.remove("animation");
      }
    }
  }

  /**/

  return (
    <>
      <div class="guideline-popup quiz-place-holder make-flex min-w-300px ml-5 mr-5 rounded d-flex flex-column" style={{
        display: "none"
      }}>
        {!quizCompleted ?
          <div>
            <div class="container twidth make-flex rounded pt-5 pb-5">
              <p id="question" class="font-size-h4"></p>
              <div class="optnContainer make-flex text-white">
                <p id="optn1" class="answer rounded" onClick={(event) => {
                  checkAnswer(event);
                }}></p>
                <p id="optn2" class="answer rounded" onClick={(event) => {
                  checkAnswer(event);
                }}></p>
                <p id="optn3" class="answer rounded" onClick={(event) => {
                  checkAnswer(event);
                }}></p>
                <p id="optn4" class="answer rounded" onClick={(event) => {
                  checkAnswer(event);
                }}></p>

              </div>
            </div>
            <div class="score twidth d-flex flex-row flex-coloumn-gap-10">
              <p id="hscore1" class="twidth text-dark bg-white rounded">{activeQuestion}/{totalQuestions}</p><p id="hscore" class="twidth text-dark bg-white rounded">Score:{currentScore}</p>
            </div><p id="skip" class="twidth text-white bg-red rounded" onClick={() => {
              skipQuestion();
            }}>Skip Question</p>
          </div>
          :
          <div class="app-info-dialog modal-dialog modal-confirm">
            <div class="modal-content rounded">
              <div class="modal-header justify-content-center">
                <div class="icon-box">
                  <i class="material-icons text-white done"></i>
                </div>
              </div>
              <div class="modal-body text-center">
                <h4>Congragulations!!</h4>
                <p>You successfully finished the quiz. The result would be announced shortly.</p>
                <div class="btn btn-primary" data-dismiss="modal" onClick={() => {
                  navigatetoHome();
                }}><span>Start Exploring</span> <i class="material-icons"></i></div>
              </div>
            </div>
          </div>
        }
      </div>
    </>
  );
}