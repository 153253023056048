import axios from "axios";

export const GET_REWARD_AMOUNT = "v1/payments/getTotalBalance";
export const ADD_BANK_DETAILS = "v1/payments/createBankAccount";

export function getRewardAmount(params) {
  return axios.post(GET_REWARD_AMOUNT, {
    ...params,
    frontendAction: "payments.getTotalBalance",
    trackingPriority: 0,
  });
}

export function addBankDetails(params) {
  return axios.post(ADD_BANK_DETAILS, {
    ...params,
    frontendAction: "payments.createBankAccount",
    trackingPriority: 0,
  });
}
