import React, { memo, useState, useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { getApplicationDetails } from "../../campaign/_redux/campaignActions";
import ApplicationDetails from "./engageMentsTab/ApplicationDetails";
import ContentDetails from "./engageMentsTab/ContentDetails";
import PaymentDetails from "./engageMentsTab/PaymentDetails";
import GiftorOfferDetails from "./engageMentsTab/GiftorOfferDetails";
// import ShippingDetails from "./engageMentsTab/ShippingDetails";
import UrlDetails from "./engageMentsTab/UrlDetails";
import UrlDetailsForSocialCampaign from "./engageMentsTab/UrlDetailsForSocialCampaign";

const EngagementsTab = ({ isActive, campaignType, application }) => {
  const [stepper, setStepper] = useState("applicationDetails");

  const dispatch = useDispatch();
  const {
    loggedInUserId,
    applicationDetails,
    campaignDetails,
    listLoading,
  } = useSelector(
    (state) => ({
      loggedInUserId: state.auth.user?.userId,
      campaignDetails: state.campaign.campaignDetails,
      applicationDetails: state.campaign.applicationDetails[0] || {},
      listLoading: state.campaign.listLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    // let { brandId } = campaignDetails;

    if (campaignDetails?.campaignId && loggedInUserId && isActive) {
      dispatch(
        getApplicationDetails({
          page: 1,
          pageSize: 5,
          loggedInUserId,
          populate: ["brandDetail", "paymentDetails"],
          query: {
            campaignId: campaignDetails?.campaignId,
            creatorId: loggedInUserId,
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  return (
    <div
      className={`tab-pane ${isActive ? "active" : "d-none"}`}
      id="engagements"
      role="tabpanel"
    >
      <div className="row w-100 mx-0">
        <div class="card card-custom w-100 bg-transparent border py-5 pl-5 pr-5 shadow-none my-6">
          <div class="card-body p-0">
            <div class="wizard wizard-2" id="kt_wizard">
              <div class="wizard-nav border-right py-3 pr-8 py-lg-2 pr-lg-10">
                <div class="wizard-steps">
                  <div
                    class="wizard-step"
                    data-wizard-type="step"
                    data-wizard-state={
                      stepper === "applicationDetails" ? "current" : ""
                    }
                    onClick={() => setStepper("applicationDetails")}
                  >
                    <div class="wizard-wrapper cursor-pointer">
                      <div class="wizard-icon">
                        <span class="svg-icon">
                          <svg
                            width="20.246"
                            height="19.355"
                            viewBox="0 0 20.246 19.355"
                          >
                            <g
                              id="Icon_feather-edit-3"
                              data-name="Icon feather-edit-3"
                              transform="translate(1 1)"
                            >
                              <path
                                id="Path_80"
                                data-name="Path 80"
                                d="M18,30h9.123"
                                transform="translate(-8.877 -12.645)"
                                fill="none"
                                stroke="#000"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                              />
                              <path
                                id="Path_81"
                                data-name="Path 81"
                                d="M18.184,4.948a2.15,2.15,0,0,1,3.041,3.041L8.555,20.66,4.5,21.673l1.014-4.055Z"
                                transform="translate(-4.5 -4.318)"
                                fill="none"
                                stroke="#000"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                              />
                            </g>
                          </svg>
                        </span>
                      </div>
                      <div class="wizard-label">
                        <h3 class="wizard-title mb-0 font-size-sm font-weight-SemiBold">
                          Application Status
                        </h3>
                      </div>
                    </div>
                  </div>

                  {/* <div
                    class="wizard-step"
                    data-wizard-type="step"
                    data-wizard-state={
                      stepper === "shipmentDetails" ? "current" : ""
                    }
                    // onClick={() =>
                    //   (campaignType === "RELATION_POST" ||
                    //     campaignType === "SOCIAL_POST") &&
                    //   setStepper("shipmentDetails")
                    // }
                  >
                    <div class="wizard-wrapper">
                      <div class="wizard-icon">
                        <span class="svg-icon">
                          <img src="/media/svg/icons/custom/Icon_link_dark.svg" />
                        </span>
                      </div>
                      <div class="wizard-label">
                        <h3
                          class={`wizard-title mb-0 font-size-sm font-weight-SemiBold text-muted`}
                        >
                          Shipment <br /> Status
                        </h3>
                      </div>
                    </div>
                  </div> */}

                  {campaignDetails?.campaignType !== "RELATION_POST" && (
                    <div
                      class="wizard-step cursor-pointer"
                      data-wizard-type="step"
                      data-wizard-state={
                        stepper === "contentDetails" ? "current" : ""
                      }
                      onClick={() =>
                        campaignType !== "RELATION_POST" &&
                        application?.applicationStatus ===
                          "APPROVED_BY_BRAND" &&
                        setStepper("contentDetails")
                      }
                    >
                      <div class="wizard-wrapper">
                        <div class="wizard-icon">
                          <span class="svg-icon">
                            <svg
                              width="20.723"
                              height="19.504"
                              viewBox="0 0 20.723 19.504"
                            >
                              <path
                                id="Icon_material-wrap-text"
                                data-name="Icon material-wrap-text"
                                d="M6,24.566h7.314V22.128H6ZM25.5,7.5H6V9.938H25.5Zm-3.657,7.314H6v2.438H22.152a2.438,2.438,0,0,1,0,4.876H19.409V19.69l-3.657,3.657L19.409,27V24.566h2.438a4.876,4.876,0,1,0,0-9.752Z"
                                transform="translate(-6 -7.5)"
                                fill="#000"
                              />
                            </svg>
                          </span>
                        </div>
                        <div class="wizard-label">
                          <h3
                            class={`wizard-title mb-0 font-size-sm font-weight-SemiBold ${(campaignType ===
                              "RELATION_POST" ||
                              application?.applicationStatus !==
                                "APPROVED_BY_BRAND") &&
                              "text-muted"}`}
                          >
                            Content
                            <br />
                            Status
                          </h3>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* *********************Social Media stepper********************** */}
                  {!(campaignType === "PHOTOGRAPHY" ||
                    campaignType === "VIDEOGRAPHY") && (
                    <div
                      class="wizard-step cursor-pointer"
                      data-wizard-type="step"
                      data-wizard-state={
                        stepper === "urlDetails" ? "current" : ""
                      }
                      onClick={() =>
                        (campaignType === "RELATION_POST" ||
                          (campaignType === "SOCIAL_POST" &&
                            (application?.contentStatus ===
                              "SOCIAL_MEDIA_URL_REQUESTED" ||
                              application?.contentStatus ===
                                "URL_SUBMITTED_BY_CREATOR"))) &&
                        application?.applicationStatus ===
                          "APPROVED_BY_BRAND" &&
                        setStepper("urlDetails")
                      }
                    >
                      <div class="wizard-wrapper">
                        <div class="wizard-icon">
                          <span class="svg-icon">
                            <img src="/media/svg/icons/custom/Icon_feather-link.svg" />
                          </span>
                        </div>
                        <div class="wizard-label">
                          <h3
                            class={`wizard-title mb-0 font-size-sm font-weight-SemiBold ${
                              application?.applicationStatus ===
                                "APPROVED_BY_BRAND" &&
                              (campaignType === "RELATION_POST" ||
                                (campaignType === "SOCIAL_POST" &&
                                  (application?.contentStatus ===
                                    "SOCIAL_MEDIA_URL_REQUESTED" ||
                                    application?.contentStatus ===
                                      "URL_SUBMITTED_BY_CREATOR")))
                                ? ""
                                : "text-muted"
                            }`}
                          >
                            Social <br />
                            Media
                          </h3>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* *********************Payments or gift/offer stepper********************** */}
                  {campaignDetails?.rewardModel === "CASH" ? (
                    <div
                      class="wizard-step cursor-pointer"
                      data-wizard-type="step"
                      data-wizard-state={
                        stepper === "paymentDetails" ? "current" : ""
                      }
                      onClick={() =>
                        // applicationDetails?.payoutStatus !== null &&
                        setStepper("paymentDetails")
                      }
                    >
                      <div class="wizard-wrapper">
                        <div class="wizard-icon">
                          <span class="svg-icon">
                            <svg
                              width="20.291"
                              height="16.233"
                              viewBox="0 0 20.291 16.233"
                            >
                              <path
                                id="Icon_material-payment"
                                data-name="Icon material-payment"
                                d="M21.262,6H5.029A2.014,2.014,0,0,0,3.01,8.029L3,20.2a2.022,2.022,0,0,0,2.029,2.029H21.262A2.022,2.022,0,0,0,23.291,20.2V8.029A2.022,2.022,0,0,0,21.262,6Zm0,14.2H5.029V14.117H21.262Zm0-10.146H5.029V8.029H21.262Z"
                                transform="translate(-3 -6)"
                                fill="#000"
                              />
                            </svg>
                          </span>
                        </div>
                        <div class="wizard-label">
                          <h3
                            class={`wizard-title mb-0 font-size-sm font-weight-SemiBold `}
                          >
                            Payment
                            <br />
                            Status
                          </h3>
                        </div>
                      </div>
                    </div>
                  ) : 
                  // (campaignDetails?.rewardModel === "PRODUCT" || campaignDetails.rewardModel === "OFFER") && 
                  (
                    <div
                      class="wizard-step cursor-pointer"
                      data-wizard-type="step"
                      data-wizard-state={
                        stepper === "giftorofferDetails" ? "current" : ""
                      }
                      onClick={() =>
                        // applicationDetails?.payoutStatus !== null &&
                        setStepper("giftorofferDetails")
                      }
                    >
                      <div class="wizard-wrapper">
                        <div class="wizard-icon">
                          <span class="svg-icon">
                            <svg
                              width="20.291"
                              height="16.233"
                              viewBox="0 0 20.291 16.233"
                            >
                              <path
                                id="Icon_material-payment"
                                data-name="Icon material-payment"
                                d="M21.262,6H5.029A2.014,2.014,0,0,0,3.01,8.029L3,20.2a2.022,2.022,0,0,0,2.029,2.029H21.262A2.022,2.022,0,0,0,23.291,20.2V8.029A2.022,2.022,0,0,0,21.262,6Zm0,14.2H5.029V14.117H21.262Zm0-10.146H5.029V8.029H21.262Z"
                                transform="translate(-3 -6)"
                                fill="#000"
                              />
                            </svg>
                          </span>
                        </div>
                        <div class="wizard-label">
                          <h3
                            class={`wizard-title mb-0 font-size-sm font-weight-SemiBold `}
                          >
                            Gift/Offer
                            <br />
                            Status
                          </h3>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {listLoading ? (
                <div className="d-flex justify-content-center align-item-center my-10 mx-auto">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              ) : (
                // {/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>stepper body starts>>>>>>>>>>>>>>>>>>>>>>>>>> */}
                <>
                  <ApplicationDetails
                    stepper={stepper}
                    applicationDetails={{
                      applicationStatus: applicationDetails?.applicationStatus,
                      rejectedReason: applicationDetails?.rejectedReason,
                    }}
                  />
                  {/* <ShippingDetails stepper={stepper} /> */}
                  <ContentDetails stepper={stepper} />
                  {campaignType === "RELATION_POST" && (
                    <UrlDetails stepper={stepper} />
                  )}
                  {campaignType === "SOCIAL_POST" && (
                    <UrlDetailsForSocialCampaign stepper={stepper} />
                  )}
                  {campaignDetails?.rewardModel === "CASH" ? <PaymentDetails
                    stepper={stepper}
                    application={applicationDetails}
                  /> : <GiftorOfferDetails
                    stepper={stepper}
                    application={applicationDetails}
                  />}
                </>
              )}
              {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<stepper body ends here>>>>>>>>>>>>>>>>>>>>>>>>> */}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="row">
        <div className="col-md-6 d-none">
          <div className="card bg-light-danger rounded p-5 mb-5">
            <div class="flex-grow-1">
              <div class="d-flex align-items-center mb-0">
                <div class="symbol symbol-30 symbol-danger mr-2">
                  <a
                    href="#"
                    class="btn  bg-danger mr-2 h-40px w-40px p-0 pt-3"
                  >
                    <img
                      class="mr-0 h-20px"
                      src="/media/svg/icons/custom/Icon_edit.svg"
                    />
                  </a>
                </div>
                <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                  <a
                    href="#"
                    class="text-danger mb-0 font-weight-bolder  font-size-lg text-truncate"
                  >
                    Rejected
                  </a>
                  <span class="text-dark font-size-sm">Application Status</span>
                </div>
              </div>
              <p className="quotes mb-0 font-size-sm mt-5 font-weight-bold">
                Sorry! Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Donec rutrum, urna id tempor tempor, diam ligula elit to
                interdum tellus
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-6 d-none">
          <div className="card bg-light-primary rounded p-5 mb-5">
            <div class="flex-grow-1">
              <div class="d-flex align-items-center mb-0">
                <div class="symbol symbol-30 symbol-danger mr-2">
                  <a
                    href="#"
                    class="btn  bg-primary mr-2 h-40px w-40px p-0 pt-3"
                  >
                    <img
                      class="mr-0 h-20px"
                      src="/media/svg/icons/custom/Icon_wrap-text.svg"
                    />
                  </a>
                </div>
                <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                  <a
                    href="#"
                    class="text-primary mb-0 font-weight-bolder  font-size-lg text-truncate"
                  >
                    Draft
                  </a>
                  <span class="text-dark font-size-sm">Content Status</span>
                </div>
              </div>
              <p className="mb-0 font-size-sm mt-5 font-weight-bold">
                Content drafted. And Lorem ipsum dolor sit amet, consectetur
                adipiscing elit
              </p>
              <div className="d-flex row flex-nowrap">
                <div className="col-auto pt-4">
                  <span class="text-dark font-size-xs">
                    <img
                      class="mr-1 mt-n1"
                      src="/media/svg/icons/custom/Icon-calendar.svg"
                    />{" "}
                    <span>27th May 2022</span>
                  </span>
                </div>
                <div className="col text-right">
                  <div className="btn btn-sm btn-primary">View Details</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 d-none">
          <div className="card bg-light-success rounded p-5 mb-5">
            <div class="flex-grow-1">
              <div class="d-flex align-items-center mb-0">
                <div class="symbol symbol-30 symbol-danger mr-2">
                  <a
                    href="#"
                    class="btn  bg-success mr-2 h-40px w-40px p-0 pt-3"
                  >
                    <img
                      class="mr-0 h-20px"
                      src="/media/svg/icons/custom/Icon_payment.svg"
                    />
                  </a>
                </div>
                <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                  <a
                    href="#"
                    class="text-success mb-0 font-weight-bolder  font-size-lg text-truncate"
                  >
                    PAID
                  </a>
                  <span class="text-dark font-size-sm">Payment Status</span>
                </div>
              </div>
              <p className="mb-0 font-size-sm mt-5 font-weight-bold">
                Payment completed and credited with in 4 Working days.
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-6 d-none">
          <div className="card bg-light rounded p-5 mb-5">
            <div class="flex-grow-1">
              <div class="d-flex align-items-center mb-0">
                <div class="symbol symbol-30 symbol-danger mr-2">
                  <a href="#" class="btn  bg-dark mr-2 h-40px w-40px p-0 pt-3">
                    <img
                      class="mr-0 h-20px"
                      src="/media/svg/icons/custom/Icon_link.svg"
                    />
                  </a>
                </div>
                <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                  <a
                    href="#"
                    class="text-dark mb-0 font-weight-bolder  font-size-lg text-truncate"
                  >
                    Shipped
                  </a>
                  <span class="text-dark font-size-sm">Shipment Status</span>
                </div>
              </div>
              <p className="mb-0 font-size-sm mt-5 font-weight-bold">
                Item Shipped and tracking details below
              </p>
              <a href="#" className="font-size-sm pt-3">
                Click here to track
              </a>
            </div>
          </div>
        </div>

        <div className="col-12 d-none">
          <div
            class="alert alert-custom alert-secondary px-4 py-2 rounded"
            role="alert"
          >
            <div class="alert-icon">
              <i class="flaticon-questions-circular-button icon-md"></i>
            </div>
            <div class="alert-text font-size-sm">
              Upload contents as per the campaign requirements
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="row d-none">
        <div className="col-md-6">
          <h6>Images</h6>
          <div className="row">
            <div class="col-md-6">
              <div class="w-100 mr-5">
                <div class="symbol-label w-100 media-file">
                  <img
                    src="/media/stock-600x600/img-1.jpg"
                    class="w-100 rounded"
                  />
                  <div className="btn btn-icon btn-light btn-sm">
                    <img src="/media/svg/icons/custom/Icon_download.svg" />
                  </div>
                </div>
                <h6 class="font-size-sm mt-4">Photography/ Creatives</h6>
                <div class="d-flex justify-content-between align-items-center m-0 mt-2">
                  <button class="btn btn-icon btn-light-danger btn-sm">
                    <img
                      class="mr-0 h-30px p-2"
                      src="/media/svg/icons/custom/Icon_instagram.svg"
                    />
                  </button>
                  <span class="mr-2 mt-1 font-size-sm mr-1 mt-2">
                    {" "}
                    <i class=" far fa-thumbs-up text-dark icon-nm mr-1"></i>
                    65
                  </span>
                  <span class="mr-0 mt-1 font-size-sm mr-1 mt-2">
                    <i class="far fa-eye text-dark icon-nm mr-1"></i> 4
                  </span>
                </div>
                <div class="d-flex justify-content-between align-items-center m-0 mt-2">
                  <button class="btn btn-icon btn-light-danger btn-sm">
                    <img
                      class="mr-0 w-100 px-2"
                      src="/media/svg/icons/custom/Icon_youtube.svg"
                    />
                  </button>
                  <span class="mr-2 mt-1 font-size-sm mr-1 mt-2">
                    {" "}
                    <i class=" far fa-thumbs-up text-dark icon-nm mr-1"></i>
                    65
                  </span>
                  <span class="mr-0 mt-1 font-size-sm mr-1 mt-2">
                    <i class="far fa-eye text-dark icon-nm mr-1"></i> 4
                  </span>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="w-100 mr-5">
                <div class="symbol-label w-100 media-file">
                  <img
                    src="/media/stock-600x600/img-3.jpg"
                    class="w-100 rounded"
                  />
                  <div className="btn btn-icon btn-light btn-sm">
                    <img src="/media/svg/icons/custom/Icon_download.svg" />
                  </div>
                </div>
                <h6 class="font-size-sm mt-4">Photography/ Creatives</h6>
                <div class="d-flex justify-content-between align-items-center m-0 mt-2">
                  <button class="btn btn-icon btn-light-danger btn-sm">
                    <img
                      class="mr-0 h-30px p-2"
                      src="/media/svg/icons/custom/Icon_instagram.svg"
                    />
                  </button>
                  <span class="mr-2 mt-1 font-size-sm mr-1 mt-2">
                    {" "}
                    <i class=" far fa-thumbs-up text-dark icon-nm mr-1"></i>
                    65
                  </span>
                  <span class="mr-0 mt-1 font-size-sm mr-1 mt-2">
                    <i class="far fa-eye text-dark icon-nm mr-1"></i> 4
                  </span>
                </div>
                <div class="d-flex justify-content-between align-items-center m-0 mt-2">
                  <button class="btn btn-icon btn-light-danger btn-sm">
                    <img
                      class="mr-0 w-100 px-2"
                      src="/media/svg/icons/custom/Icon_youtube.svg"
                    />
                  </button>
                  <span class="mr-2 mt-1 font-size-sm mr-1 mt-2">
                    {" "}
                    <i class=" far fa-thumbs-up text-dark icon-nm mr-1"></i>
                    65
                  </span>
                  <span class="mr-0 mt-1 font-size-sm mr-1 mt-2">
                    <i class="far fa-eye text-dark icon-nm mr-1"></i> 4
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-6"></div>
          </div>
        </div>

        <div className="col-md-6">
          <h6>Videos</h6>
          <div className="row">
            <div class="col-md-6">
              <div class="w-100 mr-5">
                <div class="symbol-label w-100 media-file video">
                  <img
                    src="/media/stock-600x600/img-9.jpg"
                    class="w-100 rounded"
                  />
                  <div className="btn btn-icon btn-light btn-sm">
                    <img src="/media/svg/icons/custom/Icon_download.svg" />
                  </div>
                </div>
                <h6 class="font-size-sm mt-4">Photography/ Creatives</h6>
                <div class="d-flex justify-content-between align-items-center m-0 mt-2">
                  <button class="btn btn-icon btn-light-danger btn-sm">
                    <img
                      class="mr-0 h-30px p-2"
                      src="/media/svg/icons/custom/Icon_instagram.svg"
                    />
                  </button>
                  <span class="mr-2 mt-1 font-size-sm mr-1 mt-2">
                    {" "}
                    <i class=" far fa-thumbs-up text-dark icon-nm mr-1"></i>
                    65
                  </span>
                  <span class="mr-0 mt-1 font-size-sm mr-1 mt-2">
                    <i class="far fa-eye text-dark icon-nm mr-1"></i> 4
                  </span>
                </div>
                <div class="d-flex justify-content-between align-items-center m-0 mt-2">
                  <button class="btn btn-icon btn-light-danger btn-sm">
                    <img
                      class="mr-0 w-100 px-2"
                      src="/media/svg/icons/custom/Icon_youtube.svg"
                    />
                  </button>
                  <span class="mr-2 mt-1 font-size-sm mr-1 mt-2">
                    {" "}
                    <i class=" far fa-thumbs-up text-dark icon-nm mr-1"></i>
                    65
                  </span>
                  <span class="mr-0 mt-1 font-size-sm mr-1 mt-2">
                    <i class="far fa-eye text-dark icon-nm mr-1"></i> 4
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="w-100 mr-5">
                <div class="symbol-label w-100 media-file video">
                  <img
                    src="/media/stock-600x600/img-7.jpg"
                    class="w-100 rounded"
                  />
                  <div className="btn btn-icon btn-light btn-sm">
                    <img src="/media/svg/icons/custom/Icon_download.svg" />
                  </div>
                </div>
                <h6 class="font-size-sm mt-4">Photography/ Creatives</h6>
                <div class="d-flex justify-content-between align-items-center m-0 mt-2">
                  <button class="btn btn-icon btn-light-danger btn-sm">
                    <img
                      class="mr-0 h-30px p-2"
                      src="/media/svg/icons/custom/Icon_instagram.svg"
                    />
                  </button>
                  <span class="mr-2 mt-1 font-size-sm mr-1 mt-2">
                    {" "}
                    <i class=" far fa-thumbs-up text-dark icon-nm mr-1"></i>
                    65
                  </span>
                  <span class="mr-0 mt-1 font-size-sm mr-1 mt-2">
                    <i class="far fa-eye text-dark icon-nm mr-1"></i> 4
                  </span>
                </div>
                <div class="d-flex justify-content-between align-items-center m-0 mt-2">
                  <button class="btn btn-icon btn-light-danger btn-sm">
                    <img
                      class="mr-0 w-100 px-2"
                      src="/media/svg/icons/custom/Icon_youtube.svg"
                    />
                  </button>
                  <span class="mr-2 mt-1 font-size-sm mr-1 mt-2">
                    {" "}
                    <i class=" far fa-thumbs-up text-dark icon-nm mr-1"></i>
                    65
                  </span>
                  <span class="mr-0 mt-1 font-size-sm mr-1 mt-2">
                    <i class="far fa-eye text-dark icon-nm mr-1"></i> 4
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-6"></div>
          </div>
        </div>
        <div className="col-md-6"></div>
      </div> */}
    </div>
  );
};

export default memo(EngagementsTab);
