import React, { memo, useEffect } from "react";
import moment from "moment";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { getConvertedContentStatus, getMediaPreview } from "../helper/helper";
import useFileDownloader from "../../../components/file-downloader/useFileDownloader";
// import { listApplicationContents } from "../../../../campaignApplications/_redux/applicationActions";
import { getSpecificCampaignGallery } from "../../campaignApplications/_redux/applicationActions";

const GalleryTab = ({ isActive }) => {
  const dispatch = useDispatch();
  const [downloadFile, downloaderComponentUI] = useFileDownloader();
  const download = (file) => downloadFile(file);

  const {
    loggedInUserId,
    campaignLoading,
    listLoading,
    galleryContents,
    applicationDetails,
  } = useSelector(
    (state) => ({
      loggedInUserId: state.auth.user?.userId,
      campaignLoading: state.campaign.listLoading,
      listLoading: state.application.listLoading,
      galleryContents: state.application.galleryContents,
      applicationDetails: state.campaign.applicationDetails[0] || {},
    }),
    shallowEqual
  );

  useEffect(() => {
    Object.keys(applicationDetails)?.length > 0 &&
      isActive &&
      dispatch(
        getSpecificCampaignGallery({
          page: 1,
          pageSize: 20,
          loggedInUserId,
          sort: "createdAt",
          query: {
            campaignId: applicationDetails?.campaignId,
            creatorId: loggedInUserId,
          },
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationDetails, isActive]);

  return (
    <>
      {isActive && (listLoading || campaignLoading) ? (
        <div className="d-flex justify-content-center align-item-center my-10 mx-auto">
          <div className="spinner spinner-lg spinner-success" />
        </div>
      ) : (
        <div
          className={`tab-pane ${isActive ? "active" : "d-none"}`}
          id="gallery"
          role="tabpanel"
        >
          {/* <div class="row w-100 mx-0">
        <div class="card-body p-0 row">
          <div class="col-md-4">
            <div class="d_card d_gallery w-100 min-h-130px d-flex align-content-between media-file flex-wrap cursor-pointer pt-0">
              <div class="col-12 px-4 d-flex justify-content-end align-items-center">
                <div class="bg_light_dark btn h-30px p-0 d-flex justify-content-center align-items-center">
                  <img src="/media/svg/icons/custom/Icon_download_light.svg" />
                </div>
              </div>
              <div class="d-flex d-flex align-items-center w-100 px-4">
                <div class="flex-grow-1">
                  <div class="d-flex align-items-center mb-0">
                    <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                      <a
                        href="#"
                        class="text-light text-hover-primary mb-0 font-size-sm text-truncate text_break"
                      >
                        Innovation Incubator
                      </a>
                    </div>
                  </div>
                </div>
                <div class="flex-shrink-0">
                  <span class="label_dark label bg-dark-50 mr-0 h-30px w-30px p-0 d-flex align-items-center justify-content-center">
                    <img
                      class="mt-n1 h-15px max-w-15px"
                      src="/media/svg/icons/custom/Icon_youtube.svg"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="d_card d_gallery w-100 min-h-130px d-flex align-content-between media-file video flex-wrap cursor-pointer pt-0">
              <div class="col-12 px-4 d-flex justify-content-end">
                <div class="bg_light_dark btn h-30px w-30px p-0 d-flex justify-content-center align-items-center">
                  <img src="/media/svg/icons/custom/Icon_download_light.svg" />
                </div>
              </div>
              <div class="d-flex d-flex align-items-center w-100 px-4 card_title">
                <div class="flex-grow-1">
                  <div class="d-flex align-items-center mb-0">
                    <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                      <a
                        href="#"
                        class="text-light text-hover-primary mb-0 font-size-sm text-truncate text_break"
                      >
                        Innovation Incubator
                      </a>
                    </div>
                  </div>
                </div>
                <div class="flex-shrink-0">
                  <span class="label_dark label bg-dark-50 mr-0 h-30px w-30px p-0 d-flex align-items-center justify-content-center">
                    <img
                      class="mt-n1 h-15px max-w-15px"
                      src="/media/svg/icons/custom/Icon_youtube.svg"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
          {galleryContents?.length > 0 ? (
            <div class="row w-100 mx-0">
              {galleryContents?.map((content) => (
                <>
                  {content?.postType === "VIDEO_POST" && (
                    <div class="col-md-4">
                      <div
                        class="d_card d_gallery_view w-100 d-flex video align-content-between flex-wrap cursor-pointer"
                        style={{
                          backgroundSize: "cover",
                          backgroundImage:
                            "url(/media/dummyImages/no_preview_v2.png)",
                        }}
                        // onClick={() =>
                        //   window.open(
                        //     `${process.env.REACT_APP_AWS_S3_BASE_URL +
                        //       content?.contentMediaUrl}`
                        //   )
                        // }
                      >
                        <div class="col-12 px-4 d-flex justify-content-end  align-items-center">
                          <span class="label label-outline-success label-pill label-inline label_dark mr-2">
                            {getConvertedContentStatus(content?.contentStatus)}
                          </span>

                          <span
                            class="bg_light btn h-30px w-30px p-0 d-flex justify-content-center align-items-center"
                            onClick={() =>
                              window.open(
                                `${process.env.REACT_APP_AWS_S3_BASE_URL +
                                  content?.contentMediaUrl}`
                              )
                            }
                          >
                            <img src="/media/svg/icons/custom/icon_view.svg" />
                          </span>

                          {!getConvertedContentStatus(
                            content?.contentStatus
                          ) === "RESUBMIT_CONTENT" && (
                            <span
                              class="bg_light_dark btn h-30px w-30px p-0 d-flex justify-content-center align-items-center ml-2"
                              onClick={() =>
                                download({
                                  file:
                                    process.env.REACT_APP_AWS_S3_BASE_URL +
                                    content.contentMediaUrl,
                                  filename:
                                    content.campaignType +
                                    "_" +
                                    Math.floor(Math.random() * 1000000 + 1),
                                })
                              }
                            >
                              <img src="/media/svg/icons/custom/Icon_download_light.svg" />
                            </span>
                          )}
                        </div>
                        <div class="col-12 px-4">
                          {/* <p
                      class="w-100 mb-1 text-light text-truncate font-weight-SemiBold"
                      title=" Influencer campaign"
                    >
                      Influencer campaign
                    </p> */}
                          <div class="d-flex d-flex align-items-center">
                            <div class="flex-grow-1">
                              <div class="d-flex align-items-center mb-0">
                                {/* <div class="symbol symbol-30 symbol-circle symbol-light-success mr-2">
                            <span class="symbol-label"></span>
                          </div> */}
                                <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                                  {/* <a
                              href="#"
                              class="text-light text-hover-primary mb-0 font-size-sm text-truncate"
                            >
                              Innovation Incubator
                            </a> */}
                                  <span class="text-light font-size-xs">
                                    {/* <img
                                class="mr-1 h-10px mt-n1"
                                src="/media/svg/icons/custom/Icon_calendar_light.svg"
                              /> */}
                                    <span class="">
                                      <span>
                                        {moment(
                                          new Date(content?.createdAt)
                                        ).format("MMMM DD, YYYY")}
                                      </span>
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {content?.postType === "IMAGE_POST" && (
                    <div class="col-md-4">
                      <div
                        class="d_card d_gallery_view w-100 d-flex align-content-between flex-wrap cursor-pointer"
                        style={{
                          backgroundSize: "cover",
                          backgroundImage: content?.contentMediaUrl
                            ? getMediaPreview(content)
                            : // "url(" +
                              //   `${process.env.REACT_APP_AWS_S3_BASE_URL +
                              //     content?.contentMediaUrl}` +
                              //   ")"
                              "url(/media/dummyImages/no_preview_v2.png)",
                        }}
                        // onClick={() =>
                        //   window.open(
                        //     `${process.env.REACT_APP_AWS_S3_BASE_URL +
                        //       content?.contentMediaUrl}`
                        //   )
                        // }
                      >
                        <div class="col-12 px-4 d-flex justify-content-end align-items-center">
                          <span class="label label-outline-success label-pill label-inline label_dark mr-2">
                            {getConvertedContentStatus(content?.contentStatus)}
                          </span>

                          <span
                            class="bg_light btn h-30px w-30px p-0 d-flex justify-content-center align-items-center"
                            onClick={() =>
                              window.open(
                                `${process.env.REACT_APP_AWS_S3_BASE_URL +
                                  content?.contentMediaUrl}`
                              )
                            }
                          >
                            <img src="/media/svg/icons/custom/icon_view.svg" />
                          </span>

                          {!getConvertedContentStatus(
                            content?.contentStatus
                          ) === "RESUBMIT_CONTENT" && (
                            <span
                              class="bg_light_dark btn h-30px w-30px p-0 d-flex justify-content-center align-items-center ml-2"
                              onClick={() =>
                                download({
                                  file:
                                    process.env.REACT_APP_AWS_S3_BASE_URL +
                                    content.contentMediaUrl,
                                  filename:
                                    content.campaignType +
                                    "_" +
                                    Math.floor(Math.random() * 1000000 + 1),
                                })
                              }
                            >
                              <img src="/media/svg/icons/custom/Icon_download_light.svg" />
                            </span>
                          )}
                        </div>
                        <div class="col-12 px-4">
                          {/* <p
                      class="w-100 mb-1 text-light text-truncate font-weight-SemiBold"
                      title=" Influencer campaign"
                    >
                      Influencer campaign
                    </p> */}
                          <div class="d-flex d-flex align-items-center">
                            <div class="flex-grow-1">
                              <div class="d-flex align-items-center mb-0">
                                {/* <div class="symbol symbol-30 symbol-circle symbol-light-success mr-2">
                            <span class="symbol-label"></span>
                          </div> */}
                                <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                                  {/* <a
                              href="#"
                              class="text-light text-hover-primary mb-0 font-size-sm text-truncate"
                            >
                              Innovation Incubator
                            </a> */}
                                  <span class="text-light font-size-xs">
                                    {/* <img
                                class="mr-1 h-10px mt-n1"
                                src="/media/svg/icons/custom/Icon_calendar_light.svg"
                              /> */}
                                    <span class="">
                                      <span>
                                        {moment(
                                          new Date(content?.createdAt)
                                        ).format("MMMM d, YYYY")}
                                      </span>
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>
          ) : (
            <h3 className="mt-5 d-flex justify-content-center">
              <b>No Contents Found</b>
            </h3>
          )}
          {downloaderComponentUI}
        </div>
      )}
    </>
  );
};

export default memo(GalleryTab);
