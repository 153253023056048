import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import axios from 'axios'


export function GuidelineQuizMaster({
  resetTimerPrimaryCounterFlag,
  setTimerResetPrimaryCounterFlag,
  expiredTimerPrimaryCounterFlag,
  setTimerExpiredPrimaryCounterFlag,
  resetTimerSecondaryCounterFlag,
  setTimerResetSecondaryCounterFlag,
  expiredTimerSecondaryCounterFlag,
  setTimerExpiredSecondaryCounterFlag,
  startFlag,
  setStartFlag,
  tickerActivate,
  setTickerActivate,
  campaignAppliedFlag,
  campaignFinish,
  setCampaignFinish
}) {
  const dispatch = useDispatch();

  var ans = document.querySelectorAll(".answer");
  var resetScore = document.querySelector("#resetscore");
  var skipQues = document.querySelector("#skip");
  var waiting = document.querySelector("#waiting");
  var loadAnim = document.querySelector("#waitanim");
  var curScore = 0;

  const [isLoaded, setIsLoaded] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps
  const [activeQuestion, setActiveQuestion] = useState(0); //this helps
  const [totalQuestions, setTotalQuestion] = useState(5); //this helps
  const [data, setData] = useState({}); //this helps

  const [quizCompleted, setQuizCompleted] = useState(campaignAppliedFlag); //this help

  const [correctAnswer, setCorrectAnswer] = useState(0); //this help


  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      setIsPageLoaded(true);
      ans = document.querySelectorAll(".answer");
      resetScore = document.querySelector("#resetscore");
      skipQues = document.querySelector("#skip");
      waiting = document.querySelector("#waiting");
      loadAnim = document.querySelector("#waitanim");
      curScore = 0;
      //setStartFlag(true);
      //fetchReq();
    }
  }, [isLoaded]);

  useEffect(() => {
    if (expiredTimerSecondaryCounterFlag) {
      skipQuestion()
      setTimerExpiredSecondaryCounterFlag(false);
    }
  }, [expiredTimerSecondaryCounterFlag]);

  useEffect(() => {
    if (expiredTimerPrimaryCounterFlag) {
      quizCompleteFunction();
      setTimerExpiredPrimaryCounterFlag(false);
    }
  }, [expiredTimerPrimaryCounterFlag]);



  const { userId } = useSelector(
    (state) => ({
      userId: state.auth ? state.auth.user ? state.auth.user.userId : null : null,
    }),
    shallowEqual
  );

  const skipQuestion = () => {
    if (activeQuestion < totalQuestions) {
      loading(true);
      fetchReq();
    } else {
      quizCompleteFunction();
    }
  }

  const quizCompleteFunction = () => {
    setQuizCompleted(true);
    setTickerActivate(false);
    setCampaignFinish(true)
  }

  const randIndex = () => {
    return Math.floor(Math.random() * 4);
  }
  const fetchReq = () => {

  }

  const updateQues = (response) => {
    setData(response.data.results[0]);
    var activeData = response.data.results[0];
    if (activeData != null) {
      document.querySelector("#question").innerHTML = activeData.question;
      var options = activeData.incorrect_answers;
      options.splice(randIndex(), 0, activeData.correct_answer);
      document.querySelector("#optn1").innerHTML = options[0];
      document.querySelector("#optn2").innerHTML = options[1];
      document.querySelector("#optn3").innerHTML = options[2];
      document.querySelector("#optn4").innerHTML = options[3];
      setActiveQuestion(activeQuestion + 1);
      setTimerResetSecondaryCounterFlag(true);
      setStartFlag(true);
    }
    loading(false);
  }

  const checkAnswer = (event) => {
    setStartFlag(false);
    var ele = event.currentTarget;
    if (ele.innerHTML === { data }.correct_answer) {
      ele.classList.add("correct");
      loading(true);
      document.querySelector("#hscore").innerHTML = ++curScore;
      changeQues();
    } else {
      ele.classList.add("incorrect");
      loading(true);
      document.querySelector("#hscore").innerHTML = --curScore;
      changeQues();
    }
  }
  const changeQues = () => {
    if (activeQuestion < totalQuestions) {
      setTimeout(function () {
        for (var j = 0; j < ans.length; j++) {
          ans[j].classList.remove("incorrect")
          ans[j].classList.remove("correct")
        }
        fetchReq();
      }, 800)
    } else {
      quizCompleteFunction();
    }
  }
  const loading = (fire) => {
    if (fire) {
      waiting.classList.add("loading");
      loadAnim.classList.add("animation");
    } else {
      waiting.classList.remove("loading");
      loadAnim.classList.remove("animation");
    }
  }

  /**/

  return (
    <>
      <div class="quiz-master-data guideline-popup guideline-shadow-border-nopad cursor-pointer h-100 
   mt-5">
        <div class="d-flex flex-row flex-nowrap flex-coloumn-gap-20 flex-row-gap-20 w-100 pb-5">
          <div class="btn btn-primary flex-center d-flex flex-coloumn-gap-5 flex-row-gap-5" data-dismiss="modal" style={{ columnGap: "5px" }}>
            <span>Save</span> <i class="material-icons">save</i></div>
          <div class="btn btn-secondary flex-center d-flex flex-coloumn-gap-10 flex-row-gap-5" data-dismiss="modal" style={{ columnGap: "5px" }}><span>Add Question</span> <i class="material-icons">add</i></div>
        </div>
        <div data-section-name="guideline_content" class="guideline_block col-md-12 row px-0 mx-0 mt-0 grow-card flex-row-gap-20 h-100" style={{ overflowY: "auto" }}>
          <div class="guide_advanced_mode col-md-12 row mx-0 px-0 mt-0 show_item flex-coloumn-gap-20 flex-row-gap-20 pt-0 fadeinelement h-100" style={{ background: "#00000000" }}>
            <div class="row px-0 mx-0 mt-0 pb-0 pb-5 align-content-start flex-column grow-card t-plc flex-coloumn-gap-20 flex-row-gap-20" style={{display: "flex !important"}} data-sub-section-name="guideline-content-images">
              <div class="row px-0 mx-0 mt-0 pb-0 align-content-start flex-column flex-coloumn-gap-20 w-100 grow-card guideline-two-column-container fadeinelement bg-white" data-level="4">
                <div class="d-flex row mx-0 mt-0 px-0 mt-0 flex-column w-100 pt-5 pl-5 pr-5">
                  <div class="row mx-0 mt-0 px-0 mt-0 flex-column">
                    <div class="d-flex flex-nowrap justify-content-between mt-0 mb-2">
                      <div class="row mx-0 d-flex px-0 pb-0 mb-0 mt-0 flex-coloumn-gap-10 align-items-center">
                        <div class="mb-0 mt-0 px-0 font-size-h6 t-plc s-l ed_placeholder_text" contenteditable="true" style={{
                          backgroundColor: "#f3f6f9!important",
                          border: "1px solid #f3f6f9",
                          borderRadius: "4px!important",
                          cursor: "text",
                          padding: "12px 10px!important",
                          transition: "color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease"
                        }}
                        >Add your question?</div>
                      </div>
                      <div class="guideline-image-toolbar cursor-pointer d-flex h-25px flex-row justify-content-end flex-coloumn-gap-10 d-flex-row-gap-20">
                        <div class="p-1 rounded-circle d-flex justify-content-center align-items-center cursor-pointer  __se__tag w-25px h-25px" data-guideline="guideline-remove-guideline-child" style={{ backgroundColor: "#000000c4", zIndex: 99 }}><i class="material-icons small">close</i></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-0 d-flex flex-wrap overflow-hidden pr-5 pt-5 pb-5 pl-5  flex-column-gap-20 flex-row-gap-20 w-100 fadeinelement" >
                  <div class="px-0 d-flex mt-0 flex-column card card_list overflow-hidden card_view align-items-start flex-nowrap h-auto justify-space-between grow-card d-w-auto-column-restricted guideline-shadow-border" data-level="5" data-filled="true">
                    <div class="guideline-image-toolbar w-100 cursor-pointer d-flex h-25px flex-row justify-content-end flex-coloumn-gap-10 d-flex-row-gap-20" >
                      <div class="p-1 rounded-circle d-flex justify-content-center align-items-center cursor-pointer  __se__tag w-25px h-25px" data-guideline="guideline-image-upload" style={{ backgroundColor: "#6FCF97", zIndex: 99 }}><i class="material-icons small text-white">done</i></div>
                      <div class="p-1 rounded-circle d-flex justify-content-center align-items-center cursor-pointer  __se__tag w-25px h-25px" data-guideline="guideline-remove-guideline-child" style={{ backgroundColor: "#000000c4", zIndex: 99 }}><i class="material-icons small">close</i></div>
                    </div>
                    <div class="font-size-base t-plc s-l d-flex pt-5 flex-coloumn-gap-15 flex-row-gap-15 flex-wrap flex-row w-100" >
                      <div class="ed_placeholder_text min-h-60px rounded overflow-auto editable-field w-100 __se__tag ed_placeholder_text min-h-60px rounded overflow-auto editable-field w-100 __se__tag p-2" data-place-holder-text="Add any additional information you want to share with the platform administrators." data-hide-on-empty="true"
                        style={{
                          backgroundColor: "#f3f6f9!important",
                          border: "1px solid #f3f6f9",
                          borderRadius: "4px!important",
                          cursor: "text",
                          padding: "12px 10px!important",
                          transition: "color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease"
                        }}
                        contenteditable="true text_muted">Add answer</div>
                    </div>
                  </div>
                  <div class="px-0 d-flex mt-0 flex-column card card_list overflow-hidden card_view align-items-start flex-nowrap h-auto justify-space-between grow-card d-w-auto-column-restricted guideline-shadow-border" data-level="5" data-filled="true">
                    <div class="guideline-image-toolbar w-100 cursor-pointer d-flex h-25px flex-row justify-content-end flex-coloumn-gap-10 d-flex-row-gap-20" >
                      <div class="p-1 rounded-circle d-flex justify-content-center align-items-center cursor-pointer  __se__tag w-25px h-25px" data-guideline="guideline-image-upload" style={{ backgroundColor: "#6FCF97", zIndex: 99 }}><i class="material-icons small text-white">done</i></div>
                      <div class="p-1 rounded-circle d-flex justify-content-center align-items-center cursor-pointer  __se__tag w-25px h-25px" data-guideline="guideline-remove-guideline-child" style={{ backgroundColor: "#000000c4", zIndex: 99 }}><i class="material-icons small">close</i></div>
                    </div>
                    <div class="font-size-base t-plc s-l d-flex pt-5 flex-coloumn-gap-15 flex-row-gap-15 flex-wrap flex-row w-100" >
                      <div class="ed_placeholder_text min-h-60px rounded overflow-auto editable-field w-100 __se__tag ed_placeholder_text min-h-60px rounded overflow-auto editable-field w-100 __se__tag p-2" data-place-holder-text="Add any additional information you want to share with the platform administrators." data-hide-on-empty="true"
                        style={{
                          backgroundColor: "#f3f6f9!important",
                          border: "1px solid #f3f6f9",
                          borderRadius: "4px!important",
                          cursor: "text",
                          padding: "12px 10px!important",
                          transition: "color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease"
                        }}
                        contenteditable="true">Add answer</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex row mx-0 mt-0 px-0 mt-0 flex-column w-100 pt-5 pl-5 pr-5">
                  <div class="row mx-0 mt-0 px-0 mt-0 flex-column">
                    <div class="d-flex flex-nowrap justify-content-end mt-0 mb-2">
                      <div class="d-flex justify-content-end px-0 brand_editor_data mb-0 cursor-pointer" data-guideline="guideline-add-guideline-child">                        <img class="mb-1 cursor-pointer __se__tag data-image-value" src="/media/custom/add_data.svg" data-guideline="guideline-add-guideline-child" data-se-embed="true" data-image-value="true" data-origin="," /> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}