import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  page: "",
  pageSize: "",
  populate: [],
  fields: [],
   sort: "",
  query: {
    fromUserId: ""
  },
  totalCount: 0,
  totalPages: 1,
  paymentHistoryDetails:null,
  rows:null
}
  
  export const callTypes = {
    list: "list",
    action: "action",
  };
  
  export const paymentHistorySlice = createSlice({
    name: "paymenthistory",
    initialState: initialState,
    reducers: {
      catchError: (state, action) => {
        state.error = `${action.type}: ${action.payload.error}`;
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else {
          state.actionsLoading = false;
        }
      },
      startCall: (state, action) => {
        state.error = null;
        if (action.payload.callType === callTypes.list) {
          state.listLoading = true;
        } else {
          state.actionsLoading = true;
        }
      },
      callSuccess: (state, action) => {
        state.error = null;
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else {
          state.actionsLoading = false;
        }
      },
      paymentHistoryDetailsFetched: (state, action) => {
        let { paymentHistoryDetails } = action.payload;
        state.listLoading = false;
        state.error = null;
        state.paymentHistoryDetails = paymentHistoryDetails;
      },
    }
});