import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  error: null,
  totalRewardAmount: 0,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const rewardSlice = createSlice({
  name: "reward",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    callSuccess: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    rewardAmountFetched: (state, action) => {
      let { totalRewardAmount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.totalRewardAmount = totalRewardAmount;
    },

  },
});
