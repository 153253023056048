/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, useEffect, useState } from "react";
import { getConvertedPayoutStatus } from "../../helper/helper";
import moment from "moment";
import { shallowEqual, useSelector } from "react-redux";

const PaymentDetails = ({ stepper, application }) => {
  const [payment, setPayment] = useState({
    status: "Payment Pending",
    color: "text-warning",
  });

  useEffect(() => {
    if (application?.payoutStatus) {
      setPayment(getConvertedPayoutStatus(application?.payoutStatus));
    } else if (campaignDetails?.campaignType === "SOCIAL_POST" && application?.contentStatus === "REJECTED_BY_BRAND" && application?.paymentStatus === "CAPTURED") {
      setPayment({
        status: "Payment in Hold",
        color: "text-warning",
      })
    }
    else if (application?.paymentStatus === "CAPTURED") {
      setPayment({
        status: "Payment Initiated",
        color: "text-success",
      });
    }
  }, [application]);

  const {
    campaignDetails, 
  } = useSelector(
    (state) => ({
      campaignDetails: state.campaign.campaignDetails,
    }),
    shallowEqual
  );

  return (
    <>
      {application?.payoutStatus !== null ? (
        <div
          class={`wizard-body py-8 px-8 py-lg-20 px-lg-10 ${
            stepper === "paymentDetails" ? "" : "d-none"
          }`}
        >
          <div className="row">
            <div className="w-100">
              <div className={`card  bg-light-warning rounded p-5 mb-5`}>
                <div class="flex-grow-1">
                  <div class="d-flex align-items-center mb-0">
                    <div class="symbol symbol-30 symbol-danger mr-2">
                      <a
                        href="#"
                        class={`btn  bg-warning mr-2 h-40px w-40px p-0 pt-3`}
                      >
                        <img
                          class="mr-0 h-20px"
                          src="/media/svg/icons/custom/Icon_edit.svg"
                          alt="edit-icon"
                        />
                      </a>
                    </div>
                   {campaignDetails?.campaignType === "SOCIAL_POST" &&
                   application?.contentStatus ==="SOCIAL_MEDIA_URL_REQUESTED" ?
                    <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                      <a
                        href="#"
                        class={`text-warning mb-0 font-weight-bolder  font-size-lg text-truncate`}
                      >
                        Payment Pending
                      </a>
                      <span class="text-dark font-size-sm">Payment Status</span>
                    </div> :null}
                  </div>
                  {/* {applicationDetails?.rejectedReason && (
                <p className="quotes mb-0 font-size-sm mt-5 font-weight-bold">
                  {applicationDetails?.rejectedReason}
                </p>
              )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          class={`wizard-body py-8 px-8 py-lg-20 px-lg-10 ${
            stepper === "paymentDetails" ? "" : "d-none"
          }`}
        >
          <div class="row">
            {/* <div class="w-100">
          <h6 class="font-weight-Bold mb-6">Photography/ Creatives</h6>
        </div> */}
            <div class="card card-custom card-stretch gutter-b shadow-none mb-0 w-100">
              <div class="card-header border-0 pt-5 px-0 d-flex align-items-center">
                <div class="flex-nowrap d-flex">
                  <div class="symbol symbol-30 symbol-danger mr-2">
                    <a class="btn  bg-success mr-2 h-40px w-40px p-0 pt-3">
                      <img
                        class="mr-0 h-20px"
                        src="/media/svg/icons/custom/Icon_payment.svg"
                        alt="payment-icon"
                      />
                    </a>
                  </div>
                  <h3 class="font-weight-bolder my-0 text-dark card-title align-items-start flex-column">
                  <span
                      class={`card-label font-weight-SemiBold ${payment?.color}`}
                    >
                      {payment?.status}
                    </span>
                    <span class="text-dark-50 mt-1 font-weight-bold font-size-sm">
                      Payment Status
                    </span>
                  </h3>
                </div>
                {["PAYOUT_INITIATED", "PAYOUT_PROCESSED"]?.includes(
                  application?.payoutStatus
                ) && (
                  <div class="card-toolbar">
                    <div class="d-flex flex-nowrap">
                      <div class="d-flex align-items-center mb-0">
                        <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                          <a
                            href="#"
                            class="text-dark mb-0 font-size-lg font-weight-bolder text-right text-truncate text-dark-75"
                          >
                            {moment(
                              new Date(application?.paymentDetails?.updatedAt)
                            ).format("MMMM DD, YYYY")}
                          </a>
                          {/* <span class="text-dark-50 font-size-xs text-right">
                          <a href="#" class="font-weight-SemiBold text-primary">
                            Download Invoice
                          </a>
                        </span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {application?.payoutStatus === "PAYOUT_PROCESSED" && (
                <div class="card-body px-0">
                  <div class="row col-12">
                    <div class="col-md-8"></div>
                    <div class="col-md-4">
                      <div class="w-100 rewards_card">
                        <img
                          src="/media/custom/rewards.png"
                          alt="reward-icon"
                          class="w-100"
                        />
                        <h5>Congrats!</h5>
                        <h6>{application?.paymentDetails?.totalAmount}</h6>
                        <p>Rewards received!</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(PaymentDetails);
