export const getApplicationStatus = (data) => {
  switch (data) {
    case "WAITING_FOR_APPROVAL_BY_BRAND":
      return "secondary";
    case "REJECTED_BY_BRAND":
      return "danger";
    case "APPROVED_BY_BRAND":
      return "primary";
    default:
      return "info";
  }
};

export const getMyApplicationStatus = (data) => {
  switch (data) {
    case "WAITING_FOR_APPROVAL_BY_BRAND":
      return "warning";
    case "REJECTED_BY_BRAND":
      return "danger";
    case "APPROVED_BY_BRAND":
      return "success";
    default:
      return "info";
  }
};

export const getContentStatus = (data) => {
  switch (data) {
    case "RESUBMIT_CONTENT":
      return "warning";
    case "REJECTED_BY_BRAND":
      return "danger";
    case "SOCIAL_MEDIA_URL_REQUESTED":
      return "warning";
    case "URL_SUBMITTED_BY_CREATOR":
      return "primary";
    case "APPROVED_BY_BRAND":
      return "success";
    case "WAITING_FOR_APPROVAL_BY_BRAND":
      return "warning";
    case "DRAFT":
      return "primary";
    default:
      return "info";
  }
};

export const getColor = (data) => {
  switch (data) {
    case "TECHNOLOGY":
      return "#1BC5BD";
    case "ART":
      return "green";
    case "SPORTS":
      return "yellow";
    case "ENTERTAINMENT":
      return "#F64E60";
    case "FASHION":
      return "primary";
    case "white":
      return "warning";
    case "violet":
      return "warning";
    case "#FEF9A7":
      return "warning";
    case "BEVERAGES":
      return "#C70A80";
    case "E-COMMERCE":
      return "#A5BECC";
    case "ELECTRONICS":
      return "#EC9B3B";
    case "FINANCE":
      return "#afff7b";
    case "FITNESS":
      return "#14C38E";
    case "HEALTHCARE":
      return "#EEB0B0";
    case "HOMEAPPLIANCES":
      return "#F9CEEE";
    case "MEDIA":
      return "#82954B";
    case "PET":
      return "#E4AEC5";
    case "RESTAURANT":
      return "#DEA057";
    case "RETAIL":
      return "#FFA800";
    case "TRAVEL":
      return "#1BC5BD";

    default:
      return "#ffffff";
  }
};

export const getreward = (data) => {
  if (data?.endsWith("INR")) {
    let newString = data?.replace("INR", "");
    let reward = " " + parseInt(newString);
    return reward;
  } else {
    let newString = data?.replace("USD", "");
    let reward = parseInt(newString);
    return reward;
  }
};
