import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { GuidelineQuizComponent } from "./micro-interations/GuidelineQuizComponent";
import { ScratchCardComponent } from "./micro-interations/ScratchCardComponent";
import CountDownGuidelineTicker from "./micro-interations/CountDownGuidelineTicker";
import { GuidelineQuizMaster } from "./micro-interations/GuidelineQuizMaster";
import axios from 'axios'

export function GuidelineImagePopup({
  popupFullScreen,
  setPopupFullScreen,
  refreshPopup,
  setRefreshPopup,
  showPopupFunction,
  updateImageArrayFunction,
  applyForThisCampaign,
  campaignAppliedFlag,
  setCampaignAppliedFlag,
}) {
  const { userId } = useSelector(
    (state) => ({
      userId: state.auth ? state.auth.user ? state.auth.user.userId : null : null,
    }),
    shallowEqual
  );

  const [resetTimerPrimaryCounterFlag, setTimerResetPrimaryCounterFlag] = useState(false);
  const [resetTimerSecondaryCounterFlag, setTimerResetSecondaryCounterFlag] = useState(false);
  const [startTimerCounterFlag, setStartTimerCounterFlag] = useState(false);

  const [expiredTimerPrimaryCounterFlag, setTimerExpiredPrimaryCounterFlag] = useState(false);
  const [expiredTimerSecondaryCounterFlag, setTimerExpiredSecondaryCounterFlag] = useState(false);

  const [closePopupOnClick, setPopupCloseOnClick] = useState(false);

  const [popupLocalStateFullScreen, setPopupLocalStateFullScreen] = useState(false);

  const [tickerActivate, setTickerActivate] = useState(false);

  const [quizActivate, setQuizActivate] = useState(false);

  const [scratchCardActive, setScratchCardActive] = useState(false);


  const [imagePreviewActivate, setImagePreviewActivate] = useState(false);


  const [videoPreviewActivate, setVideoPreviewActivate] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps

  const [guideLineImageArray, setGuideLineImageArray] = useState([]); //this helps

  const [guideLineIndex, setGuideLineIndex] = useState([]); //this helps

  const [campaignFinish, setCampaignFinish] = useState(setCampaignAppliedFlag); //this helps

  const [dialogActive, setDialogActive] = useState(false);

  const [scratchCardPeriod, setScratchCardPeriod] = useState("10:m");


  const [eventTimerPeriod, setEventTimerPeriod] = useState("");

  const [quizMasterActivate, setQuizMasterActivate] = useState(false);

  const [quizMasterData, setQuizMasterData] = useState();

  const [quizUserState, setUserState] = useState();

  const campaign = useSelector((state) => state.campaign ? state.campaign.campaignDetails : null, shallowEqual);

  useEffect(() => {
    if (refreshPopup) {
      setPopupLocalStateFullScreen(popupFullScreen);
      setRefreshPopup(false);
    }
  }, [refreshPopup]);

  useEffect(() => {
    if (!tickerActivate) {
      setPopupFullScreen(false);
      setRefreshPopup(true);
    }
  }, [tickerActivate]);

  useEffect(() => {
    if (campaignFinish && !campaignAppliedFlag) {
      if (applyForThisCampaign != null) {
        applyForThisCampaign(false);
        setPopupFullScreen(false);
        setCampaignAppliedFlag(true);
      }
      //alert("Camapign Completed");
    }
  }, [campaignFinish]);



  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      showPopupFunction.current = showGuidelineImagePopup;
      updateImageArrayFunction.current = updateImageArray;
    }
  }, [isLoaded]);


  const updateImage = (i) => {
    let path = guideLineImageArray[i];
    let guidelineImagePopup = document.getElementById("guideline-image-popup");
    let popupDialog = guidelineImagePopup.querySelector(".popup");
    let popupDialogOverlay = guidelineImagePopup.querySelector(".popup-overlay");

    if (guidelineImagePopup != null) {

      let imageTitle = guidelineImagePopup.querySelector(".image-name");
      let imageIndex = guidelineImagePopup.querySelector(".guideLineIndex");
      let leftNavig = guidelineImagePopup.querySelector(".left-arrow");
      let rightNavig = guidelineImagePopup.querySelector(".right-arrow");

      var matchType = "image";
      if (path.match(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i)) {
        matchType = "image";
      } else if (path.match(/\.(doc?x|pdf?|txt|ppt?x|bmp)$/i)) {
        matchType = "document";
      } else if (path.match(/\.(avi|mov|mp4|mpeg)$/i)) {
        matchType = "video";
      } else if (path.match(/\.(zip|.z)$/i)) {
        matchType = "compressed";
      } else if (path.match(/\.(mp3|wav)$/i)) {
        matchType = "vector";
      }

      let imageHolder = guidelineImagePopup.querySelector(".image-placeholder img");
      let videoHolder = guidelineImagePopup.querySelector(".video-placeholder video");
      switch (matchType) {
        case "image":
          imageHolder.src = path;
          guidelineImagePopup.querySelector(".image-placeholder").style.setProperty("display", "flex", "important");
          guidelineImagePopup.querySelector(".video-placeholder").style.setProperty("display", "none", "important");
          videoHolder.removeAttribute("autoplay");
          videoHolder.src = "";
          break;
        case "video":
          videoHolder.setAttribute("autoplay", "");
          videoHolder.setAttribute("muted", "");
          videoHolder.src = path;
          guidelineImagePopup.querySelector(".video-placeholder").style.setProperty("display", "flex", "important");
          guidelineImagePopup.querySelector(".image-placeholder").style.setProperty("display", "none", "important");
          break;
        default:
          break;
      }

      setGuideLineIndex(i);

      if (guideLineIndex > 0) {
        leftNavig.style.setProperty(
          "display",
          "block",
          "important"
        );
      } else {
        leftNavig.style.setProperty(
          "display",
          "none",
          "important"
        );
      }
      if (guideLineIndex < guideLineImageArray.length - 1) {
        rightNavig.style.setProperty(
          "display",
          "block",
          "important"
        );
      } else {
        rightNavig.style.setProperty(
          "display",
          "none",
          "important"
        );
      }
    }
  }

  const showGuidelineImagePopup = (showFlag, rewardType, imageArray, title) => {
    var parentEle = document.getElementById("guideline-image-popup");
    let guidelineImagePopup = parentEle.querySelector(".parent-guideline-popup");
    let popupDialog = parentEle.querySelector(".popup");
    let popupDialogOverlay = parentEle.querySelector(".popup-overlay");
    setGuideLineIndex(0);
    if (guidelineImagePopup != null) {
      if (showFlag) {
        checkUserLoggedIn();
        let imageTitle = guidelineImagePopup.querySelector(".image-name");
        var path = rewardType;
        imageTitle.innerHTML = title;
        var matchType = "image";;
        if (path.match(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i)) {
          matchType = "image";
        } else if (path.match(/\.(doc?x|pdf?|txt|ppt?x|bmp)$/i)) {
          matchType = "document";
        } else if (path.match(/\.(avi|mov|mp4|mpeg)$/i)) {
          matchType = "video";
        } else if (path.match(/\.(zip|.z)$/i)) {
          matchType = "compressed";
        } else if (path.match(/\.(mp3|wav)$/i)) {
          matchType = "vector";
        } else if (path.match(/(scratch-card|gift-card)/g)) {
          matchType = "scratch-card";
        } else if (path.match(/(quiz-card)/g)) {
          matchType = "quiz-card";
        } else if (path.match(/(quiz-master-card)/g)) {
          matchType = "quiz-master-card";
        }
        //imageHolder = guidelineImagePopup.querySelector(".image-placeholder");
        //let videoHolder = guidelineImagePopup.querySelector(".video-placeholder");
        //let videoHolderObject = videoHolder.querySelector("video");
        var allPlaceHolders = guidelineImagePopup.querySelectorAll(".guideline-popup");
        Array.from(allPlaceHolders).forEach((element, index) => {
          element.style.setProperty("display", "none", "important");
        });
        switch (matchType) {
          case "image":
            setPopupFullScreen(false);
            setImagePreviewActivate(true);
            setVideoPreviewActivate(true);
            setScratchCardActive(false);
            setRefreshPopup(true);
            var imageHolder = guidelineImagePopup.querySelector(".image-placeholder img");
            imageHolder.src = path;
            guidelineImagePopup.querySelector(".image-placeholder").style.setProperty("display", "flex", "important");
            break;
          case "video":
            setPopupFullScreen(false);
            setScratchCardActive(false);
            setImagePreviewActivate(true);
            setVideoPreviewActivate(true);
            setRefreshPopup(true);
            var videoHolderObject = guidelineImagePopup.querySelector(".video-placeholder video");
            videoHolderObject.setAttribute("autoplay", "");
            videoHolderObject.setAttribute("muted", "");
            videoHolderObject.src = path;
            guidelineImagePopup.querySelector(".video-placeholder").style.setProperty("display", "flex", "important");
            break;
          case "scratch-card":
            setScratchCardActive(true);
            setPopupFullScreen(false);
            var matchScratchCardPeriod = path.match("\\[.*]");
            if (matchScratchCardPeriod != null) {
              var rst = matchScratchCardPeriod[0].replace("[", "")
              setScratchCardPeriod(rst.replace("]", ""));
            }
            var scratchCardPlaceholder = guidelineImagePopup.querySelector(".scratch-card-placeholder");
            if (scratchCardPlaceholder != null) {
              var rand = Math.random() * imageArray.length;
              rand = Math.floor(rand); // 99
              var rewardImageURL = imageArray[rand];
              var imgHolder = scratchCardPlaceholder.querySelector("img");
              if (imgHolder != null) {
                imgHolder.src = rewardImageURL;
              }
            }
            scratchCardPlaceholder.style.setProperty("display", "flex", "important");
            break;
          case "quiz-card":
            var matchScratchCardPeriod = path.match("\\[.*]");
            if (matchScratchCardPeriod != null) {
              var rst = matchScratchCardPeriod[0].replace("[", "")
              rst = rst.replace("]", "");
            }
            if (rst != null) {
              instance.get('thox-quiz-master?q={"quiz_name":"' + rst + '"}&max=1')
                .then(updateState);
              var scratchCardPlaceholder = guidelineImagePopup.querySelector(".quiz-place-holder");
              if (scratchCardPlaceholder != null) {
                scratchCardPlaceholder.style.setProperty("display", "flex", "important");
              }

            }
            break;
          case "quiz-master-card":
            setQuizMasterActivate(true);
            break;
          default:
            break;
        }
        guidelineImagePopup = parentEle.querySelector(".parent-guideline-popup");
        popupDialogOverlay.style.setProperty(
          "display",
          "flex",
          "important"
        );
        if (!guidelineImagePopup.classList.contains("active")) {
          guidelineImagePopup.classList.toggle('active');
        }

        document.getElementsByTagName('html')[0].style.overflow = "hidden";
        setDialogActive(true);
      } else {
        document.getElementsByTagName('html')[0].style.overflow = "auto";
        popupDialogOverlay.style.setProperty(
          "display",
          "none",
          "important"
        );
        if (guidelineImagePopup.classList.contains("active")) {
          guidelineImagePopup.classList.toggle('active');
        }
        let videoHolder = guidelineImagePopup.querySelector(".video-placeholder");
        if (videoHolder != null) {
          let videoHolderObject = videoHolder.querySelector("video");
          videoHolderObject.removeAttribute("autoplay");
          videoHolderObject.src = "";
        }
        setQuizActivate(false);
        setTickerActivate(false);
        setScratchCardActive(false);
        setVideoPreviewActivate(false);
        setImagePreviewActivate(false);
        setDialogActive(false);
      }
    }
  };

  const instance = axios.create({
    baseURL: 'https://iaaquiz-1cdf.restdb.io/rest/',
    headers: {
      'content-type': 'application/json',
      'x-apikey': '63c626c6969f06502871af7b'
    },
  });


  // server request for apply campaign
  const checkUserLoggedIn = () => {
    if (userId == null) {
      localStorage.setItem("reloadUrl", window.location.pathname);
      window.location.replace("/auth/login");
    }
  };

  const updateState = (response) => {
    var activeData = response.data[0];
    if (activeData != null) {
      setQuizMasterData(activeData);
      var primaryTimerSector = activeData.duration;
      var secondaryTimerSector = activeData.question_time_out;
      var primaryTimeArray = primaryTimerSector.split(" ");
      var primaryDuration = primaryTimeArray[0];
      var primaryDurationType = primaryTimeArray[1];

      var secondaryTimeArray = secondaryTimerSector.split(" ");
      var secondaryDuration = secondaryTimeArray[0];
      var secondaryDurationType = secondaryTimeArray[1];

      setEventTimerPeriod(primaryTimerSector + "-" + secondaryTimerSector);
      campaign ?
        instance.get('thox-quiz-user-data?q={' +
          '"quiz_master_id._id":"' + activeData._id + '",' +
          '"campaign_id":"' + campaign.campaignId + '",' +
          '"user_id":"' + userId + '"' +
          '}&max=1')
          .then(updateUserState) :
        instance.get('thox-quiz-user-data?q={' +
          '"quiz_master_id._id":"' + activeData._id + '",' +
          '"user_id":"' + userId + '"' +
          '}&max=1').then(updateUserState)
    }
  }

  const updateUserState = (response) => {
    if (response.data != null && response.data.length > 0) {
      setUserState(response.data[0]);
      if (response.data[0].status == "completed") {
        setCampaignFinish(true);
        setPopupFullScreen(false);
      } else {
        setPopupFullScreen(true);
        setTickerActivate(true);
      }
    } else {
      setPopupFullScreen(true);
      setTickerActivate(true);
    }
    setQuizActivate(true);
    setRefreshPopup(true);
  }

  const updateImageArray = (targetImageArray) => {
    setGuideLineImageArray(targetImageArray);
    let guidelineImagePopup = document.getElementById("guideline-image-popup");
    let popupDialog = guidelineImagePopup.querySelector(".popup");
    let leftNavig = guidelineImagePopup.querySelector(".left-arrow");
    let rightNavig = guidelineImagePopup.querySelector(".right-arrow");
    leftNavig.style.setProperty(
      "display",
      "none",
      "important"
    );
    if (targetImageArray != null && targetImageArray.length > 1) {
      rightNavig.style.setProperty(
        "display",
        "block",
        "important"
      );
    } else {
      rightNavig.style.setProperty(
        "display",
        "none",
        "important"
      );
    }
  }

  return (
    <>
      <div id="guideline-image-popup">
        <div class="popup-overlay flex flex-column rounded __se__tag" style={{ zIndex: 999999999, backdropFilter: "blur(5px)" }} onClick={() => {
          showGuidelineImagePopup(false);
          setStartTimerCounterFlag(false);
        }}></div>
        <div id="guideline-popup"
          className={!popupLocalStateFullScreen ? dialogActive ? "active " + "parent-guideline-popup popup flex flex-column rounded d-flex justify-content-center" : "parent-guideline-popup popup flex flex-column rounded d-flex justify-content-center"
            : dialogActive ? "active " + "parent-guideline-popup popup flex flex-column h-100 w-100 max-w-100 max-h-100 d-flex justify-content-center" : "parent-guideline-popup popup flex flex-column h-100 w-100 max-w-100 max-h-100 d-flex justify-content-center"
          }
          style={{ zIndex: 999999999 }}>
          <div id="waiting"></div>
          <div id="waitanim"></div>
          <div class="top-bar flex flex-column">
            <div class="image-name flex-center text-white pb-5 font-size h2">Title placeholder</div>
            <div class="close-btn p-1 rounded-circle d-flex justify-content-center align-items-center cursor-pointer position-absolute"
              style={{ top: 12, right: 13, width: 30, height: 30, backgroundColor: "#ececec" }} onClick={() => {
                showGuidelineImagePopup(false);
                setStartTimerCounterFlag(false);
              }}>
              <img alt="icon-view" src="/media/svg/icons/custom/Icon_close.svg" style={{ width: 8, height: 8, resizeMode: "contain" }}></img>
            </div>
          </div>

          <div class="arrow-btn left-arrow"><img
            src="/media/custom/back_btn.svg" alt="" onClick={() => {
              if (guideLineIndex > 0) {
                updateImage(guideLineIndex - 1);
              }
            }}></img></div>
          <div class="arrow-btn right-arrow"><img
            src="/media/custom/back_btn.svg" alt="" onClick={() => {
              if (guideLineIndex < guideLineImageArray.length - 1) {
                updateImage(guideLineIndex + 1);
              }
            }}></img></div>
          {imagePreviewActivate ?
            <div class="image-placeholder large-image  position-relative pb-5 justify-content-center"
              style={{
                display: "none"
              }}>
              <div class="d-flex flex-center h-100 w-100">
                <img src="" class="large-image  position-relative pb-5" alt="" style={{
                  objectFit: "contain"
                }}
                ></img>
              </div>
            </div>
            : null}

          {videoPreviewActivate ?
            <div class="video-placeholder guideline-popup large-image  position-relative pb-5 justify-content-center"
              style={{
                display: "none"
              }}>
              <div class="h-100">
                <div class="d-flex video-container flex-center h-100 w-100">
                  <video class="flex-center h-100 w-100" controls>
                  </video>
                </div>
              </div>
            </div>
            : null}
          {tickerActivate && !campaignFinish ?
            <CountDownGuidelineTicker
              eventTimerPeriod={eventTimerPeriod}
              resetTimerPrimaryCounterFlag={resetTimerPrimaryCounterFlag}
              setTimerResetPrimaryCounterFlag={setTimerResetPrimaryCounterFlag}
              expiredTimerPrimaryCounterFlag={expiredTimerPrimaryCounterFlag}
              setTimerExpiredPrimaryCounterFlag={setTimerExpiredPrimaryCounterFlag}
              resetTimerSecondaryCounterFlag={resetTimerSecondaryCounterFlag}
              setTimerResetSecondaryCounterFlag={setTimerResetSecondaryCounterFlag}
              expiredTimerSecondaryCounterFlag={expiredTimerSecondaryCounterFlag}
              setTimerExpiredSecondaryCounterFlag={setTimerExpiredSecondaryCounterFlag}
              startFlag={startTimerCounterFlag}
              setStartFlag={setStartTimerCounterFlag}
              tickerActivate={tickerActivate}
              setTickerActivate={setTickerActivate}
              quizMasterData={quizMasterData}
              quizUserState={quizUserState}
            /> : null
          }
          {scratchCardActive ?
            <ScratchCardComponent
              scratchCardPeriod={scratchCardPeriod}
              campaignAppliedFlag={campaignAppliedFlag}
              campaignFinish={campaignFinish}
              setCampaignFinish={setCampaignFinish} />
            : null}

          {quizActivate ?
            <GuidelineQuizComponent
              resetTimerPrimaryCounterFlag={resetTimerPrimaryCounterFlag}
              setTimerResetPrimaryCounterFlag={setTimerResetPrimaryCounterFlag}
              expiredTimerPrimaryCounterFlag={expiredTimerPrimaryCounterFlag}
              setTimerExpiredPrimaryCounterFlag={setTimerExpiredPrimaryCounterFlag}
              resetTimerSecondaryCounterFlag={resetTimerSecondaryCounterFlag}
              setTimerResetSecondaryCounterFlag={setTimerResetSecondaryCounterFlag}
              expiredTimerSecondaryCounterFlag={expiredTimerSecondaryCounterFlag}
              setTimerExpiredSecondaryCounterFlag={setTimerExpiredSecondaryCounterFlag}
              startFlag={startTimerCounterFlag}
              setStartFlag={setStartTimerCounterFlag}
              tickerActivate={tickerActivate}
              setTickerActivate={setTickerActivate}
              campaignAppliedFlag={campaignAppliedFlag}
              campaignFinish={campaignFinish}
              setCampaignFinish={setCampaignFinish}
              quizMasterData={quizMasterData}
              quizUserState={quizUserState}
              campaign={campaign}
            />
            : null}

          {quizMasterActivate ?
            <GuidelineQuizMaster
              resetTimerPrimaryCounterFlag={resetTimerPrimaryCounterFlag}
              setTimerResetPrimaryCounterFlag={setTimerResetPrimaryCounterFlag}
              expiredTimerPrimaryCounterFlag={expiredTimerPrimaryCounterFlag}
              setTimerExpiredPrimaryCounterFlag={setTimerExpiredPrimaryCounterFlag}
              resetTimerSecondaryCounterFlag={resetTimerSecondaryCounterFlag}
              setTimerResetSecondaryCounterFlag={setTimerResetSecondaryCounterFlag}
              expiredTimerSecondaryCounterFlag={expiredTimerSecondaryCounterFlag}
              setTimerExpiredSecondaryCounterFlag={setTimerExpiredSecondaryCounterFlag}
              startFlag={startTimerCounterFlag}
              setStartFlag={setStartTimerCounterFlag}
              tickerActivate={tickerActivate}
              setTickerActivate={setTickerActivate}
              campaignAppliedFlag={campaignAppliedFlag}
              campaignFinish={campaignFinish}
              setCampaignFinish={setCampaignFinish}
            />
            : null}
        </div>
      </div>
    </>
  );
}