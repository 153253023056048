/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, memo } from "react";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { getApplicationDetails } from "../../../campaign/_redux/campaignActions";
import {
  updateSocialUrl,
  submitAllUrlStatus,
  listApplicationContents,
} from "../../../campaignApplications/_redux/applicationActions";
import useFileDownloader from "../../../../components/file-downloader/useFileDownloader";
import { Loader } from "../Loader";

const UrlDetailsForSocialCampaign = ({ stepper }) => {
  const dispatch = useDispatch();
  const [urls, setUrls] = useState({});
  const [clickedButton, setClickedButton] = useState();
  const [downloadFile, downloaderComponentUI] = useFileDownloader();
  const download = (file) => downloadFile(file);

  const {
    loggedInUserId,
    listLoading,
    actionsLoading,
    campaignDetails,
    applicationDetails,
    applicationContents,
  } = useSelector(
    (state) => ({
      loggedInUserId: state.auth.user?.userId,
      listLoading: state.application.listLoading,
      actionsLoading: state.application.actionsLoading,
      campaignDetails: state.campaign.campaignDetails,
      applicationDetails: state.campaign.applicationDetails[0] || {},
      applicationContents: state.application.applicationContents,
    }),
    shallowEqual
  );

  const uploadSocialUrl = (applicationContentId) => {
    dispatch(
      updateSocialUrl({
        loggedInUserId,
        applicationContentId,
        contentInstagramUrl: urls[applicationContentId]?.contentInstagramUrl,
        contentYoutubeUrl: urls[applicationContentId]?.contentYoutubeUrl,
      })
    ).then(() => {
      setUrls({});
      dispatch(
        listApplicationContents({
          page: 1,
          pageSize: 20,
          loggedInUserId,
          sort: "applicationContentId",
          query: {
            resubmitCount: applicationDetails?.resubmitCount,
            campaignId: applicationDetails?.campaignId,
            creatorId: loggedInUserId,
          },
        })
      );
    });
  };

  const submitSocialUrl = () => {
    let applicationContentId = applicationContents?.map(
      (content) => content.applicationContentId
    );

    dispatch(
      submitAllUrlStatus({
        loggedInUserId,
        applicationContentId: applicationContentId,
        applicationId: applicationDetails?.applicationId,
      })
    ).then(() =>
      dispatch(
        getApplicationDetails({
          page: 1,
          pageSize: 5,
          loggedInUserId,
          populate: ["brandDetail", "paymentDetails"],
          query: {
            campaignId: applicationDetails?.campaignId,
            creatorId: loggedInUserId,
          },
        })
      )
    );
  };

  const deleteUrl = (applicationContentId, platformUrl) => {
    let updateObj = {
      loggedInUserId,
      applicationContentId,
    };

    if (platformUrl === "contentInstagramUrl") {
      updateObj.contentInstagramUrl = null;
    } else {
      updateObj.contentYoutubeUrl = null;
    }
    dispatch(updateSocialUrl(updateObj)).then(() =>
      dispatch(
        listApplicationContents({
          page: 1,
          pageSize: 20,
          loggedInUserId,
          sort: "applicationContentId",
          query: {
            resubmitCount: applicationDetails?.resubmitCount,
            campaignId: applicationDetails?.campaignId,
            creatorId: loggedInUserId,
          },
        })
      )
    );
  };

  return (
    <>
      {listLoading ? (
        <div className="d-flex justify-content-center align-item-center my-10 mx-auto">
          <div className="spinner spinner-lg spinner-success" />
        </div>
      ) : (
        <>
          {stepper === "urlDetails" ? (
            <div class="content_list active col-9 px-0  border-bottom">
              <div class="col-12 mx-0 px-0">
                <div class="row mt-4">
                  {applicationContents?.map((content, index) => (
                    <div class="col-6 col-md-6 mt-2 ml-2">
                      <div
                        class="d_card d_gallery_view w-100 d-flex align-content-between flex-wrap cursor-pointer"
                        style={{
                          backgroundSize: "cover",
                          backgroundImage: content?.contentMediaUrl
                            ? "url(" +
                              `${process.env.REACT_APP_AWS_S3_BASE_URL +
                                content?.contentMediaUrl}` +
                              ")"
                            : "url(/media/dummyImages/no_preview_v2.png)",
                        }}
                      />
                      <div class="mx-0 row py-3 d-flex justify-content-between">
                        <span
                          class="btn btn-sm bg-light p-2 w-30px"
                          onClick={() =>
                            window.open(
                              `${process.env.REACT_APP_AWS_S3_BASE_URL +
                                content?.contentMediaUrl}`
                            )
                          }
                        >
                          <img
                            class=" h-15px "
                            src="/media/svg/icons/custom/icon_view.svg"
                          />
                        </span>
                        <span
                          class="btn btn-sm bg-light p-2 w-30px"
                          onClick={() =>
                            download({
                              file:
                                process.env.REACT_APP_AWS_S3_BASE_URL +
                                content.contentMediaUrl,
                              filename:
                                content.campaignType +
                                "_" +
                                Math.floor(Math.random() * 1000000 + 1),
                            })
                          }
                        >
                          <img
                            class=" h-15px "
                            src="/media/svg/icons/custom/Icon_download.svg"
                          />
                        </span>
                        {/* <span class="btn btn-sm bg-light p-2 w-30px">
                      <img
                        class=" h-15px "
                        src="/media/svg/icons/custom/Icon_delete.svg"
                      />
                    </span> */}
                      </div>

                      {/* <<<<<<<<<<<<<<<<<<<<<<<<<<Instagram URL>>>>>>>>>>>>>>>>>>>>>>>>> */}
                      <div class="mx-0 row py-3 d-flex justify-content-between">
                        {content?.contentInstagramUrl === null ? (
                          (campaignDetails?.isInstagramPostRequired ||
                            campaignDetails?.isInstagramStoryRequired) && (
                            <div class="form-group">
                              <h6 class="font-weight-Bold font-size-lg mb-6">Instagram</h6>
                              <label>Instagram media link</label>
                              <div class="d-flex flex-nowrap">
                                <div class="input-icon input-icon-right col-12">
                                  <input
                                    type="text"
                                    class="form-control form-control-solid"
                                    placeholder="Media Link"
                                    value={
                                      Object.keys(urls)?.includes(
                                        content.applicationContentId
                                      ) &&
                                      urls[content.applicationContentId]
                                        ?.contentInstagramUrl
                                        ? urls[content.applicationContentId]
                                            .contentInstagramUrl
                                        : ""
                                    }
                                    onChange={(e) =>
                                      setUrls({
                                        ...urls,
                                        [content.applicationContentId]: {
                                          ...urls[content.applicationContentId],
                                          contentInstagramUrl: e.target.value,
                                        },
                                      })
                                    }
                                  />
                                </div>

                                {/*START ***************button for update instagram URL-**************** */}
                                <button
                                  class="btn btn-sm btn-primary ml-2"
                                  disabled={
                                    listLoading ||
                                    actionsLoading ||
                                    (urls[content?.applicationContentId]
                                      ?.contentInstagramUrl
                                      ? false
                                      : true)
                                  }
                                  onClick={() => {
                                    setClickedButton(
                                      `contentInstagramUrl${index}`
                                    );
                                    uploadSocialUrl(
                                      content.applicationContentId
                                    );
                                  }}
                                >
                                  {clickedButton ===
                                    `contentInstagramUrl${index}` &&
                                  actionsLoading ? (
                                    <div class="spinner-border" role="status">
                                      <span class="sr-only">Loading...</span>
                                    </div>
                                  ) : (
                                    <span>
                                      <b>Update</b>
                                    </span>
                                  )}
                                </button>
                                {/*END ***************button for update instagram URL**************** */}
                              </div>
                            </div>
                          )
                        ) : (
                          <div class="col-12">
                            <div class="btn btn-icon btn-outline-secondary btn-sm mr-4">
                              <img
                                src="/media/svg/icons/custom/Icon_feather-link.svg"
                                class="h-15px"
                                alt="link-icon"
                              />{" "}
                            </div>

                            <a
                              class="font-weight-bold"
                              href={content?.contentInstagramUrl}
                            >
                              {content?.contentInstagramUrl}
                            </a>

                            {clickedButton ===
                              `deleteContentInstagramUrl${index}` &&
                            actionsLoading ? (
                              <Loader />
                            ) : (
                              applicationDetails?.contentStatus ===
                                "SOCIAL_MEDIA_URL_REQUESTED" && (
                                <span
                                  class="btn btn-sm bg-light p-2 w-30px"
                                  onClick={() => {
                                    setClickedButton(
                                      `deleteContentInstagramUrl${index}`
                                    );
                                    deleteUrl(
                                      content?.applicationContentId,
                                      "contentInstagramUrl"
                                    );
                                  }}
                                >
                                  <img
                                    class=" h-15px "
                                    src="/media/svg/icons/custom/Icon_delete.svg"
                                    alt="delete-icon"
                                  />
                                </span>
                              )
                            )}
                          </div>
                        )}
                      </div>

                      {/* <<<<<<<<<<<<<<<<<<<<<<<<<<Youtube url>>>>>>>>>>>>>>>>>>>>>>>>> */}
                      <div class="mx-0 row py-3 d-flex justify-content-between">
                        {content?.contentYoutubeUrl === null ? (
                          (campaignDetails?.isYoutubePostRequired ||
                            campaignDetails?.isYoutubeStoryRequired) && (
                            <div class="form-group">
                              <h6 class="font-weight-Bold font-size-lg mb-6">Youtube</h6>
                              <label>YouTube media link</label>
                              <div class="d-flex flex-nowrap">
                                <div class="input-icon input-icon-right col-12">
                                  <input
                                    type="text"
                                    class="form-control form-control-solid"
                                    placeholder="YouTube media link"
                                    value={
                                      Object.keys(urls)?.includes(
                                        content.applicationContentId
                                      ) &&
                                      urls[content.applicationContentId]
                                        ?.contentYoutubeUrl
                                        ? urls[content.applicationContentId]
                                            .contentYoutubeUrl
                                        : ""
                                    }
                                    onChange={(e) =>
                                      setUrls({
                                        ...urls,
                                        [content.applicationContentId]: {
                                          ...urls[content.applicationContentId],
                                          contentYoutubeUrl: e.target.value,
                                        },
                                      })
                                    }
                                  />
                                </div>
                                <button
                                  class="btn btn-sm btn-primary ml-2"
                                  disabled={
                                    listLoading ||
                                    actionsLoading ||
                                    (urls[content?.applicationContentId]
                                      ?.contentYoutubeUrl
                                      ? false
                                      : true)
                                  }
                                  onClick={() => {
                                    setClickedButton(
                                      `contentYoutubeUrl${index}`
                                    );
                                    uploadSocialUrl(
                                      content.applicationContentId
                                    );
                                  }}
                                >
                                  {clickedButton ===
                                    `contentYoutubeUrl${index}` &&
                                  actionsLoading ? (
                                    <div class="spinner-border" role="status">
                                      <span class="sr-only">Loading...</span>
                                    </div>
                                  ) : (
                                    <span>
                                      <b>Update</b>
                                    </span>
                                  )}
                                </button>
                              </div>
                            </div>
                          )
                        ) : (
                          <div class="col-12">
                            <div class="btn btn-icon btn-outline-secondary btn-sm mr-4">
                              <img
                                src="/media/svg/icons/custom/Icon_feather-link.svg"
                                class="h-15px"
                                alt="link-icon"
                              />{" "}
                            </div>

                            <a
                              class="font-weight-bold"
                              href={content?.contentYoutubeUrl}
                            >
                              {content?.contentYoutubeUrl}
                            </a>

                            {clickedButton ===
                              `deleteContentYoutubeUrl${index}` &&
                            actionsLoading ? (
                              <Loader />
                            ) : (
                              applicationDetails?.contentStatus ===
                                "SOCIAL_MEDIA_URL_REQUESTED" && (
                                <span
                                  class="btn btn-sm bg-light p-2 w-30px"
                                  onClick={() => {
                                    setClickedButton(
                                      `deleteContentYoutubeUrl${index}`
                                    );
                                    deleteUrl(
                                      content?.applicationContentId,
                                      "contentYoutubeUrl"
                                    );
                                  }}
                                >
                                  <img
                                    class=" h-15px "
                                    src="/media/svg/icons/custom/Icon_delete.svg"
                                  />
                                </span>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>

                {![
                  "URL_SUBMITTED_BY_CREATOR",
                  "REJECTED_BY_BRAND",
                  "URL_APPROVED_BY_BRAND",
                ]?.includes(applicationDetails?.contentStatus) && (
                  <button
                    disabled={
                      listLoading ||
                      actionsLoading ||
                      applicationContents?.filter(
                        (content) =>
                          (campaignDetails?.isInstagramPostRequired ||
                            campaignDetails?.isInstagramStoryRequired) &&
                          content?.contentInstagramUrl === null
                      )?.length > 0 ||
                      applicationContents?.filter(
                        (content) =>
                          (campaignDetails?.isYoutubePostRequired ||
                            campaignDetails?.isYoutubeStoryRequired) &&
                          content?.contentYoutubeUrl === null
                      )?.length > 0
                    }
                    class="btn btn-sm btn-primary ml-2 mt-3"
                    onClick={() => {
                      setClickedButton("urlSubmission");
                      submitSocialUrl();
                    }}
                  >
                    {clickedButton === "urlSubmission" && actionsLoading ? (
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <span>
                        <b>Submit</b>
                      </span>
                    )}
                  </button>
                )}
              </div>
            </div>
          ) : null}
        </>
      )}
      {downloaderComponentUI}
    </>
  );
};

export default memo(UrlDetailsForSocialCampaign);
