/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
// import Select from "react-select";
import { useFormik } from "formik";
import { connect } from "react-redux";
// import Swal from "sweetalert2";
import { RegistrationSchema } from "./RegistartionSchema";
// import { Link } from "react-router-dom";
// import { GoogleLogin } from 'react-google-login';
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import {
  sendPhoneOtpForRegistration,
  googleSignInorLogin,
  login,
  generateOtpForLogin,
  completeRegistrationWithOtp,
} from "../_redux/authCrud";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { Toaster } from "../../../components/Toaster";
// import { GoogleLogin } from '@react-oauth/google';
// import {
//   selectFieldBuilder,
//   inputFieldBuilder,
// } from "../../../components/helper/customHtml";

function Registration(props) {
  const [disableResendOtp, setDisableResendOtp] = useState(false);
  const [credential, setCredential] = useState();
  const [isOtp, setIsOtp] = useState(false);
  const [dataToSend, setDataToSend] = useState({});
  const [phoneDetails, setPhoneDetails] = useState(null);
  const [error, setError] = useState("");
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [seconds, setSeconds] = useState(0);
  // const [phoneCode, setPhoneCode] = useState("");
  // const registerData = props.history.location.state?.verifiedData?.data[0];
  // console.log("registerData >>>>>>>>>...",registerData);
  // const { intl } = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const googleMapScript = document.createElement("script");
    googleMapScript.src = "https://accounts.google.com/gsi/client";
    googleMapScript.async = true;
    window.document.body.appendChild(googleMapScript);
  }, []);

  //------------------------------- Google Signin Setup ---------------------------

  useEffect(() => {
    credential && enableLoading();

    credential &&
      googleSignInorLogin({ token: credential }).then(({ data }) => {
        localStorage.setItem("openModal", true);
        if (data.data.status === "Login") {
          props.login(data?.data);
          disableLoading();
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credential]);

  // ----------------------------------End of Google Signin Setup- ----------------------------

  // const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  // const categories = [
  //   { value: 1, label: "Beauty" },
  //   { value: 2, label: "Beverages" },
  //   { value: 3, label: "e-commerce" },
  //   { value: 4, label: "Electronics" },
  //   { value: 5, label: "Entertaiment" },
  //   { value: 6, label: "Fashion" },
  //   { value: 7, label: "Finance" },
  //   { value: 8, label: "Fitness" },
  //   { value: 9, label: "Food" },
  //   { value: 10, label: "Health Care" },
  //   { value: 11, label: "Home Appliances" },
  //   { value: 12, label: "Media" },
  //   { value: 13, label: "Pet" },
  //   { value: 14, label: "Restaurant" },
  //   { value: 15, label: "Retail" },
  //   { value: 16, label: "Sports" },
  //   { value: 17, label: "Technology" },
  //   { value: 18, label: "Travel" },
  //   { value: 19, label: "Others" },
  // ];
  // ["Food", "Fashion", "Fitness","Technology","Sports","Adventure","Photography","Pet","Other"]
  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const verifyOtp = (paramsForLogin) => {
    enableLoading();
    completeRegistrationWithOtp(paramsForLogin)
      .then(({ data }) => {
        setOtp1("");
        setOtp2("");
        setOtp3("");
        setOtp4("");
        props.login(data?.data);
        disableLoading();
        // setSubmitting(false);
      })
      .catch((err) => {
        setOtp1("");
        setOtp2("");
        setOtp3("");
        setOtp4("");
        disableLoading();
        // setSubmitting(false);
        setError(err.response.data.message);
      });
  };
  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validationSchema: RegistrationSchema,
    validateOnMount: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      let { category, ...rest } = values;
      setSubmitting(true);
      enableLoading();
      // values["userId"] = registerData?.userId;
      // values["loggedInUserId"] = registerData?.userId;
      // const arrOfCategories = [];
      // category.forEach((obj) => {
      //   arrOfCategories.push(obj.label);
      // });

      // changing category field to areasOfIneterst

      setPhoneDetails({
        phoneNumber: rest.phoneNumber,
        phoneCode: rest.phoneCode,
      });

      let objToSend = {
        ...rest,
        // areasOfIneterst: arrOfCategories,
      };
      // console.log("values",values)
      sendPhoneOtpForRegistration(objToSend)
        .then(({ data }) => {
          setSeconds(30);
          setIsOtp(true);
          Toaster({ message: "OTP Sent", variant: "dark" });
          setDataToSend({
            loggedInUserId: data.data.userId,
            phoneNumber: values.phoneNumber,
            phoneCode: values.phoneCode,
            firstName: values.firstName,
          });
          // Toaster({
          //   message:
          //     "Successfully registered as a contributor.",
          //   variant: "success",
          // });
          localStorage.setItem("openModal", true);
          //   disableLoading();
          // Swal.fire({
          //   title: `Verify your otp send to ${values.phoneNumber}`,
          //   input: 'text',
          //   allowOutsideClick: false,
          //   inputAttributes: {
          //     autocapitalize: 'off',
          //     type: "email"
          //   },
          //   inputValidator: (value) => {
          //     // if (!value) return 'Your text here'
          //     // else return null
          //     // if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
          //     //   return null;
          //     // }
          //     // return ("You have entered an invalid email address!")
          //     // return (false)
          //   },
          //   showCancelButton: false,
          //   confirmButtonText: 'Verify',
          //   showLoaderOnConfirm: true,
          //   preConfirm: (loginCode) => {
          //     let objToSend = {
          //       // loggedInUserId: userId,
          //       userId: userId,
          //       loginCode: loginCode
          //     }
          //     return completeRegistrationWithOtp(objToSend)
          //       .then(response => {
          //         // return "Verifaication link succesfully send to your email";
          //         let paramsForLogin = {phoneNumber:values.phoneCode + values.phoneNumber,d }
          //         login(paramsForLogin)
          //           .then(({ data }) => {
          //             props.login(data?.data);
          //             disableLoading();
          //             setSubmitting(false);
          //           })
          //           .catch((err) => {
          //             setSubmitting(false);
          //             setStatus(
          //               intl.formatMessage({
          //                 id: "AUTH.VALIDATION.INVALID_LOGIN",
          //               })
          //             );
          //           })
          //       })
          //       .catch((error)=> {
          //         Swal.showValidationMessage(
          //           `Request failed: ${error.response.data.message}`
          //         ).then((resp)=>{

          //         })
          //       })
          //   },

          // })
          // });
          disableLoading();
        })
        .catch((err) => {
          setSubmitting(false);
          Toaster({ message: "User already registered", variant: "dark" });
          // setStatus(
          //   intl.formatMessage({
          //     id: "AUTH.VALIDATION.INVALID_LOGIN",
          //   })
          // );
          disableLoading();
        });
    },
  });
  // const prepareLoginButton = () => {
  //   // console.log(this.refs.googleLoginBtn);
  //   // this.auth2.attachClickHandler(this.refs.googleLoginBtn, {},
  //   // (googleUser) => {
  //   // let profile = googleUser.getBasicProfile();
  //   // console.log('Token || ' + googleUser.getAuthResponse().id_token);
  //   // console.log('ID: ' + profile.getId());
  //   // console.log('Name: ' + profile.getName());
  //   // console.log('Image URL: ' + profile.getImageUrl());
  //   // console.log('Email: ' + profile.getEmail());
  //   // //YOUR CODE HERE
  //   // }, (error) => {
  //   // alert(JSON.stringify(error, undefined, 2));
  //   // });
  // }

  // const googleSDK = () => {
  //   window['googleSDKLoaded'] = () => {
  //     window['gapi'].load('auth2', () => {
  //       let auth2 = window['gapi'].auth2.init({
  //         client_id: '917710550368-5bbr14ekiuujnep16vuhh40hj3rsh4ve.apps.googleusercontent.com',
  //         cookiepolicy: 'single_host_origin',
  //         scope: 'profile email',
  //         plugin_name:"Web client 1"
  //       });
  //       prepareLoginButton();
  //     });
  //   }
  // }
  // useEffect(()=>{
  //   googleSDK();
  // })
  const move = (e, p, c, n) => {
    let length = document.getElementById(c).value.length;
    let maxLength = document.getElementById(c).getAttribute("maxlength");
    if (length == maxLength) {
      if (n !== "") {
        document.getElementById(n).focus();
      }
    }
    if (e.key === "Backspace") {
      if (p !== "") {
        document.getElementById(p).focus();
      }
    }
  };

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds("time exceed");
    }
  }, [seconds]);

  let minutesTobeShown = Math.floor(seconds / 60);
  let secondsToBeShown = seconds - minutesTobeShown * 60;

  return (
    <div className="login-form login-signin w-100" style={{ display: "block" }}>
      {/* {loading && <div><ComponentLoader /></div>} */}
      <div className="mb-10 mb-lg-0">
        <h4 className="font-size-h3 text-center mb-8">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h4>
        {/* <p className="text-muted font-weight-bold">
          Enter your details to create an Account
        </p> */}
      </div>
      {!isOtp && (
        <form
          id="kt_login_signin_form"
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          onSubmit={formik.handleSubmit}
        >
          {/* begin: Alert */}
          {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}
          {/* end: Alert */}

          {/* {


        {/* begin: Email */}
          {/* <div className="form-group fv-plugins-icon-container">
          <label>Email</label>
          <label className="text-danger ml-1">*</label>
          <input
            placeholder="Primary Email"
            disabled={registerData?.loginEmail ? true : false}
            type="email"
            className={`form-control ${getInputClasses(
              "loginEmail"
            )}`}
            name="loginEmail"
            {...formik.getFieldProps("loginEmail")}
            value={formik.values.loginEmail || ""}
          />
          {formik.touched.loginEmail && formik.errors.loginEmail ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.loginEmail}</div>
            </div>
          ) : null}
        </div> */}
          {/* end: Email */}

          {/* begin: Work Email */}
          {/* <div className="form-group fv-plugins-icon-container">
        <label>Work Email</label>
          <input
            placeholder="Work Email"
            type="email"
            className={`form-control ${getInputClasses(
              "workEmail"
            )}`}
            name="workEmail"
            {...formik.getFieldProps("workEmail")}
            value={formik.values.workEmail || ""}
          />
          {formik.touched.workEmail && formik.errors.workEmail ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.workEmail}</div>
            </div>
          ) : null}
        </div> */}
          {/* end: Work Email */}
          {/* begin: phoneNumber */}
          <div className="form-group ">
            <PhoneInput
              enableAreaCodes={false}
              inputProps={{
                name: "phoneNumber",
                required: true,
              }}
              value={
                formik.values["phoneCode"]
                  ? formik.values["phoneCode"] + formik.values["phoneNumber"]
                  : null
              }
              placeholder="Enter phone number"
              country={"in"}
              onlyCountries={["in", "ae", "sa", "us"]}
              countryCodeEditable={false}
              // disabled={registerData?.phoneNumber ? true : false}
              enableSearch={true}
              inputStyle={{
                width: "100%",
                height: "40px",
              }}
              onChange={(value, countryData) => {
                let phoneCode = `+${countryData.dialCode}`;
                let phoneNumber = value.replace(countryData.dialCode, "");
                formik.setFieldValue("phoneNumber", phoneNumber);
                formik.setFieldValue("phoneCode", phoneCode);
              }}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.phoneNumber}</div>
              </div>
            ) : null}
            <div className="fv-plugins-message-container">
              <p className="ml-0 terms_text font-weight-bold font-size-sm text-muted required">
                We respect your privacy and we will never share it with others
                or spam.
              </p>
            </div>
          </div>
          {/* begin: firstname */}
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="What should we call you?"
              type="text"
              className={`form-control ${getInputClasses("firstName")}`}
              name="firstName"
              {...formik.getFieldProps("firstName")}
              value={formik.values.firstName || ""}
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.firstName}</div>
              </div>
            ) : null}
            <div className="fv-plugins-message-container">
              <p className="ml-0 terms_text font-weight-bold font-size-sm text-muted required">
                Your name, nick name or name appears on any id card.
              </p>
            </div>
          </div>
          {/* end: firstname */}

          {/* begin: lastname */}
          {/* <div className="form-group fv-plugins-icon-container">
          <label>Last Name</label>
          <label className="text-danger ml-1">*</label>
          <input
            placeholder="Last name"
            type="text"
            className={`form-control ${getInputClasses(
              "lastName"
            )}`}
            name="lastName"
            {...formik.getFieldProps("lastName")}
            value={formik.values.lastName || ""}
          />
          {formik.touched.lastName && formik.errors.lastName ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.lastName}</div>
            </div>
          ) : null}
        </div> */}
          {/* end: lastname */}

          {/* end: countryCode */}

          {/* begin: companyName */}
          {/* <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Company Name"
            type="text"
            className={`form-control ${getInputClasses(
              "companyName"
            )}`}
            name="companyName"
            {...formik.getFieldProps("companyName")}
            value={formik.values.companyName || ""}
          />
          {formik.touched.companyName && formik.errors.companyName ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.companyName}</div>
            </div>
          ) : null}
        </div> */}
          {/* end: companyName */}

          {/* begin: Password */}
          {/* <div className="form-group">
          <input
            placeholder=" Create password"
            type="password"
            className={`form-control ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
            value={formik.values.password || ""}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div> */}
          {/* end: Password */}

          {/* begin: Confirm Password */}
          {/* <div className="form-group fv-plugins-icon-container">
          <label>Confirm Password</label>
          <label className="text-danger ml-1">*</label>
          <input
            placeholder="Confirm Password"
            type="password"
            className={`form-control ${getInputClasses(
              "changepassword"
            )}`}
            name="changepassword"
            {...formik.getFieldProps("changepassword")}
            value={formik.values.changepassword || ""}
          />
          {formik.touched.changepassword && formik.errors.changepassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.changepassword}
              </div>
            </div>
          ) : null}
        </div> */}

          {/* <div className="form-group fv-plugins-icon-container">
          <label>Instagram URL</label>
          <input
            placeholder="Instagram URL"
            type="instagramId"
            className={`form-control ${getInputClasses(
              "instagramId"
            )}`}
            name="instagramId"
            {...formik.getFieldProps("instagramId")}
            value={formik.values.instagramId || ""}
          />
          {formik.touched.instagramId && formik.errors.instagramId ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.instagramId}</div>
            </div>
          ) : null}
        </div> */}

          {/* <div className="form-group fv-plugins-icon-container">
          <label>Youtube URL</label>
          <input
            placeholder="YouTube URL"
            type="youtubeId"
            className={`form-control ${getInputClasses(
              "youtubeId"
            )}`}
            name="youtubeId"
            {...formik.getFieldProps("youtubeId")}
            value={formik.values.youtubeId || ""}
          />
          {formik.touched.youtubeId && formik.errors.youtubeId ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.youtubeId}</div>
            </div>
          ) : null}
        </div> */}

          {/* <div className="form-group fv-plugins-icon-container">
          {selectFieldBuilder(
            "What Kind Of Creator You are",
            formik.getFieldProps("creatorType"),
            ["INFLUENCER", "CREATOR", "BOTH"],
            formik.getFieldMeta("creatorType").touched,
            formik.getFieldMeta("creatorType").error
          )}
        </div> */}

          {/* <div className="form-group fv-plugins-icon-container">
          <label>Age</label>
          <label className="text-danger ml-1">*</label>
          <input
            placeholder="Age"
            type="age"
            className={`form-control ${getInputClasses(
              "age"
            )}`}
            name="age"
            {...formik.getFieldProps("age")}
            value={formik.values.age || ""}
          />
          {formik.touched.age && formik.errors.age ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.age}</div>
            </div>
          ) : null}
        </div> */}

          {/* <div className="form-group fv-plugins-icon-container">
          {selectFieldBuilder(
            "Gender",
            formik.getFieldProps("gender"),
            ["MALE", "FEMALE", "OTHER"],
            formik.getFieldMeta("gender").touched,
            formik.getFieldMeta("gender").error
          )}
        </div> */}

          {/* <div className="form-group fv-plugins-icon-container">
          <label>Interested Categories</label>
          <label className="text-danger ml-1">*</label>
          <Select
            isMulti
            isClearable
            options={categories}
            value={formik.values?.category || ""}
            onChange={(option) => formik.setFieldValue("category", option)}
          >
            {formik.touched.category && formik.errors.category ? (
              <div className="invalid-feedback display-block">
                {formik.errors.website}
              </div>
            ) : null}
          </Select>
          {selectFieldBuilder(
            "Interested Category",
            formik.getFieldProps("category"),
            ["Food", "Fashion", "Fitness","Technology","Sports","Adventure","Photography","Pet","Other"],
            formik.getFieldMeta("category").touched,
            formik.getFieldMeta("category").error
          )}
        </div> */}

          {/* <div className="form-group fv-plugins-icon-container">
        <label>Expected Reward</label>
          <input
            placeholder="Expected Reward"
            type="expectedReward"
            className={`form-control ${getInputClasses(
              "expectedReward"
            )}`}
            name="expectedReward"
            {...formik.getFieldProps("expectedReward")}
            value={formik.values.expectedReward || ""}
          />
          {formik.touched.expectedReward && formik.errors.expectedReward ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.expectedReward}
              </div>
            </div>
          ) : null}
        </div> */}

          {/* <h2 className="mb-5 text-center">Shipping details</h2> */}

          {/* <div className="form-group fv-plugins-icon-container">
          {inputFieldBuilder(
            "Name",
            formik.getFieldProps("shippingUserName"),
            "text",
            formik.getFieldMeta("shippingUserName").touched,
            formik.getFieldMeta("shippingUserName").error
          )}
        </div>

        <div className="form-group fv-plugins-icon-container">
          {inputFieldBuilder(
            "Address",
            formik.getFieldProps("shippingAddress"),
            "text",
            formik.getFieldMeta("shippingAddress").touched,
            formik.getFieldMeta("shippingAddress").error
          )}
        </div>

        <div className="form-group fv-plugins-icon-container">
          {inputFieldBuilder(
            "City",
            formik.getFieldProps("shippingCity"),
            "text",
            formik.getFieldMeta("shippingCity").touched,
            formik.getFieldMeta("shippingCity").error
          )}
        </div>

        <div className="form-group fv-plugins-icon-container">
          {inputFieldBuilder(
            "State",
            formik.getFieldProps("shippingState"),
            "text",
            formik.getFieldMeta("shippingState").touched,
            formik.getFieldMeta("shippingState").error
          )}
        </div>

        <div className="form-group fv-plugins-icon-container">
          {inputFieldBuilder(
            "Pincode",
            formik.getFieldProps("shippingPincode"),
            "text",
            formik.getFieldMeta("shippingPincode").touched,
            formik.getFieldMeta("shippingPincode").error
          )}
        </div>

        <div className="form-group fv-plugins-icon-container">
          {inputFieldBuilder(
            "Country",
            formik.getFieldProps("shippingCountry"),
            "text",
            formik.getFieldMeta("shippingCountry").touched,
            formik.getFieldMeta("shippingCountry").error
          )}
        </div> */}

          {/* end: Confirm Password */}

          {/* begin: Terms and Conditions */}

          {/* end: Terms and Conditions */}
          <div className=" d-flex flex-wrap d-flex justify-content-center mb-3">
            {/* <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Cancel
            </button>
          </Link> */}
            <button
              type="submit"
              disabled={
                formik.isSubmitting || !formik.isValid
                // !formik.values.acceptTerms
              }
              className="btn btn_secondary w-100 h-45px"
            >
              {!loading ? (
                <span>
                  <b>Register for free</b>
                </span>
              ) : (
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              )}
              {/* {loading && <span className="ml-3 spinner spinner-white"></span>} */}
            </button>
          </div>
        </form>
      )}
      {isOtp && (
        <div className="fv-plugins-icon-container">
          {error.length > 1 ? (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">
                {"You have entered wrong otp"}
              </div>
            </div>
          ) : (
            ""
          )}
          {/* <input
            placeholder="Enter otp"
            type="text"
            className={'form-control '}
            name="otp"
            onChange={(e) => {
              setOtp(e.target.value)
              setError('')
            }}
          // {...formik.getFieldProps("email")}
          // value={formik.values.email || ""}
          /> */}
          <div className="d-flex justify-content-between mx-6 mt-8 form-group">
            <input
              name="otp1"
              type="text"
              autoComplete="off"
              className="otpInput"
              id="otp1"
              // value={this.state.otp1}
              // onKeyPress={this.keyPressed}
              onChange={(e) =>
                /[^0-9]/g.test(e.target.value)
                  ? setOtp1("")
                  : setOtp1(e.target.value)
              }
              tabIndex="1"
              maxLength="1"
              autocomplete="one-time-code"
              onKeyUp={(e) => move(e, "", "otp1", "otp2")}
              value={otp1}
            />
            <input
              name="otp2"
              type="text"
              autoComplete="off"
              className="otpInput"
              id="otp2"
              // value={this.state.otp2}
              onChange={(e) =>
                /[^0-9]/g.test(e.target.value)
                  ? setOtp2("")
                  : setOtp2(e.target.value)
              }
              tabIndex="2"
              maxLength="1"
              autocomplete="one-time-code"
              onKeyUp={(e) => move(e, "otp1", "otp2", "otp3")}
              value={otp2}
            />
            <input
              name="otp3"
              type="text"
              autoComplete="off"
              className="otpInput"
              // value={this.state.otp3}
              id="otp3"
              onChange={(e) =>
                /[^0-9]/g.test(e.target.value)
                  ? setOtp3("")
                  : setOtp3(e.target.value)
              }
              tabIndex="3"
              maxLength="1"
              autocomplete="one-time-code"
              onKeyUp={(e) => move(e, "otp2", "otp3", "otp4")}
              value={otp3}
            />
            <input
              name="otp4"
              type="text"
              autoComplete="off"
              className="otpInput"
              // value={this.state.otp4}
              id="otp4"
              onChange={(e) =>
                /[^0-9]/g.test(e.target.value)
                  ? setOtp4("")
                  : setOtp4(e.target.value)
              }
              tabIndex="4"
              maxLength="1"
              autocomplete="one-time-code"
              onKeyUp={(e) => move(e, "otp3", "otp4", "")}
              value={otp4}
            />
          </div>
          <div className="fv-plugins-message-container">
            <div className="fv-help-block-info">
              We sent a 4 digit code to{" "}
              <b>{formik.values.phoneCode + "-" + formik.values.phoneNumber}</b>{" "}
              via SMS to verify your number
            </div>
          </div>
          <div className="mb-3 mt-5 d-flex flex-wrap align-items-center justify-content-between">
            <div className="col-md-12 text-center px-0">
              <button
                id="kt_login_signin_submit"
                // type="submit"
                // disabled={formik.isSubmitting}
                disabled={
                  loading ||
                  otp1.length < 1 ||
                  otp2.length < 1 ||
                  otp3.length < 1 ||
                  otp4.length < 1
                }
                className={`btn btn_secondary w-100 h-45px`}
                onClick={() => {
                  let objToSend = {
                    registrationCode: otp1 + otp2 + otp3 + otp4,
                    ...dataToSend,
                  };
                  verifyOtp(objToSend);
                }}
              >
                {<span>Verify Otp</span>}
                {/* {loading && <span className="ml-3 spinner spinner-white"></span>} */}
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
            </div>
          </div>
          {seconds >= 1 && (
            <a
              // to="https://1000x.club/legal.html"
              target="_blank"
              className={` d-flex justify-content-center font-weight-bold ml-0 font-size-sm cursor-pointer`}
              rel="noopener noreferrer"
              // href="#"
            >
              Resend otp in {"0" + minutesTobeShown + " : " + secondsToBeShown}
            </a>
          )}
          {typeof seconds === "string" ? (
            <a
              // to="https://1000x.club/legal.html"
              target="_blank"
              className={` d-flex justify-content-center font-weight-bold ml-0 font-size-sm cursor-pointer ${
                disableResendOtp ? "text-muted" : ""
              }`}
              rel="noopener noreferrer"
              // href="#"
              onClick={() => {
                setSeconds(30);
                setDisableResendOtp(true);
                sendPhoneOtpForRegistration({
                  ...phoneDetails,
                }).then(() => {
                  Toaster({ message: "OTP Sent", variant: "dark" });
                  setDisableResendOtp(false);
                });
              }}
            >
              Click here to resend OTP
            </a>
          ) : null}
        </div>
      )}

      {/* <GoogleLogin
    clientId="917710550368-5bbr14ekiuujnep16vuhh40hj3rsh4ve.apps.googleusercontent.com"
    buttonText="Sign In with Google"
    onSuccess={onSuccess}
    onFailure={onFailure}
    cookiePolicy={'single_host_origin'}
    isSignedIn={true}
  /> */}
      {/* <button className="loginBtn loginBtn--google" ref="googleLoginBtn">
          Login with Google
        </button> */}
      {/* <div class="g_id_signin"
         data-type="standard"
         data-size="large"
         data-theme="outline"
         data-text="sign_in_with"
         data-shape="rectangular"
         data-logo_alignment="left">
      </div> */}

      {/* -----------------Google Signin Call back function--------------- */}

      {
        (window.loginCallBackFn = (resp, resp1) => {
          setCredential(resp.credential);
        })
      }

      {/* -----------------End of Google Signin Call back function--------------- */}
      <p className="d-flex justify-content-center text-muted mb-2">Or</p>

      <div className="d-flex justify-content-center mb-7">
        {/* ----------------Google Siginin Button */}
        <div
          id="g_id_onload"
          data-client_id={process.env.REACT_APP_GOOGLE_SIGN_UP_CLIENT_ID}
          data-context="signin"
          data-ux_mode="popup"
          data-callback="loginCallBackFn"
          data-auto_prompt="false"
        ></div>

        <div
          class="g_id_signin w-auto mx-auto"
          data-type="standard"
          data-shape="rectangular"
          data-theme="outline"
          data-text="continue_with"
          data-size="large"
          data-logo_alignment="left"
        ></div>
      </div>
      {/*---------------- End of Google  Siginin Button*/}

      <div className="form-group mb-0">
        {/* <label className="checkbox"> </label>
            <input
              type="checkbox"
              name="acceptTerms"
              className="m-1"
              {...formik.getFieldProps("acceptTerms")}
            /> */}
        <p className="ml-0 terms_text font-weight-bold font-size-sm text-muted">
          By creating an account, you agree to our
          <a
            // to="https://1000x.club/legal.html"
            target="_blank"
            className="font-weight-bold ml-0 font-size-sm ml-1 "
            rel="noopener noreferrer"
            href="https://1000x.club/legal.html"
            onClick={() => window.open(`https://1000x.club/legal.html`)}
          >
            Terms of Use and Privacy Policy
          </a>
        </p>
        <span />

        {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik.errors.acceptTerms}</div>
          </div>
        ) : null}
      </div>

      {/* {isLoggedIn && <button onClick={signIn}>Sign In</button>}

        {!isLoggedIn && <button onClick={signOut}>Sign Out</button>} */}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
