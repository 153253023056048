import React, { useEffect, useState } from "react";
import LeftCampaignBriefInfo from "./components/LeftCampaignBriefInfo";
import Tabs from "./components/Tabs";
import { useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../campaign/_redux/campaignActions";
import { clearDetailsOfCampaign } from "../campaignApplications/_redux/applicationActions";
import { clearAllCampaignDetails } from "../campaign/_redux/campaignActions";
// import { listApplicationContents } from "../campaignApplications/_redux/applicationActions";

const CampaignDetailView = () => {
  const { campaignId } = useParams();
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);

  const [campaignLoaded, setCampaignLoaded] = useState(false);

  const { loggedInUserId, campaignDetails, applicationDetails } = useSelector(
    (state) => ({
      loggedInUserId: state.auth.user?.userId,
      campaignDetails: state.campaign.campaignDetails,
      applicationDetails: state.campaign.applicationDetails,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server call for getting Campaign by id if login success
    if (campaignId && loggedInUserId) {
      dispatch(
        actions.getCampaignDetails({
          campaignId,
          loggedInUserId,
          populate: ["brandDetail"],
        })
      ).then(() => setCampaignLoaded(true));
    } else {
      dispatch(
        actions.getPublicCampaignDetails({
          campaignId,
          populate: ["brandDetail"],
        })
      ).then(() => setCampaignLoaded(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, dispatch, refresh]);

  useEffect(() => {
    // let { brandId } = campaignDetails;

    if (campaignId && loggedInUserId) {
      dispatch(
        actions.getApplicationDetails({
          page: 1,
          pageSize: 5,
          loggedInUserId,
          populate: ["brandDetail", "paymentDetails"],
          query: {
            campaignId,
            // brandId,
            creatorId: loggedInUserId,
          },
        })
      );
    }
    return () => {
      dispatch(clearAllCampaignDetails());
      dispatch(clearDetailsOfCampaign());
    };
  }, [campaignId, refresh]);

  return (
    <div className="container px-0">
      {campaignLoaded?
      <div className="row mx-0 mt-4">
        <LeftCampaignBriefInfo campaign={campaignDetails} />
        <Tabs
          campaign={campaignDetails}
          application={
            applicationDetails?.length > 0 ? applicationDetails[0] : null
          }
          isOnceApplied={applicationDetails?.length > 0 || false}
          setRefresh={() => setRefresh(!refresh)}
        />
      </div>
      :null}
    </div>
  );
};

export default CampaignDetailView;
