import { ChatModuleSlice } from "./ChatModuleSlice";
import {
  fetchChatInbox,
  fetchChatMessages,
  sendNewChat,
  fetchCampaignDetails,
  // getApplicationDetail,
  getUserData,
} from "./ChatModuleCrud";
const { actions } = ChatModuleSlice;

export const loadInbox = (userId, page, pageSize = 10, loggedInUserId) => (
  dispatch
) => {
  dispatch(actions.toggleIsApiInprogressFlag(true));
  fetchChatInbox(userId, page, pageSize, loggedInUserId)
    .then(({ data }) => {
      dispatch(actions.updateChatInboxArray(data?.rows));
      dispatch(actions.toggleIsApiInprogressFlag(false));
    })
    .catch((err) => {
      console.log(err);
      dispatch(actions.toggleIsApiInprogressFlag(false));
    });
};

export const loadMessages = (
  fromUserId,
  toUserId,
  page,
  pageSize = 10,
  campaignId
) => (dispatch) => {
  dispatch(actions.toggleIsApiInprogressFlag(true));
  return fetchChatMessages(fromUserId, toUserId, page, pageSize, campaignId)
    .then(({ data }) => {
      // dispatch(actions.resetMessages());
      if (data?.rows !== []) {
        dispatch(actions.updateChatMessagesArray(data?.rows));
        dispatch(actions.updateTotalMessages(data?.total));
        dispatch(actions.toggleIsApiInprogressFlag(false));
      }
      return data;
    })
    .catch((err) => {
      console.log(err);
      dispatch(actions.toggleIsApiInprogressFlag(false));
    });
};

export const updateIncomingMessage = (incomingData) => (dispatch) => {
  dispatch(actions.updateIncomingMessage(incomingData));
};

export const sendNewChatAction = (queryParams) => (dispatch) => {
  sendNewChat(queryParams)
    .then(({ response }) => {})
    .catch((err) => {
      console.log("err", err);
    });
};

export const getCampaignDetails = (userId, campaignId) => (dispatch) => {
  return fetchCampaignDetails(userId, campaignId)
    .then((response) => {
      const campaignDetails = response.data.data;
      dispatch(actions.campaignDetailsFetched({ campaignDetails }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find campaigns";
      // dispatch(actions.campaignDetailsFetched({ campaignDetails: {} }));
      // dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getCreatorDetails = (params) => (dispatch) => {
  return getUserData(params)
    .then((response) => {
      console.log("response", response);
      const creatorDetails = response.data.data;

      dispatch(actions.creatorDetailsFetched({ creatorDetails }));
    })
    .catch((error) => {
      console.log("err", error);
    });
};

// export const getApplicationDetails = (queryParams) => (dispatch) => {
//   // dispatch(actions.startCall({ callType: callTypes.action }));
//   return getApplicationDetail(queryParams)
//     .then((response) => {
//       const applicationDetails = response.data.data;
//       // console.log("applictionDetails",applicationDetails)
//       dispatch(actions.applicationDetailsFetched({ applicationDetails }));
//     })
//     .catch((error) => {
//       // error.clientMessage = "Can't find ";
//       // Toaster({ message: error?.response?.data?.message, variant: "error" });
//       console.log("err", error);
//     });
// };

export const fetchedApplication = actions.applicationDetailsFetched;
export const resetInbox = actions.resetInbox;
export const resetMessages = actions.resetMessages;
