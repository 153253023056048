import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  portfolioDetails:{},
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const portfolioSlice = createSlice({
  name: "portfolio",
  initialState: initialState,
  reducers: {

    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    callSuccess: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    PortfolioDetailsFetched: (state, action) => {
      state.listLoading = false;
      state.actionsLoading= false
      state.error = null;
      let { portfolioDetails } = action.payload;
      state.portfolioDetails = portfolioDetails;
    },
  },
});
