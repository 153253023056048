import {createSlice} from "@reduxjs/toolkit";

const initialOrdersState = {
  listLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null
};
export const callTypes = {
  list: "list",
};

export const ordersSlice = createSlice({
  name: "Orders",
  initialState: initialOrdersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`; 
        state.listLoading = false;
    },
    startCall: (state, action) => {
      state.error = null;
        state.listLoading = true;
    },
 
    // findOrders
    ordersFetched: (state, action) => {
      const { total, rows } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = rows;
      state.totalCount = total;
    },
   
   
   
  }
});
