import axios from "axios";

export const GET_APPLICATIONS = "v1/creator/campaign/applications/list";
export const ADD_RATING = "v1/brand/rating/review/addRating";
export const GET_RATING = "v1/brand/rating/review/getPreviousBrandRating";
export const GET_APPLICATION_CONTENTS =
  "v1/creator/campaign/application/contents/list";
export const CREATE_APPLICATION_CONTENTS =
  "v1/creator/campaign/application/contents/create";
export const UPDATE_APPLICATION_CONTENTS =
  "v1/creator/campaign/application/contents/update";
export const GENERATE_MEDIA_FILE_URL =
  "v1/creator/campaign/application/contents/generateMediaUploadUrl";
export const SUBMIT_FOR_APPROVAL =
  "v1/creator/campaign/application/contents/submitForApproval";
export const SET_AS_DRAFT =
  "v1/creator/campaign/application/contents/setAsDraft";
export const UPDATE_SOCIAL_URL =
  "v1/creator/campaign/application/contents/updateSocailMediaUrl";
export const GET_DOWNLOAD_MEDIA_URL =
  "v1/creator/campaign/application/contents/generateMediaDownloadUrl";
  export const GET_TRACKING_DETAILS = "v1/shipment/tracking/getTracking";

export const REMOVE_APPLICATION_CONTENT =
  "v1/creator/campaign/application/contents/remove";
export const GET_CAMPAIGN_DETAILS = "v1/brand/campaigns/details";
export const SUBMIT_URL_STATUS =
  "v1/creator/campaign/application/contents/urlSubmittedForSocialMediaCampaign";


export function getCampaignDetails(params) {
  return axios.post(GET_CAMPAIGN_DETAILS, {
    ...params,
    frontendAction: "creator.campaigns.details",
    trackingPriority: 0,
  });
}

export function getApplications(params) {
  return axios.post(GET_APPLICATIONS, {
    ...params,
    frontendAction: "creator.campaign.applications.list",
    trackingPriority: 0,
  });
}

export function addRating(params) {
  return axios.post(ADD_RATING, {
    ...params,
    trackingPriority: 0,
    frontendAction: "brand.rating.review.addRating",
  });
}

export function updateSocialUrl(params) {
  return axios.post(UPDATE_SOCIAL_URL, {
    ...params,
    trackingPriority: 0,
    frontendAction:
      "creator.campaign.application.contents.updateSocailMediaUrl",
  });
}

export function getRating(params) {
  return axios.post(GET_RATING, {
    ...params,
    trackingPriority: 0,
    frontendAction: "brand.rating.review.getPreviousBrandRating",
  });
}

export function getApplicationContents(params) {
  return axios.post(GET_APPLICATION_CONTENTS, {
    ...params,
    frontendAction: "creator.campaign.application.contents.list",
    trackingPriority: 0,
  });
}

export function createApplicationContents(params) {
  return axios.post(CREATE_APPLICATION_CONTENTS, {
    ...params,
    frontendAction: "creator.campaign.application.contents.create",
    trackingPriority: 0,
  });
}
export function updateApplicationContents(params) {
  return axios.post(UPDATE_APPLICATION_CONTENTS, {
    ...params,
    frontendAction: "creator.campaign.application.contents.update",
    trackingPriority: 0,
  });
}

export function generateMediaUploadUrl(params) {
  return axios.post(GENERATE_MEDIA_FILE_URL, {
    ...params,
    frontendAction:
      "creator.campaign.application.contents.generateMediaUploadUrl",
    trackingPriority: 0,
  });
}

export function signedUpload(url, file) {
  let config = {
    headers: {
      "Content-type": file.type,
    },
  };
  return axios.put(url, file, config);
}

export function submitForApproval(params) {
  return axios.post(SUBMIT_FOR_APPROVAL, {
    ...params,
    frontendAction: "creator.campaign.application.contents.submitForApproval",
    trackingPriority: 0,
  });
}

export function submitAllUrlStatus(params) {
  return axios.post(SUBMIT_URL_STATUS, {
    ...params,
    frontendAction: "creator.campaign.application.contents.urlSubmittedForSocialMediaCampaign",
    trackingPriority: 0,
  });
}

export function setAsDraft(params) {
  return axios.post(SET_AS_DRAFT, {
    ...params,
    frontendAction: "creator.campaign.application.contents.setAsDraft",
    trackingPriority: 0,
  });
}

export function getMediaDownloadURL(params) {
  return axios.post(GET_DOWNLOAD_MEDIA_URL, {
    ...params,
    frontendAction:
      "creator.campaign.application.contents.generateMediaDownloadUrl",
    trackingPriority: 0,
  });
}

export function removeApplicationContent(params) {
  return axios.post(REMOVE_APPLICATION_CONTENT, {
    ...params,
    frontendAction:
      "creator.campaign.application.contents.remove",
    trackingPriority: 0,
  });
}

export function getTrackingDetails(params){
  return axios.get(GET_TRACKING_DETAILS, {
    params
  });
}

