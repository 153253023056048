import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  error: null,
  totalCount: 0,
  totalPages: 1,
  analytics: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const postAnalyticsSlice = createSlice({
  name: "postAnalytics",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    callSuccess: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    analyticsFetched: (state, action) => {
      let { analytics, totalCount,totalPages } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.analytics = analytics;
      state.totalCount = totalCount;
      state.totalPages = totalPages
    },


  },
});
