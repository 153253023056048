import axios from "axios";

export const GET_SIGNED_URL = "v1/image/signedUrl";
export const GET_CREATOR_PROFILE = "v1/creator/profile/getProfileDetails";
export const UPDATE_CREATOR_PROFILE = "v1/creator/profile/updateProfile";
export const CHANGE_PASSWORD = "v1/creator/profile/changePassword";
export const GET_CREATOR_RATING = "v1/creator/rating/review/getRating";
export const UPDATE_CREATOR_EMAIL = "v1/creator/profile/verifyUserEmail";
export const UPDATE_CREATOR_NUMBER = "v1/creator/profile/verifyUserNumber";
export const VERIFY_CREATOR_EMAIL = "v1/creator/profile/updateEmail";
export const VERIFY_CREATOR_NUMBER = "v1/creator/profile/updateNumber";

// GET =>  get signed Url
export function getSignedUrl(signedUrl) {
  return axios.post(GET_SIGNED_URL, {
    ...signedUrl,
    frontendAction: "ImageSignedUrl",
    trackingPriority: 0,
  });
}

//file upload to s3
export function signedUpload(url, file) {
  let config = {
    headers: {
      "Content-type": file.type,
    },
  };
  return axios.put(url, file, config);
}

export function getCreatorProfile(params) {
  return axios.post(GET_CREATOR_PROFILE, {
    ...params,
    trackingPriority: 0,
    frontendAction: "creator.profile.getProfileDetails",
  });
}

export function updateCreatorProfile(params) {
  return axios.post(UPDATE_CREATOR_PROFILE, {
    ...params,
    trackingPriority: 0,
    frontendAction: "creator.profile.updateProfile",
  });
}

export function changePassword(params) {
  return axios.post(CHANGE_PASSWORD, {
    ...params,
    trackingPriority: 0,
    frontendAction: "creator.profile.changePassword",
  });
}

export function getCreatorRating(params) {
  return axios.post(GET_CREATOR_RATING, {
    ...params,
    trackingPriority: 0,
    frontendAction: "creator.rating.review.getRating",
  });
}

export function updateEmail(params) {
  return axios.post(UPDATE_CREATOR_EMAIL, {
    ...params,
    trackingPriority: 0,
    frontendAction: "creator.profile.verifyUserEmail",
  });
}

export function updateNumber(params) {
  return axios.post(UPDATE_CREATOR_NUMBER, {
    ...params,
    trackingPriority: 0,
    frontendAction: "creator.profile.verifyUserNumber",
  });
}

export function verifyBrandEmail(params) {
  return axios.post(VERIFY_CREATOR_EMAIL, {
    ...params,
    trackingPriority: 0,
    frontendAction: "creator.profile.updateEmail",
  });
}

export function verifyCreatorNumber(params) {
  return axios.post(VERIFY_CREATOR_NUMBER, {
    ...params,
    trackingPriority: 0,
    frontendAction: "creator.profile.updateNumber",
  });
}
