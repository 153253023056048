/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
import { MessageInbox } from "./MessageInbox";
import * as actions from "../../../../app/modules/_redux/commonActions";
import { ChatModuleSelector } from "../../../../app/modules/ChatModule/_redux/ChatModuleSelector";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  loadInbox,
  resetInbox,
} from "../../../../app/modules/ChatModule/_redux/ChatModuleActions";
import {
  unreadNotificationCount,
  listNotifications,
  updateUnreadNotificationCount,
  clearNotification,
} from "../extras/dropdowns/notifications/_redux/NotificationActions";
import { getPage } from "./SideBarHelper";
import { viewModalInfo } from "../../../../app/modules/_redux/commonActions";
import {
  body1,
  body2,
  body3,
} from "../../../../app/modules/Dashboard/helper/ModalBody";
import { toAbsoluteUrl } from "../../../_helpers";
import { getCreatorProfile } from "../../../../app/modules/_redux/commonActions";
import { UserNotificationsDropdownV2 } from "../extras/dropdowns/notifications/UserNotificationsDropdownV2";
import { getPortfolio } from "../../../../app/modules/Portfolio/_redux/portfolioActions";
import SideBarCampaigns from "./SideBarCampaigns";
export function SideBar() {
  // UserNotificationsDropdownV2
  const history = useHistory();
  const [screen, setScreen] = useState(1);
  const location = useLocation();
  const dispatch = useDispatch();
  const [component, setComponent] = useState("messageInbox");

  const [isVisible, setIsVisible] = useState(false);

  const { isApiInprogressFlag, user, chatInboxArray } = useSelector(
    ChatModuleSelector,
    shallowEqual
  );
  const {
    userDetails,
    unreadNotificationCounts,
    notificationsList,
    list,
    viewSideBar,
    profileToEdit,
    isVisibleModalInfo,
    portfolioDetails,
  } = useSelector(
    (state) => ({
      userDetails: state.auth.user,
      notificationsList: state.notificationModule.notificationsList,
      unreadNotificationCounts:
        state.notificationModule.unreadNotificationCount,
      list: state.notificationModule.list,
      viewSideBar: state.common.isVisibleSideBar,
      profileToEdit: state.common.profileToEdit,
      isVisibleModalInfo: state.common.isVisibleModalInfo,
      portfolioDetails: state.portfolio.portfolioDetails,
    }),
    shallowEqual
  );
  const pageSize = 10;
  const page = 1;
  const loggedInUserId = user.userId;
  const fetchMoreInInboxData = () => {
    dispatch(loadInbox(user.userId, page, pageSize, loggedInUserId));
  };

  useEffect(() => {
    dispatch(
      unreadNotificationCount({
        loggedInUserId,
        appInfo: "CREATOR",
      })
    );
    location.pathname !== "/messagings/inbox" && fetchMoreInInboxData();
    dispatch(
      actions.getCreatorProfile({
        userId: user?.userId,
        loggedInUserId: user?.userId,
        fields: [
          "profilePic",
          "firstName",
          "lastName",
          "loginEmail",
          "phoneNumber",
          "gender",
          "age",
          "creatorType",
          "aboutUser",
          "areasOfIneterst",
          "number",
          "phoneCode",
          "instagramFollowerCount",
          "youtubeSubscriptionCount",
          "instagramId",
          "youtubeId",
          "paymentAccountId",
          "primaryModeOfRegistration",
          "totalRating",
          "totalRatingCount"
        ],
      })
    );
    dispatch(
      getPortfolio({
        page: 1,
        pageSize: 6,
        loggedInUserId: loggedInUserId,
        userId: loggedInUserId,
        fileType: "IMAGE",
      })
    );
    return () => {
      dispatch(resetInbox());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //for changing the sidebar according to pathname
  useEffect(() => {
    setComponent("messageInbox");
    let campaignUrl = new RegExp("/campaigns/list");
    let viewUrl = new RegExp("/view");
    campaignUrl.test(window.location.href) &&
      viewUrl.test(window.location.href) &&
      setComponent("campaigns");
  }, [location.pathname]);

  const listNotificationData = () => {
    dispatch(
      listNotifications({
        page: 1,
        pageSize: 50,
        loggedInUserId,
        appInfo: "CREATOR",
      })
    ).then(() => dispatch(updateUnreadNotificationCount(0)));
    // dispatch(actions.updateUnreadNotificationCount(0));
  };
  const clearNotificationData = () => {
    dispatch(
      clearNotification({
        loggedInUserId,
        userId: loggedInUserId,
      })
    );
  };

  const hideSideBar = () => {
    dispatch(actions.viewSideBar(false));
  };

  const viewModal = (params) => {
    dispatch(viewModalInfo(params));
  };
  const getmodalScreen = (screenNumber) => {
    switch (screenNumber) {
      case 1:
        return body1;
      case 2:
        return body2;
      case 3:
        return body3;
      default:
        return null;
    }
  };
  useEffect(() => {
    if (isVisibleModalInfo === true) {
      screen <= 3 &&
        Swal.fire({
          type: "info",
          html: getmodalScreen(screen),
          // text:body1,
          // content: errors,
          showCloseButton: true,
          // showCancelButton: screen === 1 ? false :true,
          confirmButtonText: screen === 3 ? "Close" : "Next",
          confirmButtonColor: "#3699FF",
          // cancelButtonColor: "#EBEBED",
          confirmButtonClass: "btn_primary",
          // cancelButtonClass: "btn_secondary",
          cancelButtonText: "Back",
          reverseButtons: true,
          focusConfirm: false,
          focusCancel: true,
          allowOutsideClick: false,
          reversebuttonsText: "Back",
          showDenyButton: screen === 1 ? false : true,
          denyButtonColor: "#EBEBED",
          denyButtonClass: "btn_secondary",
          denyButtonText: "Back",
        }).then((resp) => {
          resp.isConfirmed && setScreen(screen + 1);
          resp.isDenied && setScreen(screen - 1);
          resp.isDismissed && dispatch(viewModalInfo(false)) && setScreen(1);
          resp.isDismissed && setScreen(1);
          screen >= 3 && dispatch(viewModalInfo(false)) && setScreen(1);
          screen >= 3 && setScreen(1);
        });
    }
  }, [isVisibleModalInfo, dispatch, screen]);

  // let stepperView = profileToEdit?.aboutUser?.length > 1 && profileToEdit?.instagramFollowerCount?.length !== 0 && profileToEdit?.youtubeSubscriptionCount?.length !== 0 && profileToEdit?.instagramId?.length !== 0 && profileToEdit?.youtubeId?.length !== 0 && portfolioDetails?.rows?.length > 0 ? "true" : "false";
  // console.log("profileToEdit >>>>>>>>>>>>>>>>...sdcgvsdg",profileToEdit);

  return (
    <div
      className={`sidebar ${
        viewSideBar ? "mob_active " : ""
      }  sidebar-right d-flex flex-row-auto flex-column`}
      id="kt_sidebar"
    >
      {/*sidebar header start::Content*/}
      <div className="sidebar-header flex-column-auto pt-5 pt-lg-10 pb-0 px-5 px-lg-10">
        <div className="d-flex">
          <div className="quick-search quick-search-inline flex-grow-1">
            <div class="d-flex align-items-center mb-0 d-none d-sm-flex">
              <div class="symbol symbol-40 rounded symbol-light-success mr-3 cursor-pointer">
                <span
                  class="symbol-label"
                  onClick={() => {
                    history.push("/user-profile/personal-information");
                    viewSideBar && hideSideBar();
                  }}
                  style={{
                    backgroundImage: profileToEdit?.profilePic
                      ? "url(" +
                        `${process.env.REACT_APP_AWS_S3_BASE_URL +
                          process.env.REACT_APP_S3_USER_PROFILE_FOLDER +
                          "/" +
                          profileToEdit?.profilePic}` +
                        ")"
                      : "url(/media/dummyImages/no_preview.png)",
                  }}
                >
                  {/* <img src={userDetails ? process.env.REACT_APP_AWS_S3_BASE_URL +
                    "profilePic/" +
                    userDetails?.profilePic : null} class="w-100 rounded" alt="" /> */}
                </span>
              </div>
              <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                <a
                  class="text-dark text-hover-primary mb-0 font-size-md font-weight-bolder cursor-pointer"
                  onClick={() => {
                    history.push("/user-profile/personal-information");
                    viewSideBar && hideSideBar();
                  }}
                >
                  {profileToEdit.firstName}{" "}
                  {profileToEdit.lastName && profileToEdit.lastName}
                </a>
                <span class="text-dark-50 font-size-xs">
                  <Link to="/logout" className="">
                    Sign Out
                  </Link>
                </span>
              </div>
            </div>
          </div>

          <div class="dropdown dropdown-inline">
            <a
              class="btn btn-icon btn-light-primary  ml-3 h-40px w-40px flex-shrink-0"
              onClick={() => {
                setComponent("notification");
                listNotificationData();
              }}
            >
              <span class="svg-icon svg-icon-lg">
                <img
                  className="h-20px"
                  src="/media/svg/icons/custom/notifications-outline.svg"
                />
              </span>
              {unreadNotificationCounts && unreadNotificationCounts !== 0 ? (
                <>
                  <span className="pulse-ring"></span>
                  <span className="position-absolute badge badge-circle badge-sm badge-primary badge_user">
                    {unreadNotificationCounts && unreadNotificationCounts}
                  </span>
                </>
              ) : null}
            </a>
          </div>
          <div class="dropdown dropdown-inline">
            <a
              href="#"
              className="btn btn-icon btn-light-primary  ml-3 h-40px w-40px flex-shrink-0"
              onClick={() => setComponent("messageInbox")}
            >
              <span className="svg-icon svg-icon-lg">
                <img
                  className="h-20px"
                  src="/media/svg/icons/custom/message-square.svg"
                />
              </span>
            </a>
          </div>
          {/* <div class="dropdown dropdown-inline">
            <a
              href="#"
              className="btn btn-icon btn-light-secondary  ml-3 h-40px w-40px flex-shrink-0"
              onClick={() => {
                {
                  viewModal(true);
                }
              }}
            >
              <span className="svg-icon svg-icon-lg">
                <img
                  className="h-20px"
                  src="/media/svg/icons/custom/Icon_info.svg"
                />
              </span>
            </a>
          </div> */}
          <div class="dropdown dropdown-inline d-block d-sm-none">
            <a
              href="#"
              className="btn btn-icon btn-light  ml-3 h-40px w-40px flex-shrink-0"
              onClick={() => {
                hideSideBar();
              }}
            >
              <span className="svg-icon svg-icon-lg">
                <img
                  className="h-20px"
                  src="/media/svg/icons/custom/Icon_close.svg"
                />
              </span>
            </a>
          </div>
        </div>
      </div>
      {/*sidebar header end::Content*/}
      {/*sidebar content::Content*/}
      <div className="sidebar-content flex-column-fluid pb-10 pt-9 px-5 px-lg-10">
        {
          <>
               {(profileToEdit?.aboutUser?.length !== 0 &&
                profileToEdit?.instagramFollowerCount?.length !== 0) ||
                profileToEdit?.youtubeSubscriptionCount?.length !== 0 ||
                profileToEdit?.instagramId?.length !== 0 ||
                (profileToEdit?.youtubeId?.length !== 0 &&
                profileToEdit?.paymentAccountId !== 0 &&
                portfolioDetails?.rows?.length !== 0 ? null : (<div class="d-flex bg-light-primary rounded p-5 mb-6 flex-wrap ">
           
                  <div className="d-flex ">
                    <span className="svg-icon svg-icon-danger mr-5">
                      <span className="svg-icon svg-icon-lg">
                        <img src="/media/svg/icons/General/Half-star.svg" />
                      </span>
                    </span>
                    <div className="d-flex flex-column flex-grow-1 mr-0">
                      <span
                        className="font-weight-SemiBold text-dark-75 font-size-md mb-1"
                        onClick={() => {
                          history.push("/user-profile");
                        }}
                      >
                        Your 1000X Profile brings more brand opportunities.
                      </span>
                    </div>
                  </div>
              {/* <div class="w-100 col-12 mt-3 px-0">
                <ul class="list_item m-0 p-0 progress_list">
                  <li class="font-size-sm font-weight-Medium mb-3"><img class={`mr-3 ${profileToEdit?.aboutUser?.length > 1 ? "" : "opacity-50"}`} src={`${profileToEdit?.aboutUser?.length > 1 ? "/media/svg/icons/custom/list_icon.svg" : "/media/svg/icons/custom/list_icon_inactive.svg"}`} />Basic profile information.</li>
                  <li class="font-size-sm font-weight-Medium mb-3"><img class={`mr-3 ${profileToEdit?.instagramFollowerCount?.length === 0 || profileToEdit?.youtubeSubscriptionCount?.length === 0 || profileToEdit?.instagramId?.length === 0 || profileToEdit?.youtubeId?.length === 0 ? "opacity-50" : ""}`} src={`${profileToEdit?.instagramFollowerCount?.length === 0 || profileToEdit?.youtubeSubscriptionCount?.length === 0 || profileToEdit?.instagramId?.length === 0 || profileToEdit?.youtubeId?.length === 0 ? "/media/svg/icons/custom/list_icon_inactive.svg" : "/media/svg/icons/custom/list_icon.svg"}`} />Add social media details for the influencer marketing.</li>
                  <li class="font-size-sm font-weight-Medium mb-3"><img class={`mr-3 ${profileToEdit?.paymentAccountId ? "" : "opacity-50"}`} src={`${profileToEdit?.paymentAccountId ? "/media/svg/icons/custom/list_icon.svg" : "/media/svg/icons/custom/list_icon_inactive.svg"}`} />Add your bank details for payment.</li>
                  <li class="font-size-sm font-weight-Medium mb-3"><img class={`mr-3 ${portfolioDetails?.rows?.length > 0 ? "" : "opacity-50"}`} src={` ${portfolioDetails?.rows?.length > 0 ? "/media/svg/icons/custom/list_icon.svg" : "/media/svg/icons/custom/list_icon_inactive.svg"}`} />Build your portfolio for the content works.</li>
                  <img class="view_profile_btn" src="/media/custom/arrow_right.svg" onClick={() => { history.push("/user-profile") }} />
                </ul>
              </div> */}
              <div class="w-100 col-12 mt-3 px-0">
                <ul class="list_item m-0 p-0 progress_list">
                  {profileToEdit?.aboutUser?.length <= 1 && (
                    <li class="font-size-sm font-weight-Medium mb-3">
                      <img
                        class={`mr-3 opacity-50`}
                        src={`${
                          profileToEdit?.aboutUser?.length > 1
                            ? "/media/svg/icons/custom/list_icon.svg"
                            : "/media/svg/icons/custom/list_icon_inactive.svg"
                        }`}
                      />
                      Basic profile information.
                    </li>
                  )}
                  {profileToEdit?.instagramFollowerCount?.length === 0 ||
                    profileToEdit?.youtubeSubscriptionCount?.length === 0 ||
                    profileToEdit?.instagramId?.length === 0 ||
                    (profileToEdit?.youtubeId?.length === 0 && (
                      <li class="font-size-sm font-weight-Medium mb-3">
                        <img
                          class={`mr-3 "opacity-50"`}
                          src={`${
                            profileToEdit?.instagramFollowerCount?.length ===
                              0 ||
                            profileToEdit?.youtubeSubscriptionCount?.length ===
                              0 ||
                            profileToEdit?.instagramId?.length === 0 ||
                            profileToEdit?.youtubeId?.length === 0
                              ? "/media/svg/icons/custom/list_icon_inactive.svg"
                              : "/media/svg/icons/custom/list_icon.svg"
                          }`}
                        />
                        Add social media details for the influencer marketing.
                      </li>
                    ))}
                  {profileToEdit?.paymentAccountId <= 1 && (
                    <li class="font-size-sm font-weight-Medium mb-3">
                      <img
                        class={`mr-3 opacity-50`}
                        src={`${
                          profileToEdit?.paymentAccountId
                            ? "/media/svg/icons/custom/list_icon.svg"
                            : "/media/svg/icons/custom/list_icon_inactive.svg"
                        }`}
                      />
                      Add your bank details for payment.
                    </li>
                  )}
                  {portfolioDetails?.rows?.length < 1 && (
                    <li class="font-size-sm font-weight-Medium mb-3">
                      <img
                        class={`mr-3 opacity-50`}
                        src={` ${
                          portfolioDetails?.rows?.length > 0
                            ? "/media/svg/icons/custom/list_icon.svg"
                            : "/media/svg/icons/custom/list_icon_inactive.svg"
                        }`}
                      />
                      Build your portfolio for the content works.
                    </li>
                  )}
                  <img
                    class="view_profile_btn"
                    src="/media/custom/arrow_right.svg"
                    onClick={() => {
                      history.push("/user-profile");
                    }}
                  />
                </ul>
              </div>
            </div>))}
            {profileToEdit?.aboutUser?.length < 1 ||
            profileToEdit?.profilePic?.length < 1 ||
            profileToEdit?.fullName?.length < 1 ||
            profileToEdit?.gender?.length < 1 ||
            profileToEdit?.creatorType?.length < 1 ||
            profileToEdit?.areasOfIneterst?.length < 1 ? (
              <div class="d-flex bg-light-primary rounded p-5 mb-6 flex-wrap ">
                <div className="d-flex ">
                  <span className="svg-icon svg-icon-danger mr-5">
                    <span className="svg-icon svg-icon-lg">
                      <img src="/media/svg/icons/General/Half-star.svg" />
                    </span>
                  </span>
                  <div className="d-flex flex-column flex-grow-1 mr-0">
                    <span
                      className="font-weight-SemiBold text-dark-75 font-size-md mb-1 cursor-pointer"
                      onClick={() => {
                        history.push("/user-profile");
                        hideSideBar();
                      }}
                    >
                      Update your profile information to maximise the brand
                      opportunities
                    </span>
                  </div>
                </div>
              </div>
            ) : null}
            {/* {profileToEdit?.instagramFollowerCount === null ||
            profileToEdit?.instagramFollowerCount?.length < 1 ||
            profileToEdit?.youtubeSubscriptionCount === null ||
            profileToEdit?.youtubeSubscriptionCount?.length < 1 ||
            profileToEdit?.instagramId === null ||
            profileToEdit?.instagramId?.length < 1 ||
            profileToEdit?.youtubeId === null ||
            profileToEdit?.youtubeId?.length < 1 ? (
              <div class="d-flex bg-light-primary rounded p-5 mb-6 flex-wrap ">
              <div className="d-flex ">
                  <span className="svg-icon svg-icon-danger mr-5">
                    <span className="svg-icon svg-icon-lg">
                      <img src="/media/svg/icons/General/Half-star.svg" />
                    </span>
                  </span>
                  <div className="d-flex flex-column flex-grow-1 mr-0">
                    <span
                      className="font-weight-SemiBold text-dark-75 font-size-md mb-1 cursor-pointer"
                      onClick={() => {
                        history.push("/user-profile");
                      }}
                    >
                     Update your social media details for applying influencer campaigns.
                    </span>
                  </div>
                 </div>
             </div>
            ) : null} */}
            {/* {profileToEdit?.paymentAccountId === null ? (
              <div class="d-flex bg-light-primary rounded p-5 mb-6 flex-wrap ">
                <div className="d-flex ">
                  <span className="svg-icon svg-icon-danger mr-5">
                    <span className="svg-icon svg-icon-lg">
                      <img src="/media/svg/icons/General/Half-star.svg" />
                    </span>
                  </span>
                  <div className="d-flex flex-column flex-grow-1 mr-0">
                    <span
                      className="font-weight-SemiBold text-dark-75 font-size-md mb-1 cursor-pointer"
                      onClick={() => {
                        history.push("/user-profile");
                      }}
                    >
                      Add your bank details to collect the payment for your work.
                    </span>
                  </div>
                </div>
              </div>
            ) : null} */}
            {/* {portfolioDetails?.rows?.length < 1 ? (
              <div class="d-flex bg-light-primary rounded p-5 mb-6 flex-wrap ">
                <div className="d-flex ">
                  <span className="svg-icon svg-icon-danger mr-5">
                    <span className="svg-icon svg-icon-lg">
                      <img src="/media/svg/icons/General/Half-star.svg" />
                    </span>
                  </span>
                  <div className="d-flex flex-column flex-grow-1 mr-0">
                    <span
                      className="font-weight-SemiBold text-dark-75 font-size-md mb-1 cursor-pointer"
                      onClick={() => {
                        history.push("/user-profile");
                      }}
                    >
                      Create a portfolio to qualify your profile for the content
                      campaign selection.
                    </span>
                  </div>
                </div>
              </div>
            ) : null} */}
          </>
        }

        <div className="mb-15">
          {/*Notification*/}
          {component === "notification" && (
            <div className="card card-custom  gutter-b bg-light mb-4">
              <div className="card-header border-0 pl-5">
                <h3 className="card-title font-weight-bolder text-dark">
                  Notification
                </h3>
                <div className="card-toolbar">
                  <div class="dropdown dropdown-inline">
                    {/* <a href="#" class="btn btn-light bg-white btn-sm font-size-sm font-weight-bolder dropdown-toggle text-dark-75" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Latest</a> */}
                    <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                      <ul class="navi navi-hover">
                        <li class="navi-header pb-1">
                          <span class="text-primary text-uppercase font-weight-bold font-size-sm">
                            Add new:
                          </span>
                        </li>

                        <li class="navi-item">
                          <a href="#" class="navi-link">
                            <span class="navi-icon">
                              <i class="flaticon2-writing"></i>
                            </span>
                            <span class="navi-text">File</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body pt-0 mob_view_h max-h-500px pr-5 overflow-auto pl-5">
                {/* <div className="d-flex align-items-center flex-wrap mb-4 m-0">
                <div className="symbol symbol-50 mr-5 symbol-light-success">
                  <span className="symbol-label">
                    <img src="/media/users/300_20.jpg" className="w-100 align-self-center rounded" alt="" />
                  </span>
                </div>
                <div className="d-flex flex-column flex-grow-1 mr-0">
                  <div className="d-flex align-items-center flex-wrap justify-content-between">
                    <a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1 text-truncate  max-w-150px">New Application</a>
                    <span className="text-muted font-size-sm">5 Mins</span>
                  </div>
                  <span className="text-muted font-weight-bold text-truncate max-w-200px">You have a new application request received</span>
                </div>
              </div> */}
                {list === true && (
                  <div className="d-flex justify-content-center align-item-center my-10 mx-auto">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                {notificationsList.length
                  ? notificationsList.map((item, index) => (
                      <div
                        className="d-flex align-items-center flex-nowrap mb-4 m-0"
                        key={index}
                      >
                        <div className="symbol symbol-50 mr-5 symbol-light-success">
                          <span
                            className="symbol-label"
                            style={{
                              backgroundImage: item.profileImage
                                ? "url(" +
                                  `${process.env.REACT_APP_AWS_S3_BASE_URL +
                                    process.env
                                      .REACT_APP_S3_USER_PROFILE_FOLDER +
                                    "/" +
                                    item.profileImage}` +
                                  ")"
                                : "url(/media/dummyImages/no_preview.png)",
                            }}
                          >
                            {/* <img src="/media/users/300_20.jpg" className="w-100 align-self-center rounded" alt="" /> */}
                          </span>
                        </div>
                        <div className="d-flex flex-column flex-grow-1 mr-0">
                          <div className="d-flex align-items-center flex-nowrap justify-content-between">
                            <a
                              href="#"
                              style={{ width: "60%" }}
                              className="font-weight-bolder text-dark-75 text-hover-primary notification_text font-size-lg mb-1 max-w-160px  text-truncate"
                              title={item.notfyHead}
                              onClick={() =>
                                item?.path ? history.push(item?.path) : "#"
                              }
                            >
                              {item.notfyHead}
                            </a>
                            <span className="text-muted font-size-sm max-w-100px">
                              {moment(item.date)
                                .startOf("hour")
                                .fromNow() !== "Invalid date"
                                ? moment(item.date)
                                    .startOf("hour")
                                    .fromNow()
                                : null}
                            </span>
                          </div>
                          <span
                            className="text-muted message_text text-truncate"
                            title={item.message}
                          >
                            {item.message}
                          </span>
                        </div>
                      </div>
                    ))
                  : null}
                {notificationsList?.length < 1 && (
                  <div class="card-body pt-0 pb-0 px-0">
                    {" "}
                    <div class="row no_data_campaign text-center mx-0">
                      {" "}
                      <img
                        class=" mr-0 w-100 gray_scale"
                        src="/media/custom/no_notification.svg"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {/*Notification END*/}
          {/*message*/}
          {component === "messageInbox" && (
            <MessageInbox chatInboxArray={chatInboxArray} />
          )}
          {component === "campaigns" && <SideBarCampaigns />}
          {/*message END*/}
        </div>
      </div>
      {/*sidebar content end::Content*/}
    </div>
  );
}
