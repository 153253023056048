import React from "react";
import { parseISO } from "date-fns";
import { format } from "date-fns";

export function DateColumnFormatter(cellContent) {
  let formattedDate = "";
  if (cellContent) {
    let date = parseISO(cellContent);
    formattedDate = format(date, "do MMM , yyyy").toString();
  }
  return <span>{formattedDate}</span>;
}
export const getCampaignType = (data) => {
  switch (data) {
    case "SOCIAL_POST":
      return "Achieve Reach";
    case "PHOTOGRAPHY":
      return "Create Contents";
    case "VIDEOGRAPHY":
      return "Boost Sales";
    case "RELATION_POST":
      return "Grow Customer-base";
    case null:
      return "not mentioned";
    default:
      return "data";
  }
};

export const productCategoryName = (productCategory) => {
  if (productCategory === "ACCOUNTINGORBOOKKEEPING") {
    return "Accounting or bookkeeping";
  } else if (productCategory === "AGRICULTUREINDUSTRY") {
    return "Agriculture Industry";
  } else if (productCategory === "AGRITECH") {
    return "Agritech";
  } else if (productCategory === "ALCOHOL") {
    return "Alcohol";
  } else if (productCategory === "ANIMALS") {
    return "Animals";
  } else if (productCategory === "APPAREL") {
    return "Apparel";
  } else if (productCategory === "ART") {
    return "Art";
  } else if (productCategory === "AUTOMOTIVE") {
    return "Automotive";
  } else if (productCategory === "ADROPPINGSTORE") {
    return "A dropping store";
  } else if (productCategory === "APRINTONDEMAND") {
    return "A print-on-demand store";
  } else if (productCategory === "BABYPRODUCTS") {
    return "Baby products";
  } else if (productCategory === "BEAUTY") {
    return "Beauty";
  } else if (productCategory === "BEVERAGES") {
    return "Beverages";
  } else if (productCategory === "BUSINESSSERVICES") {
    return "Business services";
  } else if (productCategory === "BUSINESSCOACHING") {
    return "Business coaching";
  } else if (productCategory === "BUYBULKPRODUCTS") {
    return "Buy Products in Bulk to sell";
  } else if (productCategory === "CLOUDKITCHENS") {
    return "Cloud Kitchens";
  } else if (productCategory === "COLLEGECONSULTING") {
    return "College consulting";
  } else if (productCategory === "CONSUMERPACKEDGOODS") {
    return "Consumer Packaged Goods";
  } else if (productCategory === "CYBERSECURITYANDITCONSULTING") {
    return "Cybersecurity and IT consulting";
  } else if (productCategory === "DECOR") {
    return "Decor";
  } else if (productCategory === "DESIGNING") {
    return "Designing";
  } else if (productCategory === "DIGITALADVERTISING") {
    return "Digital advertising";
  } else if (productCategory === "DIVERSIFIED") {
    return "Diversified";
  } else if (productCategory === "DOGWALKING") {
    return "Dog-walking";
  } else if (productCategory === "DROPSHIPPING") {
    return "Dropshipping";
  } else if (productCategory === "EDITING") {
    return "Editing";
  } else if (productCategory === "EDUCATION") {
    return "Education";
  } else if (productCategory === "E-BOOKWRITING") {
    return "e-Book writing";
  } else if (productCategory === "E-COMMERCE") {
    return "E-commerce";
  } else if (productCategory === "E-COMMERCESTORE") {
    return "E-commerce store";
  } else if (productCategory === "ELECTRONICS") {
    return "Electronics";
  } else if (productCategory === "ENTERTAINMENT") {
    return "Entertaiment";
  } else if (productCategory === "FASHION") {
    return "Fashion";
  } else if (productCategory === "FINANCE") {
    return "Financial Services";
  } else if (productCategory === "FINTECH") {
    return "Fintech";
  } else if (productCategory === "FITNESS") {
    return "Fitness";
  } else if (productCategory === "FOOD") {
    return "Food";
  } else if (productCategory === "FREELANCECODING&WEBDESIGN") {
    return "Freelance coding and web design";
  } else if (productCategory === "FREELANCEDESIGN") {
    return "Freelance designing";
  } else if (productCategory === "FREELANCERESEARCH") {
    return "Freelance researching";
  } else if (productCategory === "FREELANCEWRITING") {
    return "Freelance writing";
  } else if (productCategory === "HEALTHCARE") {
    return "Health Care";
  } else if (productCategory === "HEAVYEQUIPEMENT") {
    return "Heavy Equipment";
  } else if (productCategory === "HOLIDAY") {
    return "Holiday";
  } else if (productCategory === "HOMEAPPLIANCES") {
    return "Home Appliances";
  } else if (productCategory === "HOUSECLEANING") {
    return "House cleaning";
  } else if (productCategory === "HUMANRESOURCESERVICE") {
    return "Human Resources Service";
  } else if (productCategory === "INFORMATIONTECH") {
    return "Information technology";
  } else if (productCategory === "INTERNETDOMAINSELLING") {
    return "Internet domain selling";
  } else if (productCategory === "LEISURE") {
    return "Leisure";
  } else if (productCategory === "LIFECOACHING") {
    return "Life coaching";
  } else if (productCategory === "LOGISTICS") {
    return "Logistics";
  } else if (productCategory === "LUXURY") {
    return "Luxury";
  } else if (productCategory === "MARKETING") {
    return "Marketing";
  } else if (productCategory === "MARKETINGCONSULTING") {
    return "Marketing consulting";
  } else if (productCategory === "MEDIA") {
    return "Media";
  } else if (productCategory === "MEDICALSERVICE") {
    return "Medical Service";
  } else if (productCategory === "MEDTECH") {
    return "Medtech";
  } else if (productCategory === "OILANDGAS") {
    return "Oil and Gas";
  } else if (productCategory === "ONLINEFITNESSTRAINER") {
    return "Online fitness trainer";
  } else if (productCategory === "ONLINEFUNDRAISINGORGRANTWRITING") {
    return "Online fundraising or grant writing";
  } else if (productCategory === "ONLINEPERSONALASSISTANT") {
    return "Online personal assistant";
  } else if (productCategory === "ONLINERECRUITING") {
    return "Online recruiting";
  } else if (productCategory === "ONLINETHERAPY") {
    return "Online therapy";
  } else if (productCategory === "PET") {
    return "Pet";
  } else if (productCategory === "PERSONALTRAINING") {
    return "Personal training";
  } else if (productCategory === "PHOTOGRAPHY") {
    return "Photography";
  } else if (productCategory === "PODCASTING") {
    return "Podcasting";
  } else if (productCategory === "PRCONSULTING") {
    return "PR consulting";
  } else if (productCategory === "PRODUCTIZESERVICEOREXPERTISE") {
    return "Productize your service or expertise";
  } else if (productCategory === "RESTAURANT") {
    return "Restaurant";
  } else if (productCategory === "RESUMEWRITINGANDCAREERCOACHING") {
    return "Resume writing and career coaching";
  } else if (productCategory === "RETAIL") {
    return "Retail";
  } else if (productCategory === "SELLHOMEMADEPRODUCTS") {
    return "Sell homemade products you make yourself";
  } else if (productCategory === "SELLSERVICEOREXPERTISE") {
    return "Sell  service or expertise";
  } else if (productCategory === "SEOCONSULTING") {
    return "SEO consulting";
  } else if (productCategory === "SOCIALMEDIAMARKETING") {
    return "Social media marketing";
  } else if (productCategory === "SOFTWARE&APPDEV") {
    return "Software and app development";
  } else if (productCategory === "SPORTS") {
    return "Sports";
  } else if (productCategory === "STOCKORFOREIGNCURRENCYTRADING") {
    return "Stock or foreign currency trading";
  } else if (productCategory === "TECHNOLOGY") {
    return "Technology";
  } else if (productCategory === "TELECOM") {
    return "Telecom";
  } else if (productCategory === "TELECOMMUNICATION") {
    return "Telecommunication";
  } else if (productCategory === "TELEMARKETING") {
    return "Telemarketing";
  } else if (productCategory === "TOBACCO") {
    return "Tobacco";
  } else if (productCategory === "TRANSCRIPTION") {
    return "Transcription";
  } else if (productCategory === "TRANSPORTATION") {
    return "Transportation";
  } else if (productCategory === "TRANSLATION") {
    return "Translation";
  } else if (productCategory === "TRAVEL") {
    return "Travel";
  } else if (productCategory === "TRAVELPLANNING") {
    return "Travel planning";
  } else if (productCategory === "TUTORING") {
    return "Tutoring";
  } else if (productCategory === "VIDEOPRODUCTION") {
    return "Video production";
  } else if (productCategory === "VIRTUALASSISTANCE") {
    return "Virtual assistance";
  } else if (productCategory === "VIRTUALTHRIFTSHOP") {
    return "Virtual thrift shop";
  } else if (productCategory === "WRITINGABLOG") {
    return "Writing a blog";
  } else if (productCategory === "YOUTUBE") {
    return "YouTube channel";
  } else if (productCategory === "OTHERS") {
    return "Others";
  }
};