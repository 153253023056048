import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg";
import moment from "moment";

export function MessageInbox({ chatInboxArray }) {
  const history = useHistory();
  const [item, setItem] = useState("");
  const { campaignId } = useParams();
  const [refresh, setRefresh] = useState(false);
  const user = useSelector(({ auth }) => auth.user, shallowEqual);

  useEffect(() => {
    return () => {};
  }, [user]);

  return (
    <div
      className="card card-custom card-shadowless gutter-b bg-light mb-6"
      style={{ height: "100%" }}
    >
      <div className="card-header border-0 pl-5">
        <a href="" className="card-title font-weight-bolder text-dark">
          Message Inbox
        </a>
        <div className="card-toolbar">
          <div class="dropdown dropdown-inline">
            <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
              <ul class="navi navi-hover">
                <li class="navi-header pb-1">
                  <span class="text-primary text-uppercase font-weight-bold font-size-sm">
                    Add new:
                  </span>
                </li>
                <li class="navi-item">
                  <a href="#" class="navi-link">
                    <span class="navi-icon">
                      <i class="flaticon2-writing"></i>
                    </span>
                    <span class="navi-text">File</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body pt-0 mob_view_h max-h-550px pr-5 overflow-auto p-5">
        {chatInboxArray &&
          chatInboxArray.map((item, index) => (
            <div className="d-flex align-items-center flex-nowrap mb-4 m-0 ml-5">
              <div className="symbol symbol-50 mr-5 symbol-light-success">
                <span
                  className="symbol-label"
                  style={{
                    backgroundImage: item.toBrandUserDetails?.profilePic
                      ? "url(" +
                        `${process.env.REACT_APP_AWS_S3_BASE_URL +
                          process.env.REACT_APP_S3_USER_PROFILE_FOLDER +
                          "/" +
                          item.toBrandUserDetails?.profilePic}` +
                        ")"
                      : "url(/media/svg/avatars/admin-avatar.svg)",
                  }}
                ></span>
              </div>
              <div className="d-flex flex-column flex-grow-1 mr-0" style={{width:'40%'}}>
                <div className="d-flex align-items-center justify-content-between">
                  <a
                    href="#"
                    className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1 text-truncate "
                    title={
                      item.toBrandUserDetails
                        ? item.toBrandUserDetails.companyName
                        : "1000x Customer Support"
                    }
                    onClick={() => {
                      history.push(
                        `/campaigns/list/${item?.campaignId}/view?tab=messages`
                      );
                    }}
                  >
                    {item.toBrandUserDetails
                      ? item.toBrandUserDetails.companyName
                      : "Customer Support"}
                  </a>
                </div>
                <span className="text-muted font-weight-bold text-truncate message_text">
                  {item?.messageType === "M_TEXT" ? item.messageValue : null}
                  {item?.messageType === "M_VIDEO" ? (
                    <SVG
                      style={{
                        fill: "#B5B5C3",
                        height: "17px",
                        width: "17px",
                      }}
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/video-camera.svg"
                      )}
                    ></SVG>
                  ) : null}
                  {item?.messageType === "M_IMAGE" ? (
                    <SVG
                      style={{
                        fill: "#B5B5C3",
                        height: "17px",
                        width: "17px",
                      }}
                      src={toAbsoluteUrl("/media/svg/icons/General/image.svg")}
                    ></SVG>
                  ) : null}
                  {item?.messageType === "M_AUDIO" ? (
                    <SVG
                      style={{
                        fill: "#B5B5C3",
                        height: "17px",
                        width: "17px",
                      }}
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/microphone.svg"
                      )}
                    ></SVG>
                  ) : null}
                </span>
              </div>
              <div class="d-flex flex-row mb-7">
                <span className="text-muted font-size-sm max-w-100px">
                  {moment(item.lastMeassgeAt)
                    .startOf("hour")
                    .fromNow() !== "Invalid date"
                    ? moment(item.lastMeassgeAt)
                        .startOf("hour")
                        .fromNow()
                    : null}
                </span>
              </div>
            </div>
          ))}
        {chatInboxArray?.length < 1 && (
          <div class="card-body pt-0 pb-0 px-0">
            <div class="row no_data_campaign text-center mx-0">
              <img
                class=" mr-0 w-100 gray_scale"
                src="/media/custom/no_inbox.svg"
              />
            </div>
          </div>
        )}
        {/* {user && (
          <div className="d-flex flex-row justify-content-end align-self-end">
            <Fab
              onClick={() =>
                history.push(`/messagings/list/${user.userId}/${true}`)
              }
            >
              <img
                src="/media/svg/icons/Communication/chatsupport.svg"
                style={{ minWidth: 10 }}
              />
            </Fab>
          </div>
        )} */}
      </div>
    </div>
  );
}
