/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthRootPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { ToastContainer } from "react-toastify";
import CampaignCardPageV2 from "./modules/CampaignV2/CampaignPageV2" 

let loadWithoutLogin = false;

export function Routes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth?.user != null,
    }),
    shallowEqual
  );

  let isReloadRequired = localStorage.getItem("reloadUrl");

  //is user required load without login or not?
  let urlType = new RegExp(process.env.REACT_APP_UNAUTHORIZED_CAMPAIGN_PATH);
  let isUnAuthorizedAccess = urlType.test(window.location.href);
  if (isUnAuthorizedAccess && !isAuthorized) {
    loadWithoutLogin = true;
  } else {
    loadWithoutLogin = false;
  }

  return (
    <>
      <Switch>
        {!isAuthorized ? (
          /*Render auth page when user at `/auth` and not authorized.*/
          <Route>
            {loadWithoutLogin ? (
              <div style={{ margin: "10px" }}>
                <div className="logo">
                    <img alt="logo" width="110" src="/media/logos/logo-dark.png"/>
                </div>
                <CampaignCardPageV2 />
              </div>
            ) : (
              <AuthRootPage />
            )}
          </Route>
        ) : (
          /*Otherwise redirect to root page (`/`)*/
          <Redirect
            from="/auth"
            to={isReloadRequired ? isReloadRequired : "/"}
          />
        )}

        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={Logout} />

        {!isAuthorized ? (
          loadWithoutLogin ? (
            <CampaignCardPageV2 />
          ) : (
            /*Redirect to `/auth` when user is not authorized*/
            <Redirect to="/auth/login" />
          )
        ) : (
          <Layout>
            <BasePage />
          </Layout>
        )}
      </Switch>
      <ToastContainer />
    </>
  );
}
