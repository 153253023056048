import React, { useEffect, useState } from "react";
// import { Card , CardBody } from "../../../_metronic/_partials/controls";
import { makeStyles } from "@material-ui/core";
import { Card, Box } from "@material-ui/core";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { CardActionArea } from "@material-ui/core";
// import Pagination from "@material-ui/lab/Pagination";
import { Pagination } from "../../../_metronic/_partials/controls";
import { CardActions } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { CardMedia } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { CardHeader } from "@material-ui/core";
import { Avatar } from "@material-ui/core";
import { Chip } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../campaign/_redux/campaignActions";
import ComponentLoader from "../../components/ComponentLoader";
import { getCampaignType, DateColumnFormatter, productCategoryName } from "./helperLabel";
import { getColor, getreward } from "../Dashboard/helper/helper";
import { DateFormatter } from "../Dashboard/helper/DateFormatter";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
// import ComponentLoader from "../../../components/ComponentLoader";
const useStyles = makeStyles({
  root: {
    // maxWidth: 300,
    // minWidth: 150,
    // width: 345,
    // margin: 8,
  },
  media: {
    // height: 140,
  },
  // avatar: {
  //   backgroundColor: red[500],
  // }
});
export const nFormatter = (num) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 100000) {
    return (num / 100000).toFixed(1).replace(/\.0$/, "") + "L";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "k";
  }
  //   if (num <= 1000) {
  //     return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  //  }
  return num;
};
export default function CampaignCardV2() {
  const classes = useStyles();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(12);
  const [searchText, setSearchText] = useState("");
  const { currentState, loggedInUserId, isRightSidebar } = useSelector(
    (state) => ({
      currentState: state.campaign,
      loggedInUserId: state.auth.user?.userId,
      isRightSidebar: state.common.isRightSidebar,
    }),
    shallowEqual
  );
  
  const { totalCount, entities, totalPages } = currentState;
  const location = useLocation();
  const dispatch = useDispatch();
  const commonQueryParams = {
    pageSize: currentPageSize,
    query: {},
    sort: "-updatedAt",
    populate: ["brandDetail"],
    loggedInUserId,
  };

  useEffect(() => {
    // server call by queryParams
    const handler = setTimeout(() => {
      if (searchText?.length > 0) {
        dispatch(
          actions.searchCampaigns({
            ...commonQueryParams,
            search: searchText,
            page: currentPage,
          })
        );
      } else if (location.search) {
        let query = {};
        if (location.search) {
          query = { brandId: location?.search?.replace("?", "") };
        }

        dispatch(
          actions.listSpecificCampaigns({
            ...commonQueryParams,
            page: currentPage,
            query,
          })
        );
      } else {
        dispatch(
          actions.listCampaigns({
            ...commonQueryParams,
            page: currentPage,
          })
        );
      }
    }, 500);

    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchText, currentPageSize]);

  let filteredData = currentState?.entities?.filter((item) => {
    return item.campaignStatus === "APPROVED_BY_ADMIN";
  });
  const sizePerPageList = [
    { text: "3", value: 3 },
    { text: "6", value: 6 },
    { text: "12", value: 12 },
  ];
  const onPageChange = (e) => {
    console.log("e", setCurrentPage(e));
  };
  const onSizePerPageChange = (e) => {
    console.log("onSizePerPageChange", e);
    setCurrentPageSize(e);
  };
  const paginationOptions = {
    custom: true,
    totalSize: currentState.totalCount,
    sizePerPageList: sizePerPageList,
    sizePerPage: currentPageSize,
    page: currentPage,
    dataSize: currentState.totalCount,
    pageStartIndex: 1,
    paginationSize: 5,
    onPageChange: onPageChange,
    onSizePerPageChange: onSizePerPageChange,
  };

  return (
    <>
      {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< Search starts >>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
      <div className="row mt-5">
        <div className="col-xl-12">
          <div className="card card-custom card-stretch  gutter-b">
            <div className="card-header border-0 py-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label font-weight-bolder text-dark">
                  Discover
                </span>
                <span className="text-muted mt-3 font-weight-bold font-size-sm">
                  Discover brand campaigns and offers
                </span>
              </h3>
              <div className="card-toolbar">
                <div className="input-icon input-icon-right mr-2">
                  <input
                    type="text"
                    class="form-control bg-light-primary "
                    placeholder="Search..."
                    onChange={(e) => {
                      setSearchText(
                        e.target.value?.length > 0 ? e.target.value : ""
                      );
                    }}
                    value={searchText}
                  />
                  <span>
                    <i class="flaticon2-search-1 icon-md text-primary"></i>
                  </span>
                </div>
                {/* <div class="dropdown dropdown-inline mr-0 show">

                  <button type="button" class="btn btn-light btn-sm mr-2  dropdown-toggle">
                    <span class="svg-icon svg-icon-md">
                      <img className="mr-1" src="/media/svg/icons/custom/feather-box.svg" />
                    </span>category</button>
                  <button type="button" class="btn btn-light btn-sm mr-2  dropdown-toggle">
                    <span class="svg-icon svg-icon-md">
                      <img className="mr-1" src="/media/svg/icons/custom/rupee-sign.svg" />
                    </span>cost</button>
                  <button type="button" class="btn btn-light btn-sm  dropdown-toggle">
                    <span class="svg-icon svg-icon-md">
                      <img className="mr-1" src="/media/svg/icons/custom/Icon-calendar.svg" />
                    </span>Date</button>
                </div> */}
              </div>
            </div>
            {currentState.listLoading && <ComponentLoader />}
            <div className="card-body py-0 row">
              {entities &&
                entities.map((item, index) => (
                  <div class={` ${
                    !isRightSidebar
                      ? "col-md-3"
                      : "col-md-4"
                  }`} key={index}>
                    {/* item?.productImageUrl
                          ? `${process.env.REACT_APP_AWS_S3_BASE_URL +
                              item.productImageUrl}`
                          : "/media/dummyImages/no_preview.png" */}
                    <div
                      className="d_card campaign_card w-100 d-flex align-content-between flex-wrap cursor-pointer"
                      onClick={() => {
                        history.push(`/campaigns/list/${item.campaignId}/view`);
                      }}
                    >
                      <div
                        class="w-100 thumb_img min-h-175px"
                        style={{
                          backgroundImage: item?.productImageUrl
                            ? "url(" +
                              `${process.env.REACT_APP_AWS_S3_BASE_URL +
                                item?.productImageUrl}` +
                              ")"
                            : "url(/media/dummyImages/no_preview_v2.png)",
                        }}
                      ></div>
                      <div className="col-12 px-4 d-flex justify-content-end campaign_cat">
                        <span
                          className="label label-outline-success label-pill label-inline label_dark"
                          style={{ color: getColor(item.campaignCategory) }}
                          // dangerouslySetInnerHTML={{
                          //   __html: item.productCategory,
                          // }}
                        >
                          {(item?.campaignCategory)}
                        </span>
                      </div>
                      <div className="col-12 px-4">
                        {/* <p className="w-100 mb-1 text-dark text-truncate" title="Photography/Creatives" dangerouslySetInnerHTML={{
                      __html: getCampaignType(item?.campaignName)
                    }} /> */}
                        <p
                          className="w-100 mb-1 text-dark text-truncate font-weight-SemiBold"
                          title={item?.campaignName}
                        >
                          {item?.campaignName}
                        </p>

                        <div className="d-flex d-flex align-items-center">
                          <div className="flex-grow-1">
                            <div className="d-flex align-items-center mb-0">
                              <div className="symbol symbol-30 symbol-circle symbol-light-success mr-2">
                                <span
                                  className="symbol-label"
                                  style={{
                                    backgroundImage: item?.brandDetail
                                      ?.profilePic
                                      ? "url(" +
                                        `${process.env
                                          .REACT_APP_AWS_S3_BASE_URL +
                                          process.env
                                            .REACT_APP_S3_USER_PROFILE_FOLDER +
                                          "/" +
                                          item?.brandDetail?.profilePic}` +
                                        ")"
                                      : "url(/media/dummyImages/no_preview.png)",
                                  }}
                                >
                                  {/* <img src={process.env.REACT_APP_AWS_S3_BASE_URL +
                                                        process.env.REACT_APP_S3_USER_PROFILE_FOLDER +
                                                        "/" +
                                                        item?.brandDetail?.profilePic} className="h-75 align-self-end rounded-circle" alt="" /> */}
                                </span>
                              </div>
                              <div className="d-flex flex-column flex-grow-1 font-weight-bold">
                                <a
                                  href="#"
                                  className="text-dark mb-0 font-size-sm text-truncate"
                                  dangerouslySetInnerHTML={{
                                    __html: item.brandDetail?.companyName,
                                  }}
                                />
                                <span className="text-dark font-size-xs">
                                  <img
                                    class="mr-1 h-10px mt-n1"
                                    src="/media/svg/icons/custom/Icon-calendar.svg"
                                  />{" "}
                                  <span class="opacity-50">
                                    {DateFormatter(item.campaignClosureTime)}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="flex-shrink-0">
                            {/* <span className="number text-theme font-weight-bolder font-size-h3">{item.rewardModel ==="PRODUCT" && item.rewardProductDescription} </span> */}
                            {item.rewardModel === "PRODUCT" && (
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="group-edit-tooltip">
                                    {item.rewardProductDescription}
                                  </Tooltip>
                                }
                              >
                                <span className="svg-icon svg-icon-md svg-icon-secondary">
                                  {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />  <i className="fas fa-gift  text-primary"></i>*/}

                                  <a
                                    href="#"
                                    class="btn bg-custom-primary btn-pill btn-sm"
                                  >
                                    Gift
                                  </a>
                                </span>
                              </OverlayTrigger>
                            )}
                            {item.rewardModel === "OFFER" && (
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="group-edit-tooltip">
                                    {item.rewardProductDescription}
                                  </Tooltip>
                                }
                              >
                                <span className="svg-icon svg-icon-md svg-icon-secondary">
                                  {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />  <i className="fas fa-gift  text-primary"></i>*/}
                                  <a
                                    href="#"
                                    class="btn bg-custom-primary btn-pill btn-sm"
                                  >
                                    Offer
                                  </a>
                                </span>
                              </OverlayTrigger>
                            )}
                            <p class="small mb-0 text-light text-uppercase d-none">
                              Starting At
                            </p>
                            <span className="number text-theme font-weight-bolder font-size-h3">
                              {item.rewardModel === "CASH" &&
                                (item.reward?.endsWith("INR") ? (
                                  <img
                                    src="/media/custom/Icon_rupee_sign.svg"
                                    class="mt-n1"
                                  />
                                ) : (
                                  "$"
                                ))}{" "}
                              {item.rewardModel === "CASH" &&
                                nFormatter(getreward(item.reward))}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>

        <Box
          display="flex"
          flexWrap="wrap"
          className=" d-none justify-content-between row MuiBox-root-23  mx-2 mb-3 "
        >
          <h2>Discover</h2>
          <div className="input-icon">
            <Box className="input-group-lg">
              <input
                type="text"
                placeholder="Search here"
                className="form-control "
                onChange={(e) => {
                  setSearchText(
                    e.target.value?.length > 0 ? e.target.value : ""
                  );
                }}
                value={searchText}
              />
            </Box>
            <span className="pl-4">
              <i className="flaticon2-search-1 icon-md"></i>
            </span>
          </div>
        </Box>
        {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< Search ENDS>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

        <Box
          display="flex"
          flexWrap="wrap"
          alignContent="space-between"
          className="row mx-0 d-none "

          // bgcolor="background.paper"
          // sx={{ height: 100 }}
          // key={index}
        >
          {filteredData
            ? filteredData.map((item, index) => (
                <Box p={1} key={index} className="col-md-3">
                  <Card className="" key={index} sx={{ width: 345 }}>
                    <CardHeader
                      // dangerouslySetInnerHTML={{ __html: "Hello" }}
                      avatar={
                        <Avatar
                          aria-label="recipe"
                          className="symbol rounded-0 symbol-lg-50"
                          src={
                            process.env.REACT_APP_AWS_S3_BASE_URL +
                            process.env.REACT_APP_S3_USER_PROFILE_FOLDER +
                            "/" +
                            item?.brandDetail?.profilePic
                          }
                        ></Avatar>
                      }
                      subheader={
                        <div
                          className="text-dark font-weight-bold font-size-h5 mb-0"
                          dangerouslySetInnerHTML={{
                            __html: item.brandDetail?.companyName,
                          }}
                        />
                      }
                    />
                    <CardActionArea
                      onClick={() =>
                        history.push(`/campaigns/list/${item.campaignId}/view`)
                      }
                    >
                      <CardMedia
                        className="bgi-no-repeat bgi-size-cover min-h-180px w-100 rounded-0"
                        alt=""
                        image={
                          item?.productImageUrl
                            ? `${process.env.REACT_APP_AWS_S3_BASE_URL +
                                item.productImageUrl}`
                            : "/media/dummyImages/no_preview.png"
                        }
                        title="Contemplative Reptile"
                        height="140"
                      />
                      <CardContent>
                        <Chip
                          className="label label-lg label-light-primary label-inline"
                          label={
                            <span
                              dangerouslySetInnerHTML={{
                                __html: item.productCategory,
                              }}
                            />
                          }
                        />
                        <Typography
                          gutterBottom
                          variant="subtitle1"
                          component="h2"
                          className="w-100"
                        >
                          <Box
                            display="flex"
                            flexWrap="wrap"
                            alignContent="space-between"
                            className="w-100"
                          >
                            <Box className="w-100">
                              {
                                <div
                                  className="text-dark mt-2 font-size-h5 font-weight-bold col-12 pl-0 text-truncate"
                                  dangerouslySetInnerHTML={{
                                    __html: item.campaignName,
                                  }}
                                />
                              }
                            </Box>
                          </Box>
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          color="textSecondary"
                          component="p"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: getCampaignType(item?.campaignType),
                            }}
                          ></div>
                          {/* {item.campaignType === "SOCIAL_POST" && (
                          <>Social Post</>
                        )}
                        {item.campaignType === "PHOTOGRAPHY" && (
                          <>Photography/Creatives</>
                        )}
                        {item.campaignType === "VIDEOGRAPHY" && (
                          <>Video Content </>
                        )}
                        {item.campaignType === "RELATION_POST" && (
                          <>Relationship Building</>
                        )} */}
                        </Typography>

                        <Box
                          display="flex"
                          flexDirection="row"
                          sx={{ justifyContent: "space-between" }}
                        >
                          <Typography variant="subtitle2" sx={{ mt: 5 }}>
                            <i className="flaticon2-calendar-9 mr-2 font-size-lg"></i>{" "}
                            {DateColumnFormatter(item.campaignClosureTime)}
                          </Typography>
                          {/* <Box m={1}> */}

                          <div className="font-size-h4 font-weight-bolder text-primary">
                            {item.reward === "0.00 null"
                              ? "Gift/Offer"
                              : item.reward}
                          </div>
                          {/* </Box> */}
                        </Box>
                      </CardContent>
                    </CardActionArea>
                    {/* <CardActions> */}
                    {/* <Button size="small" color="success"
          View details
        </Button> */}

                    {/* <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >

                    </Typography>
                  </CardActions> */}
                  </Card>
                </Box>
              ))
            : !currentState?.listLoading && "No data found"}
        </Box>
        {/* )} */}
        {totalPages > 1 && (
          // <Box display="flex" justifyContent="center" className="datatable d-none datatable-default">
          <div className="datatable-pager datatable-paging-loaded">
            <Pagination paginationProps={paginationOptions} />
            {/* <Pagination
            onChange={(e, p) => setCurrentPage(p)}
            page={currentPage}
            count={totalPages}
            color="secondary"
             className="datatable-pager-nav my-2 mb-sm-0"
          /> */}
          </div>
          // </Box>
        )}
      </div>
    </>
  );
}
