/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  memo,
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
} from "react";
import {
  loadMessages,
  updateIncomingMessage,
  sendNewChatAction,
  resetMessages,
} from "../../ChatModule/_redux/ChatModuleActions";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import moment from "moment";
import { getSignedUrl } from "../../_redux/commonActions";
import ChatFile from "../../UserProfile/components/ChatFile";
import { getFileType } from "../helper/helper";
import { useStopwatch } from "react-timer-hook";
import useRecorder from "../../ChatModule/chat-voice-recorder/useRecorder";
import * as actions from "../../_redux/commonActions";
import { Button } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

function pad(num, size) {
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
}

const MessagesTab = ({ campaign, isActive, refreshApplication }) => {
  const dispatch = useDispatch();
  const scrollRef = useRef();
  const [messageContent, setMessageContent] = useState("");
  const [loadBottom, setLoadBottom] = useState(true);
  const [newMessageType, setNewMessageType] = useState(null);
  const [newMessageContent, setNewMessageContent] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const imageRef = useRef(null);

  const {
    loggedInUserId,
    listLoading,
    actionsLoading,
    chatMessagesArray,
    socket,
  } = useSelector(
    (state) => ({
      loggedInUserId: state.auth.user?.userId,
      listLoading: state.chatModule.isApiInprogressFlag,
      actionsLoading: state.common.actionsLoading,
      chatMessagesArray: state.chatModule.chatMessagesArray,
      socket: state.common.socket,
    }),
    shallowEqual
  );

  useLayoutEffect(() => {
    // if (loadBottom) {
    if (scrollRef?.current) {
      scrollRef.current.scrollIntoView();
    }
    // }
  }, [loadBottom]);

  useEffect(() => {
    Object.keys(campaign)?.length > 0 &&
      isActive &&
      dispatch(
        loadMessages(
          loggedInUserId,
          campaign?.brandId,
          1,
          1000,
          campaign?.campaignId
        )
      ).then(() => setLoadBottom(!loadBottom));

    return () => dispatch(resetMessages());
  }, [campaign, isActive]);

  let [
    audioURL,
    isRecording,
    startRecording,
    stopRecording,
    audioFile,
  ] = useRecorder();

  const {
    seconds,
    minutes,
    pause: pauseWatch,
    start: startWatch,
    reset: resetWatch,
  } = useStopwatch({ autoStart: false });

  useEffect(() => {
    if (audioFile) {
      setNewMessageType("M_AUDIO");
      setNewMessageContent(audioURL);
    }
  }, [audioFile]);

  const sendNewMessageAudio = () => {
    resetWatch();
    pauseWatch();
    let size = audioFile?.size;
    let fileName = `chatMedia_${new Date().getTime()}_${size}.webm`;
    let newMessageType = "M_AUDIO";
    fileUpload(audioFile, fileName, newMessageType);
  };

  const fileUpload = (file, fileName, newMessageType) => {
    let SignedUrlObject = {
      file_name: fileName,
      file_type: file.type,
      folder: process.env.REACT_APP_S3_USER_CHAT_MEDIA,
      loggedInUserId: loggedInUserId,
      file,
    };

    socket.emit(
      "sentDataToRoom",
      campaign?.brandId + "_chat",
      "messageService",
      SignedUrlObject
    );
    let metaData = {
      message: `new message recived`,
      notfyHead: "Message from user",
      idType: "chatmessage",
      id: loggedInUserId,
    };
    socket.emit(
      "sentDataToRoom",
      campaign?.brandId,
      "notificationService",
      metaData
    );

    dispatch(actions.getSignedUrl(SignedUrlObject)).then((response) => {
      let newMessageContent = `${process.env.REACT_APP_AWS_S3_BASE_URL}chatMedia/${fileName}`;
      setNewMessageType(newMessageType);
      setNewMessageContent(newMessageContent);
      sendNewMessage(newMessageContent, "M_AUDIO");
      updateIncomingMessage(SignedUrlObject);
    });
  };

  const sendNewMessage = (mediaUrl, msgType) => {
    let msgToSend = {
      messageFromUserId: loggedInUserId,
      messageToUserId: campaign?.brandId,
      messageType: msgType ? msgType : "M_TEXT",
      messageValue: mediaUrl ? mediaUrl : messageContent,
      createdAt: new Date().toISOString(),
    };

    socket.emit(
      "sentDataToRoom",
      campaign?.brandId + "_chat",
      "messageService",
      msgToSend
    );
    let metaData = {
      message: `new message recived`,
      notfyHead: "Message from user",
      idType: "chatmessage",
      id: loggedInUserId,
    };
    socket.emit(
      "sentDataToRoom",
      campaign?.brandId,
      "notificationService",
      metaData
    );

    dispatch(updateIncomingMessage(msgToSend));
    dispatch(
      sendNewChatAction({
        fromUserId: loggedInUserId,
        toUserId: campaign?.brandId,
        loggedInUserId,
        messageType: msgType ? msgType : "M_TEXT",
        messageContent: mediaUrl ? mediaUrl : messageContent,
        campaignId: campaign?.campaignId,
      })
    );
    //handleScrollToBottom();
    setMessageContent("");
    setLoadBottom(!loadBottom);
    setNewMessageType(null);
    setNewMessageContent(null);
    dispatch(actions.clearSignedUrlFetched(""));
  };

  const uploadMediaFile = () => {
    let input = document.createElement("input");
    input.type = "file";
    input.onchange = () => {
      // you can use this method to get file and perform respective operations
      let files = Array.from(input.files);
      let SignedUrlObject = {
        file_name: files[0].name,
        file_type: files[0].type,
        folder: "chatMedia/" + loggedInUserId,
        loggedInUserId: loggedInUserId,
        file: files[0],
      };

      dispatch(getSignedUrl(SignedUrlObject)).then((response) => {
        let newMessageContent = `chatMedia/${loggedInUserId}/${response?.data?.data?.path}`;

        sendNewMessage(newMessageContent, getFileType(files[0].type));
      });
    };
    input.click();
  };

  const [expandRow, setExpandRow] = useState(1);
  const onKeyUp = (event) => {
    if (event.key === "Enter" && event.shiftKey) {
      setExpandRow(expandRow + 1);
    } else if (event.key === "Enter" && messageContent.trim() !== "") {
      event.preventDefault();
      sendNewMessage();
      setExpandRow(1);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    socket?.on(
      "messageService",
      (message) => {
        dispatch(updateIncomingMessage(message));
        setLoadBottom(!loadBottom);
      },
      []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  return (
    <div
      className={`tab-pane ${isActive ? "active" : "d-none"}`}
      id="messages"
      role="tabpanel"
    >
      <div className="d-flex flex-row mt-5">
        <div
          className="flex-row-auto offcanvas-mobile w-350px w-xl-250px offcanvas-mobile-on d-none"
          id="kt_chat_aside"
        >
          <div className="card card-custom shadow-none">
            <div className="card-body p-0 pr-3">
              <div class="input-group input-group-solid">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <span class="svg-icon svg-icon-lg">
                      <img src="/media/svg/icons/General/Search.svg" />
                    </span>
                  </span>
                </div>
                <input
                  type="text"
                  class="form-control py-4 h-auto"
                  placeholder="Search"
                />
              </div>
              <div className="mt-7 scroll scroll-pull ps">
                <div class="d-flex align-items-center justify-content-between mb-5">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-circle symbol-30 mr-3">
                      <img
                        alt="Pic"
                        src={
                          campaign?.brandDetail?.profilePic
                            ? process.env.REACT_APP_AWS_S3_BASE_URL +
                              process.env.REACT_APP_S3_USER_PROFILE_FOLDER +
                              "/" +
                              campaign?.brandDetail?.profilePic
                            : "/media/dummyImages/no_preview.png"
                        }
                      />
                    </div>
                    <div class="d-flex flex-column">
                      <a
                        href="#"
                        class="text-dark-75 text-hover-primary font-weight-bold font-size-lg"
                      >
                        {campaign?.brandDetail?.companyName}
                      </a>
                      <span class="text-muted font-weight-bold font-size-sm">
                        Head of Development
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-row-fluid" id="kt_chat_content">
          <div className="card card-custom bg-light">
            <div class="card-header align-items-start px-4 py-3">
              <div class="text-left ">
                <div class="symbol symbol-circle symbol-40 mr-3">
                  <img
                    alt="profile-Pic"
                    src={
                      campaign?.brandDetail?.profilePic
                        ? process.env.REACT_APP_AWS_S3_BASE_URL +
                          process.env.REACT_APP_S3_USER_PROFILE_FOLDER +
                          "/" +
                          campaign?.brandDetail?.profilePic
                        : "/media/dummyImages/no_preview.png"
                    }
                  />
                </div>
              </div>
              <div class="text-center flex-grow-1 text-left">
                <div class="text-dark-75 font-weight-bold font-size-h5 text-left">
                  {campaign?.brandDetail?.companyName}
                </div>
                <div class="text-left">
                  <span class="label label-lg label-dot label-success"></span>
                  <span class="font-weight-bold text-muted font-size-xs pl-3">
                    Active
                  </span>
                </div>
              </div>
            </div>

            <div className="card-body p-6">
              <div class="scroll scroll-pull ps">
                <div class="messages">
                  {/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>chat Messages starts here>>>>>>>>>>>>>>>>>*/}
                  {listLoading ? (
                    <div className="d-flex justify-content-center align-item-center my-10 mx-auto">
                      <div className="spinner spinner-lg spinner-success" />
                    </div>
                  ) : (
                    chatMessagesArray?.map((message) => (
                      <>
                        {loggedInUserId === message?.messageToUserId ? (
                          <div
                            class="d-flex flex-column mb-5 align-items-start"
                            key={message?.messageId}
                          >
                            <div class="d-flex align-items-center">
                              <div>
                                <span class="text-muted font-size-sm mr-3">
                                  {moment(new Date(message?.createdAt)).format(
                                    "D MMM, YYYY"
                                  )}
                                </span>
                                <span class="text-muted font-size-sm">
                                  {moment(new Date(message?.createdAt)).format(
                                    "h:mm a"
                                  )}
                                </span>
                              </div>
                            </div>
                            {message?.messageType === "M_TEXT" ? (
                              <div
                                class={`mt-2 rounded  px-5 py-2 bg-light-success text-dark-50 font-weight-bold font-size-xs max-w-400px`}
                              >
                                {message?.messageValue}
                              </div>
                            ) : message?.messageType === "M_AUDIO" ? (
                              <div class="mt-2 rounded px-5 py-2 bg-light-primary max-w-400px">
                                <audio
                                  src={message?.messageValue}
                                  controls={true}
                                />
                                <p>{message?.messageValue.split("/")[4]}</p>
                              </div>
                            ) : (
                              <ChatFile
                                file={message?.messageValue}
                                type={message?.messageType}
                              />
                            )}
                          </div>
                        ) : (
                          <div
                            class="d-flex flex-column mb-5 align-items-end"
                            key={message?.messageId}
                          >
                            <div class="d-flex align-items-center">
                              <div>
                                <span class="text-muted font-size-sm mr-3">
                                  {moment(new Date(message?.createdAt)).format(
                                    "D MMM, YYYY"
                                  )}
                                </span>
                                <span class="text-muted font-size-sm">
                                  {moment(new Date(message?.createdAt)).format(
                                    "h:mm a"
                                  )}
                                </span>
                              </div>
                            </div>
                            {message?.messageType === "M_TEXT" ? (
                              <div class="mt-2 rounded px-5 py-2 bg-light-primary text-dark-50 font-weight-bold font-size-sm max-w-400px">
                                {message?.messageValue}
                              </div>
                            ) : message?.messageType === "M_AUDIO" ? (
                              <div class="mt-2 rounded px-5 py-2 bg-light-primary max-w-400px">
                                <audio
                                  src={message?.messageValue}
                                  controls={true}
                                />
                                <p>{message?.messageValue.split("/")[4]}</p>
                              </div>
                            ) : (
                              <ChatFile
                                file={message?.messageValue}
                                type={message?.messageType}
                              />
                            )}
                          </div>
                        )}
                        <span ref={scrollRef} />
                      </>
                    ))
                  )}
                  {/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>chat Messages Ends here>>>>>>>>>>>>>>>>>*/}
                </div>
              </div>
            </div>

            {actionsLoading && (
              <div className="w-100 d-flex justify-content-center">
                <div className="w-100 bg-success-o-50 d-flex justify-content-center">
                  Sending Media...
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              </div>
            )}
            <div class="card-footer align-items-center p-3">
              {newMessageType === "M_AUDIO" ? (
                <div className="d-flex">
                  <audio
                    src={newMessageContent}
                    controls
                    className="w-100 mr-5"
                    disabled={actionsLoading}
                  />
                  <Button
                    className="btn-danger m-auto"
                    onClick={() => {
                      setNewMessageContent(null);
                      setNewMessageType(null);
                      resetWatch();
                      pauseWatch();
                    }}
                    disabled={actionsLoading}
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                <textarea
                  class="form-control border-0 p-5 font-size-sm h-60px"
                  rows="2"
                  placeholder={
                    isRecording
                      ? "Your voice is recording...."
                      : "Enter Text Message"
                  }
                  spellcheck="false"
                  value={messageContent}
                  onChange={(e) => setMessageContent(e.target.value)}
                  onKeyUp={onKeyUp}
                />
              )}
              <div class="d-flex align-items-center justify-content-between mt-5">
                <div class="mr-3">
                  <a
                    class="btn btn-clean btn-icon btn-md mr-1"
                    onClick={() => uploadMediaFile()}
                  >
                    <img
                      alt=""
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/Attachment1.svg"
                      )}
                      className="svg-icon-lg"
                    ></img>
                  </a>
                  {/* <a href="#" class="btn btn-clean btn-icon btn-md">
                    <i class="flaticon2-photo-camera icon-lg"></i>
                  </a> */}
                </div>
                {messageContent === "" ? (
                  newMessageType === "M_AUDIO" ? (
                    <Button
                      variant="outline-primary"
                      disabled={actionsLoading}
                      onClick={() => sendNewMessageAudio()}
                    >
                      Send
                    </Button>
                  ) : !isRecording ? (
                    <Button
                      className="btn btn-icon btn-outline-primary btn-circle mr-2"
                      onClick={() => {
                        startRecording();
                        startWatch();
                      }}
                      disabled={actionsLoading}
                    >
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Devices/Mic.svg")}
                      ></SVG>
                    </Button>
                  ) : (
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <div
                        className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-danger"
                        onClick={() => {
                          stopRecording();
                          resetWatch();
                          pauseWatch();
                        }}
                      >
                        <span className="svg-icon svg-icon-xl svg-icon-danger">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Devices/Mic.svg"
                            )}
                            className="svg-icon-lg svg-icon-danger"
                          />
                        </span>
                        <span className="pulse-ring"></span>
                      </div>
                      <div>
                        <span>{pad(minutes, 2)}</span>:
                        <span>{pad(seconds, 2)}</span>
                      </div>
                    </div>
                  )
                ) : (
                  <div>
                    <button
                      type="button"
                      class="btn btn-primary btn-md text-uppercase font-weight-bold chat-send py-2 px-6"
                      disabled={messageContent === ""}
                      onClick={() => sendNewMessage !== "" && sendNewMessage()}
                    >
                      Send
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="row w-100 mx-0">
        <div class="card-body p-0 row">
              <div class="col-md-4">
                <div class="d_card d_gallery w-100 min-h-130px d-flex align-content-between media-file flex-wrap cursor-pointer pt-0">
                    <div class="col-12 px-4 d-flex justify-content-end align-items-center">
                      <div class="bg_light_dark btn h-30px p-0 d-flex justify-content-center align-items-center"><img src="/media/svg/icons/custom/Icon_download_light.svg"/></div>
                    </div>
                    <div class="d-flex d-flex align-items-center w-100 px-4"><div class="flex-grow-1"><div class="d-flex align-items-center mb-0"><div class="d-flex flex-column flex-grow-1 font-weight-bold"><a href="#" class="text-light text-hover-primary mb-0 font-size-sm text-truncate text_break">Innovation Incubator</a></div></div></div><div class="flex-shrink-0"><span class="label_dark label bg-dark-50 mr-0 h-30px w-30px p-0 d-flex align-items-center justify-content-center"><img class="mt-n1 h-15px max-w-15px" src="/media/svg/icons/custom/Icon_youtube.svg"/></span></div></div>
                      </div>
              </div>
              <div class="col-md-4">
                <div class="d_card d_gallery w-100 min-h-130px d-flex align-content-between media-file video flex-wrap cursor-pointer pt-0">
                <div class="col-12 px-4 d-flex justify-content-end">
                  <div class="bg_light_dark btn h-30px w-30px p-0 d-flex justify-content-center align-items-center"><img src="/media/svg/icons/custom/Icon_download_light.svg"/></div>
                </div>
                    <div class="d-flex d-flex align-items-center w-100 px-4 card_title"><div class="flex-grow-1"><div class="d-flex align-items-center mb-0"><div class="d-flex flex-column flex-grow-1 font-weight-bold"><a href="#" class="text-light text-hover-primary mb-0 font-size-sm text-truncate text_break">Innovation Incubator</a></div></div></div><div class="flex-shrink-0"><span class="label_dark label bg-dark-50 mr-0 h-30px w-30px p-0 d-flex align-items-center justify-content-center"><img class="mt-n1 h-15px max-w-15px" src="/media/svg/icons/custom/Icon_youtube.svg"/></span></div></div>
                      </div>
              </div>

        </div>
      </div>
      <div class="row w-100 mx-0">
        <div class="card-body p-0 row">
              <div class="col-md-4">
                    <div class="d_card d_gallery_view w-100 d-flex video align-content-between flex-wrap cursor-pointer">
                      <div class="col-12 px-4 d-flex justify-content-end  align-items-center">
                        <span class="label label-outline-success label-pill label-inline label_dark mr-2">BEVERAGES</span>
                        <span class="bg_light_dark btn h-30px w-30px p-0 d-flex justify-content-center align-items-center"><img src="/media/svg/icons/custom/Icon_download_light.svg"/></span>
                      </div>
                      <div class="col-12 px-4">
                        <p class="w-100 mb-1 text-light text-truncate font-weight-SemiBold" title=" Influencer campaign"> Influencer campaign</p>
                        <div class="d-flex d-flex align-items-center">
                          <div class="flex-grow-1">
                            <div class="d-flex align-items-center mb-0">
                              <div class="symbol symbol-30 symbol-circle symbol-light-success mr-2">
                                <span class="symbol-label"></span>
                              </div>
                              <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                                <a href="#" class="text-light text-hover-primary mb-0 font-size-sm text-truncate">Innovation Incubator</a>
                                <span class="text-light font-size-xs"><img class="mr-1 h-10px mt-n1" src="/media/svg/icons/custom/Icon_calendar_light.svg"/> <span class=""><span>31 Jul 2022</span></span></span>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
              </div>
              <div class="col-md-4">
                    <div class="d_card d_gallery_view w-100 d-flex align-content-between flex-wrap cursor-pointer">
                      <div class="col-12 px-4 d-flex justify-content-end align-items-center">
                        <span class="label label-outline-success label-pill label-inline label_dark mr-2">BEVERAGES</span>
                        <span class="bg_light_dark btn h-30px w-30px p-0 d-flex justify-content-center align-items-center"><img src="/media/svg/icons/custom/Icon_download_light.svg"/></span>
                      </div>
                      <div class="col-12 px-4">
                        <p class="w-100 mb-1 text-light text-truncate font-weight-SemiBold" title=" Influencer campaign"> Influencer campaign</p>
                        <div class="d-flex d-flex align-items-center">
                          <div class="flex-grow-1">
                            <div class="d-flex align-items-center mb-0">
                              <div class="symbol symbol-30 symbol-circle symbol-light-success mr-2">
                                <span class="symbol-label"></span>
                              </div>
                              <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                                <a href="#" class="text-light text-hover-primary mb-0 font-size-sm text-truncate">Innovation Incubator</a>
                                <span class="text-light font-size-xs"><img class="mr-1 h-10px mt-n1" src="/media/svg/icons/custom/Icon_calendar_light.svg"/> <span class=""><span>31 Jul 2022</span></span></span>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
              </div>
        </div>
      </div> */}
    </div>
  );
};

export default memo(MessagesTab);
