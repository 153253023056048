import { createSlice } from "@reduxjs/toolkit";

const initialUsersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  userForEdit: undefined,
  lastError: null,
  roles: null,
  permissions: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const userAccessSlice = createSlice({
  name: "userAccess",
  initialState: initialUsersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}:${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    //got users
    usersFetched: (state, action) => {
      const { total, rows } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = rows;
      state.totalCount = total;
    },
    //got user roles
    userRolesFetched: (state, action) => {
      const rows = action.payload;
      state.roles = rows;
    },
    //got permissions
    permissionsFetched: (state, action) => {
      const permissions = action.payload;
      state.permissions = permissions;
    },
  },
});
