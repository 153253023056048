/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, memo } from "react";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { getApplicationDetails } from "../../../campaign/_redux/campaignActions";
import {
  updateSocialUrl,
  submitAllUrlStatus,
  listApplicationContents,
} from "../../../campaignApplications/_redux/applicationActions";
import { Loader } from "../Loader";
import moment from "moment";
import { getConvertedContentStatus } from "../../helper/helper";

const UrlDetails = ({ stepper }) => {
  const dispatch = useDispatch();
  const [urls, setUrls] = useState({});
  const [clickedButton, setClickedButton] = useState();
  //   const [showDetailedUrl, setShowDetailedUrl] = useState(false);

  const {
    loggedInUserId,
    listLoading,
    actionsLoading,
    campaignDetails,
    applicationDetails,
    applicationContents,
  } = useSelector(
    (state) => ({
      loggedInUserId: state.auth.user?.userId,
      listLoading: state.application.listLoading,
      actionsLoading: state.application.actionsLoading,
      campaignDetails: state.campaign.campaignDetails,
      applicationDetails: state.campaign.applicationDetails[0] || {},
      applicationContents: state.application.applicationContents,
    }),
    shallowEqual
  );

  const uploadSocialUrl = (applicationContentId) => {
    dispatch(
      updateSocialUrl({
        loggedInUserId,
        applicationContentId,
        contentInstagramUrl: urls[applicationContentId]?.contentInstagramUrl,
        contentYoutubeUrl: urls[applicationContentId]?.contentYoutubeUrl,
      })
    ).then(() =>
      dispatch(
        listApplicationContents({
          page: 1,
          pageSize: 20,
          loggedInUserId,
          sort: "applicationContentId",
          query: {
            resubmitCount: applicationDetails?.resubmitCount,
            campaignId: applicationDetails?.campaignId,
            creatorId: loggedInUserId,
          },
        })
      )
    );
  };

  const submitSocialUrl = () => {
    let applicationContentId = applicationContents?.map(
      (content) => content.applicationContentId
    );

    dispatch(
      submitAllUrlStatus({
        loggedInUserId,
        applicationContentId: applicationContentId,
        applicationId: applicationDetails?.applicationId,
      })
    ).then(() =>
      dispatch(
        getApplicationDetails({
          page: 1,
          pageSize: 5,
          loggedInUserId,
          populate: ["brandDetail", "paymentDetails"],
          query: {
            campaignId: applicationDetails?.campaignId,
            creatorId: loggedInUserId,
          },
        })
      )
    );
  };

  const deleteUrl = (applicationContentId, platformUrl) => {
    let updateObj = {
      loggedInUserId,
      applicationContentId,
    };

    if (platformUrl === "contentInstagramUrl") {
      updateObj.contentInstagramUrl = null;
    } else {
      updateObj.contentYoutubeUrl = null;
    }
    dispatch(updateSocialUrl(updateObj)).then(() =>
      dispatch(
        listApplicationContents({
          page: 1,
          pageSize: 20,
          loggedInUserId,
          sort: "applicationContentId",
          query: {
            resubmitCount: applicationDetails?.resubmitCount,
            campaignId: applicationDetails?.campaignId,
            creatorId: loggedInUserId,
          },
        })
      )
    );
  };

  {
    /*<<<<<<<<<<<<<<<<<<<<<<<<<For Deliverables Headings>>>>>>>>>>>>>>>>>>>>>>>>*/
  }
  function getImageDeliverables(postImageStyle) {
    if (postImageStyle === "PORTRAIT_STYLE_PHOTOGRAPHY") {
      return "Document Content";
    } else if (postImageStyle === "LIFESTYLE_PRODUCT_IN_ACTION") {
      return "Audio Content";
    } else if (postImageStyle === "GRAPHIC_DESIGN") {
      return "Image Content";
    }
  }

  function getVideoDeliverables(postVideoStyle) {
    if (postVideoStyle === "ACTION_PRODUCT_IN_USE_VIDEO") {
      return "Video Content";
    } else if (postVideoStyle === "PROMOTIONAL_VIDEO") {
      return "Other Files";
    }
  }

  return (
    <>
      {listLoading ? (
        <div className="d-flex justify-content-center align-item-center my-10 mx-auto">
          <div className="spinner spinner-lg spinner-success" />
        </div>
      ) : (
        <>
          {stepper === "urlDetails" ? (
            <div class="content_list active col-12 px-0  border-bottom">
              <div class="col-12 mx-0 px-0">
                {/* <div class="d-flex d-flex align-items-center py-4">
              <div class="flex-grow-1">
                <div class="d-flex align-items-center mb-0">
                  <div class="symbol symbol-50 rounded symbol-light-success mr-4">
                    <span class="symbol-label"></span>
                  </div>
                  <div
                    class="d-flex flex-column flex-grow-1 font-weight-bold"
                    onClick={() => setShowDetailedUrl(!showDetailedUrl)}
                  >
                    <a
                      // href="#"
                      class="text-dark mb-0 font-size-lg text-truncate font-weight-SemiBold"
                    >
                      Innovation Incubator
                      <span class="label label-inline label-light-success ml-3">
                        Reword
                      </span>
                    </a>
                    <span class="text-dark font-size-xs pt-2">
                      <img
                        class="mr-1 h-10px mt-n1"
                        src="/media/svg/icons/custom/Icon-calendar.svg"
                      />{" "}
                      <span class="font-weight-SemiBold opacity-50 font-size-sm">
                        <span>31 Jul 2022</span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="flex-shrink-0"
                onClick={() => setShowDetailedUrl(!showDetailedUrl)}
              >
                <img
                  class={`${showDetailedUrl ? "content_list_active" : ""}`}
                  src="/media/svg/icons/custom/arrow_btn.svg"
                />
              </div>
            </div> */}

                {applicationContents?.map((content, index) => (
                  <>
                    {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<For Instagram URL>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
                    {content?.contentInstagramUrl === null ? (
                      (campaignDetails?.isInstagramPostRequired ||
                        campaignDetails?.isInstagramStoryRequired) && (
                        <div class="form-group">
                          <h6 class="font-weight-Bold font-size-lg mb-6">
                            Instagram
                          </h6>
                          <label>Instagram Url</label>
                          <label className="d-flex flex-row mt-4">
                            {content?.postType === "IMAGE_POST"
                              ? getImageDeliverables(content?.postImageStyle)
                              : getVideoDeliverables(content?.postVideoStyle)}
                          </label>
                          <div class="d-flex flex-nowrap">
                            <div class="input-icon input-icon-right">
                              <input
                                type="text"
                                class="form-control form-control-solid"
                                placeholder="Instagram media Link"
                                onChange={(e) =>
                                  setUrls({
                                    ...urls,
                                    [content.applicationContentId]: {
                                      ...urls[content.applicationContentId],
                                      contentInstagramUrl: e.target.value,
                                    },
                                  })
                                }
                              />
                            </div>
                            <button
                              class="btn btn-sm btn-primary ml-4"
                              disabled={
                                listLoading ||
                                actionsLoading ||
                                (urls[content?.applicationContentId]
                                  ?.contentInstagramUrl
                                  ? false
                                  : true)
                              }
                              onClick={() => {
                                setClickedButton(`contentInstagramUrl${index}`);
                                uploadSocialUrl(content.applicationContentId);
                              }}
                            >
                              {clickedButton ===
                                `contentInstagramUrl${index}` &&
                              actionsLoading ? (
                                <div class="spinner-border" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              ) : (
                                <span>
                                  <b>Update</b>
                                </span>
                              )}
                            </button>
                          </div>
                        </div>
                      )
                    ) : (
                      <div class="col-12">
                        <div class="btn btn-icon btn-outline-secondary btn-sm mr-4">
                          <img
                            src="/media/svg/icons/custom/Icon_feather-link.svg"
                            class="h-15px"
                            alt="link-icon"
                          />{" "}
                        </div>
                        <a
                          class="font-weight-bold"
                          href={content?.contentInstagramUrl}
                        >
                          {content?.contentInstagramUrl}
                        </a>

                        {clickedButton ===
                          `deleteContentInstagramUrl${index}` &&
                        actionsLoading ? (
                          <Loader />
                        ) : (
                          applicationDetails?.contentStatus ===
                            "SOCIAL_MEDIA_URL_REQUESTED" && (
                            <span
                              class="btn btn-sm bg-light p-2 w-30px"
                              onClick={() => {
                                setClickedButton(
                                  `deleteContentInstagramUrl${index}`
                                );
                                deleteUrl(
                                  content?.applicationContentId,
                                  "contentInstagramUrl"
                                );
                              }}
                            >
                              <img
                                class=" h-15px "
                                src="/media/svg/icons/custom/Icon_delete.svg"
                              />
                            </span>
                          )
                        )}
                      </div>
                    )}

                    {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<for Youtube URL>>>>>>>>>>>>>>>>>>>>>>>>> */}

                    {content?.contentYoutubeUrl === null ? (
                      (campaignDetails?.isYoutubePostRequired ||
                        campaignDetails?.isYoutubeStoryRequired) && (
                        <div class="form-group">
                          <h6 class="font-weight-Bold font-size-lg mb-6">
                            Youtube
                          </h6>
                          <label>YouTube media link</label>
                          <div class="d-flex flex-nowrap">
                            <div class="input-icon input-icon-right">
                              <input
                                type="text"
                                class="form-control form-control-solid"
                                placeholder="YouTube media link"
                                onChange={(e) =>
                                  setUrls({
                                    ...urls,
                                    [content.applicationContentId]: {
                                      ...urls[content.applicationContentId],
                                      contentYoutubeUrl: e.target.value,
                                    },
                                  })
                                }
                              />
                            </div>
                            <button
                              class="btn btn-sm btn-primary ml-4"
                              disabled={
                                listLoading ||
                                actionsLoading ||
                                (urls[content?.applicationContentId]
                                  ?.contentYoutubeUrl
                                  ? false
                                  : true)
                              }
                              onClick={() => {
                                setClickedButton(`contentYoutubeUrl${index}`);
                                uploadSocialUrl(content.applicationContentId);
                              }}
                            >
                              {clickedButton === `contentYoutubeUrl${index}` &&
                              actionsLoading ? (
                                <div class="spinner-border" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              ) : (
                                <span>
                                  <b>Update</b>
                                </span>
                              )}
                            </button>
                          </div>
                        </div>
                      )
                    ) : (
                      <div class="col-12">
                        <div class="btn btn-icon btn-outline-secondary btn-sm mr-4">
                          <img
                            src="/media/svg/icons/custom/Icon_feather-link.svg"
                            class="h-15px"
                            alt="link-icon"
                          />{" "}
                        </div>
                        <a
                          class="font-weight-bold"
                          href={content?.contentYoutubeUrl}
                        >
                          {content?.contentYoutubeUrl}
                        </a>

                        {clickedButton === `deleteContentYoutubeUrl${index}` &&
                        actionsLoading ? (
                          <Loader />
                        ) : (
                          applicationDetails?.contentStatus ===
                            "SOCIAL_MEDIA_URL_REQUESTED" && (
                            <span
                              class="btn btn-sm bg-light p-2 w-30px"
                              onClick={() => {
                                setClickedButton(
                                  `deleteContentYoutubeUrl${index}`
                                );
                                deleteUrl(
                                  content?.applicationContentId,
                                  "contentYoutubeUrl"
                                );
                              }}
                            >
                              <img
                                class=" h-15px "
                                src="/media/svg/icons/custom/Icon_delete.svg"
                              />
                            </span>
                          )
                        )}
                      </div>
                    )}
                  </>
                ))}

                {[
                  "URL_SUBMITTED_BY_CREATOR",
                  "REJECTED_BY_BRAND",
                  "URL_APPROVED_BY_BRAND",
                ]?.includes(applicationDetails?.contentStatus) && (
                  <div class="content_list active col-8 px-0 mx-0 mb-5 border-bottom mt-5">
                    <div className="row ml-5 d-flex justify-content-between">
                      <p
                        class={`${
                          applicationDetails?.contentStatus ===
                            "URL_SUBMITTED_BY_CREATOR" ||
                          applicationDetails?.contentStatus ===
                            "URL_APPROVED_BY_BRAND"
                            ? "text-success"
                            : "text-danger"
                        }`}
                      >
                        {getConvertedContentStatus(
                          applicationDetails?.contentStatus
                        )}
                      </p>
                      {applicationDetails?.contentStatus ===
                        "REJECTED_BY_BRAND" && (
                        <p>
                          Rejected reason : {applicationDetails?.rejectedReason}
                        </p>
                      )}

                      <span class="text-dark font-size-xs pt-2 pl-2 ml-3">
                        <img
                          class="mr-1 h-10px mt-n1"
                          src="/media/svg/icons/custom/Icon-calendar.svg"
                        />{" "}
                        {moment(new Date(applicationDetails?.updatedAt)).format(
                          "MMMM DD, YYYY"
                        )}
                      </span>
                    </div>
                  </div>
                )}

                {![
                  "URL_SUBMITTED_BY_CREATOR",
                  "REJECTED_BY_BRAND",
                  "URL_APPROVED_BY_BRAND",
                ]?.includes(applicationDetails?.contentStatus) && (
                  <button
                    disabled={
                      listLoading ||
                      actionsLoading ||
                      applicationContents?.filter(
                        (content) =>
                          (campaignDetails?.isInstagramPostRequired ||
                            campaignDetails?.isInstagramStoryRequired) &&
                          content?.contentInstagramUrl === null
                      )?.length > 0 ||
                      applicationContents?.filter(
                        (content) =>
                          (campaignDetails?.isYoutubePostRequired ||
                            campaignDetails?.isYoutubeStoryRequired) &&
                          content?.contentYoutubeUrl === null
                      )?.length > 0
                    }
                    class="btn btn-sm btn-primary ml-2 mt-3"
                    onClick={() => {
                      setClickedButton("urlSubmission");
                      submitSocialUrl();
                    }}
                  >
                    {clickedButton === "urlSubmission" && actionsLoading ? (
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <span>
                        <b>Submit</b>
                      </span>
                    )}
                  </button>
                )}
              </div>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

export default memo(UrlDetails);
