/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import EngagementsTab from "./EngagementsTab";
import GalleryTab from "./GalleryTab";
import MessagesTab from "./MessagesTab";
import SummaryTab from "./SummaryTab";
import { useLocation, useHistory } from "react-router-dom";
import { campaignType, getCurrencySymbol,getReferenceMediaCount } from "../helper/helper";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

const Tabs = ({ campaign, application, setRefresh }) => {
  const location = useLocation();
  const history = useHistory();
  const [tab, setTab] = useState("summary");
  const [mediaCount, setMediaCount] = useState({});

  useEffect(() => {
    campaign && setMediaCount(getReferenceMediaCount(campaign));
  }, [campaign]);

  useEffect(() => {
    let tabFromUrl = location.search;
    tabFromUrl = tabFromUrl?.split("?tab=");
    tabFromUrl?.length > 1 && setTab(tabFromUrl[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex-row-fluid main-content-container">
      <div
        type="button"
        class="d-flex w-100 col-12 d-lg-none bg-white mb-5 shadow-1 mt-4"
        id="kt_app_chat_toggle"
      >
        <div class="d-flex align-items-center justify-content-between my-5 w-100">
          <div class="d-flex align-items-center">
            <div class="symbol symbol-50 mr-3">
              <div
                class="btn border-0 bg-special-primary mr-2 h-40px w-40px p-0 "
                style={{
                  backgroundSize: "cover",
                  backgroundImage: campaign?.productImageUrl
                    ? "url(" +
                      `${process.env.REACT_APP_AWS_S3_BASE_URL +
                        campaign?.productImageUrl}` +
                      ")"
                    : "url(/media/dummyImages/no_preview_v2.png)",
                }}
              />
            </div>
            <div class="d-flex flex-column">
              <a
                // href="#"
                class="text-dark text-hover-primary font-weight-SemiBold font-size-lg"
              >
                {campaign?.campaignName}
              </a>
              <span class="text-muted font-weight-bold font-size-sm">
                {campaignType(campaign?.campaignType)}
              </span>
            </div>
          </div>
          <div class="d-flex flex-column align-items-end">
            {campaign?.rewardModel === "CASH" && (
              <span class="text-dark font-weight-SemiBold font-size-h4">
                <img
                  class="mr-2"
                  src="/media/svg/icons/custom/Icon_award.svg"
                  alt="icon-award"
                />
                {getCurrencySymbol(campaign?.productCurrency) +
                  campaign?.productValue}
              </span>
            )}

            {campaign?.rewardModel === "PRODUCT" && (
              <OverlayTrigger
                overlay={
                  <Tooltip id="group-edit-tooltip">
                    {campaign?.rewardProductDescription ||
                      "No Description Provided"}
                  </Tooltip>
                }
              >
                <span class="text-dark font-weight-SemiBold font-size-h4">
                  <img
                    class="mr-2"
                    src="/media/svg/icons/custom/Icon_award.svg"
                    alt="icon-award"
                  />
                  Gift
                </span>
              </OverlayTrigger>
            )}

            {campaign?.rewardModel === "OFFER" && (
              <OverlayTrigger
                overlay={
                  <Tooltip id="group-edit-tooltip">
                    {campaign?.rewardProductDescription ||
                      "No Description Provided"}
                  </Tooltip>
                }
              >
                <span class="text-dark font-weight-SemiBold font-size-h4">
                  <img
                    class="mr-2"
                    src="/media/svg/icons/custom/Icon_award.svg"
                    alt="icon-award"
                  />
                  Offer
                </span>
              </OverlayTrigger>
            )}
          </div>
        </div>
      </div>
      <div class="card card-custom gutter-b ml-lg-6">
        <div class="card-body">
        { mediaCount?.imageCount > 0 ||
                mediaCount?.videoCount > 0 ||
                mediaCount?.documentCount > 0 ||
                mediaCount?.audioCount > 0 ||
                mediaCount?.otherCount > 0
                  ? 
            <div class="px-0 d-flex align-items-center">
              <div class="font-weight-bolder text-dark card-title align-items-start flex-column">
                <div
                  class="text-dark"
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                >
                  {campaign?.campaignName}
                </div>
                <div class="text-dark-50 font-weight-bold font-size-sm">
                  {campaignType(campaign?.campaignType)}
                </div>
                <div
                  class="p-1 rounded-circle d-flex justify-content-center align-items-center cursor-pointer"
                  onClick={() => {
                    history.goBack()
                  }}
                  style={{
                    position: "absolute",
                    top: 15,
                    right: 20,
                    width: 30,
                    height: 30,
                    backgroundColor: "#ececec",
                  }}
                >
                  <img
                    alt="icon-view"
                    style={{ width: 8, height: 8, resizeMode: "contain" }}
                    src={toAbsoluteUrl("/media/svg/icons/custom/Icon_close.svg")}
                  />
                </div>
              </div>
              <div class="border-top m-3 p-1"></div>
            </div>
          :
          <div class="px-0 d-flex align-items-center">
            <div class="w-100 font-weight-bolder text-dark pb-3 card-title align-items-start flex-column border-bottom">
              <div
                class="text-dark"
                style={{ fontWeight: "bold", fontSize: "20px" }}
              >
                {campaign?.campaignName}
              </div>
              <div class="text-dark-50 font-weight-bold font-size-sm">
                {campaignType(campaign?.campaignType)}
              </div>
              <div
                class="p-1 rounded-circle d-flex justify-content-center align-items-center cursor-pointer"
                onClick={() => {
                  history.goBack()
                }}
                style={{
                  position: "absolute",
                  top: 15,
                  right: 20,
                  width: 30,
                  height: 30,
                  backgroundColor: "#ececec",
                }}
              >
                <img
                  alt="icon-view"
                  style={{ width: 8, height: 8, resizeMode: "contain" }}
                  src={toAbsoluteUrl("/media/svg/icons/custom/Icon_close.svg")}
                />
              </div>
            </div>
            <div class="border-top m-3 p-1"></div>
          </div>
          }
          {/*Show only the campaign do have deliverables count*/}
          { mediaCount?.imageCount > 0 ||
                mediaCount?.videoCount > 0 ||
                mediaCount?.documentCount > 0 ||
                mediaCount?.audioCount > 0 ||
                mediaCount?.otherCount > 0
                  ? 
          <ul
            class="dashboard-tabs nav nav-pills nav-primary row row-paddingless m-0 p-0 flex-nowrap flex-sm-row border-bottom pb-5 overflow-auto"
            role="tablist"
          >
            {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<Summary Tab>>>>>>>>>>>>>>>>>>>>>>>> */}
            <li class="nav-item d-flex  mr-3 mb-3 mb-lg-0">
              <a
                // href="#"
                class={`nav-link border ${
                  tab === "summary" ? "active text-light" : ""
                } nav-link border py-2 d-flex flex-grow-1 rounded flex-nowrap align-items-center justify-content-center`}
                data-toggle="pill"
                onClick={() => {
                  history.push(
                    `/campaigns/list/${campaign?.campaignId}/view?tab=summary`
                  );
                  setTab("summary");
                }}
                // href="#summary"
              >
                <span class="nav-icon py-4 w-auto d-flex flex-nowrap d-flex align-items-center">
                  <span class="svg-icon ">
                    <svg
                      width="15.74"
                      height="17.464"
                      viewBox="0 0 15.74 17.464"
                    >
                      <g
                        id="Icon_feather-box"
                        data-name="Icon feather-box"
                        transform="translate(0.5 0.522)"
                      >
                        <path
                          id="Path_43"
                          data-name="Path 43"
                          d="M19.24,14.466V7.915A1.638,1.638,0,0,0,18.421,6.5L12.689,3.222a1.638,1.638,0,0,0-1.638,0L5.319,6.5A1.638,1.638,0,0,0,4.5,7.915v6.551a1.638,1.638,0,0,0,.819,1.417l5.732,3.276a1.638,1.638,0,0,0,1.638,0l5.732-3.275a1.638,1.638,0,0,0,.819-1.417Z"
                          transform="translate(-4.5 -3.003)"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1"
                        />
                        <path
                          id="Path_44"
                          data-name="Path 44"
                          d="M4.905,10.44l7.149,4.135L19.2,10.44"
                          transform="translate(-4.684 -6.38)"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1"
                        />
                        <path
                          id="Path_45"
                          data-name="Path 45"
                          d="M18,26.254V18"
                          transform="translate(-10.63 -9.813)"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1"
                        />
                      </g>
                    </svg>
                  </span>
                  <span class="w-auto pl-2 font-weight-SemiBold">Summary</span>
                </span>
              </a>
            </li>

            {/* <<<<<<<<<<<<<<<<<<<<<<<<<Engagements Tab>>>>>>>>>>>>>>>>>>>>>>>>> */}
            <li class="nav-item d-flex  mr-3 mb-3 mb-lg-0">
              <a
                class={`nav-link border ${
                  tab === "engagements" ? "active" : ""
                } nav-link border py-2 d-flex flex-grow-1 rounded flex-nowrap align-items-center justify-content-center`}
                data-toggle="pill"
                onClick={() => {
                  history.push(
                    `/campaigns/list/${campaign?.campaignId}/view?tab=engagements`
                  );
                  setTab("engagements");
                }}
              >
                <span class="nav-icon py-4 w-auto d-flex flex-nowrap d-flex align-items-center mr-2">
                  <span class="svg-icon ">
                    <svg
                      width="18.141"
                      height="16.846"
                      viewBox="0 0 18.141 16.846"
                    >
                      <path
                        id="Icon_ionic-ios-star-outline"
                        data-name="Icon ionic-ios-star-outline"
                        d="M19.7,9.206H13.746l-1.81-5.4a.656.656,0,0,0-1.231,0L8.9,9.206h-6a.65.65,0,0,0-.648.648.476.476,0,0,0,.012.109.623.623,0,0,0,.271.458l4.9,3.45L5.55,19.334a.65.65,0,0,0,.223.729.627.627,0,0,0,.364.158.794.794,0,0,0,.4-.146l4.778-3.406L16.1,20.075a.759.759,0,0,0,.4.146.582.582,0,0,0,.36-.158.642.642,0,0,0,.223-.729l-1.879-5.463,4.855-3.483.117-.1a.679.679,0,0,0,.211-.433A.686.686,0,0,0,19.7,9.206Zm-5.151,3.746a1.137,1.137,0,0,0-.413,1.288l1.219,3.551a.163.163,0,0,1-.247.186l-3.134-2.235a1.131,1.131,0,0,0-.66-.211,1.11,1.11,0,0,0-.656.211L7.526,17.973a.162.162,0,0,1-.247-.186L8.5,14.236a1.141,1.141,0,0,0-.417-1.3L4.8,10.628a.163.163,0,0,1,.093-.3h4a1.132,1.132,0,0,0,1.073-.773l1.2-3.572a.163.163,0,0,1,.308,0l1.2,3.572a1.132,1.132,0,0,0,1.073.773h3.94a.16.16,0,0,1,.093.292Z"
                        transform="translate(-2.25 -3.375)"
                      />
                    </svg>
                  </span>
                  <span class="w-auto pl-2 font-weight-SemiBold">
                    Engagements
                  </span>
                </span>
              </a>
            </li>

            {/* <<<<<<<<<<<<<<<<<<<<<<<<<Messages Tab>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
            <li class="nav-item d-flex  mr-3 mb-3 mb-lg-0">
              <a
                class={`nav-link  border ${
                  tab === "messages" ? "active" : ""
                } nav-link border py-2 d-flex flex-grow-1 rounded flex-nowrap align-items-center justify-content-center`}
                data-toggle="pill"
                onClick={() => {
                  history.push(
                    `/campaigns/list/${campaign?.campaignId}/view?tab=messages`
                  );
                  setTab("messages");
                }}
                // href="#messages"
              >
                <span class="nav-icon py-4 w-auto d-flex flex-nowrap d-flex align-items-center mr-2">
                  <span class="svg-icon ">
                    <svg width="15.74" height="15.74" viewBox="0 0 15.74 15.74">
                      <path
                        id="Icon_feather-message-square"
                        data-name="Icon feather-message-square"
                        d="M19.24,14.326A1.638,1.638,0,0,1,17.6,15.964H7.775L4.5,19.24V6.138A1.638,1.638,0,0,1,6.138,4.5H17.6A1.638,1.638,0,0,1,19.24,6.138Z"
                        transform="translate(-4 -4)"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                      />
                    </svg>
                  </span>
                  <span class="w-auto pl-2 font-weight-SemiBold">Messages</span>
                </span>
              </a>
            </li>

            {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<gallery Tab>>>>>>>>>>>>>>>>>>>>>>>> */}
            {campaign?.campaignType !== "RELATION_POST" && (
              <li class="nav-item d-flex  mr-3 mb-3 mb-lg-0">
                <a
                  class={`nav-link border ${
                    tab === "gallery" ? "active" : ""
                  } nav-link border py-2 d-flex flex-grow-1 rounded flex-nowrap align-items-center justify-content-center`}
                  data-toggle="pill"
                  onClick={() => {
                    history.push(
                      `/campaigns/list/${campaign?.campaignId}/view?tab=gallery`
                    );
                    setTab("gallery");
                  }}
                >
                  <span class="nav-icon py-4 w-auto d-flex flex-nowrap d-flex align-items-center mr-2">
                    <span class="svg-icon ">
                      <svg width="16.41" height="16" viewBox="0 0 16.41 16">
                        <g
                          id="Icon_feather-image"
                          data-name="Icon feather-image"
                          transform="translate(-3.717 -4)"
                        >
                          <path
                            id="Path_48"
                            data-name="Path 48"
                            d="M6.167,4.5H17.833A1.667,1.667,0,0,1,19.5,6.167V17.833A1.667,1.667,0,0,1,17.833,19.5H6.167A1.667,1.667,0,0,1,4.5,17.833V6.167A1.667,1.667,0,0,1,6.167,4.5Z"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1"
                          />
                          <path
                            id="Path_49"
                            data-name="Path 49"
                            d="M13.306,11.9a1.4,1.4,0,1,1-1.4-1.4A1.4,1.4,0,0,1,13.306,11.9Z"
                            transform="translate(-3.08 -3)"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1"
                          />
                          <path
                            id="Path_50"
                            data-name="Path 50"
                            d="M22.3,19.625,17.674,15,7.5,25.174"
                            transform="matrix(1, -0.017, 0.017, 1, -3.328, -6.767)"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1"
                          />
                        </g>
                      </svg>
                    </span>
                    <span class="w-auto pl-2 font-weight-SemiBold">
                      Gallery
                    </span>
                  </span>
                </a>
              </li>
            )}
          </ul>
          :null
          }
          <div class="tab-content m-0 p-0">
            <SummaryTab
              campaign={campaign}
              isActive={tab === "summary"}
              setRefresh={setRefresh}
            />
            <EngagementsTab
              campaignType={campaign?.campaignType}
              application={application}
              isActive={tab === "engagements"}
            />
            <MessagesTab campaign={campaign} isActive={tab === "messages"} />
            <GalleryTab campaign={campaign} isActive={tab === "gallery"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
