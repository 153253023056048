import { createSelector } from "@reduxjs/toolkit";

const userDeatils = ({ auth ,application}) => {
  return {
    userId: auth.user.userId,
  };
};
const chatState = ({ chatModule, common }) => ({
  socket: common.socket,
  isApiInprogressFlag: chatModule.isApiInprogressFlag,
  chatInboxArray: chatModule.chatInboxArray,
  chatMessagesArray: chatModule.chatMessagesArray,
  campaignDetails:chatModule.campaignDetails,
  creatorDetails:chatModule.creatorDetails,
  totalMessages: chatModule.totalMessages,

  // applicationDetails:chatModule.applicationDetails
});

// const applcationList=({application})=>{
//   return{
//     application:application.entities
//   }
// }

export const ChatModuleSelector = createSelector(
  [userDeatils, chatState],
  (user, chatState) => ({
    user,
    ...chatState,
  })
);
