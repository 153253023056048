import axios from "axios";

export const GET_CAMPAIGNS = "v1/creator/campaigns/list";
export const GET_SPECIFIC_CAMPAIGNS =
  process.env.REACT_APP_API_PUBLIC_URL + "/v1/creator/campaigns/listCampaigns";
export const SEARCH_CAMPAIGNS = "v1/creator/campaigns/campaignSearch";
export const SEARCH_BRANDS = "v1/creator/brands/searchBrands";
export const GET_CAMPAIGNS_WITH_BRAND_ID =
  "v1/creator/brands/getCampaignWithBrandId";
export const GET_CAMPAIGN_DETAILS = "v1/creator/campaigns/details";
export const GET_CAMPAIGN_DETAILS_PUBLIC =
  process.env.REACT_APP_API_PUBLIC_URL +
  "/v1/creator/campaigns/campaignDetails";
export const GET_APPLICATION_DETAILS = "v1/creator/campaign/applications/list";
export const REJECT_CAMPAIGN = "v1/admin/campaigns/reject";
export const GET_CAMPAIGN_APPLICATIONS = "v1/admin/campaign/applications/list";
export const APPLY_FOR_CAMPAIGN =
  "v1/creator/campaign/applications/applyForCampaign";
export const SET_WAIT_CAMPAIGN =
  "v1/admin/campaigns/setCampaignAsWaitingForApprovalByAdmin";
export const GET_APPLICATION_CONTENT =
  "v1/admin/campaign/application/contents/list";
export const ADD_QR_COUNT =
  process.env.REACT_APP_API_PUBLIC_URL + "/v1/brand/profile/qrCodeScanned";

// GET =>  get Campaigns
var date = new Date();
date.setUTCHours(0, 0, 0, 0);
let expiryDate = date;

export function getCampaigns(params) {
  return axios.post(GET_CAMPAIGNS, {
    ...params,
    expiryFilter: expiryDate,
    frontendAction: "creator.campaigns.list",
    trackingPriority: 0,
  });
}

// GET =>  get signed Url
export function getSpecificCampaigns(params) {
  return axios.post(GET_SPECIFIC_CAMPAIGNS, {
    ...params,
    expiryFilter: expiryDate,
    frontendAction: "creator.campaigns.listCampaigns",
    trackingPriority: 0,
  });
}

// GET =>  search campaigns
export function searchCampaigns(params) {
  return axios.post(SEARCH_CAMPAIGNS, {
    ...params,
    expiryFilter: expiryDate,
    frontendAction: "creator.campaigns.campaignSearch",
    trackingPriority: 0,
  });
}

export function searchBrands(params) {
  return axios.post(SEARCH_BRANDS, {
    ...params,
    frontendAction: "creator.brands.searchBrands",
    trackingPriority: 0,
  });
}

export function getCampaignsByBrandId(params) {
  return axios.post(GET_CAMPAIGNS_WITH_BRAND_ID, {
    ...params,
    frontendAction: "creator.brands.getCampaignWithBrandId",
    trackingPriority: 0,
  });
}

export function getCampaignDetails(params) {
  return axios.post(GET_CAMPAIGN_DETAILS, {
    ...params,
    frontendAction: "creator.campaigns.details",
    trackingPriority: 0,
  });
}

export function getPublicCampaignDetails(params) {
  return axios.post(GET_CAMPAIGN_DETAILS_PUBLIC, {
    ...params,
    frontendAction: "creator.campaign.campaignDetails",
    trackingPriority: 0,
  });
}

export function getApplicationDetails(params) {
  return axios.post(GET_APPLICATION_DETAILS, {
    ...params,
    frontendAction: "creator.campaign.applications.list",
    trackingPriority: 0,
  });
}

export function applyForCampaign(params) {
  return axios.post(APPLY_FOR_CAMPAIGN, {
    ...params,
    frontendAction: "creator.campaign.applications.applyForCampaign",
    trackingPriority: 0,
  });
}
export function rejectCampaign(params) {
  return axios.post(REJECT_CAMPAIGN, {
    ...params,
    frontendAction: "admin.campaigns.reject",
    trackingPriority: 0,
  });
}
export function setWaitForAdminApproval(params) {
  return axios.post(SET_WAIT_CAMPAIGN, {
    ...params,
    frontendAction: "admin.campaigns.setCampaignAsWaitingForApprovalByAdmin",
    trackingPriority: 0,
  });
}

export function listCampaignApplications(params) {
  return axios.post(GET_CAMPAIGN_APPLICATIONS, {
    ...params,
    frontendAction: "admin.campaign.applications.list",
    trackingPriority: 0,
  });
}

export function getApplicationContent(params) {
  return axios.post(GET_APPLICATION_CONTENT, {
    ...params,
    frontendAction: "admin.campaign.application.contents.list",
    trackingPriority: 0,
  });
}
export function addQrCodeCount(params) {
  return axios.post(ADD_QR_COUNT, {
    ...params,
    frontendAction: "brand.profile.qrCodeScanned",
    trackingPriority: 0,
  });
}
