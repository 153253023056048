import { toast } from 'react-toastify';

const toastSettings = {
 position: "top-right",
autoClose: 5000,
hideProgressBar: false,
closeOnClick: true,
pauseOnHover: true,
draggable: true,
progress: undefined,
}

export function Toaster({ message, variant }) {
    switch (variant) {
        case "info":
            toast.info(message, toastSettings);
            break;
            
        case "success":
            toast.success(message, toastSettings);
            break;

        case "warn":
            toast.warn(message, toastSettings);
            break;

        case "error":
            toast.error(message, toastSettings);
            break;

        case "dark":
            toast.dark(message, toastSettings);
            break;

        default:
            toast(message ? message : "", toastSettings);
            break;
    }

}
