/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
// import { useLocation } from "react-router";
// import { NavLink } from "react-router-dom";
// import SVG from "react-inlinesvg";
// import { checkIsActive } from "../../../../_helpers";
// import { useSelector } from 'react-redux';
// import { shallowEqual } from 'react-redux';

export function HeaderMenu({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
      >
      <div class="brand flex-column-auto" id="kt_brand">
          <NavLink className="brand-logo" to="/dashboard">
            <img alt="Logo" src="/media/logo/Blue.svg" class="max-h-30px mr-8 mt-2"/>
          </NavLink>
			</div>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}
{/*
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/campaigns",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/campaigns/list">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")} />
            </span>
            <span className="menu-text">Discover</span>
          </NavLink> */}
          {/* <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Campaigns</span>
                </span>
              </li>
              begin::2 Level
              <li
                className={`menu-item ${getMenuItemActive("/campaigns/list")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/campaigns/list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Active Campaigns</span>
                </NavLink>
              </li>
              end::2 Level
            </ul>
          </div> */}
        {/* </li> */}
        {/* <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">

            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li> */}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="menu-text font-weight-SemiBold">Dashboard</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/campaigns/list", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/campaigns/list">
            <span className="menu-text font-weight-SemiBold">Discover</span>
          </NavLink>
        </li>

        {/* begin::Menu Nav */}
        <li
          className={`menu-item ${getMenuItemActive(
            "/applications/list",
            false
          )}`}
          aria-haspopup="true"
          // data-menu-toggle="hover"
        >
          <NavLink className="menu-link" to="/applications/list">

            <span className="menu-text font-weight-SemiBold">Engagements</span>
          </NavLink>
          {/* <div className="menu-submenu menu-submenu-classic menu-submenu-left">

            <ul className="menu-subnav"> */}
              {/* <li className="menu-item menu-item-submenu" aria-haspopup="true">

                <span className="menu-link">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-pie.svg")} />
                </span>
                  <span className="menu-text font-weight-SemiBold">Campaigns</span>
                </span>
              </li> */}
              {/*begin::2 Level*/}
              {/* <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/applications/list"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/applications/list">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-pie.svg")} />
                </span>
                  <span className="menu-text font-weight-SemiBold">Applications</span>
                </NavLink>
              </li>
            </ul>
          </div> */}
        </li>

        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/messagings", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/messagings">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Mail-opened.svg")} />
            </span>
            <span className="menu-text font-weight-SemiBold">Messaging</span>
          </NavLink>
        </li> */}
        <li
          class="menu-item btn disabled d-none"
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/post-analytics">
            <span className="menu-text font-weight-SemiBold">Proposals</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/post-analytics", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/post-analytics">

            <span className="menu-text font-weight-SemiBold">Gallery</span>
          </NavLink>
        </li>
        <li
          class="menu-item btn disabled d-none"
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/post-analytics">
            <span className="menu-text font-weight-SemiBold">Community</span>
          </NavLink>
        </li>
        {/* <li
          className={`menu-item ${getMenuItemActive("/PaymentHistory", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/PaymentHistory">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Payment History</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/portfolio", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/portfolio">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Portfolio</span>
          </NavLink>
        </li> */}

        {/* BEGIN------REWARD---------BANK DETAILS*/}

        {/* END------REWARD---------BANK DETAILS*/}

        {/*begin::1 Level*/}

        {/*end::1 Level*/}
        {/*begin::1 Level*/}

        {/*end::1 Level*/}
        {/*begin::1 Level*/}

        {/*end::1 Level*/}
        {/*begin::1 Level*/}

        {/*end::1 Level*/}
        {/*begin::1 Level*/}

        {/*end::1 Level*/}
        {/*begin::1 Level*/}

        {/*end::1 Level*/}
        {/*begin::1 Level*/}

        {/*end::1 Level*/}
        {/*begin::1 Level*/}

        {/*end::1 Level*/}

        {/* Applications */}
        {/* begin::section */}

        {/* end:: section */}

        {/* eCommerce */}
        {/*begin::1 Level*/}

        {/* end:: section */}
        {/*begin::1 Level*/}

        {/*end::1 Level*/}
      </ul>
      </div>

      {/* end::Menu Nav */}
    </>
  );
}
