import React, { useEffect, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import moment from "moment";

const minuteSeconds = 60;
const hourSeconds = 3600;
const daySeconds = 86400;


const renderTimeMin = ({ remainingTime }) => {
  if (remainingTime < 1) {
    return <div className="timer font-size-xs ">Too lale...</div>;
  }

  return (
    <div className="time flex-center d-flex flex-column">
      <div className="value font-weight-bolder font-size-h6">{Math.ceil((remainingTime % hourSeconds) / 60)}</div>
      <div className="text">min</div>
    </div>
  );
};

const renderTimeSec = ({ remainingTime }) => {
  if (remainingTime < 3) {
    return <div className="timer font-size-xs ">Too lale...</div>;
  }

  return (
    <div className="time flex-center d-flex flex-column">
      <div className="value font-weight-bolder font-size-h6">{Math.ceil((remainingTime % hourSeconds))}</div>
      <div className="text">sec</div>
    </div>
  );
};

const getDurationBasedOnType = (type) => {
  switch (type) {
    case "m":
      return 60;
      break;
    case "s":
      return 1;
      break;
    default:
      return 1
  }
}


const getDateDifference = (primaryRemainingTime, startdate, enddate) => {
  const dateB = moment(startdate);
  const dateC = moment(enddate);
  var diffDateinSec = dateC.diff(dateB, 'seconds');
  diffDateinSec = primaryRemainingTime - diffDateinSec;
  diffDateinSec = diffDateinSec < 0 ? 0 : diffDateinSec;
  return diffDateinSec;
}

const getPercentage = (number, pecentage) => {
  return Math.ceil((number / 100) * pecentage);
}


export default function CountDownGuidelineTicker({
  resetTimerPrimaryCounterFlag,
  setTimerResetPrimaryCounterFlag,
  resetTimerSecondaryCounterFlag,
  setTimerResetSecondaryCounterFlag,

  expiredTimerPrimaryCounterFlag,
  setTimerExpiredPrimaryCounterFlag,
  expiredTimerSecondaryCounterFlag,
  setTimerExpiredSecondaryCounterFlag,

  startFlag,
  setStartFlag,

  tickerActivate,
  setTickerActivate,
  eventTimerPeriod,
  quizUserState
}) {

  if (eventTimerPeriod == null) {
    eventTimerPeriod = "10:m-20:s";
  }
  var timePeriodArray = eventTimerPeriod.split("-");

  var primaryTimerSector = timePeriodArray[0];
  var secondaryTimerSector = timePeriodArray[1];
  var primaryTimeArray = primaryTimerSector.split(" ");
  var primaryDuration = primaryTimeArray[0];
  var primaryDurationType = primaryTimeArray[1];


  var secondaryTimeArray = secondaryTimerSector.split(" ");
  var secondaryDuration = secondaryTimeArray[0];
  var secondaryDurationType = secondaryTimeArray[1];

  const [primaryRemainingTime, setPrimaryRemainingTime] = useState(primaryDuration * getDurationBasedOnType(primaryDurationType));
  const [secondaryRemainingTime, setSecondaryRemainingTime] = useState(secondaryDuration * getDurationBasedOnType(secondaryDurationType));




  const [primaryCounter, setPrimaryCounter] = useState(0);

  const [secondaryCounter, setSecondaryCounter] = useState(0);

  const [userStateData, setUserStateData] = useState(quizUserState); //this helps


  const [primaryInitRemainingTime, setPrimaryInitRemainingTime] = useState(quizUserState ? getDateDifference(primaryRemainingTime, quizUserState.start_time, new Date()) : primaryRemainingTime); //this helps
  const [secondaryInitRemainingTime, setSecondaryInitRemainingTime] = useState(secondaryRemainingTime); //this helps


  useEffect(() => {
    if (resetTimerPrimaryCounterFlag) {
      setPrimaryCounter(prevKey => prevKey + 1);
      setTimerResetPrimaryCounterFlag(false);
    }
  }, [resetTimerPrimaryCounterFlag]);

  useEffect(() => {
    if (resetTimerSecondaryCounterFlag) {
      setSecondaryCounter(prevKey => prevKey + 1);
      setTimerResetSecondaryCounterFlag(false);
    }
  }, [resetTimerSecondaryCounterFlag]);


  return (
    <div className="d-flex flex-row flex-coloumn-gap-10 position-fixed left-0 right-0 top-0 p-5 simple-chip">
      <div className="countdown-small">
        <CountdownCircleTimer
          isPlaying={startFlag}
          key={primaryCounter}
          duration={primaryRemainingTime}
          initialRemainingTime={primaryInitRemainingTime}
          size="60"
          strokeWidth="4"
          colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
          colorsTime={[getPercentage(primaryRemainingTime, 100), getPercentage(primaryRemainingTime, 60), getPercentage(primaryRemainingTime, 30), getPercentage(primaryRemainingTime, 0)]}
          onComplete={() => {
            // do your stuff here
            setTimerExpiredPrimaryCounterFlag(true);
            return { shouldRepeat: true, delay: 0 } // repeat animation in 1.5 seconds
          }}
        >
          {renderTimeMin}
        </CountdownCircleTimer>
      </div>
      <div className="countdown-small">
        <CountdownCircleTimer
          isPlaying={startFlag}
          key={secondaryCounter}
          duration={secondaryRemainingTime}
          initialRemainingTime={secondaryInitRemainingTime}
          size="60"
          strokeWidth="4"
          colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
          colorsTime={[getPercentage(secondaryRemainingTime, 100), getPercentage(secondaryRemainingTime, 60), getPercentage(secondaryRemainingTime, 30), getPercentage(secondaryRemainingTime, 0)]}
          onComplete={() => {
            // do your stuff here
            setTimerExpiredSecondaryCounterFlag(true);
            return { shouldRepeat: true, delay: 1 } // repeat animation in 1.5 seconds
          }}
        >
          {renderTimeSec}
        </CountdownCircleTimer>
      </div>
    </div>
  );
}
