import watermark from "watermarkjs";
import Resizer from "react-image-file-resizer";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export const getAgeCategoryTotext = (data) => {
  switch (data) {
    case "MILLENIALS_26_41":
      return "Age varies from 26-41";
    case "GENX_42_57":
      return "Age varies from 42-57";
    case "ANY":
      return "Any";
    case "GENZ_10_25":
      return "Age varies from 10-25";
    case null:
      return "Not mentioned";
    default:
      return data; 
  }
};

export const getConvertedApplicationStatus = (data) => {
  switch (data) {
    case "WAITING_FOR_APPROVAL_BY_BRAND":
      return "Pending Approval";
    case "REJECTED_BY_BRAND":
      return "Rejected";
    case "APPROVED_BY_BRAND":
      return "Approved";
    default:
      return "Not Applied";
  }
};

export const getConvertedContentStatus = (data) => {
  switch (data) {
    case "WAITING_FOR_APPROVAL_BY_BRAND":
      return "Waiting for Approval";
    case "REJECTED_BY_BRAND":
      return "Rejected";
    case "APPROVED_BY_BRAND":
      return "Approved";
    case "DRAFT":
      return "Draft";

    case "RESUBMIT_CONTENT":
      return "Rework Required";

    case "SOCIAL_MEDIA_URL_REQUESTED":
      return "Social Media Url Requested";

    case "URL_SUBMITTED_BY_CREATOR":
      return "URL Submitted";

    case "URL_APPROVED_BY_BRAND":
      return "URL Approved";

    default:
      return "No Status";
  }
};

export const getTargetGenderToText = (data) => {
  switch (data) {
    case "MALE":
      return "Male";
    case "FEMALE":
      return "Female";
    case "ANY":
      return "Any";
    case null:
      return "Not mentioned";
    default:
      return data;
  }
};

export const getFeaturesToText = (data) => {
  switch (data) {
    case "TECHNOLOGY":
      return "Technology";
    case "SPORTS":
      return "Sports";
    case "ENTERTAINMENT":
      return "Entertainement";
    case "MUSIC":
      return "Music";
    case "FASHION":
      return "Fashion";
    case "FOOD":
      return "Food";
    case "ART":
      return "Art";
    // postImageStyle
    case "PRODUCT_PHOTOGRAPHY":
      return "URL Content";
    case "LIFESTYLE_PRODUCT_IN_ACTION":
      return "Audio Content";
    case "PORTRAIT_STYLE_PHOTOGRAPHY":
      return "Potrait style photography";
    // postVideoStyle
    case "ACTION_PRODUCT_IN_USE_VIDEO":
      return "Action product in use video";
    case "UNBOXING_EXPLANATION_VIDEO":
      return "Unboxing explanation video";
    case "PRODUCT_REVIEW_TESTIMONIAL_VIDEO":
      return "Product review testimonial video";
    // CAMPAIGN TYPE
    case "SOCIAL_POST":
      return "Socail Media Campaign";
    case "PHOTOGRAPHY":
      return "Photography Campaign";
    case "VIDEOGRAPHY":
      return "Videography Campaign";
    // POST TYPE
    case "VIDEO_POST":
      return "Video Post";
    case "IMAGE_POST":
      return "Image Post";
    default:
      return data;
  }
};

export const getPostType = (data) => {
  switch (data) {
    case "SOCIAL_POST":
      return "Achieve Reach";
    case "PHOTOGRAPHY":
      return "Create Contents";
    case "VIDEOGRAPHY":
      return "Boost Sales";
    case "RELATION_POST":
      return "Grow Customer-base";
    case null:
      return "not mentioned";
    default:
      return "data";
  }
};

export const getCurrencySymbol = (data) => {
  switch (data) {
    case "USD":
      return "$ ";
    case "INR":
      return "₹ ";
    default:
      return "₹ ";
  }
};

export const campaignType = (data) => {
  switch (data) {
    case "SOCIAL_POST":
      return "Achieve Reach";
    case "PHOTOGRAPHY":
      return "Create Contents";
    case "VIDEOGRAPHY":
      return "Boost Sales";
    case "RELATION_POST":
      return "Grow Customer-base";
    case null:
      return "not mentioned";
    default:
      return "data";
  }
};

export const getPlatform = (data) => {
  switch (data) {
    case "INSTAGRAM":
      return "Instagram";
    case "FACEBOOK":
      return "Facebook";
    case "TIKTOK":
      return "Tik tok";
    case "YOUTUBE":
      return "Youtube";
    case "AMAZONE":
      return "Amazone";
    case "GENERAL_USE":
      return "General Use";
    default:
      return "not mentioned";
  }
};

export const getVideoLength = (data) => {
  switch (data) {
    case "UP_TO_15_SECONDS":
      return " Up to 15 seconds";
    case "15_TO_30_SECONDS":
      return " 15 to 30 seconds";
    case "30_TO_60_SECONDS":
      return " 30 to 60 seconds";
    case "MORE_THAN_60_SECONDS":
      return " More than 60 seconds";
    default:
      return data;
  }
};

export const getReferenceMediaCount = (campaign) => {
  let videoCount =
    +Number(campaign.styleSelectionActionProductInUseCount);
  let imageCount =
    +Number(campaign.styleSelectionGraphicDesignCount);
  let documentCount =
    +Number(campaign.styleSelectionPortraitStylePhotographyCount);
  let audioCount =
    +Number(campaign.styleSelectionLifestyleProductInActionCount);
  let otherCount =
    +Number(campaign.styleSelectionPromotionalVideoCount);
  
  return { videoCount, imageCount, documentCount, audioCount, otherCount};
};

export const getFileType = (type) => {
  let splited = type.split("/");
  switch (splited[0]) {
    case "image":
      return "M_IMAGE";
    case "video":
      return "M_VIDEO";
    case "application":
      return "M_FILE";
    default:
      return "M_TEXT";
  }
};

export const initialFiles = {
  actionProductInUseFiles: {},
  graphicDesignFiles: {},
  lifestyleProductInActionFiles: {},
  portraitStylePhotographyFiles: {},
  productPhotographyFiles: {},
  productReviewTestimonialVideoFiles: {},
  promotionalVideoFiles: {},
  unboxingExplanationVideoFiles: {},
};

export const getWatermarkImage = (imageFile) => {
  return new Promise((resolve, reject) => {
    watermark([imageFile, toAbsoluteUrl("/media/svg/logos/thumbnail-logo.svg")])
      .blob(watermark.image.lowerRight(0))
      .then((img) => {
        return resolve(img);
      })
      .catch((err) => reject());
  });
};

export const getCompressImage = (file) => {
  return new Promise((resolve, reject) => {
    Resizer.imageFileResizer(
      file,
      1000,
      1000,
      "JPEG",
      90,
      0,
      (uri) => {
        resolve(uri);
      },
      "blob"
    );
  });
};

export const getHighCompressImage = (file) => {
  return new Promise((resolve, reject) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      70,
      0,
      (uri) => {
        resolve(uri);
      },
      "blob"
    );
  });
};

export const getFileTypeFromExtension = (url) => {
  let extension = url?.split(".").pop();

  let videoExtensions = [
    "mp4",
    "m4p",
    "mpeg",
    "mpg",
    "flv",
    "3gp",
    "webm",
    "mkv",
    "flv",
    "ogg",
    "ogv",
    "avi",
    "mov",
    "wmv",
    "",
  ];
  let imageExtensions = [
    "gif",
    "jpg",
    "jpeg",
    "png",
    "svg",
    "webp",
    "ico",
    "bmp",
    "jfif",
  ];
  let docuementExtensions = ["pdf", "txt", "doc", "docx", "html", "htm", "odt"];

  if (videoExtensions?.includes(extension)) {
    return "video";
  } else if (imageExtensions?.includes(extension)) {
    return "image";
  } else if (docuementExtensions?.includes(extension)) {
    return "document";
  } else {
    return null;
  }
};

export const getConvertedRejectReason = (reason) => {
  switch (reason) {
    case "POOR_CONTENT_QUALITY":
      return "Poor Content Quality";
    case "DELAYED_DELIVERY":
      return "Delayed Delivery";
    case "DEVIATION_FROM_GUIDELINES":
      return "Deviation From Guidelines";
    default:
      return null;
  }
};

export const getConvertedPayoutStatus = (status) => {
  switch (status) {
    case "PAYOUT_INITIATED":
      return { status: "Payment Initiated", color: "text-warning" };
    case "PAYOUT_PROCESSED":
      return { status: "Payment Success", color: "text-success" };
    case "PAYOUT_REVERSED":
      return { status: "Payment Reversed", color: "text-danger" };
    case "PAYOUT_QUEUED":
      return { status: "Payment Queued", color: "text-warning" };
    case "PAYOUT_REJECTED":
      return { status: "Payment Rejected", color: "text-danger" };
    case "PAYOUT_PENDING":
      return { status: "Payment Pending", color: "text-warning" };
    default:
      return { status: "Payment Pending", color: "text-warning" };
  }
};

export const getMediaPreview = (content) => {
  if (content?.postType === "VIDEO_POST") {
    return "url(/media/custom/video-icon.png)";
  } else if (content?.postType === "IMAGE_POST") {
    if (content?.contentMediaUrl.endsWith(".pdf")) {
      return "url(/media/dummyImages/PDF_no_prview.png)";
    } else {
      return (
        "url(" +
        `${process.env.REACT_APP_AWS_S3_BASE_URL + content?.contentMediaUrl}` +
        ")"
      );
    }
  }
};


export const checkCampaignContentRequest = (campaign) => {
  var retFlag = campaign?.styleSelectionGraphicDesignCount > 0 || //Image Count
  campaign?.styleSelectionActionProductInUseCount > 0 || //Video Count
  campaign?.styleSelectionPortraitStylePhotographyCount > 0 || //Document Count
  campaign?.styleSelectionLifestyleProductInActionCount > 0 || //Audio Count
  campaign?.styleSelectionPromotionalVideoCount > 0 //Other Count
    ? true:false
  return retFlag;
}

export const checkMediaContentRequest = (mediaCount) => {
  var retFlag = mediaCount?.imageCount > 0 ||
  mediaCount?.videoCount > 0 ||
  mediaCount?.documentCount > 0 ||
  mediaCount?.audioCount > 0 ||
  mediaCount?.otherCount > 0
    ? true:false
  return retFlag;
}

export const getTotalMediaContentRequest = (mediaCount) => {
  var retFlag = mediaCount?.imageCount+
  mediaCount?.videoCount+
  mediaCount?.documentCount +
  mediaCount?.audioCount +
  mediaCount?.otherCount;
  return retFlag;
}