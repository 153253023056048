import React, { useState } from "react";
import moment from "moment";
import { getConvertedContentStatus, getMediaPreview } from "../helper/helper";
import { useDispatch } from "react-redux";
import { removeApplicationContent } from "../../campaignApplications/_redux/applicationActions";

export const GalleryCard = ({
  content,
  loggedInUserId,
  actionsLoading,
  setReload,
}) => {
  const [clickedButton, setClickedButton] = useState();
  const dispatch = useDispatch();
  const deleteContent = () => {
    dispatch(
      removeApplicationContent({
        loggedInUserId,
        applicationContentId: content?.applicationContentId,
      })
    ).then(() => setReload());
  };

  console.log("content>>>>>.", content);
  return (
    <div class="row w-100 mx-0">
      <div class="col-md-12">
        <div
          class="d_card d_gallery_view w-50 d-flex align-content-between flex-wrap cursor-pointer"
          style={{
            backgroundSize: "cover",
            backgroundImage: content?.contentMediaUrl
              ? getMediaPreview(content)
              : //  "url(" +
                //   `${process.env.REACT_APP_AWS_S3_BASE_URL +
                //     content?.contentMediaUrl}` +
                //   ")"
                "url(/media/dummyImages/no_preview_v2.png)",
          }}
        >
          <div class="col-12 px-4 d-flex justify-content-end align-items-center">
            <span class="label label-outline-success label-pill label-inline label_dark mr-2">
              {getConvertedContentStatus(content?.contentStatus)}
            </span>
            <span
              class="bg_light_dark btn h-30px w-30px p-0 d-flex justify-content-center align-items-center"
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_AWS_S3_BASE_URL +
                    content?.contentMediaUrl}`
                )
              }
            >
              <img src="/media/svg/icons/custom/icon_view.svg" />
            </span>
            <span
              class="bg_light_dark btn h-30px w-30px p-0 d-flex justify-content-center align-items-center ml-2"
              onClick={() => {
                setClickedButton("delete-button");
                deleteContent();
              }}
            >
              {clickedButton === "delete-button" && actionsLoading ? (
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                <img src="/media/svg/icons/custom/Icon_delete.svg" />
              )}
            </span>
          </div>
          <div class="col-12 px-4">
            <div class="d-flex d-flex align-items-center">
              <div class="flex-grow-1">
                <div class="d-flex align-items-center mb-0">
                  <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                    <span class="text-light font-size-xs">
                      <span class="">
                        <span>
                          {moment(new Date(content?.createdAt)).format(
                            "MMMM DD, YYYY"
                          )}
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
