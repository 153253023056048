import * as requestFromServer from "./applicationCrud";
import { applicationSlice, callTypes } from "./applicationSlice";
import { Toaster } from "../../../components/Toaster";

const { actions } = applicationSlice;

export const listApplications = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getApplications(queryParams)
    .then((response) => {
      const { total: totalCount, rows: applications } = response.data.data;
      dispatch(actions.applicationsFetched({ totalCount, applications }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find applications";
      dispatch(
        actions.applicationsFetched({ totalCount: 0, applications: null })
      );
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const addRating = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addRating(queryParams)
    .then((response) => {
      Toaster({
        message: "Your rating has been submitted",
        variant: "dark",
      });
      dispatch(actions.callSuccess({ callType: callTypes.action }));
    })
    .catch((error) => {
      error.clientMessage = "Can't send rating";
      Toaster({ message: error?.response?.data?.message, variant: "dark" });
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateSocialUrl = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateSocialUrl(queryParams)
    .then((response) => {
      // if (queryParams.basic.styleName === "PRODUCT_PHOTOGRAPHY") {
      //   dispatch(
      //     actions.updateProductPhotography({
      //       productPhotographyElements: {
      //         applicationContentId: queryParams.applicationContentId,
      //         type: queryParams.basic.type,
      //         index: queryParams.basic.index,
      //         contentYoutubeLikesCount:
      //           response?.data?.data.contentYoutubeLikesCount,
      //         contentYoutubeUrl: response?.data?.data.contentYoutubeUrl,
      //         contentYoutubeViewsCount:
      //           response?.data?.data.contentYoutubeViewsCount,
      //         contentInstagramLikesCount:
      //           response?.data?.data.contentInstagramLikesCount,
      //         contentInstagramUrl: response?.data?.data.contentInstagramUrl,
      //         contentInstagramViewsCount:
      //           response?.data?.data.contentInstagramViewsCount,
      //       },
      //     })
      //   );
      // } else if (
      //   queryParams.basic.styleName === "LIFESTYLE_PRODUCT_IN_ACTION"
      // ) {
      //   dispatch(
      //     actions.updateLifestyleProductInAction({
      //       lifestyleProductInActionElements: {
      //         applicationContentId: queryParams.applicationContentId,
      //         type: queryParams.basic.type,
      //         index: queryParams.basic.index,
      //         contentYoutubeLikesCount:
      //           response?.data?.data.contentYoutubeLikesCount,
      //         contentYoutubeUrl: response?.data?.data.contentYoutubeUrl,
      //         contentYoutubeViewsCount:
      //           response?.data?.data.contentYoutubeViewsCount,
      //         contentInstagramLikesCount:
      //           response?.data?.data.contentInstagramLikesCount,
      //         contentInstagramUrl: response?.data?.data.contentInstagramUrl,
      //         contentInstagramViewsCount:
      //           response?.data?.data.contentInstagramViewsCount,
      //       },
      //     })
      //   );
      // } else if (queryParams.basic.styleName === "PORTRAIT_STYLE_PHOTOGRAPHY") {
      //   dispatch(
      //     actions.updatePortraitStylePhotography({
      //       portraitStylePhotographyElements: {
      //         applicationContentId: queryParams.applicationContentId,
      //         type: queryParams.basic.type,
      //         index: queryParams.basic.index,
      //         contentYoutubeLikesCount:
      //           response?.data?.data.contentYoutubeLikesCount,
      //         contentYoutubeUrl: response?.data?.data.contentYoutubeUrl,
      //         contentYoutubeViewsCount:
      //           response?.data?.data.contentYoutubeViewsCount,
      //         contentInstagramLikesCount:
      //           response?.data?.data.contentInstagramLikesCount,
      //         contentInstagramUrl: response?.data?.data.contentInstagramUrl,
      //         contentInstagramViewsCount:
      //           response?.data?.data.contentInstagramViewsCount,
      //       },
      //     })
      //   );
      // } else if (queryParams.basic.styleName === "GRAPHIC_DESIGN") {
      //   dispatch(
      //     actions.updateGraphicDesign({
      //       graphicDesignElements: {
      //         applicationContentId: queryParams.applicationContentId,
      //         type: queryParams.basic.type,
      //         index: queryParams.basic.index,
      //         contentYoutubeLikesCount:
      //           response?.data?.data.contentYoutubeLikesCount,
      //         contentYoutubeUrl: response?.data?.data.contentYoutubeUrl,
      //         contentYoutubeViewsCount:
      //           response?.data?.data.contentYoutubeViewsCount,
      //         contentInstagramLikesCount:
      //           response?.data?.data.contentInstagramLikesCount,
      //         contentInstagramUrl: response?.data?.data.contentInstagramUrl,
      //         contentInstagramViewsCount:
      //           response?.data?.data.contentInstagramViewsCount,
      //       },
      //     })
      //   );
      // } else if (
      //   queryParams.basic.styleName === "ACTION_PRODUCT_IN_USE_VIDEO"
      // ) {
      //   dispatch(
      //     actions.updateActionProductInUse({
      //       actionProductInUseElements: {
      //         applicationContentId: queryParams.applicationContentId,
      //         type: queryParams.basic.type,
      //         index: queryParams.basic.index,
      //         contentYoutubeLikesCount:
      //           response?.data?.data.contentYoutubeLikesCount,
      //         contentYoutubeUrl: response?.data?.data.contentYoutubeUrl,
      //         contentYoutubeViewsCount:
      //           response?.data?.data.contentYoutubeViewsCount,
      //         contentInstagramLikesCount:
      //           response?.data?.data.contentInstagramLikesCount,
      //         contentInstagramUrl: response?.data?.data.contentInstagramUrl,
      //         contentInstagramViewsCount:
      //           response?.data?.data.contentInstagramViewsCount,
      //       },
      //     })
      //   );
      // } else if (queryParams.basic.styleName === "UNBOXING_EXPLANATION_VIDEO") {
      //   dispatch(
      //     actions.updateUnboxingExplanationVideo({
      //       unboxingExplanationVideoElements: {
      //         applicationContentId: queryParams.applicationContentId,
      //         type: queryParams.basic.type,
      //         index: queryParams.basic.index,
      //         contentYoutubeLikesCount:
      //           response?.data?.data.contentYoutubeLikesCount,
      //         contentYoutubeUrl: response?.data?.data.contentYoutubeUrl,
      //         contentYoutubeViewsCount:
      //           response?.data?.data.contentYoutubeViewsCount,
      //         contentInstagramLikesCount:
      //           response?.data?.data.contentInstagramLikesCount,
      //         contentInstagramUrl: response?.data?.data.contentInstagramUrl,
      //         contentInstagramViewsCount:
      //           response?.data?.data.contentInstagramViewsCount,
      //       },
      //     })
      //   );
      // } else if (
      //   queryParams.basic.styleName === "PRODUCT_REVIEW_TESTIMONIAL_VIDEO"
      // ) {
      //   dispatch(
      //     actions.updateProductReviewTestimonialVideo({
      //       productReviewTestimonialVideoElements: {
      //         applicationContentId: queryParams.applicationContentId,
      //         type: queryParams.basic.type,
      //         index: queryParams.basic.index,
      //         contentYoutubeLikesCount:
      //           response?.data?.data.contentYoutubeLikesCount,
      //         contentYoutubeUrl: response?.data?.data.contentYoutubeUrl,
      //         contentYoutubeViewsCount:
      //           response?.data?.data.contentYoutubeViewsCount,
      //         contentInstagramLikesCount:
      //           response?.data?.data.contentInstagramLikesCount,
      //         contentInstagramUrl: response?.data?.data.contentInstagramUrl,
      //         contentInstagramViewsCount:
      //           response?.data?.data.contentInstagramViewsCount,
      //       },
      //     })
      //   );
      // } else if (queryParams.basic.styleName === "PROMOTIONAL_VIDEO") {
      //   dispatch(
      //     actions.updatePromotionalVideo({
      //       promotionalVideoElements: {
      //         applicationContentId: queryParams.applicationContentId,
      //         type: queryParams.basic.type,
      //         index: queryParams.basic.index,
      //         contentYoutubeLikesCount:
      //           response?.data?.data.contentYoutubeLikesCount,
      //         contentYoutubeUrl: response?.data?.data.contentYoutubeUrl,
      //         contentYoutubeViewsCount:
      //           response?.data?.data.contentYoutubeViewsCount,
      //         contentInstagramLikesCount:
      //           response?.data?.data.contentInstagramLikesCount,
      //         contentInstagramUrl: response?.data?.data.contentInstagramUrl,
      //         contentInstagramViewsCount:
      //           response?.data?.data.contentInstagramViewsCount,
      //       },
      //     })
      //   );
      // }
      Toaster({
        message: response?.data?.message && "Url Updated Successfully",
        variant: "dark",
      });
      dispatch(actions.callSuccess({ callType: callTypes.action }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update Social URL";
      Toaster({ message: error?.response?.data?.message, variant: "dark" });
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getRating = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getRating(queryParams)
    .then((response) => {
      dispatch(actions.ratingFetched(response.data.data[0]));
    })
    .catch((error) => {
      error.clientMessage = "Can't fetch rating";
      dispatch(actions.ratingFetched(0));
      Toaster({ message: error?.response?.data?.message, variant: "dark" });
      // dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const listApplicationContents = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getApplicationContents(queryParams)
    .then((response) => {
      const {
        total: totalCount,
        rows: contents,
        contentHistory,
      } = response.data.data;
      dispatch(
        actions.contentsFetched({ totalCount, contents, contentHistory })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't fetch contents";

      dispatch(
        actions.contentsFetched({
          totalCount: 0,
          contents: [],
          contentHistory: [],
        })
      );
      Toaster({ message: error?.response?.data?.message, variant: "dark" });
      // dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getSpecificCampaignGallery = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getApplicationContents(queryParams)
    .then((response) => {
      const { total: totalCount, rows: contents } = response.data.data;
      dispatch(actions.galleryFetched({ totalCount, contents }));
    })
    .catch((error) => {
      error.clientMessage = "Can't fetch contents";

      dispatch(actions.galleryFetched({ totalCount: 0, contents: [] }));
      Toaster({ message: error?.response?.data?.message, variant: "dark" });
      // dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const clearDetailsOfCampaign = () => (dispatch) => {
  dispatch(actions.clearDetailsOfCampaign());
};

export const listApplicationContent = (queryParams) => (dispatch) => {
  dispatch(actions.clearProductPhotography());
  dispatch(actions.clearLifestyleProductInAction());
  dispatch(actions.clearPortraitStylePhotography());
  dispatch(actions.clearActionProductInUse());
  dispatch(actions.clearUnboxingExplanationVideo());
  dispatch(actions.clearProductReviewTestimonialVideo());
  dispatch(actions.clearGraphicDesign());
  dispatch(actions.clearPromotionalVideo());
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getApplicationContents(queryParams)
    .then((response) => {
      response.data.data.rows.forEach((content) => {
        if (content.postType === "IMAGE_POST") {
          if (content.postImageStyle === "PRODUCT_PHOTOGRAPHY") {
            dispatch(
              actions.updateProductPhotography({
                productPhotographyElements: {
                  url: content?.contentMediaUrl,
                  path: content?.contentMediaUrl + "_thumbnailImage",
                  applicationContentId: content.applicationContentId,
                  type: content.postImageStyle,
                  contentYoutubeLikesCount: content.contentYoutubeLikesCount,
                  contentYoutubeUrl: content.contentYoutubeUrl,
                  contentYoutubeViewsCount: content.contentYoutubeViewsCount,
                  contentInstagramLikesCount:
                    content.contentInstagramLikesCount,
                  contentInstagramUrl: content.contentInstagramUrl,
                  contentInstagramViewsCount:
                    content.contentInstagramViewsCount,
                },
              })
            );
          } else if (content.postImageStyle === "LIFESTYLE_PRODUCT_IN_ACTION") {
            dispatch(
              actions.updateLifestyleProductInAction({
                lifestyleProductInActionElements: {
                  url: content?.contentMediaUrl,
                  path: content?.contentMediaUrl + "_thumbnailImage",
                  applicationContentId: content.applicationContentId,
                  type: content.postImageStyle,
                  contentYoutubeLikesCount: content.contentYoutubeLikesCount,
                  contentYoutubeUrl: content.contentYoutubeUrl,
                  contentYoutubeViewsCount: content.contentYoutubeViewsCount,
                  contentInstagramLikesCount:
                    content.contentInstagramLikesCount,
                  contentInstagramUrl: content.contentInstagramUrl,
                  contentInstagramViewsCount:
                    content.contentInstagramViewsCount,
                },
              })
            );
          } else if (content.postImageStyle === "PORTRAIT_STYLE_PHOTOGRAPHY") {
            dispatch(
              actions.updatePortraitStylePhotography({
                portraitStylePhotographyElements: {
                  url: content?.contentMediaUrl,
                  path: content?.contentMediaUrl + "_thumbnailImage",
                  applicationContentId: content.applicationContentId,
                  type: content.postImageStyle,
                  contentYoutubeLikesCount: content.contentYoutubeLikesCount,
                  contentYoutubeUrl: content.contentYoutubeUrl,
                  contentYoutubeViewsCount: content.contentYoutubeViewsCount,
                  contentInstagramLikesCount:
                    content.contentInstagramLikesCount,
                  contentInstagramUrl: content.contentInstagramUrl,
                  contentInstagramViewsCount:
                    content.contentInstagramViewsCount,
                },
              })
            );
          } else if (content.postImageStyle === "GRAPHIC_DESIGN") {
            dispatch(
              actions.updateGraphicDesign({
                graphicDesignElements: {
                  url: content?.contentMediaUrl,
                  path: content?.contentMediaUrl,
                  applicationContentId: content.applicationContentId,
                  type: content.postImageStyle,
                  contentYoutubeLikesCount: content.contentYoutubeLikesCount,
                  contentYoutubeUrl: content.contentYoutubeUrl,
                  contentYoutubeViewsCount: content.contentYoutubeViewsCount,
                  contentInstagramLikesCount:
                    content.contentInstagramLikesCount,
                  contentInstagramUrl: content.contentInstagramUrl,
                  contentInstagramViewsCount:
                    content.contentInstagramViewsCount,
                },
              })
            );
          }
        } else if (content.postType === "VIDEO_POST") {
          if (content.postVideoStyle === "ACTION_PRODUCT_IN_USE_VIDEO") {
            dispatch(
              actions.updateActionProductInUse({
                actionProductInUseElements: {
                  url: content?.contentMediaUrl,
                  path: content?.contentMediaUrl,
                  applicationContentId: content.applicationContentId,
                  type: content.postVideoStyle,
                  contentYoutubeLikesCount: content.contentYoutubeLikesCount,
                  contentYoutubeUrl: content.contentYoutubeUrl,
                  contentYoutubeViewsCount: content.contentYoutubeViewsCount,
                  contentInstagramLikesCount:
                    content.contentInstagramLikesCount,
                  contentInstagramUrl: content.contentInstagramUrl,
                  contentInstagramViewsCount:
                    content.contentInstagramViewsCount,
                },
              })
            );
          } else if (content.postVideoStyle === "UNBOXING_EXPLANATION_VIDEO") {
            dispatch(
              actions.updateUnboxingExplanationVideo({
                unboxingExplanationVideoElements: {
                  url: content?.contentMediaUrl,
                  path: content?.contentMediaUrl,
                  applicationContentId: content.applicationContentId,
                  type: content.postVideoStyle,
                  contentYoutubeLikesCount: content.contentYoutubeLikesCount,
                  contentYoutubeUrl: content.contentYoutubeUrl,
                  contentYoutubeViewsCount: content.contentYoutubeViewsCount,
                  contentInstagramLikesCount:
                    content.contentInstagramLikesCount,
                  contentInstagramUrl: content.contentInstagramUrl,
                  contentInstagramViewsCount:
                    content.contentInstagramViewsCount,
                },
              })
            );
          } else if (
            content.postVideoStyle === "PRODUCT_REVIEW_TESTIMONIAL_VIDEO"
          ) {
            dispatch(
              actions.updateProductReviewTestimonialVideo({
                productReviewTestimonialVideoElements: {
                  url: content?.contentMediaUrl,
                  path: content?.contentMediaUrl,
                  applicationContentId: content.applicationContentId,
                  type: content.postVideoStyle,
                  contentYoutubeLikesCount: content.contentYoutubeLikesCount,
                  contentYoutubeUrl: content.contentYoutubeUrl,
                  contentYoutubeViewsCount: content.contentYoutubeViewsCount,
                  contentInstagramLikesCount:
                    content.contentInstagramLikesCount,
                  contentInstagramUrl: content.contentInstagramUrl,
                  contentInstagramViewsCount:
                    content.contentInstagramViewsCount,
                },
              })
            );
          } else if (content.postVideoStyle === "PROMOTIONAL_VIDEO") {
            dispatch(
              actions.updatePromotionalVideo({
                promotionalVideoElements: {
                  url: content?.contentMediaUrl,
                  path: content?.contentMediaUrl,
                  applicationContentId: content.applicationContentId,
                  type: content.postVideoStyle,
                  contentYoutubeLikesCount: content.contentYoutubeLikesCount,
                  contentYoutubeUrl: content.contentYoutubeUrl,
                  contentYoutubeViewsCount: content.contentYoutubeViewsCount,
                  contentInstagramLikesCount:
                    content.contentInstagramLikesCount,
                  contentInstagramUrl: content.contentInstagramUrl,
                  contentInstagramViewsCount:
                    content.contentInstagramViewsCount,
                },
              })
            );
          }
        }
      });
      const { total: totalCount, rows: contents } = response.data.data;
      dispatch(actions.contentsFetched({ totalCount, contents }));
      dispatch(actions.callSuccess({ callType: callTypes.action }));
      dispatch(actions.callSuccess({ callType: callTypes.list }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find applications";
      dispatch(actions.contentsFetched({ totalCount: 0, contents: null }));
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getCampaignDetails = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getCampaignDetails(queryParams)
    .then((response) => {
      const campaignDetails = response.data.data;
      dispatch(actions.campaignDetailsFetched({ campaignDetails }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find campaigns";
      dispatch(actions.campaignDetailsFetched({ campaignDetails: {} }));
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const createContent = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createApplicationContents(queryParams)
    .then((response) => {
      dispatch(actions.contentFirstStageRecieved(response.data.data));
      // Toaster({ message: response?.data?.message, variant: "success" });
      dispatch(actions.callSuccess({ callType: callTypes.action }));
      return response;
    })
    .catch((error) => {
      error.clientMessage = "Can't send rating";
      Toaster({ message: error?.response?.data?.message, variant: "dark" });
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const generateMediaUploadUrl = (queryParams) => (dispatch) => {
  let { currentId, mediaFile, basic, ...rest } = queryParams;
  dispatch(actions.startCall({ callType: callTypes.action }));
  if (queryParams.basic.type !== "VIDEO_POST") {
    return requestFromServer
      .generateMediaUploadUrl(queryParams.basic.thumbnailImageUpdateObj)
      .then((response) => {
        // Toaster({
        //   message: response?.data?.message && "Media Uploaded Successfully ",
        //   variant: "success",
        // });
        requestFromServer
          .signedUpload(
            response?.data?.data.url,
            queryParams.basic.thumbnailImage
          )
          .then(async (res) => {
            await requestFromServer
              .generateMediaUploadUrl(
                queryParams.basic.compressedImageUpdateObj
              )
              .then((response) =>
                requestFromServer.signedUpload(
                  response?.data?.data.url,
                  queryParams.basic.compressedImage
                )
              );
            await requestFromServer
              .generateMediaUploadUrl(
                queryParams.basic.compressedWatermarkImageUpdateObj
              )
              .then((response) =>
                requestFromServer.signedUpload(
                  response?.data?.data.url,
                  queryParams.basic.compressedWatermarkImage
                )
              );
            await requestFromServer
              .generateMediaUploadUrl(
                queryParams.basic.watermarkedThumbnailImageUpdateObj
              )
              .then((response) =>
                requestFromServer.signedUpload(
                  response?.data?.data.url,

                  queryParams.basic.watermarkedThumbnailImage
                )
              );

            await requestFromServer
              .generateMediaUploadUrl(rest)
              .then((response) =>
                requestFromServer.signedUpload(
                  response?.data?.data.url,
                  mediaFile
                )
              );

            if (queryParams.basic.styleName === "PRODUCT_PHOTOGRAPHY") {
              dispatch(
                actions.updateProductPhotography({
                  productPhotographyElements: {
                    url: response?.data?.data.url,
                    path: response?.data?.data.path,
                    applicationContentId: queryParams.applicationContentId,
                    type: queryParams.basic.type,
                    index: queryParams.basic.index,
                  },
                })
              );
            } else if (
              queryParams.basic.styleName === "LIFESTYLE_PRODUCT_IN_ACTION"
            ) {
              dispatch(
                actions.updateLifestyleProductInAction({
                  lifestyleProductInActionElements: {
                    url: response?.data?.data.url,
                    path: response?.data?.data.path,
                    applicationContentId: queryParams.applicationContentId,
                    type: queryParams.basic.type,
                    index: queryParams.basic.index,
                  },
                })
              );
            } else if (
              queryParams.basic.styleName === "PORTRAIT_STYLE_PHOTOGRAPHY"
            ) {
              dispatch(
                actions.updatePortraitStylePhotography({
                  portraitStylePhotographyElements: {
                    url: response?.data?.data.url,
                    path: response?.data?.data.path,
                    applicationContentId: queryParams.applicationContentId,
                    type: queryParams.basic.type,
                    index: queryParams.basic.index,
                  },
                })
              );
            } else if (queryParams.basic.styleName === "GRAPHIC_DESIGN") {
              dispatch(
                actions.updateGraphicDesign({
                  graphicDesignElements: {
                    url: response?.data?.data.url,
                    path: response?.data?.data.path,
                    applicationContentId: queryParams.applicationContentId,
                    type: queryParams.basic.type,
                    index: queryParams.basic.index,
                  },
                })
              );
            }
            dispatch(actions.callSuccess({ callType: callTypes.action }));
            dispatch(actions.callSuccess({ callType: callTypes.list }));
          })
          .catch((err) => {
            dispatch(actions.catchError({ callType: callTypes.action }));
          });
      })
      .catch((error) => {
        error.clientMessage = "Can't send content";
        Toaster({ message: error?.response?.data?.message, variant: "dark" });
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
  } else {
    return requestFromServer
      .generateMediaUploadUrl(rest)
      .then((response) => {
        // Toaster({
        //   message: response?.data?.message && "Media Uploaded Successfully ",
        //   variant: "success",
        // });
        requestFromServer
          .signedUpload(response?.data?.data.url, mediaFile)
          .then((res) => {
            if (queryParams.basic.styleName === "ACTION_PRODUCT_IN_USE_VIDEO") {
              dispatch(
                actions.updateActionProductInUse({
                  actionProductInUseElements: {
                    url: response?.data?.data.url,
                    path: response?.data?.data.path,
                    applicationContentId: queryParams.applicationContentId,
                    type: queryParams.basic.type,
                    index: queryParams.basic.index,
                  },
                })
              );
            } else if (
              queryParams.basic.styleName === "UNBOXING_EXPLANATION_VIDEO"
            ) {
              dispatch(
                actions.updateUnboxingExplanationVideo({
                  unboxingExplanationVideoElements: {
                    url: response?.data?.data.url,
                    path: response?.data?.data.path,
                    applicationContentId: queryParams.applicationContentId,
                    type: queryParams.basic.type,
                    index: queryParams.basic.index,
                  },
                })
              );
            } else if (
              queryParams.basic.styleName === "PRODUCT_REVIEW_TESTIMONIAL_VIDEO"
            ) {
              dispatch(
                actions.updateProductReviewTestimonialVideo({
                  productReviewTestimonialVideoElements: {
                    url: response?.data?.data.url,
                    path: response?.data?.data.path,
                    applicationContentId: queryParams.applicationContentId,
                    type: queryParams.basic.type,
                    index: queryParams.basic.index,
                  },
                })
              );
            } else if (queryParams.basic.styleName === "PROMOTIONAL_VIDEO") {
              dispatch(
                actions.updatePromotionalVideo({
                  promotionalVideoElements: {
                    url: response?.data?.data.url,
                    path: response?.data?.data.path,
                    applicationContentId: queryParams.applicationContentId,
                    type: queryParams.basic.type,
                    index: queryParams.basic.index,
                  },
                })
              );
            }
            dispatch(actions.callSuccess({ callType: callTypes.action }));
            dispatch(actions.callSuccess({ callType: callTypes.list }));
          })
          .catch((err) => {
            dispatch(actions.catchError({ callType: callTypes.action }));
          });
      })
      .catch((error) => {
        error.clientMessage = "Can't send Content";
        Toaster({ message: error?.response?.data?.message, variant: "dark" });
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
  }
};

export const submitForApproval = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .submitForApproval(queryParams)
    .then((response) => {
      Toaster({
        message:
          response?.data?.data?.campaignType === "RELATION_POST"
            ? "Thank you for submitting the URL! Selected posts would be contacted via WhatsApp or email."
            : response?.data?.message,
        variant: "dark",
      });
      dispatch(actions.callSuccess({ callType: callTypes.action }));
    })
    .catch((error) => {
      error.clientMessage = "Can't submit for approval";
      Toaster({ message: error?.response?.data?.message, variant: "dark" });
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const submitAllUrlStatus = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .submitAllUrlStatus(queryParams)
    .then((response) => {
      Toaster({
        message:
          response?.data?.message &&
          "Thank you for submitting the URL! Selected posts would be contacted via WhatsApp or email.",
        variant: "dark",
      });
      dispatch(actions.callSuccess({ callType: callTypes.action }));
    })
    .catch((error) => {
      error.clientMessage = "Can't submit Url";
      Toaster({ message: error?.response?.data?.message, variant: "dark" });
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const setAsDraft = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .setAsDraft(queryParams)
    .then((response) => {
      Toaster({ message: response?.data?.message, variant: "dark" });
      dispatch(actions.callSuccess({ callType: callTypes.action }));
    })
    .catch((error) => {
      error.clientMessage = "Can't save as Draft";
      Toaster({ message: error?.response?.data?.message, variant: "dark" });
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getMediaDownloadURL = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getMediaDownloadURL(queryParams)
    .then((response) => {
      window.open(response.data.data.url);
      dispatch(actions.callSuccess({ callType: callTypes.action }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find medias";
      Toaster({ message: error?.response?.data?.message, variant: "dark" });
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getMediaViewURL = (queryParams, viewStatus = false) => (
  dispatch
) => {
  let { basic, ...rest } = queryParams;
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getMediaDownloadURL(rest)
    .then((response) => {
      viewStatus && window.open(response.data.data.url);
      dispatch(actions.downloadMediaUrlFetched(response.data.data));
    })
    .catch((error) => {
      error.clientMessage = "Can't find medias";
      dispatch(actions.downloadMediaUrlFetched({ mediaURL: null }));
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const clearViewURL = (queryParams) => (dispatch) => {
  dispatch(actions.downloadMediaUrlFetched({}));
};

export const removeApplicationContent = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .removeApplicationContent(queryParams)
    .then((response) => {
      if (response.data.data.postImageStyle === "PRODUCT_PHOTOGRAPHY") {
        dispatch(
          actions.removeProductPhotography(queryParams.applicationContentId)
        );
      } else if (
        response.data.data.postImageStyle === "LIFESTYLE_PRODUCT_IN_ACTION"
      ) {
        dispatch(
          actions.removeLifestyleProductInAction(
            queryParams.applicationContentId
          )
        );
      } else if (
        response.data.data.postImageStyle === "PORTRAIT_STYLE_PHOTOGRAPHY"
      ) {
        dispatch(
          actions.removePortraitStylePhotography(
            queryParams.applicationContentId
          )
        );
      } else if (response.data.data.postImageStyle === "GRAPHIC_DESIGN") {
        dispatch(actions.removeGraphicDesign(queryParams.applicationContentId));
      } else if (
        response.data.data.postVideoStyle === "ACTION_PRODUCT_IN_USE_VIDEO"
      ) {
        dispatch(
          actions.removeActionProductInUse(queryParams.applicationContentId)
        );
      } else if (
        response.data.data.postVideoStyle === "UNBOXING_EXPLANATION_VIDEO"
      ) {
        dispatch(
          actions.removeUnboxingExplanationVideo(
            queryParams.applicationContentId
          )
        );
      } else if (
        response.data.data.postVideoStyle === "PRODUCT_REVIEW_TESTIMONIAL_VIDEO"
      ) {
        dispatch(
          actions.removeProductReviewTestimonialVideo(
            queryParams.applicationContentId
          )
        );
      } else if (response.data.data.postVideoStyle === "PROMOTIONAL_VIDEO") {
        dispatch(
          actions.removePromotionalVideo(queryParams.applicationContentId)
        );
      }
      // Toaster({
      //   message: response?.data?.message && "Media Removed Successfully",
      //   variant: "success",
      // });
      dispatch(actions.callSuccess({ callType: callTypes.action }));
      // dispatch(actions.callSuccess({ callType: callTypes.list }));
    })
    .catch((error) => {
      error.clientMessage = "Can't remove items";
      Toaster({ message: error?.response?.data?.message, variant: "dark" });
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getTrackingDetails = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getTrackingDetails(queryParams)
    .then((response) => {
      const trackingDetails = response.data.data;
      dispatch(actions.TrackingDetailsFetched({ trackingDetails }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find ";
      // Toaster({ message: error?.response?.data?.message, variant: "error" });
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
