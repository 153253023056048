import React, { memo,useRef,useEffect,useState } from "react";
import {campaignType} from "../helper/helper";
import { GuidelineImagePopup} from "./GuidelineImagePopup";

let guideLineMasterContainer = null;

const CampaignPreview = ({
  modalBody,
  close,
  htmlRenderRequired,
  showHeader,
  setGuidelinePreview,
  campaign,
  applyForThisCampaign,
  campaignAppliedFlag,
  setCampaignAppliedFlag
}) => {

  const [popupFullScreen, setPopupFullScreen] = useState(false);
  const [refreshPopup, setRefreshPopup] = useState(false);


  const showPopupFunction = useRef();
  const updateImageArrayFunction = useRef();

  const [closePopupOnClick, setPopupCloseOnClick] = useState(false);
  

  useEffect(
    (event) => {
      if (modalBody?.length > 0) {
        if (htmlRenderRequired === true) {
          try {
            guideLineMasterContainer = document.getElementById(
              "guide_line_main_container"
            );
            /*if (
            document.getElementById("guide_line_main_container") &&
            document.getElementById("secondary-font")
          ) {
            document.getElementById("primary-font").style = "display: none";
            document.getElementById("primary-font-input").style = "";
            document.getElementById("secondary-font").style = "display: none";
            document.getElementById("secondary-font-input").style = "";
          }*/
            if (guideLineMasterContainer != null) {
              guideLineMasterContainer.addEventListener("click", onClickEvent);
              //console.log(guideLineMasterContainer);
            }

            var placeHolderText = guideLineMasterContainer.querySelectorAll(
              "[data-guideline*='video-preview'] [data-guideline='guideline-child-element']"
            );
            if (placeHolderText != null) {
              Array.from(placeHolderText).forEach(
                (resultObj, index) => {
                  resultObj.addEventListener("mousemove", enableVideoPreviewFunction);
                  resultObj.addEventListener("mouseout", enableVideoPreviewFunction);
                  resultObj.addEventListener("touchstart", enableVideoPreviewFunction);
                }
              );
            }

          } catch (error) {
            console.log("error--->", error);
          }
        }
      }
    },
    [modalBody, htmlRenderRequired]
  );

  const enableVideoPreviewFunction = (event) => {
    var eventType = event.type;
    let element = event.target;
    var parentEle = element.closest(".data-guideline-parent");
    if(parentEle !=null){
      var videoEle = parentEle.querySelector(
        "video");
      if(videoEle !=null){
        if(eventType == "mousemove"){
          if(videoEle.getAttribute("autoplay") == null){
            videoEle.setAttribute("autoplay","");
            videoEle.play();            
          }
        }else if(eventType == "mouseout"){
          videoEle.removeAttribute("autoplay");
          videoEle.pause();
        }else if(eventType == "touchstart"){
          if(videoEle.getAttribute("autoplay") == null){
            videoEle.setAttribute("autoplay","");
            videoEle.play();
            setTimeout(function() {
              videoEle.pause();
              videoEle.removeAttribute("autoplay");
            }, 1000*15); //Same time as animation
          }
        }
      }
    }else{
     
    }
  };

  const handleClose = () => {
    if (guideLineMasterContainer != null) {
      guideLineMasterContainer.removeEventListener("click", onClickEvent);
      guideLineMasterContainer = null;
      console.log("Removed Event");
    }
    close();
  };

  const onClickEvent = (e) => {
    var suneditorContainer = guideLineMasterContainer;
    var targetObject = e.target;
    var componentTxt = targetObject.getAttribute("data-guideline");
    var parentWithClass = null;
    var childElement = false;
    var placeHolderText = null;
    if (componentTxt != null) {
      var componentArray = componentTxt.split(" ");
      if (componentTxt === "guideline-child-element") {
        childElement = true;
        parentWithClass = targetObject.closest(".parent");
        targetObject = parentWithClass;
        if (parentWithClass != null) {
          componentTxt = parentWithClass.getAttribute("data-guideline");
          if (componentTxt != null) {
            componentArray = componentTxt.split(" ");
          }
        }
      }
      componentArray.forEach(function(component) {
        //Invoke Component Library Dialog
        switch (component) {
          case "guideline_section_switcher":
            if (targetObject != null) {
              var targetSection = targetObject.getAttribute(
                "data-active-section-name"
              );
              var guidelineContainer = suneditorContainer.querySelector(
                "#guide_line_content"
              );
              placeHolderText = guidelineContainer.querySelectorAll(
                "[data-section-name]:not([data-section-name='" +
                  targetSection +
                  "'])"
              );
              Array.from(placeHolderText).forEach((element, index) => {
                element.classList.add("fadeoutelement");
                element.classList.remove("fadeinelement");
                //setTimeout(function() {
                element.style.setProperty("display", "none", "important");
                //}, 200); //Same time as animation
              });

              placeHolderText = targetObject.parentNode.querySelectorAll(
                "[data-active-section-name]:not([data-active-section-name='" +
                  targetSection +
                  "'])"
              );
              Array.from(placeHolderText).forEach((element, index) => {
                element.classList.remove("btn-primary");
                element.classList.remove("section-selected");
                element.classList.add("btn-white");
                element.classList.remove("active");
              });

              var targetSection = guidelineContainer.querySelector(
                "[data-section-name='" + targetSection + "']"
              );
              targetSection.classList.add("fadeinelement");
              targetSection.classList.remove("fadeoutelement");
              //setTimeout(function() {
              targetSection.style.setProperty("display", "flex", "important");
              //}, 200); //Same time as animation
              targetObject.classList.add("btn-primary");
              targetObject.classList.add("section-selected");

              targetObject.classList.remove("btn-white");
              targetObject.classList.add("active");
            }
            break;
            case "guideline-image-preview":
              var activeNode = targetObject;
              var meterImage = targetObject.querySelector(".meter-image");
              if(meterImage  == null && targetObject.classList.contains("meter-image")){
                meterImage = targetObject;
              }
              if(meterImage != null){
                var meterDataSet = targetObject.querySelector(
                  ".image-url-data-set"
                );
                var dataValue = meterImage.getAttribute("data-value");
                if(dataValue != null && meterDataSet != null){
                  var dataChildren = meterDataSet.querySelector(
                    '[data-range-value="' +
                      dataValue +
                      '"]'
                  );
                  if(dataChildren != null){
                    var imageURLList = dataChildren.innerHTML.trim().split(",");
                    var titleText = targetObject.querySelector(".meter-message .text-selection-head,.text-selection-head");
                    var imageURL = imageURLList[0];
                    ///var background = imageURL,
                    //reg = /(?:\(['"]?)(.*?)(?:['"]?\))/,
                    //extracterUrl = reg.exec(background)[1];
                    updateImageArrayFunction.current(imageURLList);

                    showPopupFunction.current(true,imageURL,imageURLList,titleText.innerHTML);
                  }
                }else{
                  var imageObject = targetObject.querySelector(".data-image-value");
                  var imageType = imageObject.getAttribute("data-file-name"); 
                  if(imageType != null && imageType != "_placeholder"){
                    var titleObject = targetObject.querySelector(".meter-message .text-selection-head,.text-selection-head");
                    var titleText = titleObject != null?titleText.innerHTML:"Preview";
                    if(imageObject != null){
                      var imageSrc = imageObject.src;
                      updateImageArrayFunction.current(null);
                      showPopupFunction.current(true,imageSrc,null,titleText);
                    }
                  }
                }
              }
            break;
            case "guideline-gift-card":
              var activeNode = targetObject;
              var meterImage = targetObject.querySelector(".meter-image");
              if(meterImage  == null && targetObject.classList.contains("meter-image")){
                meterImage = targetObject;
              }
              if(meterImage != null){
                var meterDataSet = targetObject.querySelector(
                  ".gift-data-set"
                );
                var dataValue = meterImage.getAttribute("data-value");
                if(dataValue != null && meterDataSet != null){
                  var dataChildren = meterDataSet.querySelector(
                    '[data-range-value="' +
                      dataValue +
                      '"]'
                  );
                  var rand = Math.random() * 100;
                  rand = Math.floor(rand); // 99

                  var allDataSet = targetObject.querySelectorAll(".image-url-data-set [data-range-value]");
                  var rangeData = 0;
                  var rewardCard = null;
                  Array.from(allDataSet).forEach(
                    (resultObj, index) => {
                      if(rewardCard == null){
                        var rangeValue = resultObj.getAttribute("data-range-value");
                        rangeData += parseInt(rangeValue);
                        if(rangeData >= rand ){
                          rewardCard = resultObj;
                        }
                      }
                  });
                  if(dataChildren != null){
                    var imageURLList = dataChildren.innerHTML.trim().split(",");
                    var titleText = targetObject.querySelector(".meter-message .text-selection-head,.text-selection-head");
                    var guideLineType = imageURLList[0];
                    var rewardImageArray = null;
                    if(rewardCard != null){
                      rewardImageArray = rewardCard.innerHTML.trim().split(",");
                    }
                    updateImageArrayFunction.current(rewardImageArray);
                    showPopupFunction.current(true,guideLineType,rewardImageArray,titleText.innerHTML);
                  }
                }
              }
            break;
          default:
        }
      });
    }
  };

  return (
    <div
      // show={show}
      // onHide={handleClose}
      // size="xl"
      // centered={true}
      scrollable={true}
      class=""
    >
      <div>
      {showHeader ?
        <div class="d-flex flex-nowrap d-flex justify-content-between pt-5">
          <div class="px-0 mb-0 d-flex align-items-center flex-center h6"> 
            <img
                class="h-40px mr-3 cursor-pointer mb-0"
                src="/media/custom/back_btn.svg"
                onClick={() => {
                  setGuidelinePreview(false);
                }}
              />Details
              </div>
                {campaign.socialMediaBrandName &&
            campaign.socialMediaBrandName !== null &&
            campaign.socialMediaBrandLogo !== null && (
                  <>
                  <div class="d-flex flex-nowrap d-flex flex-center">
                    <div class="text-right d-flex flex-wrap text-right justify-content-end">
                      <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                      {campaign.socialMediaBrandName}
                        </div>
                        <div class="text-muted font-size-sm d-flex w-100 justify-content-end t-plc s-l">
                      {campaignType(campaign?.campaignType)}
                      </div>
                    </div>
                    <div class="symbol symbol-30 symbol-light-success ml-2">
                      <div class="h-40px w-40px p-0 d-flex justify-content-center align-items-center">
                        <img
                            class="mr-0 h-100"
                            src={
                              process.env.REACT_APP_AWS_S3_BASE_URL +
                              campaign.socialMediaBrandLogo
                            }
                            alt=""></img>
                      </div>
                    </div>
                  </div>
                  </>
                )}
        </div>
        :null}
      </div>
      <GuidelineImagePopup 
        popupFullScreen={popupFullScreen}
        setPopupFullScreen={setPopupFullScreen}
        refreshPopup = {refreshPopup}
        setRefreshPopup = {setRefreshPopup}
        showPopupFunction = {showPopupFunction}
        updateImageArrayFunction = {updateImageArrayFunction}
        campaign = {campaign}
      />
      {htmlRenderRequired ? (
        <div
          className="modal-body pt-0 mx-auto mt-0 sun-editor-editable sun-editor-view card_form bg_primary_gradient"
          dangerouslySetInnerHTML={{
            __html: modalBody?.replace(
              // eslint-disable-next-line no-control-regex
              new RegExp("\n", "g"),
              "<br/>"
            ),
          }}
        ></div>
      ) : (
        <div className="modal-body pt-0 mx-auto text-center">{modalBody}</div>
      )}
      <div className="">
        {/* <button class="btn btn-primary" onClick={handleClose}>
          Close
        </button> */}
      </div>
    </div>
  );
};

export default memo(CampaignPreview);