import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  webLoading: false,
  error: null,
  profileToEdit: {},
  socket: null,
  signedUploadComplete: "",
  isVisibleModalInfo: false,
  isVisibleSideBar: false,
  creatorUsersList: [],
  socialMediaBrandsList: [],
  isRightSidebar: true,
};

export const callTypes = {
  list: "list",
  action: "action",
  web: "web",
};

export const commonSlice = createSlice({
  name: "common",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.web) {
        state.webLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.web) {
        state.webLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    callSuccess: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.web) {
        state.webLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    creatorProfileFetched: (state, action) => {
      state.profileToEdit = action.payload;
      state.listLoading = false;
      // state.error = null;
    },

    createSocket: (state, action) => {
      state.socket = action.payload;
    },

    ratingFetched: (state, action) => {
      state.fetchedRating = action.payload;
      state.actionsLoading = false;
      state.error = null;
    },
    signedUrlFetched: (state, action) => {
      state.signedUploadComplete = action.payload;
    },
    isVisibleSideBar: (state, action) => {
      state.isVisibleSideBar = action.payload;
      // state.listLoading = false;
      // state.error = null;
    },
    isVisibleModalInfo: (state, action) => {
      state.isVisibleModalInfo = action.payload;
      // state.listLoading = false;
      // state.error = null;
    },
    setRightSidebar: (state, action) => {
      state.isRightSidebar = action.payload;
    },
  },
});
