/* eslint-disable no-unused-expressions */
import React, { useEffect, Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
// import { ChooseApplicationType } from "./pages/ChooseApplicationType";
import { io } from "socket.io-client";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { createSocket } from "./modules/_redux/commonActions";

const Inventory = lazy(() => import("./modules/Inventory/Inventory"));
const AccountStatements = lazy(() =>
  import("./modules/AccountStatements/AccountStatements")
);
const Orders = lazy(() => import("./modules/Orders/Orders"));
const Offers = lazy(() => import("./modules/Offers/Offers"));
const Returns = lazy(() => import("./modules/Returns/Returns"));
const UserAccess = lazy(() => import("./modules/UserAccess/UserAccess"));
const ManageBranches = lazy(() =>
  import("./modules/ManageBranches/ManageBranches")
);

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);
const CampaignsPage = lazy(() => import("./modules/campaign/campaignsPage"));
const ApplicationsPage = lazy(() =>
  import("./modules/campaignApplications/applicationsPage")
);
const RewardsPage = lazy(() => import("./modules/reward/rewardPage"));
const ChatModule = lazy(() => import("./modules/ChatModule/ChatModule"));

const GalleryPage = lazy(() => import("./modules/gallery/galleryPage"));

const PostAnalyticsPage = lazy(() => import("./modules/Post-Analytics/PostAnalyticsPage"));

const PaymentHistory = lazy(() => import("./modules/PaymentHistory/PaymentHistoryPage"));

const Portfolio = lazy(() => import("./modules/Portfolio/PortfolioPage"))
const CampaignCardV2 = lazy(() => import("./modules/CampaignV2/CampaignPageV2"))
const Dashboard = lazy(()=>import("./modules/Dashboard/DashboardPage"));

//new changes according to design
const CampaignDetailPage = lazy(()=>import("./modules/CampaignDetails/CampaignDetailPage"));

export default function BasePage() {
  const dispatch = useDispatch();
  const socket = io(process.env.REACT_APP_SOCKET_SERVER_URL, {
    transports: ["websocket"],
  });

  const { userId } = useSelector(
    (state) => ({
      userId: state?.auth?.user?.userId,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(createSocket(socket));
    if (userId) {
      socket.connect();
      socket.emit("create-room", userId + "_chat");
      socket.emit("joinRoom", userId + "_chat", socket.id);
      socket.emit("create-room", userId);
      socket.emit("joinRoom", userId, socket.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  // useEffect(() => {
  //   socket?.on("messageServicesage", (message) => {
  //     console.log("message recieved.....", message);
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // });

  return (
    // <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route path="/dashboard" component={Dashboard} />

        <Route path="/campaigns/list" component={CampaignCardV2}/>
        {/* <Route path="/campaigns/:campaignId" component={CampaignDetailPage} exact={true} /> */}
        <Route path="/applications" component={ApplicationsPage} />
        <Route path="/rewards" component={RewardsPage} />
        {/* <ContentRoute path="/dashboard" component={ChooseApplicationType} /> */}
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <ContentRoute path="/inventory" component={Inventory} />
        <ContentRoute
          path="/account-statements"
          component={AccountStatements}
        />
        <ContentRoute path="/order-info" component={Orders} />
        <ContentRoute path="/offers" component={Offers} />
        <ContentRoute path="/returns" component={Returns} />
        <ContentRoute path="/user-access" component={UserAccess} />
        <ContentRoute path="/manage-branches" component={ManageBranches} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Route path="/messagings" component={ChatModule} />
        <Route path="/gallery" component={GalleryPage} />
        <Route path="/post-analytics" component={PostAnalyticsPage} />
        <Route path="/PaymentHistory" component={PaymentHistory} />
        <Route path="/portfolio" component={Portfolio} />
        {/* <Route
          path="/shop/freshmarket-dashboard/add-shop"
          component={AddShop}
        /> */}
        <Redirect to="error/error-v1" />
      </Switch>
    // </Suspense>
  );
}
