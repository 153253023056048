import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import axios from 'axios'
import { CUSTOM_BRUSH_PRESET } from 'react-scratchcard-v2';
import ScratchCard from 'react-scratchcard-v2';
import moment from "moment";

export function ScratchCardComponent({
  scratchCardPeriod,
  campaignAppliedFlag,
  campaignFinish,
  setCampaignFinish
}) {
  const dispatch = useDispatch();

  const { userId } = useSelector(
    (state) => ({
      userId: state.auth ? state.auth.user ? state.auth.user.userId : null : null,
    }),
    shallowEqual
  );

  const [scratchCompleted, setScratchCompleted] = useState(campaignAppliedFlag);
  const [validUpto, setValidUpto] = useState("");

  const scratchCardFinish = (e) => {
    var validUptoDate = moment();
    if (scratchCardPeriod == null) {
      scratchCardPeriod = "10:m";
    }
    var templateMetaDataList = scratchCardPeriod.split(",");
    templateMetaDataList.forEach(function (metadata) {
      var dataList = metadata.split(":");
      validUptoDate.add(dataList[0], dataList[1])
    });
    validUptoDate = validUptoDate.format("DD-MMM-YYYY LT");
    setValidUpto(validUptoDate);
    setScratchCompleted(true);
    setCampaignFinish(true)
  }

  const checkUserLogged = () => {
    if (!userId || userId == null) {
      localStorage.setItem("reloadUrl", window.location.pathname);
      window.location.replace("/auth/login");
    } else {
      return true;
    }
  };

  return (
    <>
      <div class="scratch-card-placeholder guideline-popup guideline-shadow-border-nopad cursor-pointer bg-white" style={{
        display: "none"
      }} onClick={() => checkUserLogged()}>
        {!campaignAppliedFlag ?
          <ScratchCard
            width={300}
            height={300}
            image={"/media/svg/misc/scratch_card_orange.svg"}
            finishPercent={50}
            customBrush={CUSTOM_BRUSH_PRESET}
            onComplete={() => scratchCardFinish()}
          >
            {scratchCompleted == true ?
              <div style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <div class="col-12 px-4 card-instruction justify-content-between position-absolute" style={{
                  top: "calc(100% - 35px)",
                }}>
                  <span class="label card-instruction-label label-outline-success label-pill label-inline label_dark text-light">
                    Valid upto {validUpto}</span>
                </div>
                <img src="/media/svg/misc/scratch_card_orange.svg" class="reward-image w-300px h-300px" alt="" style={{
                  objectFit: "contain"
                }} />
              </div>
              :
              <div style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <div hidden class="col-12 px-4 card-instruction justify-content-between position-absolute" style={{
                  top: "calc(100% - 35px)",
                }}>
                  <span class="label card-instruction-label label-outline-success label-pill label-inline label_dark text-light">
                    Valid upto {validUpto}</span>
                </div>
                <img src="/media/svg/misc/scratch_card_orange.svg" class="reward-image w-300px h-300px" alt="" style={{
                  objectFit: "contain",
                  filter: "blur(8px)"
                }} />
              </div>
            }
          </ScratchCard>
          : <div class="w-300px h-300px rounded" style={{ objectFit: "contain", background: "url(/media/svg/misc/scratch_card_green.svg)", backgroundSize: "cover" }}>
          </div>
        }
      </div>
    </>
  );
}