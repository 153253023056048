import React, { memo, useEffect, useState } from "react";
import { campaignType, getConvertedPayoutStatus } from "../../helper/helper";
import moment from "moment";
import { getApplicationStatus, getContentStatus } from "../../../Dashboard/helper/helper";
import { shallowEqual, useSelector } from "react-redux";
import ContentDetails from "./ContentDetails";

const GiftorOfferDetails = ({ stepper, application }) => {
  const [giftoroffer, setGiftorOffer] = useState({
    status: "Gift/Offer Pending",
    color: "text-warning",
  });

    useEffect(() => {
      if( {staus: "Gift Approved"}) {
        setGiftorOffer( { color: "text-success"} )
      }
    },[application])

  const {
    campaignDetails, 
  } = useSelector(
    (state) => ({
      campaignDetails: state.campaign.campaignDetails,
    }),
    shallowEqual
  );
  
 return (
    <>
      
      {application?.applicationStatus === undefined && application?.contentStatus === undefined ||
        (application.applicationStatus === "WAITING_FOR_APPROVAL_BY_BRAND"  ||
        application?.contentStatus === "URL_SUBMITTED_BY_CREATOR" || 
        application?.contentStatus === "DRAFT" ||
        application?.contentStatus === "SOCIAL_MEDIA_URL_REQUESTED"||
        application?.contentStatus === "WAITING_FOR_APPROVAL_BY_BRAND" ||
        application?.contentStatus === "REJECTED_BY_BRAND"|| 
        application?.contentStatus === "RESUBMIT_CONTENT")?
        (<div
            class={`wizard-body py-8 px-8 py-lg-20 px-lg-10 ${
              stepper === "giftorofferDetails" ? "" : "d-none"
            }`}
          >
            <div className="row">
              <div className="w-100">
                <div className={`card  bg-light-warning rounded p-5 mb-5`}>
                  <div class="flex-grow-1">
                    <div class="d-flex align-items-center mb-0">
                      <div class="symbol symbol-30 symbol-danger mr-2">
                        <a
                          href="#"
                          class={`btn  bg-warning mr-2 h-40px w-40px p-0 pt-3`}
                          >
                          <img
                            class="mr-0 h-20px"
                            src="/media/svg/icons/custom/Icon_edit.svg"
                            alt="edit-icon"
                          />
                        </a>
                      </div>
                      <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                        <a
                          href="#"
                          class={`text-warning mb-0 font-weight-bolder  font-size-lg text-truncate`}
                        >
                          Gift/Offer Pending
                        </a>
                        <span class="text-dark font-size-sm">Gift/Offer Status</span>
                      </div>
                    </div>
                    {/* {applicationDetails?.rejectedReason && (
                      <p className="quotes mb-0 font-size-sm mt-5 font-weight-bold">
                      {applicationDetails?.rejectedReason}
                  </p>
                )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ): ( application?.contentStatus === "APPROVED_BY_BRAND" || 
             application?.contentStatus === "URL_APPROVED_BY_BRAND"?
        <div
        class={`wizard-body py-8 px-8 py-lg-20 px-lg-10 ${
          stepper === "giftorofferDetails" ? "" : "d-none"
        }`}
      >
        <div class ="row xs-1 ">
          <div class="card card-custom card-stretch gutter-b shadow-none mb-0 w-100 d-flex flex-row justify-content-between">
            <div class="card-header border-0 px-0 d-flex align-items-center">
              <div class="flex-nowrap d-flex">
                <div class="symbol symbol-30 symbol-danger mr-0.01 d-flex flex-row">
                  <a class="btn  bg-success mr-1 h-40px w-30px p-0 pt-3">
                    <img
                      class="mr-5 h-20px"
                      src="/media/svg/icons/custom/Icon_payment.svg"
                      alt="payment-icon"
                    />
                  </a>
                <h3 class="font-weight-bolder my-0 text-dark card-title align-items-start flex-column">
                  <span
                    class={`card-label font-weight-SemiBold ${giftoroffer?.color}`}
                  >
                    APPROVED
                  </span>
                  <span class="text-dark-50 mt-1 font-weight-bold font-size-sm">
                    Gift/Offer Status
                  </span>
                </h3>
                </div>
              </div>
            </div>
         
            <div class="card-toolbar">
            <div class="d-flex flex-nowrap">
              <div class="d-flex align-items-center mb-0">
                <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                  <a
                    href="#"
                    class="text-dark mr-1 font-size-xs font-weight-bolder text-right text-truncate text-dark-80"
                  >
                  <img 
                  class="mr-1 h-20px"
                  alt="calender-icon"
                  src="/media/svg/icons/custom/Icon_calendar.svg"/>
                    {moment(
                      new Date(application?.updatedAt)
                    ).format("MMMM DD, YYYY")}
                  </a>
                </div>
              </div>
            </div>
          </div>
          </div>
         
        </div>
        <div class="card-body px-0 d-flex flex-row justify-content-center">
          {/* <div class="row col-12"> */}
            <div class="d-flex flex-column w-40 justify-content-center align-items-center" style={{border:'1px solid',color: '#44DFCC', borderRadius:'5px'}}>
              {/* <div class = "w-100 d-flex justify-content-center"> */}
              <img
                  src="/media/svg/icons/General/Approval.svg"
                  alt="approval-icon"
                />
                <h3 class="font-weight-bolder my-4 text-dark card-title align-items-center flex-column">
                  <span
                  class={`card-label font-size-lg font-weight-SemiBold ${giftoroffer?.color}`}
                  > APPROVED
                  </span>
                </h3>
                <p style={{color: 'black'}} class="mt-0">
                  Gift/Offer will be expected to receive
                </p>
                <span>
                <a
                    href="#"
                    class="text-dark font-size-xs font-weight-bolder text-right text-truncate text-dark-75"
                  >  
                  <img 
                  class="mr-1 h-15px"
                  alt="calender-icon"
                  src="/media/svg/icons/custom/Icon_calendar.svg"/>
                  {moment(
                    new Date(application?.updatedAt)
                  ).add({days:9}).format("MMMM DD, YYYY")}
                </a>
                </span>
              {/* </div> */}
            </div>
            {/* <div class="col-md-4"> */}
              <div class="ml-5 rewards_card d-flex w-40 flex-column align-items-center" style={{borderRadius:'10px' ,maxWidth: 240}}>
                <img
                  src="/media/custom/rewards.png"
                  alt="reward-icon"
                  class="w-100"
                />
                <h5 style={{fontSize: 15, color: 'white'}}>Congrats!!</h5>
                <h6 style={{fontSize: 15, color: '#F1E44E'}}>{campaignDetails?.rewardProductDescription}</h6>
                <p style={{fontSize: 14}}>Rewards received!</p>
              </div>
            {/* </div> */}
          {/* </div> */}
        </div>
      </div> : null
        )}
      </>
      );
    };
    
export default memo(GiftorOfferDetails);
