import * as requestFromServer from "./rewardCrud";
import { rewardSlice, callTypes } from "./rewardSlice";
import { Toaster } from "../../../components/Toaster";

const { actions } = rewardSlice;

export const getRewardAmount = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getRewardAmount(queryParams)
    .then((response) => {
      const { data: totalRewardAmount } = response.data;
      dispatch(actions.rewardAmountFetched({ totalRewardAmount }));
    })
    .catch((error) => {
      error.clientMessage = "Can't fetch Reward amount";
      dispatch(actions.rewardAmountFetched({ totalRewardAmount: 0 }));
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const addBankDetails = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addBankDetails(queryParams)
    .then((response) => {
      Toaster({ message: response?.data?.message && "Account added successfully", variant: "dark" });
      dispatch(actions.callSuccess({ callType: callTypes.action }));
    })
    .catch((error) => {
      error.clientMessage = "Can't fetch Reward amount";
      Toaster({ message: error?.response?.data?.data?.message, variant: "dark" });
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
