import axios from "axios";

export const LOGIN_URL = `${process.env.REACT_APP_API_PUBLIC_URL}/v1/creator/login/loginUser`;
export const LOGIN_OR_SIGNUP_URL = `${process.env.REACT_APP_API_PUBLIC_URL}/v1/creator/login/loginOrSignUpUser`;
export const REGISTER_URL =
  process.env.REACT_APP_API_PUBLIC_URL + "/v1/creators/signup/register";
export const SEND_PHONE_OTP =
  process.env.REACT_APP_API_PUBLIC_URL + "/v1/creators/signup/sendPhoneOtp";
export const VERIFY_URL =
  process.env.REACT_APP_API_PUBLIC_URL + "/v1/creators/signup/verifyCode";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = `${process.env.REACT_APP_API_URL}/auth/me`;
export const RENEW_ACCESS_TOKEN =
  process.env.REACT_APP_API_PUBLIC_URL + "/v1/auth/renewAccessToken";
export const CHECK_USER_FOR_FORGOT_PASSWORD_URL =
  process.env.REACT_APP_API_PUBLIC_URL +
  "v1/creators/signup/checkUserForForgetpassword";
export const UPDATE_PASSWORD_URL =
  process.env.REACT_APP_API_PUBLIC_URL + "v1/creators/signup/updateNewPassword";
export const GOOGLE_SIGN_IN_URL =
  process.env.REACT_APP_API_PUBLIC_URL +
  "/v1/creators/signup/googleSignupOrLogin";
export const SIGN_UP_REGISTRATION_OTP =
  process.env.REACT_APP_API_PUBLIC_URL +
  "/v1/creators/signup/completeRegistrationWithOtp";
export const LOG_IN_OTP =
  process.env.REACT_APP_API_PUBLIC_URL +
  "/v1/creator/login/generateOtpForLogin";
export const GET_TOKEN_DATA_FOR_WEB =
  process.env.REACT_APP_API_PUBLIC_URL + "v1/creator/login/getCreatorTokenData";

export function login(values) {
  return axios.post(LOGIN_URL, {
    // email: values?.email,
    // phoneNumber: values?.phoneNumber,
    // userPsw: values?.password,
    ...values,
    trackingPriority: 0,
    frontendAction: "creators.login.loginUser",
  });
}

export function getTokenDataForWeb(values) {
  return axios.post(GET_TOKEN_DATA_FOR_WEB, {
    ...values,
    trackingPriority: 0,
    frontendAction: "cretor.login.getCreatorTokenData",
  });
}

export function loginOrSignUp(values) {
  return axios.post(LOGIN_OR_SIGNUP_URL, {
    // email: values?.email,
    // phoneNumber: values?.phoneNumber,
    // userPsw: values?.password,
    ...values,
    trackingPriority: 0,
    frontendAction: "creators.login.loginOrSignUpUser",
  });
}

export function sendPhoneOtpForRegistration(brandRegisterObj) {
  let phoneNumber = brandRegisterObj?.phoneNumber?.replace(
    brandRegisterObj?.phoneCode,
    ""
  );
  let registerBody = {
    ...brandRegisterObj,
    trackingPriority: 1,
    frontendAction: "creators.signup.registerWithOtp",
    email: brandRegisterObj?.loginEmail,
    phoneNumber,
    countryCode: brandRegisterObj?.phoneCode,
    userPsw: brandRegisterObj?.password,
  };
  return axios.post(SEND_PHONE_OTP, registerBody);
}

export function verifyCreator(verify) {
  let verifyObj = {
    trackingPriority: 1,
    frontendAction: "creator.signup.verifyCode",
    inviteCode: verify.code,
  };
  if (
    verify.username &&
    verify.username.length === 10 &&
    !isNaN(verify.username)
  ) {
    verifyObj.phoneNumber = verify.username;
    verifyObj.countryCode = "+91";
  } else {
    verifyObj.email = verify.username;
  }
  return axios.post(VERIFY_URL, verifyObj);
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

// GET =>  renew AccessToken
export function renewAccessToken(refreshToken) {
  return axios.post(RENEW_ACCESS_TOKEN, refreshToken);
}

export function verifyUser(values) {
  console.log(values);
  return axios.post(CHECK_USER_FOR_FORGOT_PASSWORD_URL, {
    email: values?.email,
    countryCode: values?.countryCode,
    phoneNumber: values?.phoneNumber,
    trackingPriority: 0,
    frontendAction: "brand.signup.checkUserForForgetpassword",
  });
}

export function updatePassword(values) {
  console.log("params", values);
  return axios.post(UPDATE_PASSWORD_URL, {
    userId: values?.userId,
    email: values?.email,
    phoneNumber: values?.phoneNumber,
    forgotCode: values?.forgotCode,
    userPsw: values?.password,
    countryCode: values?.phoneCode,
    trackingPriority: 0,
    frontendAction: "creators.signup.updateNewPassword",
  });
}

export function googleSignInorLogin(values) {
  return axios.post(GOOGLE_SIGN_IN_URL, {
    token: values.token,
    frontendAction: "creators.google.googleSignupOrLogin",
    trackingPriority: 0,
  });
}

export function completeRegistrationWithOtp(values) {
  return axios.post(SIGN_UP_REGISTRATION_OTP, {
    frontendAction: "creators.google.googleSignupOrLogin",
    trackingPriority: 0,
    ...values,
  });
}

export function generateOtpForLogin(values) {
  return axios.post(LOG_IN_OTP, {
    frontendAction: "creator.login.generateOtpForLogin",
    trackingPriority: 0,
    ...values,
  });
}
