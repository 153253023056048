import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../app/modules/_redux/commonActions"
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { UserNotificationsDropdownV2 } from "../extras/dropdowns/notifications/UserNotificationsDropdownV2";
import NetworkStatus from "../../../../app/components/NetworkStatus";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useHistory } from "react-router-dom";
export function HeaderMobile() {
  const [view, setView] = useState(true)
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
  }, []);

  const uiService = useHtmlClassService();

  const { userDetails, unreadNotificationCounts, profileToEdit, portfolioDetails } = useSelector(
    (state) => ({
      userDetails: state.auth.user,
      unreadNotificationCounts: state.notificationModule.unreadNotificationCount,
      profileToEdit: state.common.profileToEdit,
      portfolioDetails: state.portfolio.portfolioDetails,
    }),
    shallowEqual
  );
  const layoutProps = useMemo(() => {
    return {
      headerLogo: uiService.getStickyLogo(),
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      headerMenuSelfDisplay:
        objectPath.get(uiService.config, "header.menu.self.display") === true,
      headerMobileCssClasses: uiService.getClasses("header_mobile", true),
      headerMobileAttributes: uiService.getAttributes("header_mobile"),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
    };
  }, [uiService]);

  useEffect(() => {
    dispatch(
      actions.getCreatorProfile({
        userId: userDetails?.userId,
        loggedInUserId: userDetails?.userId,
        fields: [
          "profilePic",
          "firstName",
          "lastName",
          "fullName",
          "loginEmail",
          "phoneNumber",
          "gender",
          "age",
          "creatorType",
          "aboutUser",
          "areasOfIneterst",
          "number",
          "phoneCode",
          "instagramFollowerCount",
          "youtubeSubscriptionCount",
          "instagramId",
          "youtubeId",
          "paymentAccountId",
          "dob",
          "primaryModeOfRegistration",
          "totalRating",
          "totalRatingCount"
        ],
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewSideBar = (params) => {
    dispatch(actions.viewSideBar(params));
  }
  let socialMediaInfoCount = profileToEdit?.instagramFollowerCount?.length === 0 || profileToEdit?.youtubeSubscriptionCount?.length === 0 || profileToEdit?.instagramId?.length === 0 || profileToEdit?.youtubeId?.length === 0 ? 1 : 0;
  let personalInfo = profileToEdit?.aboutUser?.length < 1 || profileToEdit?.profilePic?.length < 1 || profileToEdit?.fullName?.length < 1 || profileToEdit?.gender?.length < 1 || profileToEdit?.creatorType?.length < 1 || profileToEdit?.areasOfIneterst?.length < 1 ? 1 : 0;
  let bankInfoCount = profileToEdit?.paymentAccountId === null ? 1 : 0;
  let portfolioCount = portfolioDetails?.rows?.length < 1 ? 1 : 0;
  let totatlCountProfileInfo = portfolioCount + bankInfoCount + personalInfo + socialMediaInfoCount;
  return (
    <>
      {/*begin::Header Mobile*/}
      <div
        id="kt_header_mobile"
        className={`header-mobile align-items-center ${layoutProps.headerMobileCssClasses}`}
        {...layoutProps.headerMobileAttributes}
      >
        {/*begin::network Status*/}
        <NetworkStatus />
        {/*end::network Status*/}
        {/*begin::Logo*/}

        <Link to="/">
          <div className="d-flex justify-content-start">
            <img alt="logo" width="80" src={layoutProps.headerLogo} />
          </div>
        </Link>
        {/*end::Logo*/}

        {/*begin::Toolbar*/}
        <div className="d-flex align-items-center">

          <div class="dropdown dropdown-inline ">

            <a href="#" class="btn btn-icon btn-light-primary  ml-7 h-40px w-40px flex-shrink-0" onClick={() => {
              setView(!view)
              viewSideBar(view);
            }}>
              <span class="svg-icon svg-icon-lg">
                <img className="h-20px" src="/media/svg/icons/custom/notifications-outline.svg" />
                {totatlCountProfileInfo > 0 || unreadNotificationCounts !== 0 ? (
                  <>
                    <span className="pulse-ring"></span>
                    <span className="position-absolute badge badge-circle badge-sm badge-primary badge_user mt-3">
                      {parseInt(unreadNotificationCounts) + totatlCountProfileInfo}
                    </span>
                  </>
                ) : null}
              </span>
            </a>

          </div>
          <div class="dropdown dropdown-inline">
            <a href="#" className="btn btn-icon btn-light-primary  ml-7 h-40px w-40px flex-shrink-0" onClick={() => {
              setView(!view)
              viewSideBar(view);
            }}>
              <span className="svg-icon svg-icon-lg">
                <img className="h-20px" src="/media/svg/icons/custom/message-square.svg" />

              </span>
            </a>
          </div>







          <div className="mt-0 d-none">
            <OverlayTrigger
              placement={"bottom"}
              overlay={<Tooltip id="group-edit-tooltip">Message Inbox</Tooltip>}
            >
              <button
                // title="View CreatorInvitee"
                className="btn btn-clean btn-lg  pulse pulse-primary  btn-sm "
                onClick={() => history.push("/messagings/inbox")}
              >
                {/* <span className="svg-icon svg-icon-md svg-icon-secondary"> */}
                <i className="flaticon2-chat text-primary" />
                {/* </span> */}
              </button>
            </OverlayTrigger>
          </div>
          {/* {layoutProps.viewNotificationsDisplay && (
            <UserNotificationsDropdownV2 />
          )} */}
          <div class="symbol symbol-40 rounded symbol-light-success ml-7" >
            <span class="symbol-label"
              style={{
                backgroundImage: profileToEdit?.profilePic
                  ? "url(" +
                    `${process.env.REACT_APP_AWS_S3_BASE_URL +
                      process.env.REACT_APP_S3_USER_PROFILE_FOLDER +
                      "/" +
                      profileToEdit?.profilePic}` +
                    ")"
                  : "url(/media/dummyImages/no_preview.png)",
              }}
            onClick={() => { history.push("/user-profile/personal-information") }}>
              {/* <img src="/media/users/300_12.jpg" class="w-100 rounded" alt="" /> */}
            </span>
          </div>

          <div class="dropdown dropdown-inline ml-7">
            <button class="btn rounded-0 p-0 burger-icon burger-icon-left" id="kt_header_mobile_toggle">
              <span></span>
            </button>
          </div>
          {layoutProps.asideDisplay && (
            <>
              {/*begin::Aside Mobile Toggle*/}
              <button
                className="btn d-none p-0 burger-icon burger-icon-left"
                id="kt_aside_mobile_toggle"
              >
                <span />
              </button>
              {/*end::Aside Mobile Toggle*/}
            </>
          )}
          {/* {layoutProps.headerMenuSelfDisplay && ( */}
          <>
            {/*begin::Header Menu Mobile Toggle*/}
            {/* <button
                className="btn p-0 burger-icon ml-4"
                id="kt_header_mobile_toggle"
              >
                <span />
              </button> */}
            {/*end::Header Menu Mobile Toggle*/}
          </>
          {/* )} */}

          {/*begin::Topbar Mobile Toggle*/}
          <button
            className="btn btn-hover-text-primary d-none p-0 ml-2"
            id="kt_header_mobile_topbar_toggle"
          >
            <span className="svg-icon svg-icon-xl">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
          </button>
          {/*end::Topbar Mobile Toggle*/}
        </div>
        {/*end::Toolbar*/}
      </div>
      {/*end::Header Mobile*/}
    </>
  );
}
