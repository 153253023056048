/* eslint-disable jsx-a11y/alt-text */
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getApplicationDetails } from "../../../campaign/_redux/campaignActions";
import {
  createContent,
  listApplicationContents,
  submitForApproval,
} from "../../../campaignApplications/_redux/applicationActions";
import { getSignedUrl, uploadAllFileToS3 } from "../../../_redux/commonActions";
import { GalleryCard } from "../GalleryCard";
import useFileDownloader from "../../../../components/file-downloader/useFileDownloader";
import {
  getConvertedContentStatus,
  initialFiles,
  getWatermarkImage,
  getCompressImage,
  getHighCompressImage,
  getConvertedRejectReason,
  campaignType,
  getMediaPreview,
  getFileTypeFromExtension,
} from "../../helper/helper";
import { commonSlice, callTypes } from "../../../_redux/commonSlice";
import moment from "moment";
import CommonModal from "../../../CampaignDetails/components/CommonModal";
import {
  getTotalMediaContentRequest,
} from "../../../CampaignDetails/helper/helper"

const { actions } = commonSlice;

const ContentDetails = ({ stepper }) => {
  // const [mediaCount, setMediaCount] = useState({});
  const [clickedButton, setClickedButton] = useState();
  const [reload, setReload] = useState(false);
  const [files, setFiles] = useState(initialFiles);
  const [modal, setModal] = useState({ status: false, content: null });
  const [downloadFile, downloaderComponentUI] = useFileDownloader();
  const download = (file) => downloadFile(file);
  const dispatch = useDispatch();

  const {
    loggedInUserId,
    listLoading,
    actionsLoading,
    signedLoading,
    campaignDetails,
    applicationDetails,
    mediaCount,
    applicationContents,
    contentHistory,
  } = useSelector(
    (state) => ({
      loggedInUserId: state.auth.user?.userId,
      listLoading: state.application.listLoading,
      actionsLoading: state.application.actionsLoading,
      signedLoading: state.common.actionsLoading,
      campaignDetails: state.campaign.campaignDetails,
      mediaCount: state.campaign.mediaCount,
      applicationDetails: state.campaign.applicationDetails[0] || {},
      applicationContents: state.application.applicationContents,
      contentHistory: state.application.contentHistory,
    }),
    shallowEqual
  );

  const getModalHtml = (type, file) => {
    return type === "image" ? (
      <img
        className="p-2"
        alt="content-img"
        // height="500px"
        // width="500px"
        style={{ height: 500, objectFit: "contain" }}
        src={
          process.env.REACT_APP_AWS_S3_BASE_URL +
          file +
          "_compressedWatermarkImage"
        }
      />
    ) : (
      <video
        className="iframe-resize w-100 h-auto"
        src={process.env.REACT_APP_AWS_S3_BASE_URL + file}
        frameBorder={0}
        allowFullScreen
        controls
        controlsList="nodownload"
      />
    );
  };

  const uploadContent = async (
    file,
    postType,
    postImageStyle,
    postVideoStyle
  ) => {
    let typeOfImageArray = [
      "_compressedImage",
      "_compressedWatermarkImage",
      "_thumbnailImage",
      "_watermarkedThumbnailImage",
    ];

    let SignedUrlObject = {
      file_name:
        new Date().getTime() +
        "_" +
        file?.name.replace(
          // eslint-disable-next-line no-control-regex
          new RegExp(" ", "g"),
          "_"
        ),
      file_type: file?.type,
      folder:
        "application_contents" +
        "/" +
        applicationDetails?.creatorId +
        "/" +
        applicationDetails?.campaignId,
      loggedInUserId,
      file: file,
    };

    let contentObj = {
      applicationId: applicationDetails?.applicationId,
      campaignId: applicationDetails?.campaignId,
      brandId: applicationDetails?.brandId,
      creatorId: applicationDetails?.creatorId,
      campaignType: campaignDetails?.campaignType,
      loggedInUserId,
      contentMediaUrl:
        SignedUrlObject?.folder + "/" + SignedUrlObject?.file_name,
      postType,
      postImageStyle,
      postVideoStyle,
      contentPlatform: campaignDetails?.usedInPlatform,
      resubmitCount: Number(applicationDetails?.resubmitCount),
    };

    if (
      postType === "VIDEO_POST" ||
      file?.type?.split("/")[0] === "application"
    ) {
      dispatch(getSignedUrl(SignedUrlObject)).then(() => {
        dispatch(createContent(contentObj)).then(() => {
          setFiles(initialFiles);
          dispatch(
            listApplicationContents({
              page: 1,
              pageSize: 20,
              loggedInUserId,
              sort: "applicationContentId",
              query: {
                resubmitCount: applicationDetails?.resubmitCount,
                campaignId: applicationDetails?.campaignId,
                creatorId: loggedInUserId,
              },
            })
          );
        });
      });
    } else {
      dispatch(actions.startCall({ callType: callTypes.action }));
      let promiseArray = [];

      const signedUpload = (obj) => {
        return new Promise((resolve, reject) => {
          dispatch(uploadAllFileToS3(obj))
            .then((res) => resolve(res))
            .catch((err) => reject(err));
        });
      };

      let watermarkImage = await getWatermarkImage(file);
      let compressedImage = await getCompressImage(file);
      let compressedWatermarkImage = await getCompressImage(watermarkImage);
      let thumbnailImage = await getHighCompressImage(compressedImage);
      let watermarkedThumbnailImage = await getHighCompressImage(
        compressedWatermarkImage
      );

      let imgObj = {
        _compressedImage: compressedImage,
        _compressedWatermarkImage: compressedWatermarkImage,
        _thumbnailImage: thumbnailImage,
        _watermarkedThumbnailImage: watermarkedThumbnailImage,
      };

      // eslint-disable-next-line no-unused-expressions
      typeOfImageArray?.map((item, index) => {
        if (index === 0) {
          promiseArray.push(signedUpload(SignedUrlObject));
        }

        let obj = {
          ...SignedUrlObject,
        };
        obj.file_name = obj.file_name + item;
        obj.file_type = obj.file_type + item;
        obj.file = imgObj[item];
        promiseArray.push(signedUpload(obj));
      });

      Promise.all(promiseArray)
        .then(() => {
          dispatch(actions.callSuccess({ callType: callTypes.action }));
          dispatch(createContent(contentObj)).then(() => {
            setFiles(initialFiles);
            dispatch(
              listApplicationContents({
                page: 1,
                pageSize: 20,
                loggedInUserId,
                sort: "applicationContentId",
                query: {
                  resubmitCount: applicationDetails?.resubmitCount,
                  campaignId: applicationDetails?.campaignId,
                  creatorId: loggedInUserId,
                },
              })
            );
          });
        })
        .catch(() =>
          dispatch(actions.catchError({ callType: callTypes.action }))
        );
    }
  };

  const submitAllContentForApproval = () => {
    let contentIds = applicationContents?.map((item) => {
      if (item.contentStatus === "DRAFT") return item.applicationContentId;
    });

    dispatch(
      submitForApproval({
        loggedInUserId,
        applicationContentId: contentIds,
        applicationId: applicationDetails?.applicationId,
      })
    ).then(() =>
      dispatch(
        getApplicationDetails({
          page: 1,
          pageSize: 5,
          loggedInUserId,
          populate: ["brandDetail", "paymentDetails"],
          query: {
            campaignId: applicationDetails?.campaignId,
            creatorId: loggedInUserId,
          },
        })
      )
    );
  };

  useEffect(() => {
    Object.keys(applicationDetails)?.length > 0 &&
      (stepper === "contentDetails" || stepper === "urlDetails") &&
      dispatch(
        listApplicationContents({
          page: 1,
          pageSize: 20,
          loggedInUserId,
          sort: "applicationContentId",
          query: {
            resubmitCount: applicationDetails?.resubmitCount,
            campaignId: applicationDetails?.campaignId,
            creatorId: loggedInUserId,
          },
        })
      );

    // campaignDetails && setMediaCount(getReferenceMediaCount(campaignDetails));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationDetails, reload, stepper]);

  return (
    <div
      class={`wizard-body py-5 px-8 py-lg-5 px-lg-10  ${
        stepper === "contentDetails" ? "" : "d-none"
      }`}
    >
      <div class="row">
        {/* <div class="w-100 d-flex flex-nowrap justify-content-between mb-3"> */}
        {/* <h6 class="font-weight-Bold mb-0 d-flex align-items-center"> */}
        {/* {campaignType(campaignDetails?.campaignType)} */}
        {/* <span class="label label-inline label-light-success ml-3">
              Reword
            </span> */}
        {/* </h6> */}
        {/* <div class="btn btn-sm bg-light p-2">
            <img
              class=" h-15px "
              src="/media/svg/icons/custom/Icon_download.svg"
            />
          </div> */}
        {/* </div> */}
        {contentHistory?.length > 0 && (
          <div class="content_list active col-12 px-0 mb-5 border-bottom">
            {/* <div class="row mx-0 "> */}
            <div className="row mx-0">
              <p class="text-warning">
                {/* {getConvertedContentStatus(history?.contentStatus)}{" "} */}
                Rework History
              </p>
            </div>
            {contentHistory?.map((history, index) => (
              <div className="row mx-0 d-flex justify-content-between">
                <p class="text-dark">
                  {applicationDetails?.resubmitReason?.length > 0 &&
                    applicationDetails?.resubmitReason[index]}
                </p>

                <span class="text-dark font-size-xs pt-2 pl-2 ml-3">
                  <img
                    class="mr-1 h-10px mt-n1"
                    src="/media/svg/icons/custom/Icon-calendar.svg"
                  />{" "}
                  {moment(new Date(history?.updatedAt)).format("MMMM DD, YYYY")}
                </span>
              </div>
            ))}
            {/* </div> */}
          </div>
        )}

        {!["DRAFT", "RESUBMIT_CONTENT"].includes(
          applicationDetails?.contentStatus
        ) ? (
          <div class="content_list active col-12 px-0 mb-5 border-bottom">
            {listLoading && (
              <div className="d-flex justify-content-center align-item-center my-10 mx-auto">
                <div className="spinner spinner-lg spinner-success" />
              </div>
            )}
            <div class="row mt-4">
              {applicationContents?.map((content) => (
                <div class="col-6 col-md-4">
                  <div
                    class="d_card d_gallery_view w-100 d-flex align-content-between flex-wrap cursor-pointer"
                    style={{
                      backgroundSize: "cover",
                      backgroundImage: content?.contentMediaUrl
                        ? getMediaPreview(content)
                        : // "url(" +
                          //   `${process.env.REACT_APP_AWS_S3_BASE_URL +
                          //     content?.contentMediaUrl}` +
                          //   ")"
                          "url(/media/dummyImages/no_preview_v2.png)",
                    }}
                  />
                  <div class="mx-0 row py-3 d-flex justify-content-between">
                    <span
                      class="btn btn-sm bg-light p-2 w-30px"
                      // onClick={() =>
                      //   window.open(
                      //     `${process.env.REACT_APP_AWS_S3_BASE_URL +
                      //       content?.contentMediaUrl}`
                      //   )
                      // }
                      onClick={() => {
                        const fileType = getFileTypeFromExtension(
                          content?.contentMediaUrl
                        );
                        const file = content?.contentMediaUrl;
                        if (fileType === "document") {
                          window.open(
                            process.env.REACT_APP_AWS_S3_BASE_URL + file
                          );
                        } else {
                          setModal({
                            status: true,
                            content: getModalHtml(fileType, file),
                          });
                        }
                      }}
                    >
                      <img
                        class=" h-15px "
                        src="/media/svg/icons/custom/icon_view.svg"
                      />
                    </span>
                    <span
                      class="btn btn-sm bg-light p-2 w-30px"
                      onClick={() =>
                        download({
                          file:
                            process.env.REACT_APP_AWS_S3_BASE_URL +
                            content.contentMediaUrl,
                          filename:
                            content.campaignType +
                            "_" +
                            Math.floor(Math.random() * 1000000 + 1),
                        })
                      }
                    >
                      <img
                        class=" h-15px "
                        src="/media/svg/icons/custom/Icon_download.svg"
                      />
                    </span>
                    {/* <span class="btn btn-sm bg-light p-2 w-30px">
                      <img
                        class=" h-15px "
                        src="/media/svg/icons/custom/Icon_delete.svg"
                      />
                    </span> */}
                  </div>
                </div>
              ))}
            </div>
            <div class="row mx-0">
              <p
                class={
                  applicationDetails?.contentStatus === "APPROVED_BY_BRAND"
                    ? "text-success"
                    : applicationDetails?.contentStatus === "REJECTED_BY_BRAND"
                    ? "text-danger"
                    : "text-warning"
                }
              >
                {getConvertedContentStatus(applicationDetails?.contentStatus)}{" "}
                <span class="text-dark font-size-xs pt-2 pl-2">
                  <img
                    class="mr-1 h-10px mt-n1"
                    src="/media/svg/icons/custom/Icon-calendar.svg"
                  />{" "}
                  {moment(new Date(applicationDetails?.updatedAt)).format(
                    "MMMM DD, YYYY"
                  )}
                </span>
              </p>
            </div>
            <div className="row mx-0">
              <p>
                {/* <span class="text-success">Congrats your work approved!</span>{" "} */}
                {applicationDetails?.rejectedReason
                  ? getConvertedRejectReason(applicationDetails?.rejectedReason)
                  : ""}
              </p>
            </div>

            {/* <div class="col-12 row mx-0 px-0 mb-6">
            <div class="col-9">
              <div class="input-icon input-icon-right custom-file col-12 w-100">
                <input
                  type="file"
                  class="custom-file-input"
                  placeholder="Upload your file"
                  id="inputfileupload"
                />
                <label class="custom-file-label" for="inputfileupload">
                  Choose file
                </label>
                <span>
                  <img
                    class="mr-1 h-10px mt-n1"
                    src="/media/svg/icons/custom/Icon_upload.svg"
                  />
                </span>
              </div>
            </div>
            <button class="btn btn-sm btn-primary col-3">
              <span>
                <b>Upload</b>
              </span>
            </button>
          </div> */}
          </div>
        ) : (
          <div class="content_list active col-12 px-0  border-bottom">
            {/* <div class="col-12 mx-0 px-0">
            <div class="d-flex d-flex align-items-center py-4">
              <div class="flex-grow-1">
                <div class="d-flex align-items-center mb-0">
                  <div class="symbol symbol-50 rounded symbol-light-success mr-4">
                    <span class="symbol-label"></span>
                  </div>
                  <div
                    class="d-flex flex-column flex-grow-1 font-weight-bold"
                    onClick={() => setShowDetailedContent(!showDetailedContent)}
                  >
                    <a
                      // href="#"
                      class="text-dark mb-0 font-size-lg text-truncate font-weight-SemiBold"
                    >
                      Innovation Incubator
                      <span class="label label-inline label-light-success ml-3">
                        Reword
                      </span>
                    </a>
                    <span class="text-dark font-size-xs pt-2">
                      <img
                        class="mr-1 h-10px mt-n1"
                        src="/media/svg/icons/custom/Icon-calendar.svg"
                      />{" "}
                      <span class="font-weight-SemiBold opacity-50 font-size-sm">
                        <span>31 Jul 2022</span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              <div
                class="flex-shrink-0"
                onClick={() => setShowDetailedContent(!showDetailedContent)}
              >
                <img
                  class={`${showDetailedContent ? "content_list_active" : ""}`}
                  src="/media/svg/icons/custom/arrow_btn.svg"
                />
              </div>
            </div>
          </div> */}

            <div class="col-12 row px-0 mt-5">
              <div class="col-md-12">
                <h6 class="font-weight-Bold font-size-lg mb-6">
                  Upload your work below
                </h6>
                {/* <<<<<<<<<<<<<<<<<<<URL Content >>>>>>>>>>>>>>>>>>>>>>> */}
                {campaignDetails?.styleSelectionProductPhotographyCount !==
                  "0" && (
                  <div class="form-group input-group">
                    <label>
                      URL Content -{" "}
                      {campaignDetails?.styleSelectionProductPhotographyCount}
                    </label>
                    {applicationContents
                      ?.filter(
                        (content) =>
                          content.postImageStyle === "PRODUCT_PHOTOGRAPHY"
                      )
                      .map((content) => (
                        <GalleryCard
                          content={content}
                          loggedInUserId={loggedInUserId}
                          actionsLoading={actionsLoading}
                          setReload={() => {
                            setFiles({ ...initialFiles });
                            setReload(!reload);
                          }}
                        />
                      ))}
                    {[
                      ...Array(
                        Number(
                          campaignDetails?.styleSelectionProductPhotographyCount
                        ) -
                          applicationContents?.filter(
                            (content) =>
                              content.postImageStyle === "PRODUCT_PHOTOGRAPHY"
                          )?.length || 0
                      ),
                    ]?.map((item, index) => (
                      <>
                        <div class="input-icon input-icon-right custom-file col-12 w-100">
                          <input
                            type="file"
                            class="custom-file-input"
                            accept="image/*,application/*"
                            placeholder="Upload your file"
                            id="inputfileupload"
                            onChange={(e) =>
                              setFiles({
                                ...files,
                                productPhotographyFiles: {
                                  ...setFiles.productPhotographyFiles,
                                  [index]: e.currentTarget.files[0],
                                },
                              })
                            }
                          />
                          <label
                            class="custom-file-label"
                            for="inputfileupload"
                          >
                            {files?.productPhotographyFiles &&
                            Object.keys(
                              files?.productPhotographyFiles
                            )?.includes(`${index}`)
                              ? files?.productPhotographyFiles[index]?.name
                              : "Choose file"}
                          </label>
                          <span>
                            <img
                              class="mr-1 h-10px mt-n1"
                              src="/media/svg/icons/custom/Icon_upload.svg"
                            />
                          </span>
                        </div>
                        <button
                          class="btn btn-sm btn-primary mt-4"
                          disabled={
                            listLoading ||
                            actionsLoading ||
                            signedLoading ||
                            (Object.keys(files?.productPhotographyFiles)
                              ?.length === 0
                              ? true
                              : !files?.productPhotographyFiles?.hasOwnProperty(
                                  index
                                ))
                          }
                          onClick={() => {
                            setClickedButton(`productPhotographyFiles${index}`);
                            files?.productPhotographyFiles[index] &&
                              uploadContent(
                                files?.productPhotographyFiles[index],
                                "IMAGE_POST",
                                "PRODUCT_PHOTOGRAPHY",
                                null
                              );
                          }}
                        >
                          {clickedButton ===
                            `productPhotographyFiles${index}` &&
                          (actionsLoading || signedLoading) ? (
                            <div class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          ) : (
                            <span>
                              <b>Upload</b>
                            </span>
                          )}
                        </button>
                      </>
                    ))}
                  </div>
                )}

                {/* <<<<<<<<<<<<<<<<<<<<<<<<<Document Content>>>>>>>>>>>>> */}
                {campaignDetails?.styleSelectionPortraitStylePhotographyCount !==
                  "0" && (
                  <div class="form-group input-group">
                    <label>
                      Document Content -{" "}
                      {
                        campaignDetails?.styleSelectionPortraitStylePhotographyCount
                      }
                    </label>
                    {applicationContents
                      ?.filter(
                        (content) =>
                          content.postImageStyle ===
                          "PORTRAIT_STYLE_PHOTOGRAPHY"
                      )
                      .map((content) => (
                        <GalleryCard
                          content={content}
                          loggedInUserId={loggedInUserId}
                          actionsLoading={actionsLoading}
                          setReload={() => {
                            setFiles({ ...initialFiles });
                            setReload(!reload);
                          }}
                        />
                      ))}
                    {[
                      ...Array(
                        Number(
                          campaignDetails?.styleSelectionPortraitStylePhotographyCount
                        ) -
                          applicationContents?.filter(
                            (content) =>
                              content.postImageStyle ===
                              "PORTRAIT_STYLE_PHOTOGRAPHY"
                          )?.length || 0
                      ),
                    ]?.map((item, index) => (
                      <>
                        <div class="input-icon input-icon-right custom-file col-12 w-100">
                          <input
                            type="file"
                            accept="image/*,application/*"
                            class="custom-file-input"
                            placeholder="Upload your file"
                            id="inputfileupload"
                            onChange={(e) =>
                              setFiles({
                                ...files,
                                portraitStylePhotographyFiles: {
                                  ...setFiles.portraitStylePhotographyFiles,
                                  [index]: e.currentTarget.files[0],
                                },
                              })
                            }
                          />
                          <label
                            class="custom-file-label"
                            for="inputfileupload"
                          >
                            {files?.portraitStylePhotographyFiles &&
                            Object.keys(
                              files?.portraitStylePhotographyFiles
                            )?.includes(`${index}`)
                              ? files?.portraitStylePhotographyFiles[index]
                                  ?.name
                              : "Choose file"}
                          </label>
                          <span>
                            <img
                              class="mr-1 h-10px mt-n1"
                              src="/media/svg/icons/custom/Icon_upload.svg"
                            />
                          </span>
                        </div>
                        <button
                          class="btn btn-sm btn-primary mt-4"
                          disabled={
                            listLoading ||
                            actionsLoading ||
                            signedLoading ||
                            (Object.keys(files?.portraitStylePhotographyFiles)
                              ?.length === 0
                              ? true
                              : !files?.portraitStylePhotographyFiles?.hasOwnProperty(
                                  index
                                ))
                          }
                          onClick={() => {
                            setClickedButton(
                              `portraitStylePhotographyFiles${index}`
                            );
                            files?.portraitStylePhotographyFiles[index] &&
                              uploadContent(
                                files?.portraitStylePhotographyFiles[index],
                                "IMAGE_POST",
                                "PORTRAIT_STYLE_PHOTOGRAPHY",
                                null
                              );
                          }}
                        >
                          {clickedButton ===
                            `portraitStylePhotographyFiles${index}` &&
                          (actionsLoading || signedLoading) ? (
                            <div class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          ) : (
                            <span>
                              <b>Upload</b>
                            </span>
                          )}
                        </button>
                      </>
                    ))}
                  </div>
                )}

                {/* <<<<<<<<<<<<<<<<<<<<<<<<Life style Production In Action>>>>>>>>>>>>>>*/}
                {campaignDetails?.styleSelectionLifestyleProductInActionCount !==
                  "0" && (
                  <div class="form-group input-group">
                    <label>
                      Audio Content -{" "}
                      {
                        campaignDetails?.styleSelectionLifestyleProductInActionCount
                      }
                    </label>
                    {applicationContents
                      ?.filter(
                        (content) =>
                          content.postImageStyle ===
                          "LIFESTYLE_PRODUCT_IN_ACTION"
                      )
                      .map((content) => (
                        <GalleryCard
                          content={content}
                          loggedInUserId={loggedInUserId}
                          actionsLoading={actionsLoading}
                          setReload={() => {
                            setFiles({ ...initialFiles });
                            setReload(!reload);
                          }}
                        />
                      ))}
                    {[
                      ...Array(
                        Number(
                          campaignDetails?.styleSelectionLifestyleProductInActionCount
                        ) -
                          applicationContents?.filter(
                            (content) =>
                              content.postImageStyle ===
                              "LIFESTYLE_PRODUCT_IN_ACTION"
                          )?.length || 0
                      ),
                    ]?.map((item, index) => (
                      <>
                        <div class="input-icon input-icon-right custom-file col-12 w-100">
                          <input
                            type="file"
                            accept="image/*,application/*"
                            class="custom-file-input"
                            placeholder="Upload your file"
                            id="inputfileupload"
                            onChange={(e) =>
                              setFiles({
                                ...files,
                                lifestyleProductInActionFiles: {
                                  ...setFiles.lifestyleProductInActionFiles,
                                  [index]: e.currentTarget.files[0],
                                },
                              })
                            }
                          />
                          <label
                            class="custom-file-label"
                            for="inputfileupload"
                          >
                            {files?.lifestyleProductInActionFiles &&
                            Object.keys(
                              files?.lifestyleProductInActionFiles
                            )?.includes(`${index}`)
                              ? files?.lifestyleProductInActionFiles[index]
                                  ?.name
                              : "Choose file"}
                          </label>
                          <span>
                            <img
                              class="mr-1 h-10px mt-n1"
                              src="/media/svg/icons/custom/Icon_upload.svg"
                            />
                          </span>
                        </div>
                        <button
                          class="btn btn-sm btn-primary mt-4"
                          disabled={
                            listLoading ||
                            actionsLoading ||
                            signedLoading ||
                            (Object.keys(files?.lifestyleProductInActionFiles)
                              ?.length === 0
                              ? true
                              : !files?.lifestyleProductInActionFiles?.hasOwnProperty(
                                  index
                                ))
                          }
                          onClick={() => {
                            setClickedButton(
                              `lifestyleProductInActionFiles${index}`
                            );

                            files?.lifestyleProductInActionFiles[index] &&
                              uploadContent(
                                files?.lifestyleProductInActionFiles[index],
                                "IMAGE_POST",
                                "LIFESTYLE_PRODUCT_IN_ACTION",
                                null
                              );
                          }}
                        >
                          {clickedButton ===
                            `lifestyleProductInActionFiles${index}` &&
                          (actionsLoading || signedLoading) ? (
                            <div class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          ) : (
                            <span>
                              <b>Upload</b>
                            </span>
                          )}
                        </button>
                      </>
                    ))}
                  </div>
                )}

                {/* <<<<<<<<<<<<<<<<<<<<<<<<Image Content>>>>>>>>>>>>>>>>>>>>>>>> */}
                {campaignDetails?.styleSelectionGraphicDesignCount !== "0" && (
                  <div class="form-group input-group">
                    <label>
                      Image Content -{" "}
                      {campaignDetails?.styleSelectionGraphicDesignCount}
                    </label>
                    {applicationContents
                      ?.filter(
                        (content) => content.postImageStyle === "GRAPHIC_DESIGN"
                      )
                      .map((content) => (
                        <GalleryCard
                          content={content}
                          loggedInUserId={loggedInUserId}
                          actionsLoading={actionsLoading}
                          setReload={() => {
                            setFiles({ ...initialFiles });
                            setReload(!reload);
                          }}
                        />
                      ))}
                    {[
                      ...Array(
                        Number(
                          campaignDetails?.styleSelectionGraphicDesignCount
                        ) -
                          applicationContents?.filter(
                            (content) =>
                              content.postImageStyle === "GRAPHIC_DESIGN"
                          )?.length || 0
                      ),
                    ].map((item, index) => (
                      <>
                        <div class="input-icon input-icon-right custom-file col-12 w-100">
                          <input
                            type="file"
                            accept="image/*,application/*"
                            class="custom-file-input"
                            placeholder="Upload your file"
                            id="inputfileupload"
                            onChange={(e) =>
                              setFiles({
                                ...files,
                                graphicDesignFiles: {
                                  ...setFiles.graphicDesignFiles,
                                  [index]: e.currentTarget.files[0],
                                },
                              })
                            }
                          />
                          <label
                            class="custom-file-label"
                            for="inputfileupload"
                          >
                            {files?.graphicDesignFiles &&
                            Object.keys(files?.graphicDesignFiles)?.includes(
                              `${index}`
                            )
                              ? files?.graphicDesignFiles[index]?.name
                              : "Choose file"}
                          </label>
                          <span>
                            <img
                              class="mr-1 h-10px mt-n1"
                              src="/media/svg/icons/custom/Icon_upload.svg"
                            />
                          </span>
                        </div>
                        <button
                          class="btn btn-sm btn-primary mt-4"
                          disabled={
                            listLoading ||
                            actionsLoading ||
                            signedLoading ||
                            (Object.keys(files?.graphicDesignFiles)?.length ===
                            0
                              ? true
                              : !files?.graphicDesignFiles?.hasOwnProperty(
                                  index
                                ))
                          }
                          onClick={() => {
                            setClickedButton(`graphicDesignFiles${index}`);

                            files?.graphicDesignFiles[index] &&
                              uploadContent(
                                files?.graphicDesignFiles[index],
                                "IMAGE_POST",
                                "GRAPHIC_DESIGN",
                                null
                              );
                          }}
                        >
                          {clickedButton === `graphicDesignFiles${index}` &&
                          (actionsLoading || signedLoading) ? (
                            <div class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          ) : (
                            <span>
                              <b>Upload</b>
                            </span>
                          )}
                        </button>
                      </>
                    ))}
                  </div>
                )}

                {/* <<<<<<<<<<<<<<<<<<<<<<<<Action Product In Use>>>>>>>>>>>>>>>>>>>>>>>> */}
                {campaignDetails?.styleSelectionActionProductInUseCount !==
                  "0" && (
                  <div class="form-group input-group">
                    <label>
                      Video Content -{" "}
                      {campaignDetails?.styleSelectionActionProductInUseCount}
                    </label>
                    {applicationContents
                      ?.filter(
                        (content) =>
                          content.postVideoStyle ===
                          "ACTION_PRODUCT_IN_USE_VIDEO"
                      )
                      .map((content) => (
                        <GalleryCard
                          content={content}
                          loggedInUserId={loggedInUserId}
                          actionsLoading={actionsLoading}
                          setReload={() => {
                            setFiles({ ...initialFiles });
                            setReload(!reload);
                          }}
                        />
                      ))}
                    {[
                      ...Array(
                        Number(
                          campaignDetails?.styleSelectionActionProductInUseCount
                        ) -
                          applicationContents?.filter(
                            (content) =>
                              content.postVideoStyle ===
                              "ACTION_PRODUCT_IN_USE_VIDEO"
                          )?.length || 0
                      ),
                    ]?.map((item, index) => (
                      <>
                        <div class="input-icon input-icon-right custom-file col-12 w-100">
                          <input
                            type="file"
                            accept="video/mp4,video/x-m4v,video/*"
                            class="custom-file-input"
                            placeholder="Upload your file"
                            id="inputfileupload"
                            onChange={(e) =>
                              setFiles({
                                ...files,
                                actionProductInUseFiles: {
                                  ...setFiles.actionProductInUseFiles,
                                  [index]: e.currentTarget.files[0],
                                },
                              })
                            }
                          />
                          <label
                            class="custom-file-label"
                            for="inputfileupload"
                          >
                            {files?.actionProductInUseFiles &&
                            Object.keys(
                              files?.actionProductInUseFiles
                            )?.includes(`${index}`)
                              ? files?.actionProductInUseFiles[index]?.name
                              : "Choose file"}
                          </label>
                          <span>
                            <img
                              class="mr-1 h-10px mt-n1"
                              src="/media/svg/icons/custom/Icon_upload.svg"
                            />
                          </span>
                        </div>
                        <button
                          class="btn btn-sm btn-primary mt-4"
                          disabled={
                            listLoading ||
                            actionsLoading ||
                            signedLoading ||
                            (Object.keys(files?.actionProductInUseFiles)
                              ?.length === 0
                              ? true
                              : !files?.actionProductInUseFiles?.hasOwnProperty(
                                  index
                                ))
                          }
                          onClick={() => {
                            setClickedButton(`actionProductInUseFiles${index}`);

                            files?.actionProductInUseFiles[index] &&
                              uploadContent(
                                files?.actionProductInUseFiles[index],
                                "VIDEO_POST",
                                null,
                                "ACTION_PRODUCT_IN_USE_VIDEO"
                              );
                          }}
                        >
                          {clickedButton ===
                            `actionProductInUseFiles${index}` &&
                          (actionsLoading || signedLoading) ? (
                            <div class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          ) : (
                            <span>
                              <b>Upload</b>
                            </span>
                          )}
                        </button>
                      </>
                    ))}
                  </div>
                )}

                {/* <<<<<<<<<<<<<<<<<<<<<<<<Product Review Testimonial Video>>>>>>>>>>>>>>>>>>>>>>>> */}
                {campaignDetails?.styleSelectionProductReviewTestimonialVideoCount !==
                  "0" && (
                  <div class="form-group input-group">
                    <label>
                      Product Review Testimonial Video -{" "}
                      {
                        campaignDetails?.styleSelectionProductReviewTestimonialVideoCount
                      }
                    </label>
                    {applicationContents
                      ?.filter(
                        (content) =>
                          content.postVideoStyle ===
                          "PRODUCT_REVIEW_TESTIMONIAL_VIDEO"
                      )
                      .map((content) => (
                        <GalleryCard
                          content={content}
                          loggedInUserId={loggedInUserId}
                          actionsLoading={actionsLoading}
                          setReload={() => {
                            setFiles({ ...initialFiles });
                            setReload(!reload);
                          }}
                        />
                      ))}
                    {[
                      ...Array(
                        Number(
                          campaignDetails?.styleSelectionProductReviewTestimonialVideoCount
                        ) -
                          applicationContents?.filter(
                            (content) =>
                              content.postVideoStyle ===
                              "PRODUCT_REVIEW_TESTIMONIAL_VIDEO"
                          )?.length || 0
                      ),
                    ]?.map((item, index) => (
                      <>
                        <div class="input-icon input-icon-right custom-file col-12 w-100">
                          <input
                            type="file"
                            accept="video/mp4,video/x-m4v,video/*"
                            class="custom-file-input"
                            placeholder="Upload your file"
                            id="inputfileupload"
                            onChange={(e) =>
                              setFiles({
                                ...files,
                                productReviewTestimonialVideoFiles: {
                                  ...setFiles.productReviewTestimonialVideoFiles,
                                  [index]: e.currentTarget.files[0],
                                },
                              })
                            }
                          />
                          <label
                            class="custom-file-label"
                            for="inputfileupload"
                          >
                            {files?.productReviewTestimonialVideoFiles &&
                            Object.keys(
                              files?.productReviewTestimonialVideoFiles
                            )?.includes(`${index}`)
                              ? files?.productReviewTestimonialVideoFiles[index]
                                  ?.name
                              : "Choose file"}
                          </label>
                          <span>
                            <img
                              class="mr-1 h-10px mt-n1"
                              src="/media/svg/icons/custom/Icon_upload.svg"
                            />
                          </span>
                        </div>
                        <button
                          class="btn btn-sm btn-primary mt-4"
                          disabled={
                            listLoading ||
                            actionsLoading ||
                            signedLoading ||
                            (Object.keys(
                              files?.productReviewTestimonialVideoFiles
                            )?.length === 0
                              ? true
                              : !files?.productReviewTestimonialVideoFiles?.hasOwnProperty(
                                  index
                                ))
                          }
                          onClick={() => {
                            setClickedButton(
                              `productReviewTestimonialVideoFiles${index}`
                            );

                            files?.productReviewTestimonialVideoFiles[index] &&
                              uploadContent(
                                files?.productReviewTestimonialVideoFiles[
                                  index
                                ],
                                "VIDEO_POST",
                                null,
                                "PRODUCT_REVIEW_TESTIMONIAL_VIDEO"
                              );
                          }}
                        >
                          {clickedButton ===
                            `productReviewTestimonialVideoFiles${index}` &&
                          (actionsLoading || signedLoading) ? (
                            <div class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          ) : (
                            <span>
                              <b>Upload</b>
                            </span>
                          )}
                        </button>
                      </>
                    ))}
                  </div>
                )}

                {/* <<<<<<<<<<<<<<<<<<<<<<<<Other Files>>>>>>>>>>>>>>>>>>>>>>>> */}

                {campaignDetails?.styleSelectionPromotionalVideoCount !==
                  "0" && (
                  <div class="form-group input-group">
                    <label>
                      Other Files -{" "}
                      {campaignDetails?.styleSelectionPromotionalVideoCount}
                    </label>
                    {applicationContents
                      ?.filter(
                        (content) =>
                          content.postVideoStyle === "PROMOTIONAL_VIDEO"
                      )
                      .map((content) => (
                        <GalleryCard
                          content={content}
                          loggedInUserId={loggedInUserId}
                          actionsLoading={actionsLoading}
                          setReload={() => {
                            setFiles({ ...initialFiles });
                            setReload(!reload);
                          }}
                        />
                      ))}
                    {[
                      ...Array(
                        Number(
                          campaignDetails?.styleSelectionPromotionalVideoCount
                        ) -
                          applicationContents?.filter(
                            (content) =>
                              content.postVideoStyle === "PROMOTIONAL_VIDEO"
                          )?.length || 0
                      ),
                    ]?.map((item, index) => (
                      <>
                        <div class="input-icon input-icon-right custom-file col-12 w-100">
                          <input
                            type="file"
                            accept="video/mp4,video/x-m4v,video/*"
                            class="custom-file-input"
                            placeholder="Upload your file"
                            id="inputfileupload"
                            onChange={(e) =>
                              setFiles({
                                ...files,
                                promotionalVideoFiles: {
                                  ...setFiles.promotionalVideoFiles,
                                  [index]: e.currentTarget.files[0],
                                },
                              })
                            }
                          />
                          <label
                            class="custom-file-label"
                            for="inputfileupload"
                          >
                            {files?.promotionalVideoFiles &&
                            Object.keys(files?.promotionalVideoFiles)?.includes(
                              `${index}`
                            )
                              ? files?.promotionalVideoFiles[index]?.name
                              : "Choose file"}
                          </label>
                          <span>
                            <img
                              class="mr-1 h-10px mt-n1"
                              src="/media/svg/icons/custom/Icon_upload.svg"
                            />
                          </span>
                        </div>
                        <button
                          class="btn btn-sm btn-primary mt-4"
                          disabled={
                            listLoading ||
                            actionsLoading ||
                            signedLoading ||
                            (Object.keys(files?.promotionalVideoFiles)
                              ?.length === 0
                              ? true
                              : !files?.promotionalVideoFiles?.hasOwnProperty(
                                  index
                                ))
                          }
                          onClick={() => {
                            setClickedButton(`promotionalVideoFiles${index}`);

                            files?.promotionalVideoFiles[index] &&
                              uploadContent(
                                files?.promotionalVideoFiles[index],
                                "VIDEO_POST",
                                null,
                                "PROMOTIONAL_VIDEO"
                              );
                          }}
                        >
                          {clickedButton === `promotionalVideoFiles${index}` &&
                          (actionsLoading || signedLoading) ? (
                            <div class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          ) : (
                            <span>
                              <b>Upload</b>
                            </span>
                          )}
                        </button>
                      </>
                    ))}
                  </div>
                )}

                {/* <<<<<<<<<<<<<<<<<<<<<<<<Unboxing Explanation Video>>>>>>>>>>>>>>>>>>>>>>>> */}
                {campaignDetails?.styleSelectionUnboxingExplanationVideoCount !==
                  "0" && (
                  <div class="form-group input-group">
                    <label>
                      URL Content -{" "}
                      {
                        campaignDetails?.styleSelectionUnboxingExplanationVideoCount
                      }
                    </label>
                    {applicationContents
                      ?.filter(
                        (content) =>
                          content.postVideoStyle ===
                          "UNBOXING_EXPLANATION_VIDEO"
                      )
                      .map((content) => (
                        <GalleryCard
                          content={content}
                          loggedInUserId={loggedInUserId}
                          actionsLoading={actionsLoading}
                          setReload={() => {
                            setFiles({ ...initialFiles });
                            setReload(!reload);
                          }}
                        />
                      ))}
                    {[
                      ...Array(
                        Number(
                          campaignDetails?.styleSelectionUnboxingExplanationVideoCount
                        ) -
                          applicationContents?.filter(
                            (content) =>
                              content.postVideoStyle ===
                              "UNBOXING_EXPLANATION_VIDEO"
                          )?.length || 0
                      ),
                    ]?.map((item, index) => (
                      <>
                        <div class="input-icon input-icon-right custom-file col-12 w-100">
                          <input
                            type="file"
                            accept="video/mp4,video/x-m4v,video/*"
                            class="custom-file-input"
                            placeholder="Upload your file"
                            id="inputfileupload"
                            onChange={(e) =>
                              setFiles({
                                ...files,
                                unboxingExplanationVideoFiles: {
                                  ...setFiles.unboxingExplanationVideoFiles,
                                  [index]: e.currentTarget.files[0],
                                },
                              })
                            }
                          />
                          <label
                            class="custom-file-label"
                            for="inputfileupload"
                          >
                            {files?.unboxingExplanationVideoFiles &&
                            Object.keys(
                              files?.unboxingExplanationVideoFiles
                            )?.includes(`${index}`)
                              ? files?.unboxingExplanationVideoFiles[index]
                                  ?.name
                              : "Choose file"}
                          </label>
                          <span>
                            <img
                              class="mr-1 h-10px mt-n1"
                              src="/media/svg/icons/custom/Icon_upload.svg"
                            />
                          </span>
                        </div>
                        <button
                          class="btn btn-sm btn-primary mt-4"
                          disabled={
                            listLoading ||
                            actionsLoading ||
                            signedLoading ||
                            (Object.keys(files?.unboxingExplanationVideoFiles)
                              ?.length === 0
                              ? true
                              : !files?.unboxingExplanationVideoFiles?.hasOwnProperty(
                                  index
                                ))
                          }
                          onClick={() => {
                            setClickedButton(
                              `unboxingExplanationVideoFiles${index}`
                            );

                            files?.unboxingExplanationVideoFiles[index] &&
                              uploadContent(
                                files?.unboxingExplanationVideoFiles[index],
                                "VIDEO_POST",
                                null,
                                "UNBOXING_EXPLANATION_VIDEO"
                              );
                          }}
                        >
                          {clickedButton ===
                            `unboxingExplanationVideoFiles${index}` &&
                          (actionsLoading || signedLoading) ? (
                            <div class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          ) : (
                            <span>
                              <b>Upload</b>
                            </span>
                          )}
                        </button>
                      </>
                    ))}
                  </div>
                )}
              </div>
              {/* <div class="col-md-6">
                <img
                  src="/media/custom/media-file.png"
                  class="w-100"
                  alt="media-file"
                />
              </div> */}
            </div>

            <button
              class="btn btn-sm btn-primary ml-4"
              disabled={
                clickedButton === `applyForApproval` ||
                actionsLoading ||
                signedLoading ||
                getTotalMediaContentRequest(mediaCount) !==
                  applicationContents?.length
              }
              onClick={() => {
                setClickedButton("applyForApproval");
                submitAllContentForApproval();
              }}
            >
              {clickedButton === `applyForApproval` &&
              (actionsLoading || signedLoading) ? (
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                <span>
                  <b>Apply For Approval</b>
                </span>
              )}
            </button>
          </div>
        )}
      </div>
      {modal && (
        <CommonModal
          modalTitle={"View Content"}
          modalBody={modal?.content}
          show={modal.status}
          close={() => setModal({ status: false, content: null })}
          htmlRenderRequired={false}
          modalSize={"xl"}
        />
      )}
      {downloaderComponentUI}
      {}
    </div>
  );
};

export default memo(ContentDetails);
