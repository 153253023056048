/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Routes } from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

export default function App({ store, persistor, basename }) {

  // (function (w, d, s, u) {
  //   w.gbwawc = {
  //   url: u,
  //   options: {
  //           waId: "+91 9526151000",
  //           siteName: "1000X",
  //           siteTag: "Business Account",
  //           siteLogo: process.env.REACT_APP_1000X_LOGO,
  //           widgetPosition: "RIGHT",
  //           welcomeMessage: "Hello, how can we help you?",
  //           brandColor: "#25D366",
  //           // messageText: "I need",
  //           // replyOptions: ['Support'],
  //       },
  //   };
  //   var h = d.getElementsByTagName(s)[0],
  //   j = d.createElement(s);
  //   j.async = true;
  //   j.src = u + "/whatsapp-widget.min.js?_=" + Math.random();
  //   h.parentNode.insertBefore(j, h);
  //   })(window, document, "script", "https://waw.gallabox.com");

  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
            {/* Override `basename` (e.g: `homepage` in `package.json`) */}
            <BrowserRouter basename={basename}>
              {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
              <MaterialThemeProvider>
                {/* Provide `react-intl` context synchronized with Redux state.  */}
                <I18nProvider>
                  {/* Render routes with provided `Layout`. */}
                  {/* <ToastContainer/> */}
                  <Routes />
                </I18nProvider>
              </MaterialThemeProvider>
            </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
