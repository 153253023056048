/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, useState } from "react";
import moment from "moment";
import {
  campaignType,
  getAgeCategoryTotext,
  getFileTypeFromExtension,
  getPlatform,
  getPostType,
  getTargetGenderToText,
  getVideoLength,
  checkCampaignContentRequest
} from "../helper/helper";
import { applyForCampaign } from "../../campaign/_redux/campaignActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CampaignPreview from "./CampaignPreview";
import "suneditor/dist/css/suneditor.min.css";
import { productCategoryName } from "../../CampaignV2/helperLabel";
import { setRightSideBar } from "../../_redux/commonActions";

const SummaryTab = ({ campaign, isActive, setRefresh }) => {
  const history = useHistory();
  const [modal, setModal] = useState({
    status: false,
    modalTitle: "",
    modalBody: "",
  });

  const [guidelinePreview, setGuidelinePreview] = useState(false);
  const [campaignAppliedFlag, setCampaignAppliedFlag] = useState(false);
  
  const dispatch = useDispatch();
  const {
    loggedInUserId,
    listLoading,
    actionsLoading,
    applicationDetails,
    socket,
  } = useSelector(
    (state) => ({
      loggedInUserId: state.auth.user?.userId,
      listLoading: state.campaign.listLoading,
      actionsLoading: state.campaign.actionsLoading,
      applicationDetails: state.campaign.applicationDetails,
      socket: state.common.socket,
    }),
    shallowEqual
  );



  // server request for apply campaign
  const applyForThisCampaign = (navigateFlag) => {
    if(navigateFlag == null){
      navigateFlag = true;
    }
    let { brandId, campaignId, campaignName } = campaign;

    if (loggedInUserId) {
      dispatch(
        applyForCampaign({
          loggedInUserId,
          campaignId,
          brandId,
          creatorId: loggedInUserId,
        })
      ).then((res) => {
        if(navigateFlag){
          let metaData = {
            message: `New application submitted for ${campaignName}`,
            notfyHead: "New Application",
          };
  
          socket.emit("sentDataToRoom", brandId, "notificationService", metaData);
          if (res) {
            history.push(
              `/campaigns/list/${res.data.campaignId}/view?tab=engagements`
            );
            setRefresh();
          } else {
            history.push("/user-profile/personal-information");
          }
        }
      });
    } else {
      localStorage.setItem("reloadUrl", window.location.pathname);
      window.location.replace("/auth/login");
    }
  };

  return (
    <div
      className={`tab-pane ${isActive ? "active" : "d-none"}`}
      id="summary"
      role="tabpanel"
    >

      {!guidelinePreview && (
        <div className=
        {checkCampaignContentRequest(campaign)?
          "card card-custom card-stretch gutter-b shadow-none mb-0 pt-5"
        :
        "card card-custom card-stretch gutter-b shadow-none mb-0"}
        >
          <div class="card-toolbar">
            <div class="d-flex flex-nowrap d-flex justify-content-between">
              <div class="d-flex align-items-center mb-0">
                <div class="symbol symbol-30 symbol-light-success ml-0">
                  <div class="bg-special-success mr-2 h-40px w-40px p-0 d-flex justify-content-center align-items-center">
                    <img
                      class="mr-0 h-20px"
                      src="/media/svg/icons/custom/Icon_calendar.svg"
                      alt="calendar-icon"
                    />
                  </div>
                </div>
                <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                  <div class="text-dark mb-0 font-size-lg font-weight-bolder text-left text-truncate text-dark-75">
                    {moment(new Date(campaign?.campaignClosureTime)).format(
                      "MMMM DD, YYYY"
                    )}
                  </div>
                  <span class="text-dark-50 font-size-xs text-left">
                    Last date
                  </span>
                </div>
              </div>
              {campaign.socialMediaBrandName &&
                campaign.socialMediaBrandName !== null &&
                campaign.socialMediaBrandLogo !== null && (
                  <div class="d-flex flex-nowrap d-flex flex-center">
                    <div class="text-right d-flex flex-wrap text-right justify-content-end">
                        <div class="d-flex flex-column flex-grow-1 font-weight-bold">{campaign.socialMediaBrandName}</div>
                        <div class="text-muted font-size-sm d-flex w-100 justify-content-end t-plc s-l">{campaignType(campaign?.campaignType)}</div>
                    </div>
                    <div class="symbol symbol-30 symbol-light-success ml-2">
                        <div class="h-40px w-40px p-0 d-flex justify-content-center align-items-center"><img class="mr-0 h-100" src={
                            process.env.REACT_APP_AWS_S3_BASE_URL +
                            campaign.socialMediaBrandLogo
                          } alt=""/></div>
                    </div>
                  </div>
                )}
            </div>
          </div>
          {checkCampaignContentRequest(campaign)
                      ? 
          <div className="card-body p-0">
            <div className="row  mt-4 ">
              <div className="col-md-6">
                <div className="w-100 bg-light-primary rounded p-5 mb-4">
                  <h4 className="font-weight-bolder">Features</h4>

                  {/* <<<<<<<<<<<<<<<<<<<<<<<<<deliverable count status>>>>>>>>>>>>>>>> */}
                  <ul className="list_item m-0 p-0 font-weight-Medium">
                    {campaign?.productCategory && (
                      <li class="font-size-sm">
                        <img
                          class="mr-3"
                          src="/media/svg/icons/custom/list_icon.svg"
                          alt="list-icon"
                        />
                        {productCategoryName(campaign?.productCategory)}
                      </li>
                    )}

                    {campaign?.postType && (
                      <li class="font-size-sm">
                        <img
                          class="mr-3"
                          src="/media/svg/icons/custom/list_icon.svg"
                          alt="list-icon"
                        />
                        Post Type - {getPostType(campaign?.postType)}
                      </li>
                    )}

                    {campaign?.campaignType && (
                      <li class="font-size-sm">
                        <img
                          class="mr-3"
                          src="/media/svg/icons/custom/list_icon.svg"
                          alt="list-icon"
                        />
                        Campaign Type - {campaignType(campaign?.campaignType)}
                      </li>
                    )}

                    {campaign?.usedInPlatform && (
                      <li class="font-size-sm">
                        <img
                          class="mr-3"
                          src="/media/svg/icons/custom/list_icon.svg"
                          alt="list-icon"
                        />
                        Platform - {getPlatform(campaign?.usedInPlatform)}
                      </li>
                    )}

                    {campaign?.videoLength && (
                      <li class="font-size-sm">
                        <img
                          class="mr-3"
                          src="/media/svg/icons/custom/list_icon.svg"
                          alt="list-icon"
                        />
                        Video length - {getVideoLength(campaign?.videoLength)}
                      </li>
                    )}

                    {campaign?.targetGender && (
                      <li class="font-size-sm">
                        <img
                          class="mr-3"
                          src="/media/svg/icons/custom/list_icon.svg"
                          alt="list-icon"
                        />
                        Gender - {getTargetGenderToText(campaign?.targetGender)}
                      </li>
                    )}

                    {campaign?.targetAgeCategory && (
                      <li class="font-size-sm">
                        <img
                          class="mr-3"
                          src="/media/svg/icons/custom/list_icon.svg"
                          alt="list-icon"
                        />
                        Age Category -{" "}
                        {getAgeCategoryTotext(campaign?.targetAgeCategory)}
                      </li>
                    )}
                  </ul>
                </div>
                {checkCampaignContentRequest(campaign) //Other Count
                      ? 
                    <div class="w-100 py-2">
                      <div class="flex-grow-1">
                        <div class="flex-grow-1">
                            <div class="d-flex align-items-center mb-0">
                                <div class="symbol symbol-30 symbol-light-success mr-2">
                                    <div class="btn border-0 bg-special-warning mr-2 h-40px w-40px p-0 d-flex justify-content-center align-items-center ">
                                      <img class="mr-0 h-20px" src="/media/svg/icons/custom/revisions.svg"/>
                                    </div>
                                </div>
                                <div class="d-flex flex-column flex-grow-1 font-weight-bold ml-1">
                                    <div class="text-dark text-hover-primary mb-0 font-size-sm font-weight-bold  text-truncate"> {campaign?.revisionCount >0? campaign?.revisionCount:3}
                                    </div><span class="text-dark-50 font-size-xs">No of Revisions</span>
                                </div>
                            </div>
                        </div>
                      </div>
                  </div>
                  :null
                  }

                {/* >>>>>>>>>>>>>>>>>>>>>>styleGuideReferenceLinks>>>>>>>>>>>>>start */}
                {/* <div className="w-100 mt-4 rounded">
                <h6 class="font-weight-bolder font-size-lg">Tags based on</h6>
                <div class="col-12 px-0">
                  <span class="label label-inline label-primary mr-3">
                    Food
                  </span>
                  <span class="label label-inline label-primary">
                    Technology
                  </span>
                </div>
              </div> */}

                {/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>References started<<<<<<<<<<<<<<<<<<< */}
                {(campaign?.styleGuideReferenceLinks?.length > 0 ||
                  campaign?.styleGuideReferenceFiles?.length > 0) && (
                  <div className="w-100 border mt-4 rounded p-5">
                    <h4 className="font-weight-Bolder">References</h4>
                    {campaign?.styleGuideReferenceLinks?.map((website) => (
                      <div className="w-100 d-flex flex-nowrap">
                        <a
                          onClick={() => window.open(website)}
                          class="btn btn-icon btn-light btn-sm p-4"
                        >
                          <span class="svg-icon-1x">
                            <svg
                              width="17.347"
                              height="17.331"
                              viewBox="0 0 17.347 17.331"
                            >
                              <g transform="translate(-2.243 -2.258)">
                                <path
                                  d="M15,11.715a3.96,3.96,0,0,0,5.971.428l2.376-2.376a3.959,3.959,0,1,0-5.6-5.6L16.386,5.522"
                                  transform="translate(-5.668)"
                                  fill="none"
                                  stroke="#000"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1.5"
                                ></path>
                                <path
                                  d="M12.5,15.08a3.96,3.96,0,0,0-5.971-.428L4.153,17.028a3.959,3.959,0,1,0,5.6,5.6l1.354-1.355"
                                  transform="translate(0 -4.949)"
                                  fill="none"
                                  stroke="#000"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1.5"
                                ></path>
                              </g>
                            </svg>
                          </span>
                        </a>
                        <a
                          onClick={() => window.open(website)}
                          className="text-truncate text-primary pt-3 mb-0 pl-2 cursor-pointer"
                        >
                          {website}
                        </a>
                      </div>
                    ))}

                    {/* >>>>>>>>>>>>>>>>>>>>>>styleGuideReferenceLinks>>>>>>>>>>>>>end */}

                    {/* >>>>>>>>>>>>>>>>>.Gallery start<<<<<<<<<<<<<<<<<< */}

                    <div class="media mt-5 w-100 row mx-0 overflow-hidden rounded">
                      {campaign?.styleGuideReferenceFiles?.map((file) => (
                        <div
                          class="col-4 p-0 cursor-pointer"
                          onClick={() =>
                            window.open(
                              process.env.REACT_APP_AWS_S3_BASE_URL + file
                            )
                          }
                        >
                          <img
                            src={
                              getFileTypeFromExtension(file) === "image"
                                ? process.env.REACT_APP_AWS_S3_BASE_URL + file
                                : getFileTypeFromExtension(file) === "document"
                                ? "/media/dummyImages/pdf_prview.png"
                                : "/media/custom/video-icon.png"
                            }
                            class="w-100"
                            alt={file}
                          />
                        </div>
                      ))}
                    </div>

                    {/* >>>>>>>>>>>>>>>>>>>>>>>gallery end<<<<<<<<<<<<<<<<<<<<<<< */}
                  </div>
                )}
              </div>
              <div className="col-md-6">
                <div className="w-100 rounded p-0">
                  <h4 className="font-weight-Bolder">Campaign Goals</h4>
                  <div className="w-100 bg-light-primary rounded p-3">
                    {campaign?.aboutCampaign}
                  </div>
                  {/*  <p class="font-size-sm">
                  Below mentioned the campaign deliverables, creation style and
                  content types with count
                </p> */}

                  {/* <<<<<<<<<<<<<<<<<<Image Count starts>>>>>>>>>>>>>>>>> */}

                  {checkCampaignContentRequest(campaign)
                      ? 
                      <div className="w-100 mt-10">
                        <h5 className="font-weight-bolder font-size-lg">
                          Required Deliverables
                        </h5>
                        <div className="row col-md-12 w-100 p-0 m-0">
                          {campaign?.styleSelectionPortraitStylePhotographyCount !==
                            "0" && (
                            <div class="col-md-6 d-flex px-2 border-bottom pb-4">
                              <div class="col-auto p-0">
                                <div class="text-primary font-weight-bold font-size-lg h1 mb-0">
                                  {
                                    campaign?.styleSelectionPortraitStylePhotographyCount
                                  }
                                </div>
                                <div class="text-muted font-size-xs mt-n2">
                                  Document
                                </div>
                              </div>
                              <div class="col p-0 font-size-sm font-weight-bolder pt-1 pl-2">
                                Document Content
                              </div>
                            </div>
                          )}
                          {campaign?.styleSelectionGraphicDesignCount !== "0" && (
                            <div class="col-md-6 d-flex px-2 border-bottom pb-4">
                              <div class="col-auto p-0">
                                <div class="text-primary font-weight-bold font-size-lg h1 mb-0">
                                  {campaign?.styleSelectionGraphicDesignCount}
                                </div>
                                <div class="text-muted font-size-xs mt-n2">
                                  Image
                                </div>
                              </div>
                              <div class="col p-0 font-size-sm font-weight-bolder pt-1 pl-2">
                                Image Content
                              </div>
                            </div>
                          )}
                          {campaign?.styleSelectionActionProductInUseCount !==
                            "0" && (
                            <div class="col-md-6 d-flex px-2 border-bottom pb-4">
                              <div class="col-auto p-0">
                                <div class="text-primary font-weight-bold font-size-lg h1 mb-0">
                                  {campaign?.styleSelectionActionProductInUseCount}
                                </div>
                                <div class="text-muted font-size-xs mt-n2">
                                  Video
                                </div>
                              </div>
                              <div class="col p-0 font-size-sm font-weight-bolder pt-1 pl-2">
                                Video Content
                              </div>
                            </div>
                          )}
                          {campaign?.styleSelectionLifestyleProductInActionCount !==
                            "0" && (
                            <div class="col-md-6 d-flex px-2 border-bottom pb-4">
                              <div class="col-auto p-0">
                                <div class="text-primary font-weight-bold font-size-lg h1 mb-0">
                                  {
                                    campaign?.styleSelectionLifestyleProductInActionCount
                                  }
                                </div>
                                <div class="text-muted font-size-xs mt-n2">
                                  Audio
                                </div>
                              </div>
                              <div class="col p-0 font-size-sm font-weight-bolder pt-1 pl-2">
                                Audio Content
                              </div>
                            </div>
                          )}
                          {campaign?.styleSelectionPromotionalVideoCount !==
                            "0" && (
                            <div class="col-md-6 d-flex px-2 border-bottom pb-4">
                              <div class="col-auto p-0">
                                <div class="text-primary font-weight-bold font-size-lg h1 mb-0">
                                  {campaign?.styleSelectionPromotionalVideoCount}
                                </div>
                                <div class="text-muted font-size-xs mt-n2">
                                  Other files
                                </div>
                              </div>
                              <div class="col p-0 font-size-sm font-weight-bolder pt-1 pl-2">
                                Other Files
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                   :null
                  }
                  <div className="row mt-6 justify-content-end mr-1">
                  <div className={`d-flex flex-row`}>
                      {campaign?.styleGuideLine?.length > 200 ? (
                        <p class="font-size-sm">
                          {campaign?.styleGuideLine?.length > 200 && (
                            <button
                              className="btn float-right"
                              style={{ backgroundColor: "#CDF3FC" }}
                              onClick={() => {
                                setModal({
                                  // status: true,
                                  modalSize: "xl",
                                  htmlRenderRequired: true,
                                  modalTitle: "Campaign details and guidelines",
                                  modalBody: campaign?.styleGuideLine,
                                });
                                setGuidelinePreview(!guidelinePreview);
                              }}
                            >
                              {`${
                                guidelinePreview
                                  ? "Hide Details"
                                  : "Show Details"
                              }`}
                            </button>
                          )}
                        </p>
                      ) : (
                        <p
                          class="font-size-sm"
                          dangerouslySetInnerHTML={{
                            __html: campaign?.styleGuideLine?.replace(
                              // eslint-disable-next-line no-control-regex
                              new RegExp("\n", "g"),
                              "<br/>"
                            ),
                          }}
                        ></p>
                      )}

                    {/*Hide the Sumbit button and involved based on some actions*/}
                    { checkCampaignContentRequest(campaign)
                        ? 
                        <button
                          className={`btn btn-primary mb-4 float-right ml-5`}
                          disabled={
                            applicationDetails?.length > 0 ||
                            actionsLoading ||
                            listLoading
                          }
                          onClick={() => applyForThisCampaign()}
                        >
                          <img
                            class="mr-0 h-10px mr-2"
                            src="/media/svg/icons/custom/Icon_done.svg"
                            alt="apply-icon"
                          />
                          {listLoading || actionsLoading ? (
                            <span className="ml-3 mr-3 spinner spinner-white spinner-border-sm">
                              Loading...
                            </span>
                          ) : applicationDetails?.length > 0 ? (
                            "Applied"
                          ) : (
                            "Apply Now"
                          )}
                        </button>
                      :null
                    }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        :
        <CampaignPreview
              // show={modal?.status}
              htmlRenderRequired={true}
              modalSize="xl"
              showHeader={false}
              close={() => setModal({ status: false })}
              modalTitle={"Campaign details and guidelines"}
              modalBody={campaign?.styleGuideLine}
              setGuidelinePreview={true}
              campaign={campaign}
              applyForThisCampaign = {applyForThisCampaign}
              campaignAppliedFlag = {campaignAppliedFlag || applicationDetails?.length > 0}
              setCampaignAppliedFlag = {setCampaignAppliedFlag}
            />
        }  
        </div>
      )}
      <div class="mt-0">
        {guidelinePreview && (
          <CampaignPreview
            // show={modal?.status}
            htmlRenderRequired={modal?.htmlRenderRequired}
            modalSize="xl"
            showHeader={true}
            close={() => setModal({ status: false })}
            modalTitle={modal?.modalTitle}
            modalBody={modal?.modalBody}
            setGuidelinePreview={setGuidelinePreview}
            campaign={campaign}
            applyForThisCampaign = {applyForThisCampaign}
            campaignAppliedFlag = {campaignAppliedFlag || applicationDetails?.length > 0}
            setCampaignAppliedFlag = {setCampaignAppliedFlag}
          />
        )}
      </div>
    </div>
  );
};

export default memo(SummaryTab);
