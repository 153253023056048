import * as Yup from "yup";

export const RegistrationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    // .matches(phoneRegExp, "Phone number is not valid")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Enter your contact number"),
  // loginEmail: Yup.string()
  //   .email("Wrong email format")
  //   .min(3, "Minimum 3 symbols")
  //   .max(50, "Maximum 50 symbols")
  //   .required("Enter your Email-id "),
  // workEmail: Yup.string()
  //   .email("Wrong email format")
  //   .min(3, "Minimum 3 symbols")
  //   .max(50, "Maximum 50 symbols")
  //   .required(),
  phoneCode: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Phone code is required"),
  firstName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .matches(/^([^0-9]*)$/, "digits not allowed")
    .required("Name Required"),
  // lastName: Yup.string()
  //   .min(2, "Minimum 2 symbols")
  //   .max(50, "Maximum 50 symbols")
  //   .required("Enter your last name"),
  // password: Yup.string().min(8, "Password length must be atleast 8 characters")
  //   .max(25, "Exceeds maximum Password length")
  //   .required("Enter your password"),
  // changepassword: Yup.string()
  //   .required("Confirm your password")
  //   .when("password", {
  //     is: (val) => (val && val.length > 0 ? true : false),
  //     then: Yup.string().oneOf(
  //       [Yup.ref("password")],
  //       "Password and Confirm Password didn't match"
  //     ),
  //   }),
  // acceptTerms: Yup.bool().required("You must accept the terms and conditions"),
  // creatorType: Yup.string().required("Creator type required"),
  // instagramId: Yup.string(),
  // youtubeId: Yup.string(),
  // age: Yup.string().required("Enter your age "),
  // gender: Yup.string().required("Select gender"),
  // expectedReward: Yup.string().required("ExpectedReward Required"),
  // category: Yup.string().required("Category Required"),
  // shippingUserName: Yup.string().required("Shipping Username Required"),
  // shippingAddress: Yup.string().required("Shipping Address Required"),
  // shippingCity: Yup.string().required("Shipping City Required"),
  // shippingState: Yup.string().required("Shipping State Required"),
  // shippingPincode: Yup.string().required("Shipping Pincode Required"),
  // shippingCountry: Yup.string().required("Shipping Country Required"),
});
